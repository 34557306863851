import React, { useState, useEffect, useContext, useRef } from "react";
import { Container, Row, Button, Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import { addCategory } from "../controller/Api"
import { getEquipments } from "../controller/Api"
import { deleteCategory } from "../controller/Api"
import { editCategory } from "../controller/Api"
import { CartContext } from "../context/CartContext";

function Services() {
  const { getCategoryData, setgetCategoryData } = useContext(CartContext);
  const [inputShow, setInputShow] = useState(false);
  const [infoModel, setInfoModel] = useState(false);
  const [editModel, setEditModel] = useState(false);
  const [deleteModel, setDeleteModel] = useState(false);
  const [id, setId] = useState();
  const [inputFiled, setinputFiled] = useState(false);
  const [hide, setHide] = useState(false);
  const ref = useRef(null);
  const [categoryData, setCategoryData] = useState({
    name: ""
  });

  const inputChange = (e) => {
    const { name, value } = e.target;
    setCategoryData((oldVal) => {
      return {
        ...oldVal,
        [name]: value,
      };
    });
  };

  useEffect(() => {
    document.title = "Music Booth | Booths"
  }, []);
  //post api

  const addCategoryData = async () => {
    if (categoryData.name.trim() === "") {
      toast.error("Studio Category is required");
      return;
    }
    // Check for duplicate value
    const isDuplicate = getCategoryData.some(
      (existingCategory) => existingCategory.name.trim() === categoryData.name.trim()
    );
    if (isDuplicate) {
      toast.error("Duplicate value, please enter a different value");
      return;
    }

    try {
      const result = await addCategory(categoryData);
      const { status } = result;
      if (status === 1) {
        setGetData();
        setCategoryData({ name: "" });
      }
    } catch (error) {
      toast.error(error);
    }
  };

  //Enter key work--------------------
  useEffect(() => {
    const callback = (event) => {
      if (event.key === 'Enter') {
        event.preventDefault(); // Prevent the default behavior of the Enter key
        ref.current?.click();
      }
    };
    document.addEventListener('keydown', callback);
    return () => {
      document.removeEventListener('keydown', callback);
    };
  }, []);

  //get api
  const setGetData = async () => {
    const result = await getEquipments();
    const { data, status } = result;
    if (status === 1) {
      setgetCategoryData(data);
    }
  }
  useEffect(() => {
    setGetData();
  }, [setgetCategoryData])

  // delete api
  const deleteApi = async (id) => {
    try {
      const deleteData = await deleteCategory(id);
      const { data, message, status } = deleteData;
      if (status === 1) {
        toast.success("Deleted Successfully");
        setGetData();
        setDeleteModel(false);
      }
    } catch (error) {
      toast.error(error);
    }
  }

  const editDataa = (ele) => {
    setId(ele._id);
    setCategoryData({ name: ele.name });
    setinputFiled(true);
  }

  const editCategoryData = async (id) => {
    if (categoryData.name.trim() === "") {
      toast.error("Studio Category is required");
      return;
    }
    const isDuplicate = getCategoryData.some(
      (existingCategory) => existingCategory.name.trim() === categoryData.name.trim()
    );
    if (isDuplicate) {
      toast.error("Duplicate value, please enter a different value");
      return;
    }
    try {
      const result = await editCategory(id, categoryData);
      const { status, data, message } = result;
      if (status === 1) {
        setCategoryData(data.name);
        setGetData();
        setCategoryData({ name: "" });
        toast.success("Studio category updated successfully.");
        setHide(true);
      }
    } catch (error) {
      toast.error(error);
    }
  }
  return (
    <>
      <section>
        <Container fluid>
          <Row className="">
            {/* <Col */}
            {/* // xs={12}
              // md={12}
              // lg={3}
              // xl={2}
              // className="shadow-right d-none d-lg-block"
            > */}
            {/* <Sidbar />  */}
            {/* </Col> */}

          </Row>
        </Container>
      </section>

      {/* info model====================================================INFO */}

      <Modal
        show={infoModel}
        onHide={() => setInfoModel(false)

        }
        centered
        className="moadl-main info-modal"
      >
        <Modal.Header
          className="d-block position-relative border-0 pb-0"
          closeButton
        >
          <Modal.Title className="text-center">Booths category info</Modal.Title>
        </Modal.Header>
        <Modal.Body className="mx-md-4 pt-0 border-0 bg-transparent">
          <div className="position-relative">
            <div className="category edit-modal mt-3">
              <div className="category-btn">
                {getCategoryData.length > 0 ? (
                  // Render category data if the length is greater than 0
                  getCategoryData.map((ele, index) => (
                    <div key={index} className="closeSpan">
                      <span>{ele.name}</span>
                    </div>
                  ))
                ) : (
                  <div className="">
                    <h4 className="mt-3 m-auto text-center pt-1">Category not found</h4>
                  </div>
                )}
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      {/* edit model =======================================
      ====================EDIT CATEGORORY */}
      <Modal
        show={editModel}
        onHide={() => {
          setEditModel(false);
          setCategoryData({
            name: ""
          });
          setInputShow(false);
          setinputFiled(false);
        }}

        centered
        size=""
        className="moadl-main info-modal"
      >
        <Modal.Header
          className="d-block position-relative border-0 pb-0"
          closeButton
        >
          <Modal.Title className="text-center">Booths Category</Modal.Title>
        </Modal.Header>
        <Modal.Body className="mx-md-4 pt-0 border-0 bg-transparent ">
          <div class="position-relative">
            <div class="category edit-modal mt-3">
              <div class="d-flex justify-content-between mb-3 align-items-center">
                <h5></h5>

                <div
                  className="cursor-pointer"
                  onClick={() => {
                    if (inputShow) { setInputShow(false); }
                    else { setInputShow(true); setHide(true); }
                  }}
                >
                  <img src="../images/svg/add.svg" className="add-btn" alt="" />
                </div>

              </div>
              {
                Boolean(inputShow) && (
                  <div class="def-edit mb-4">
                    <input
                      type="text"
                      name="name"
                      placeholder="Enter service name"
                      value={categoryData.name}
                      onChange={inputChange}
                    />
                  </div>
                )
              }
              <div class="category-btn">
                {
                  getCategoryData?.map((ele, index) => {
                    return (
                      <div class="closeSpan">
                        <span>
                          {ele.name}
                          &nbsp;
                          <button class="closeBtn" onClick={() => {
                            setHide(false);
                            if (!inputShow) setInputShow(true);
                            editDataa(ele);
                          }}
                          >
                            <img height={18} width={18} src="/images/png/model-edit.png" />
                          </button>
                          &nbsp;
                          <button class="closeBtn"
                            onClick={() => {
                              setDeleteModel(true);
                              setId(ele._id);
                              setEditModel(false);
                            }}>
                            <img src="/images/svg/delete.svg" />
                          </button>
                        </span>
                      </div>
                    );
                  })
                }
              </div>
            </div>
          </div>
          <div class="edit-btn-main mt-3 d-flex align-items-center justify-content-end gap-2">

            {hide ?
              <button ref={ref} className='secondary-button text-decoration-none' onClick={addCategoryData}>
                Add
              </button> : ""}
            {
              inputFiled ?
                !hide ?
                  <button ref={ref} type="button" class="secondary-button text-decoration-none" onClick={() => editCategoryData(id)}>
                    Edit
                  </button>
                  : ""
                : ""
            }
          </div>
        </Modal.Body>
      </Modal>

      {/* Delete model */}

      <Modal
        size="md"
        show={deleteModel}
        onHide={() => setDeleteModel(false)}
        centered
        className="moadl-main delete-modal" >
        <Modal.Header
          className="d-block position-relative border-0"
          closeButton >
          <Modal.Title className="text-center">Delete Category</Modal.Title>
        </Modal.Header>
        <Modal.Body className="mx-4 border-0">
          <p>Are you sure you want to delete?</p>
        </Modal.Body>
        <Modal.Footer className="border-0">
          <div className="d-flex m-auto gap-2 pb-3">
            <Button
              className="secondary-button"
              onClick={() => deleteApi(id)} >
              Delete
            </Button>
            <Button
              className="primary-button"
              onClick={() => {
                setDeleteModel(false);
                setEditModel(true);
              }} >
              Cancel
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default Services;
