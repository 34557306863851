export const validate = (logindata) => {
    const errors = {};
    // validation email
    if (!logindata.email) {
        errors.email = 'Email is required';
    } else if (!/\S+@\S+\.\S+/.test(logindata.email)) {
        errors.email = '*Invalid email address';
    }

    if (!logindata.password) {
        errors.password = 'Password is required';
    } else if (logindata.password.length < 8) {
        errors.password = '*Password must be at least 8 characters long';
    }
    return errors;
}
