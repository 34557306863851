import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { BiSortAlt2 } from 'react-icons/bi';

const HelpAndSupportTable = () => {
    // Function to open WhatsApp in new tab
    const openWhatsApp = () => {
        window.open('https://api.whatsapp.com/send?phone=8523658965', '_blank');
    };

    // Function to open Telegram in new tab
    const openTelegram = () => {
        window.open('https://telegram.me/YOUR_TELEGRAM_USERNAME_HERE', '_blank');
    };

    return (
        <Col xs={12} className="position-relative px-md-5">
            <img src="../images/png/bg-imag.png" className="bg-imag" alt="" />
            <Row className="justify-content-between align-items-center pb-3">
                <Col sm={12} className='support-banner'>
                    <h4>Support Center</h4>
                </Col>
                <Col xl={4} md={6} lg={5}>
                    <div className='contact-box' >
                        <h3>Whatsapp</h3>
                        <img
                            src="./images/png/whatsapp.png"
                            className='cursor-pointer'
                            style={{ width: "80px", height: "80px" }}
                            onClick={openWhatsApp}
                            alt="WhatsApp"
                        />
                    </div>
                </Col>
                <Col xl={4} md={6} lg={5}>
                    <div className='contact-box'>
                        <h3>Telegram</h3>
                        <img
                            src="./images/png/telegram.png"
                            className='cursor-pointer'
                            style={{ width: "80px", height: "80px" }}
                            onClick={openTelegram}
                            alt="Telegram"
                        />
                    </div>
                </Col>
                <Col xl={4} md={6} lg={5}>
                    <h5 style={{marginTop: "40px", fontWeight: "600", fontSize: "22px"}} className='border-bottom pb-2'>Contact:</h5>
                    <div className="mt-3">
                        <div>
                            <label className="txtSubHeading">Contact Number</label>:
                            <p className='aboutText'>971 585681158</p>
                        </div>
                        <div>
                            <label className="txtSubHeading">Email Id</label>
                            <p className='aboutText'>contact@admiralworld.digital</p>
                        </div>
                    </div>
                </Col>
            </Row>
        </Col>
    );
}

export default HelpAndSupportTable;
