import React, { useState, useEffect } from "react";
import { Container, Row, Col, Table, Form, Button } from "react-bootstrap";
import Sidbar from "../layout/Sidbar";
import Header from "../layout/Header";
import { useNavigate } from "react-router-dom";
import { addContactus } from "../controller/Api";
import { editContactus } from "../controller/Api";
import { toast } from "react-toastify";
import Loader from "../components/Loader";

const ContactUs = () => {
  const navigate = useNavigate();
  const [editMode, setEditMode] = useState(false);
  const [editId, setEditId] = useState();
  const [loading, setLoading] = useState(false);
  const [editData, setEditData] = useState({
    name: "",
    phone_no: "",
    email: "",
    location: {
      lat: "",
      lng: ""
    }
  });

  const onchange = (e) => {
    const { name, value } = e.target;
    if (name === "lat" || name === "lng") {
      setEditData((oldVal) => ({
        ...oldVal ,
        location: {
          ...oldVal.location,
          [name]: value,
        },
      }));
    } else {
      setEditData((oldVal) => ({
        ...oldVal,
        [name]: value,
      }));
    }
  };

  const getData = async () => {
    try {
      setLoading(true);
      const result = await addContactus();
      const { status, message, data } = result;
      if (status === 1) {
        setEditData(data?.[0]?.head_quarter);
        setEditId(data[0]?._id)
      } 
    } catch (error) {
      toast.error(error);
    }
    setLoading(false);
  };

  const EditContact = async () => {
    try {
      setLoading(true);
      const result = await editContactus(editId, { head_quarter: editData });
      const { status, data, message } = result;
      if (status === 1) {
        toast.success(message);
        getData();
      } else {
        toast.error("error");
      }
    } catch (error) {
      toast.error(error);
    }
    setLoading(false);
  };

  useEffect(() => {
    getData();
  }, []);

  useEffect(() => {
    document.title = "Music Booth | Contact Us"
  }, []);

  const cancelItem = () => {
    setEditMode(false);
    getData();
  }

  return (
    <>
      {loading && <Loader />}
      <section>
        <Container fluid>
          <Row className="vh-100">
            <Col
              xs={12}
              md={12}
              lg={3}
              xl={2}
              className="shadow-right d-none d-lg-block"
            >
              <Sidbar />
            </Col>
            <Col xs={12} lg={9} xl={10} className="position-relative px-md-5">
              <Header />
              <img src="../images/png/bg-imag.png" className="bg-imag" alt="" />
              <Row className="justify-content-between align-items-center pb-3">
                <Col md={6} lg={5} xl={7}>
                  <h1 className="title">Contact US</h1>
                </Col>
                <Col className="text-end">
                  <div className="contact-us">
                    <button
                      className="mt-2 secondary-button contactEditLayout me-2"
                      onClick={(e) => {
                        e.preventDefault();
                        setEditMode(!editMode);
                        if (editMode) EditContact();
                      }}
                    >
                      {editMode ? "SAVE" : "EDIT"}
                    </button>
                    {
                      editMode === true ?
                        <button
                          className="mt-2 secondary-button contactEditLayout"
                          onClick={cancelItem} >
                          Cancel
                        </button>
                        : ""
                    }
                  </div>
                </Col>

                <p className="fs-18 fw-500">
                  For your comfort and ease to reach us, We are open 7 days a
                  week, We are looking forward to welcoming you.
                </p>

                <div className="all-titles contact pad-cos info-modal contact-us">
                  <h2 className="mt-4 txtMainHeading">Our Address</h2>

                  <div className="def-cos mt-3 user-data d-flex gap-1 flex-column">
                    <div>
                      <label className="txtSubHeading">Head Quarter</label>
                    </div>
                    <input
                      className={`w-100 bg-transparent ${editMode ? 'border-dark border-2 border' : ''}`}
                      type="text"
                      value={editData?.name}
                      onChange={onchange}
                      name="name"
                      placeholder="Address"
                      disabled={!editMode}
                    />
                    <input
                      className={`w-100 bg-transparent ${editMode ? 'border-dark border-2 border' : ''}`}
                      type="text"
                      value={editData?.phone_no}
                      onChange={onchange}
                      name="phone_no"
                      placeholder="Phone no"
                      disabled={!editMode}
                    />
                    <input
                      className={`w-100 bg-transparent ${editMode ? 'border-dark border-2 border' : ''}`}
                      type="text"
                      value={editData?.email}
                      name="email"
                      placeholder="Head "
                      onChange={onchange}
                      disabled={!editMode}
                    />
                    <input
                      className={`w-100 bg-transparent ${editMode ? 'border-dark border-2 border' : ''}`}
                      type="number"
                      value={editData?.location?.lat}
                      onChange={onchange}
                      name="lat"
                      placeholder="Latitude"
                      disabled={!editMode}
                    />
                    <input
                      className={`w-100 bg-transparent ${editMode ? 'border-dark border-2 border' : ''}`}
                      type="number"
                      value={editData?.location?.lng}
                      onChange={onchange}
                      name="lng"
                      placeholder="Longitude"
                      disabled={!editMode}
                    />
                  </div>
                </div>
              </Row>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default ContactUs;
