import React, { useContext, useEffect, useRef, useState } from 'react'
import { Col, Container, Form, Modal, Row, Table, Tooltip } from 'react-bootstrap';
import Sidbar from '../layout/Sidbar';
import Header from '../layout/Header';
import Loader from "../components/Loader";
import PartnerGuideTable from '../components/PartnerGuideTable';


const PartnerGuide = () => {
    return (
        <>
            <section>
                <Container fluid>
                    <Row className="vh-100">
                        <Col
                            xs={12}
                            md={12}
                            lg={3}
                            xl={2}
                            className="shadow-right d-none d-lg-block"
                        >
                            <Sidbar />
                        </Col>
                        <Col xs={12} lg={9} xl={10} className="position-relative px-md-5">
                            <Header />
                            <img src="../images/png/bg-imag.png" className="bg-imag" alt="" />
                            <Row className="justify-content-between align-items-center pb-3">
                                <Col md={6} lg={5} xl={7}>
                                    <h1 className="title">Partner Guide</h1>
                                </Col>
                            </Row>
                            <Row>
                            </Row>
                            <PartnerGuideTable />
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    )
}

export default PartnerGuide