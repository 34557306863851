import React, { useState, useEffect } from "react";
import { Container, Row, Col } from "react-bootstrap";
import Sidbar from "../layout/Sidbar";
import Header from "../layout/Header";
import { getAboutUs } from "../controller/Api";
import { EditAboutUs } from "../controller/Api";
import { toast } from "react-toastify";
import Loader from "../components/Loader";

const AboutUs = () => {
  const [editMode, setEditMode] = useState(false);
  const [aboutData, setAboutData] = useState({});
  const [editData, setEditData] = useState({});
  const [loading, setLoading] = useState(false);

  const getAboutData = async () => {
    try {
      setLoading(true);
      const result = await getAboutUs();
      const { data, status } = result;
      if (status === 1) {
        setAboutData(data);
      } else {
        console.log("error");
      }
    } catch (error) {
      toast.error(error);
    }
    setLoading(false);
  };

  const editAboutData = async () => {
    try {
      const result = await EditAboutUs(editData);
      const { status, message } = result;
      if (status === 1) {
        getAboutData();
        toast.success(message);
      } else {
        toast.success("error");
      }
    } catch (error) {
      toast.error(error);
    }
  };

  const onchange = (e) => {
    setEditData({
      ...editData,
      [e.target.name]: e.target.value,
    });
  };

  const cancelItem = () => {
    setEditMode(false);
    setEditData(aboutData);
  };

  useEffect(() => {
    document.title = "Music Booth | About us"
  }, []);

  useEffect(() => {
    getAboutData();
  }, []);

  return (
    <>
      {loading && <Loader />}
      <section>
        <Container fluid>
          <Row className="vh-100">
            <Col
              xs={12}
              md={12}
              lg={3}
              xl={2}
              className="shadow-right d-none d-lg-block"
            >
              <Sidbar />
            </Col>
            <Col xs={12} lg={9} xl={10} className="position-relative px-md-5">
              <Header />
              <img src="../images/png/bg-imag.png" className="bg-imag" alt="" />
              <Row className="justify-content-between align-items-center pb-3">
                <Col md={6} lg={5} xl={7}>
                  <h1 className="title">About Us</h1>
                </Col>
                <Col className="text-end">
                  <p>
                    <button
                      className="mt-2 secondary-button contactEditLayout me-2"
                      onClick={(e) => {
                        e.preventDefault();
                        setEditMode(!editMode);
                        if (editMode) editAboutData();
                      }}
                    >
                      {editMode ? "SAVE" : "EDIT"}
                    </button>
                    {
                      editMode === true ?
                        <button
                          className="mt-2 secondary-button contactEditLayout"
                          onClick={cancelItem}
                        >
                          Cancel
                        </button>
                        : ""
                    }
                  </p>
                </Col>
              </Row>
              <div className="all-titles mt-3 position-relative bg-transparent pt-0">
                <textarea
                  className={`w-100 bg-transparent about ${editMode ? 'border-dark border-2 border' : ''} `}
                  name={"about"}
                  rows={24}
                  onChange={onchange}
                  type="text"
                  value={editData?.about ?? aboutData?.about}
                  disabled={!editMode}
                />
                {editMode && (
                  <svg className="edit-icon" xmlns="http://www.w3.org/2000/svg" width="18" height="19" viewBox="0 0 18 19" fill="none">
                    <path d="M2.28803 18.0938C1.67803 18.0938 1.10803 17.8838 0.698033 17.4938C0.178033 17.0038 -0.0719667 16.2638 0.0180333 15.4638L0.388033 12.2238C0.458033 11.6138 0.828034 10.8038 1.25803 10.3638L9.46803 1.67378C11.518 -0.496221 13.658 -0.55622 15.828 1.49378C17.998 3.54378 18.058 5.68378 16.008 7.85378L7.79803 16.5438C7.37803 16.9938 6.59803 17.4138 5.98803 17.5138L2.76803 18.0638C2.59803 18.0738 2.44803 18.0938 2.28803 18.0938ZM12.678 1.48378C11.908 1.48378 11.238 1.96378 10.558 2.68378L2.34803 11.3838C2.14803 11.5938 1.91803 12.0938 1.87803 12.3838L1.50803 15.6238C1.46803 15.9538 1.54803 16.2238 1.72803 16.3938C1.90803 16.5638 2.17803 16.6238 2.50803 16.5738L5.72803 16.0238C6.01803 15.9738 6.49803 15.7138 6.69803 15.5038L14.908 6.81378C16.148 5.49378 16.598 4.27378 14.788 2.57378C13.988 1.80378 13.298 1.48378 12.678 1.48378Z" fill="#292D32" />
                    <path d="M14.088 9.5237C14.068 9.5237 14.038 9.5237 14.018 9.5237C10.898 9.2137 8.38799 6.8437 7.90799 3.7437C7.84799 3.3337 8.12799 2.9537 8.53799 2.8837C8.94799 2.8237 9.32799 3.1037 9.39799 3.5137C9.77799 5.9337 11.738 7.7937 14.178 8.0337C14.588 8.0737 14.888 8.4437 14.848 8.8537C14.798 9.2337 14.468 9.5237 14.088 9.5237Z" fill="#292D32" />
                  </svg>
                )}
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default AboutUs;
