import React, { useContext, useEffect, useRef, useState } from 'react'
import { Col, Container, Form, Modal, Row } from 'react-bootstrap';
import Sidbar from '../layout/Sidbar';
import Header from '../layout/Header';
import { toast } from 'react-toastify';
import { CartContext } from '../context/CartContext';
import EquipmentsTable from '../components/EquipmentsTable';
import { addEquipments, getEquipments, getSubAdmin } from '../controller/Api';
import Loader from "../components/Loader";

const Equipments = () => {
    const { perPage, admin } = useContext(CartContext);
    const [page, setPage] = useState(1);
    const [search, setSearch] = useState();
    const ref = useRef(null);
    const [loading, setLoading] = useState(false);
    const [lgShow, setLgShow] = useState(false);
    const [listEquipments, setListEquipments] = useState([]);
    const [findSubAdmin, setFindSubAdmin] = useState([]);
    const [selectedSubAdminId, setSelectedSubAdminId] = useState();
    const [userId, setUserId] = useState();
    const [imagePreviewUrl, setImagePreviewUrl] = useState(null);
    const [data, setData] = useState({
        name: "",
        desc: "",
        file: null
    });

    //Post Api
    const submitEquipments = async () => {
        if (data.name.trim() === "") {
            toast.error("Equipments Name is required");
            return;
        }
        if (!data.file) {
            toast.error("Equipments icon is required");
            return;
        }
        const uniqueName = listEquipments.data.map((ele) => {
            return ele.name;
        });
        if (uniqueName.includes(data.name.trim())) {
            toast.error("Equipment name must be unique");
            return;
        }
        try {
            const formData = new FormData();
            formData.append('name', data.name);
            formData.append('desc', data.desc);
            if (data.file) {
                formData.append('file', data.file);
            }
            formData.append('userId', userId);

            const result = await addEquipments(formData);
            const { status, message } = result;
            if (status === 1) {
                toast.success(message);
                setData({ name: "", desc: "", file: null });
                setImagePreviewUrl(null);
                showEquipments();
                setUserId("");
                setLgShow(false);
            } else {
                toast.error(message);
            }
        } catch (error) {
            console.log(error);
        }
    };


    //Get Equipments
    const showEquipments = async (searchVal) => {
        const pageVal = searchVal ? 1 : page;
        try {
            setLoading(true);
            const result = await getEquipments(pageVal, perPage, search, selectedSubAdminId);
            const { message, status } = result;
            if (status === 1) {
                const { page } = result;
                setPage(page);
                setListEquipments(result);
            } else {
                toast.error(message);
            }
        } catch (error) {
            console.log(error);
        }
        setLoading(false);
    }

    //get Sub-admin Wish Equipments
    const getSubAdminData = async () => {
        try {
            const result = await getSubAdmin();
            const { status, data } = result;
            if (status === 1) {
                setFindSubAdmin(data)
            }
        } catch (error) {
            console.log(error);
        }
    }

    const inputValChange = (e) => {
        const { name, value, files } = e.target;
        setData((oldVal) => {
            if (name === 'file') {
                const file = files[0];
                const previewUrl = URL.createObjectURL(file);
                setImagePreviewUrl(previewUrl);
                return {
                    ...oldVal,
                    [name]: file
                };
            } else {
                return {
                    ...oldVal,
                    [name]: value
                };
            }
        });
    };

    const inputHandle = (e) => {
        setSearch(e.target.value);
    }

    //Enter Key Move Next line
    const handleKeyDown = (event) => {
        if (event.keyCode === 13) {
            event.preventDefault();
            const textareaName = event.target?.name;
            const currentValue = data[textareaName];
            const cursorPosition = event.target.selectionStart;
            const newValue =
                currentValue?.substring(0, cursorPosition) +
                '\n' +
                currentValue?.substring(cursorPosition);
            setData({ ...data, [textareaName]: newValue });
        }
    };

    useEffect(() => {
        const callback = (event) => {
            if (event.key === 'Enter' && ref.current) {
                event.preventDefault();
                ref.current?.click();
            }
        };
        document.addEventListener('keydown', callback);
        return () => {
            document.removeEventListener('keydown', callback);
        };
    }, []);

    useEffect(() => {
        showEquipments();
        document.title = "Music Booth | Equipments"
        admin && getSubAdminData();
    }, [page, selectedSubAdminId]);

    return (
        <>
            {loading && <Loader />}
            <section>
                <Container fluid>
                    <Row className="vh-100">
                        <Col
                            xs={12}
                            md={12}
                            lg={3}
                            xl={2}
                            className="shadow-right d-none d-lg-block"
                        >
                            <Sidbar />
                        </Col>
                        <Col xs={12} lg={9} xl={10} className="position-relative px-md-5">
                            <Header />
                            <img src="../images/png/bg-imag.png" className="bg-imag" alt="" />
                            <Row className="justify-content-between align-items-center pb-3">
                                <Col md={6} lg={5} xl={7}>
                                    <h1 className="title">Equipments</h1>
                                </Col>
                                <Col md={6} lg={7} xl={5} className={`${admin ? "mt-3 mt-md-0 d-flex gap-2 justify-content-end" : "mt-3 mt-md-0"}`}>
                                    {admin && (
                                        <Form.Select
                                            style={{ width: "200px", height: "50px" }}
                                            className="select-bg cursor-pointer"
                                            aria-label="Default select example"
                                            value={selectedSubAdminId}
                                            onChange={(e) => { setSelectedSubAdminId(e.target.value); setPage(1) }}
                                        >
                                            <option value={""}>Select Company</option>
                                            {findSubAdmin?.map((item, index) => (
                                                <option key={index} value={item._id}>
                                                    {item.fullName}
                                                </option>
                                            ))}
                                        </Form.Select>
                                    )}
                                    <Form>
                                        <div className="d-flex justify-content-end gap-3">
                                            <div className="form-control d-flex align-items-center px-3" style={{ width: "350px" }}>
                                                <input
                                                    className="form-control border-0 shadow-none pl-black"
                                                    placeholder="Search"
                                                    type="text"
                                                    value={search}
                                                    onChange={inputHandle}
                                                />
                                                <img className="cursor-pointer" ref={ref} src="../images/svg/search.svg" onClick={() => showEquipments("search")} alt="Search" />
                                            </div>
                                            <div
                                                onClick={() => setLgShow(true)}
                                                className="cursor-pointer"
                                            >
                                                <img
                                                    src="../images/svg/add.svg"
                                                    className="add-btn"
                                                    alt=""
                                                />
                                            </div>
                                        </div>
                                    </Form>
                                </Col>
                            </Row>
                            <EquipmentsTable
                                admin={admin}
                                listEquipments={listEquipments}
                                setListEquipments={setListEquipments}
                                page={page}
                                perPage={perPage}
                                showEquipments={showEquipments}
                                setPage={setPage}
                                handleKeyDown={handleKeyDown}
                                selectedSubAdminId={selectedSubAdminId}
                            />
                        </Col>
                    </Row>
                </Container>
            </section>
            <Modal
                size="xl"
                show={lgShow}
                onHide={() => {
                    setLgShow(false)
                    setData({ name: "", desc: "", file: null });
                    setImagePreviewUrl(null);
                    setUserId("");
                }}
                centered
                className='moadl-main info-modal'
            >
                <Modal.Header className='d-block position-relative border-0' closeButton>
                    <Modal.Title className='text-center'>Add Equipment</Modal.Title>
                </Modal.Header>
                <Modal.Body className='mx-md-4 border-0 bg-transparent pb-0'>
                    <Form className='mt-4'>
                        <Row>

                            {admin &&
                                <Col md={12}>
                                    <div className="">
                                        <Form.Select
                                            style={{ width: "200px", height: "50px" }}
                                            className="select-bg cursor-pointer mb-3"
                                            aria-label="Default select example"
                                            value={userId}
                                            onChange={(e) => { setUserId(e.target.value) }}
                                        >
                                            <option value={""}>Select Company</option>
                                            {findSubAdmin?.map((item, index) => (
                                                <option key={index} value={item._id}>
                                                    {item.fullName}
                                                </option>
                                            ))}
                                        </Form.Select>
                                    </div>
                                </Col>
                            }
                            <Col md={12}>
                                <div className='form-control d-flex align-items-center px-1 mb-3'>
                                    <input className='form-control border-0 shadow-none px-0 px-2 text-black w-100' placeholder='Enter Equipment Name'
                                        type="text" name='name'
                                        value={data.name}
                                        onChange={inputValChange}
                                    />
                                </div>
                            </Col>
                            <Col md={12}>
                                <div className="form-control d-flex align-items-center px-1 mb-3">
                                    <div className="d-flex w-100">
                                        <textarea
                                            className="form-control border-0 shadow-none px-0 px-2 text-black w-100"
                                            placeholder="Enter Equipment Description"
                                            name="desc"
                                            rows={3}
                                            type="text"
                                            onChange={inputValChange}
                                            onKeyDown={handleKeyDown}
                                            value={data.desc}
                                        />
                                    </div>
                                </div>
                            </Col>

                            <Col md={12}>
                                <div className="form-control d-flex align-items-center px-1 mb-3">
                                    <div className="d-flex w-100">
                                        <input
                                            type="file"
                                            name='file'
                                            onChange={inputValChange}
                                            accept='.jpg, .png, .jpeg'
                                        />
                                    </div>
                                </div>
                            </Col>

                            {imagePreviewUrl && (
                                <Col md={12}>
                                    <div>
                                        <img style={{ height: "120px", weight: "120px" }} src={imagePreviewUrl} alt="Preview" />
                                    </div>
                                </Col>
                            )}

                        </Row>
                    </Form>
                </Modal.Body>
                <Modal.Footer className='mx-md-4 border-0 bg-transparent pt-0'>
                    <button
                        className='secondary-button text-decoration-none' onClick={submitEquipments}>
                        Add
                    </button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default Equipments