import React, { useContext, useEffect, useRef, useState } from 'react'
import { Col, Container, Form, Modal, Row, Table, Tooltip } from 'react-bootstrap';
import GetInTouchTable from '../components/GetInTouchTable';
import Sidbar from '../layout/Sidbar';
import Header from '../layout/Header';
import { getintouch } from '../controller/Api';
import { CartContext } from '../context/CartContext';
import Loader from "../components/Loader";

const GetInTouch = () => {
    const { perPage, setPerPage } = useContext(CartContext);
    const [getInTouch, setGetInTouch] = useState();
    const [search, setSearch] = useState();
    const [searchWithPage, setSearchWithPage] = useState();
    const [page, setPage] = useState(1);
    const ref = useRef(null);
    const [sortType, setSortType] = useState("asc");
    const [loading, setLoading] = useState(false);

    const getTouch = async (searchVal) => {
        const pageVal = searchVal ? 1 : page;
        try {
            setLoading(true);
            const result = await getintouch(pageVal, perPage, search, sortType);
            const { status } = result;
            if (status === 1) {
                const { page } = result;
                setPage(page);
                setGetInTouch(result);
            }
        }
        catch (error) {
            console.log(error);
        }
        setLoading(false);
    }

    const inputHandle = (e) => {
        setSearch(e.target.value);
    }

    useEffect(() => {
        getTouch();
    }, [page]);

    useEffect(() => {
        const callback = (event) => {
            if (event.key === 'Enter') {
                setPage(1);
                event.preventDefault();
                ref.current?.click();
            }
        };
        document.addEventListener('keydown', callback);
        return () => {
            document.removeEventListener('keydown', callback);
        };
    }, [search]);

    useEffect(() => {
        document.title = "Music Booth | Get In Touch"
    }, []);


    const getInTouchOrder = () => {
        const newSortType = sortType === 'asc' ? 'desc' : 'asc';
        setSortType(newSortType);
        getTouch();
    }

    return (
        <>
            {loading && <Loader />}
            <section>
                <Container fluid>
                    <Row className="vh-100">
                        <Col
                            xs={12}
                            md={12}
                            lg={3}
                            xl={2}
                            className="shadow-right d-none d-lg-block"
                        >
                            <Sidbar />
                        </Col>
                        <Col xs={12} lg={9} xl={10} className="position-relative px-md-5">
                            <Header />
                            <img src="../images/png/bg-imag.png" className="bg-imag" alt="" />
                            <Row className="justify-content-between align-items-center pb-3">
                                <Col md={6} lg={5} xl={7}>
                                    <h1 className="title">Get In Touch</h1>
                                </Col>
                                <Col md={6} lg={7} xl={5} className="mt-3 mt-md-0">
                                    <Form>
                                        <div className='d-flex justify-content-end gap-3'>
                                            <div className='form-control d-flex align-items-center px-3' style={{ height: "50px", width: "350px" }}>
                                                <input className='form-control border-0 shadow-none pl-black'
                                                    placeholder="Search"
                                                    type="text"
                                                    value={search}
                                                    onChange={inputHandle}
                                                />
                                                <img className='cursor-pointer' src="../images/svg/search.svg" ref={ref} onClick={() => getTouch("search")} alt="Search" />
                                            </div>
                                        </div>
                                    </Form>
                                </Col>
                            </Row>
                            <GetInTouchTable
                                getInTouch={getInTouch}
                                page={page}
                                setPage={setPage}
                                perPage={perPage}
                                setPerPage={setPerPage}
                                getInTouchOrder={getInTouchOrder}
                            />
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    )
}

export default GetInTouch