import moment from 'moment';
import React, { useState } from 'react'
import { Button, Col, Modal, Row, Table } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import TablePagination from '../components/TablePagination';
import { deletePartnerRequest } from '../controller/Api';
import { toast } from 'react-toastify';

const PartnerRequestTable = ({ getPartnerDataApi, partnerRequest, perPage, setPage, page }) => {
    const navigate = useNavigate();
    const [model, setModel] = useState(false);
    const [infoData, setInfoData] = useState();
    const [deleteModel, setDeleteModel] = useState(false);
    const [id, setId] = useState();

    const handleInfo = (element) => {
        setInfoData(element);
    }

    const deleteApi = async () => {
        try {
            const deleteData = await deletePartnerRequest(id);
            const { status, message } = deleteData;
            if (status === 1) {
                toast.success(message);
                getPartnerDataApi();
                setDeleteModel(false);
            } else {
                toast.error(message);
            }
        } catch (error) {
            toast.error(error);
        }
    };

    return (
        <>
            <Row>
                <Col>
                    <Table bordered responsive="xl">
                        <thead>
                            <tr>
                                <th className='number-width border'>No</th>
                                <th style={{ width: "300px" }}>Name</th>
                                <th style={{ width: "400px" }}>Notification Email</th>
                                <th style={{ width: "300px" }}>Phone No</th>
                                <th style={{ width: "700px" }}>Company Name</th>
                                <th style={{ width: "700px" }}>Company Business</th>
                                <th style={{ width: "1000px" }}>Message</th>
                                <th style={{ width: "550px" }}>Created At</th>
                                <th style={{ width: "550px" }}>Action</th>
                            </tr>
                        </thead>
                        {Boolean(partnerRequest?.data?.length) ?
                            <tbody>
                                {
                                    partnerRequest?.data?.map((element, index) => {
                                        const { name, email, phoneNo, companyName, companyBusiness, message, created_at, _id } = element;
                                        const currentNumber = (page - 1) * perPage + index + 1;
                                        const utcFormattedDateTime = created_at ? moment(created_at).format("YYYY-MM-DD, HH:mm:ss") : "-";
                                        return (
                                            <tr>
                                                <td>{currentNumber}</td>
                                                <td>{name || "-"}</td>
                                                <td>{email || "-"}</td>
                                                <td>{phoneNo || "-"}</td>
                                                <td>{companyName || "-"}</td>
                                                <td>{companyBusiness || "-"}</td>
                                                <td><div className='wordBreak1'>{message || "-"}</div></td>
                                                <td>{utcFormattedDateTime}</td>
                                                <td>

                                                    <div className="d-flex justify-content-center align-items-center gap-3">
                                                        <span
                                                            className="cursor-pointer"
                                                            onClick={() => {
                                                                setModel(true);
                                                                handleInfo(element);
                                                            }}>
                                                            <div title="Info">
                                                                <svg
                                                                    width="10"
                                                                    height="24"
                                                                    viewBox="0 0 10 24"
                                                                    fill="none"
                                                                    xmlns="http://www.w3.org/2000/svg"
                                                                >
                                                                    <path
                                                                        d="M4.64865 23.5203C4.28527 23.445 3.90859 23.4006 3.55407 23.281C2.08724 22.7891 1.3782 21.5837 1.52887 19.9928C1.64852 18.7431 2.04736 17.5643 2.36643 16.3634C2.65891 15.26 2.96468 14.1609 3.23943 13.0575C3.37238 12.5346 3.41669 11.9984 3.31477 11.4577C3.23057 11.0101 2.97797 10.7398 2.53039 10.6423C1.90112 10.5049 1.28957 10.5626 0.682452 10.7575C0.46974 10.824 0.252596 10.8949 0 10.9791C0.106356 10.5537 0.194986 10.1637 0.301343 9.77375C0.314637 9.72057 0.376678 9.66739 0.429856 9.64524C1.43581 9.2597 2.4329 8.82984 3.50532 8.63928C4.56002 8.45316 5.60143 8.47532 6.58965 8.95835C7.6222 9.46354 8.16727 10.5537 8.11852 11.7015C8.05648 13.2525 7.52027 14.6794 7.13916 16.1507C6.891 17.1212 6.62067 18.0828 6.39024 19.0578C6.27502 19.5364 6.21298 20.0283 6.31047 20.5246C6.42569 21.0963 6.69158 21.34 7.26324 21.4375C8.02103 21.5704 8.73894 21.4153 9.44798 21.1672C9.51445 21.145 9.58535 21.1184 9.68728 21.0785C9.58535 21.504 9.49229 21.9028 9.38594 22.2972C9.37264 22.3415 9.3106 22.3903 9.26185 22.408C8.46418 22.7049 7.67094 23.0195 6.86441 23.2854C6.49216 23.4095 6.08889 23.445 5.70335 23.5159C5.35326 23.5203 5.00317 23.5203 4.64865 23.5203Z"
                                                                        fill="#292D32"
                                                                    />
                                                                    <path
                                                                        d="M7.32516 6.16221C5.98241 6.1046 5.06509 5.59498 4.56432 4.50039C3.99266 3.25071 4.59091 1.79717 5.88935 1.16347C7.07256 0.582939 8.52166 0.875419 9.38581 1.87251C10.5646 3.23298 9.9752 5.33352 8.22475 5.95836C7.89239 6.08244 7.529 6.11789 7.32516 6.16221Z"
                                                                        fill="#292D32"
                                                                    />
                                                                </svg>
                                                            </div>
                                                        </span>
                                                        <button className="border-0 bg-transparent" title="Delete">
                                                            <svg
                                                                className="cursor-pointer"
                                                                width="24"
                                                                height="24"
                                                                viewBox="0 0 24 24"
                                                                fill="none"
                                                                xmlns="http://www.w3.org/2000/svg"
                                                                onClick={() => {
                                                                    setId(_id);
                                                                    setDeleteModel(true);
                                                                }}
                                                            >
                                                                <path
                                                                    d="M21.07 5.23C19.46 5.07 17.85 4.95 16.23 4.86V4.85L16.01 3.55C15.86 2.63 15.64 1.25 13.3 1.25H10.68C8.35004 1.25 8.13003 2.57 7.97004 3.54L7.76004 4.82C6.83004 4.88 5.90004 4.94 4.97004 5.03L2.93004 5.23C2.51004 5.27 2.21004 5.64 2.25004 6.05C2.29004 6.46 2.65004 6.76 3.07004 6.72L5.11004 6.52C10.35 6 15.63 6.2 20.93 6.73C20.96 6.73 20.98 6.73 21.01 6.73C21.39 6.73 21.72 6.44 21.76 6.05C21.79 5.64 21.49 5.27 21.07 5.23Z"
                                                                    fill="#292D32"
                                                                />
                                                                <path
                                                                    d="M19.23 8.14C18.99 7.89 18.66 7.75 18.32 7.75H5.67999C5.33999 7.75 4.99999 7.89 4.76999 8.14C4.53999 8.39 4.40999 8.73 4.42999 9.08L5.04999 19.34C5.15999 20.86 5.29999 22.76 8.78999 22.76H15.21C18.7 22.76 18.84 20.87 18.95 19.34L19.57 9.09C19.59 8.73 19.46 8.39 19.23 8.14ZM13.66 17.75H10.33C9.91999 17.75 9.57999 17.41 9.57999 17C9.57999 16.59 9.91999 16.25 10.33 16.25H13.66C14.07 16.25 14.41 16.59 14.41 17C14.41 17.41 14.07 17.75 13.66 17.75ZM14.5 13.75H9.49999C9.08999 13.75 8.74999 13.41 8.74999 13C8.74999 12.59 9.08999 12.25 9.49999 12.25H14.5C14.91 12.25 15.25 12.59 15.25 13C15.25 13.41 14.91 13.75 14.5 13.75Z"
                                                                    fill="#292D32"
                                                                />
                                                            </svg>
                                                        </button>
                                                    </div>


                                                </td>
                                            </tr>
                                        );
                                    })}
                            </tbody>
                            :
                            <tbody>
                                <tr>
                                    <td colSpan={10}>
                                        <div className='nodata-table'>
                                            <h3 className="text-center p-5">Data Not Found</h3>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        }
                    </Table>
                    <div className='d-md-flex justify-content-between align-items-center'>
                        <button className='secondary-button text-decoration-none' onClick={() => navigate("/dashboard")} >
                            <svg
                                stroke="currentColor"
                                fill="currentColor"
                                strokeWidth={0}
                                viewBox="0 0 24 24"
                                height="2em"
                                width="2em"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path d="M12.707 17.293 8.414 13H18v-2H8.414l4.293-4.293-1.414-1.414L4.586 12l6.707 6.707z" />
                            </svg>
                            Back
                        </button>
                        {
                            partnerRequest?.data && partnerRequest?.data?.length >= 1 &&
                            <TablePagination
                                currentPage={partnerRequest?.page}
                                totalPages={partnerRequest?.pages}
                                onPageChange={(newPage) => {
                                    setPage(newPage);
                                }}
                            />
                        }
                    </div>
                </Col>
            </Row >

            {/* Info moadl */}
            <Modal
                show={model}
                onHide={() => setModel(false)}
                centered
                className="moadl-main info-modal"
                size="xl"
            >
                <Modal.Header
                    className="d-block position-relative border-0"
                    closeButton
                >
                    <Modal.Title className="text-center">
                        Info
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className='mx-4 border-0 mb-4'>
                    <Row>
                        <Col>
                            <div className="w-100 ">
                                <div className="row align-items-center mb-1">
                                    <div className="col-3">
                                        <label className="text-nowrap fw-600">Smart Lock UserName:</label>
                                    </div>
                                    <div className="col-9">
                                        <input
                                            className="mt-1 form-control border-0 fw-400 shadow-none pl-black px-0 label-pl bg-transparent gap-1"
                                            type="text"
                                            value={infoData?.name || "-"}
                                            disabled
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="w-100 ">
                                <div className="row align-items-center mb-1">
                                    <div className="col-3">
                                        <label className="text-nowrap fw-600">Notification Email:</label>
                                    </div>
                                    <div className="col-9">
                                        <input
                                            className="mt-1 form-control border-0 fw-400 shadow-none pl-black px-0 label-pl bg-transparent gap-1"
                                            type="text"
                                            value={infoData?.email || "-"}
                                            disabled
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="w-100 ">
                                <div className="row align-items-center mb-1">
                                    <div className="col-3">
                                        <label className="text-nowrap fw-600">Phone No:</label>
                                    </div>
                                    <div className="col-9">
                                        <input
                                            className="mt-1 form-control border-0 fw-400 shadow-none pl-black px-0 label-pl bg-transparent gap-1"
                                            type="text"
                                            value={infoData?.phoneNo || "-"}
                                            disabled
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="w-100 ">
                                <div className="row align-items-center mb-1">
                                    <div className="col-3">
                                        <label className="text-nowrap fw-600">Company Name:</label>
                                    </div>
                                    <div className="col-9">
                                        <input
                                            className="mt-1 form-control border-0 fw-400 shadow-none pl-black px-0 label-pl bg-transparent gap-1"
                                            type="text"
                                            value={infoData?.companyName || "-"}
                                            disabled
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="w-100 ">
                                <div className="row align-items-center mb-1">
                                    <div className="col-3">
                                        <label className="text-nowrap fw-600">Company Business:</label>
                                    </div>
                                    <div className="col-9">
                                        <input
                                            className="mt-1 form-control border-0 fw-400 shadow-none pl-black px-0 label-pl bg-transparent gap-1"
                                            type="text"
                                            value={infoData?.companyBusiness || "-"}
                                            disabled
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className="w-100 ">
                                <div className="row align-items-center mb-1">
                                    <div className="col-3">
                                        <label className="text-nowrap fw-600">Message:</label>
                                    </div>
                                    <div className="col-9">
                                        <p>{infoData?.message}</p>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Modal.Body>
            </Modal>

            <Modal Modal
                size="md"
                show={deleteModel}
                onHide={() => setDeleteModel(false)}
                centered
                className="moadl-main delete-modal"
            >
                <Modal.Header
                    className="d-block position-relative border-0"
                    closeButton
                >
                    <Modal.Title className="text-center">Delete Partner Request</Modal.Title>
                </Modal.Header>
                <Modal.Body className="mx-4 border-0">
                    <p>Are you sure you want to delete?</p>
                </Modal.Body>
                <Modal.Footer className="border-0">
                    <div className="d-flex m-auto gap-2 pb-3">
                        <button
                            className="secondary-button text-decoration-none"
                            onClick={() => {
                                deleteApi();
                            }}
                        >
                            Delete
                        </button>
                        <Button
                            className="primary-button"
                            onClick={() => {
                                setDeleteModel(false);
                            }}
                        >
                            Cancel
                        </Button>
                    </div>
                </Modal.Footer>
            </Modal>


            {/* delete model */}


        </>
    )
}

export default PartnerRequestTable
