import React, { useEffect } from 'react';
import { Container, Row, Col, Card, Form } from 'react-bootstrap';
import Sidbar from '../layout/Sidbar';
import Header from './../layout/Header';
import { getDashboard, editTecaherDiscount, getTeacherDiscount, getSubAdmin, getChartData } from "../controller/Api";
import Chart from 'react-apexcharts';
import { useState } from 'react';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import DatePicker from 'react-datepicker';
import { useContextData } from '../context/CartContext';
import { Chart as ChartJS, LineElement, CategoryScale, LinearScale, PointElement, Legend, Tooltip } from 'chart.js';
import { Line } from 'react-chartjs-2';
import 'react-datepicker/dist/react-datepicker.css';
import Loader from '../components/Loader';
ChartJS.register(LineElement, CategoryScale, LinearScale, PointElement, Legend, Tooltip);

function Dashboard() {
  const { admin } = useContextData();
  const [getAllData, setGetAllData] = useState();
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [teacherDiscountPer, setTeacherDiscountPer] = useState();
  const [isEditing, setIsEditing] = useState(false);
  const [isEditingEmirates, setIsEditingEmirates] = useState(false);
  const [teacherData, setTeacherData] = useState(null);
  const [emiratesIdDiscount, setEmiratesIdDiscount] = useState();
  const [subAdminRoleData, setSubAdminRoleData] = useState();
  const [selectRole, setSelectRole] = useState();
  const navigate = useNavigate();
  const [chartData, setChartData] = useState(null);
  const [loading, setLoading] = useState()
  const [selectedYear, setSelectedYear] = useState();
  const [getBooking, setGetBooking] = useState();
  const [getSells, setGetSells] = useState();
  const [bookingChartData, setBookingChartData] = useState();
  const [sellsChartData, setSellsChartData] = useState();
  const [data, setData] = useState({
    booking: "week",
    sells: "week"
  });

  const dashboardApi = async (startDate, endDate, year) => {
    try {
      const newYear = selectedYear ? selectedYear : year ? year : 2024
      const res = await getDashboard(startDate, endDate, selectRole, newYear);
      const { status, message, data } = res;
      if (status == 1) {
        setGetAllData(data);
      } else {
        toast.error(message);
      }
    } catch (error) {
      toast.error(error);
    }
  }

  const getSubAdminChartApi = async (type, value, setDataFunction) => {
    try {
      const result = await getChartData(type, value);
      const { status, message, data } = result;
      if (status == 1) {
        setDataFunction(data);
      }
    } catch (error) {
      toast.error(error);
    }
  }

  useEffect(() => {
    !admin && getSubAdminChartApi();
  }, [data])

  const getSubAdminDashboard = async () => {
    try {
      setLoading(true);
      const result = await getSubAdmin();
      const { data, status } = result;
      if (status === 1) {
        setSubAdminRoleData(data);
      }
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  }

  useEffect(() => {
    admin && getSubAdminDashboard();
  }, [])

  const editTeacher = async () => {
    if (isNaN(teacherDiscountPer) || teacherDiscountPer < 1 || teacherDiscountPer > 100) {
      toast.error("Teacher Discount should be a numeric value between 1 and 100");
      return;
    }
    if (emiratesIdDiscount < 1 || emiratesIdDiscount > 100) {
      toast.error("Emirate Citizen Discount should be a numeric value between 1 and 100");
      return;
    }
    try {
      const result = await editTecaherDiscount(teacherDiscountPer, emiratesIdDiscount);
      const { message, data, status } = result;
      if (status === 1) {
        toast.success("Discount has been added successfully.");
        setTeacherData(data)
      } else {
        toast.error(message);
      }
      setIsEditing(false);
      setIsEditingEmirates(false);
    } catch (error) {
      console.log(error);
    }
  };

  const getTeacher = async () => {
    try {
      const result = await getTeacherDiscount();
      const { data } = result;
      setTeacherData(data);
      setTeacherDiscountPer(data.teacherDiscountPer)
    } catch (error) {
      console.log(error);
    }
  };

  const handleChange = (e) => {
    setTeacherDiscountPer(e.target.value);
  };

  const handleChanges = (e) => {
    setEmiratesIdDiscount(e.target.value);
  };

  useEffect(() => {
    document.title = "Music Booth | Dashboard"
    { admin && getTeacher() }
  }, [])

  useEffect(() => {
    const today = new Date();
    const endDates = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate()
    const sevenDaysAgo = new Date(today);
    sevenDaysAgo.setDate(today.getDate() - 7);
    const startDate = sevenDaysAgo.getFullYear() + '-' + (sevenDaysAgo.getMonth() + 1) + '-' + sevenDaysAgo.getDate();
    dashboardApi(startDate, endDate)
  }, [selectRole]);

  // useEffect(() => {
  //   const today = new Date();
  //   const sevenDaysAgo = new Date(today);
  //   sevenDaysAgo.setDate(today.getDate() - 7);
  //   setStartDate(sevenDaysAgo);
  //   setEndDate(today);

  //   // Initial API call with default range (last 7 days)
  //   dashboardApi(sevenDaysAgo.toISOString(), today.toISOString());
  // }, [selectRole]);

  // useEffect(() => {
  //   if (startDate && endDate) {
  //     dashboardApi(startDate, endDate)
  //   } else if (!startDate && !endDate) {
  //   }
  // }, [startDate, endDate]);

  useEffect(() => {
    if (startDate && endDate) {
      dashboardApi(startDate.toISOString(), endDate.toISOString());
    }
  }, [startDate, endDate]);

  const handleStartDateChange = (event) => {
    const date = event.target.value;
    setStartDate(date);
  };

  const handleDateChange = (dates) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
  };

  const redirectPage = (page) => {
    if (page == "user") {
      navigate("/user-details")
    } else if (page === "booth") {
      navigate("/studio-rental")
    } else if (page === "booking") {
      navigate("/booking")
    } else if (page === "payment")
      navigate("/payment")
  }

  useEffect(() => {
    const processData = () => {
      if (getAllData && getAllData.users && Array.isArray(getAllData.users?.months)) {
        const datasets = {
          label: getAllData.users?.year,
          data: getAllData.users?.months?.map((user) => user),
          fill: false,
          borderColor: '#b99e6e',
          tension: 0.1,
        };
        const chartData = {
          labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
          datasets: [datasets]
        };
        setChartData(chartData);
      } else {
      }
    };
    processData();
  }, [getAllData, selectedYear]);

  const options = {
    plugins: {
      tooltip: {
        enabled: true,
        callbacks: {
          label: function (tooltipItem) {
            return `Users: ${tooltipItem.raw}`;
          },
        },
      },
      legend: {
        display: false,
      },
    },
    scales: {
      x: {
        grid: {
          display: false,
        },
      },
      y: {
        grid: {
          display: true,
        },
        beginAtZero: true,
      },
    },
  };

  //sub admin-----------------------------------------------------------
  //Booking

  // useEffect(() => {
  //   const processData = () => {
  //     if (getBooking && getBooking?.booking && Array.isArray(getBooking?.booking)) {
  //       const datasets = {
  //         label: getBooking?.booking,
  //         data: getBooking?.booking?.map((user) => user),
  //         fill: false,
  //         borderColor: '#b99e6e',
  //         tension: 0.1,
  //       };

  //       let chartData = {};
  //       if (booking === "week") {
  //         chartData = {
  //           labels: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
  //           datasets: [datasets],
  //         };
  //       } else if (booking === "month") {
  //         chartData = {
  //           labels: ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12", "13", "14", "15", "16", "17", "18", "19", "20", "21", "22", "23", "24", "25", "26", "27", "28", "29", "30", "31"],
  //           datasets: [datasets],
  //         };
  //       } else {
  //         chartData = {
  //           labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
  //           datasets: [datasets],
  //         };
  //       }
  //       setBookingChartData(chartData);
  //     } else {
  //       console.log('Data not available or format is incorrect:', getAllData);
  //     }
  //   };

  //   processData();
  // }, [data]);

  useEffect(() => {
    !admin && getSubAdminChartApi('booking', data.booking, setGetBooking);
  }, [data.booking]);

  useEffect(() => {
    !admin && getSubAdminChartApi('sells', data.sells, setGetSells);
  }, [data.sells]);

  const processChartData = (chartData, type) => {
    if (chartData && chartData[type] && Array.isArray(chartData[type])) {
      const datasets = {
        label: type.charAt(0).toUpperCase() + type.slice(1),
        data: chartData[type].map((user) => user),
        fill: false,
        borderColor: type === 'booking' ? '#b99e6e' : '#b99e6e',
        tension: 0.1,
      };

      let processedData = {};
      if (data[type] === "week") {
        processedData = {
          labels: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
          datasets: [datasets],
        };
      } else if (data[type] === "month") {
        processedData = {
          labels: ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12", "13", "14", "15", "16", "17", "18", "19", "20", "21", "22", "23", "24", "25", "26", "27", "28", "29", "30", "31"],
          datasets: [datasets],
        };
      } else {
        processedData = {
          labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
          datasets: [datasets],
        };
      }
      return processedData;
    } else {
      return null;
    }
  };

  useEffect(() => {
    setBookingChartData(processChartData(getBooking, 'booking'));
  }, [getBooking, data.booking]);

  useEffect(() => {
    setSellsChartData(processChartData(getSells, 'sells'));
  }, [getSells, data.sells]);


  const optionsData = {
    plugins: {
      tooltip: {
        enabled: true,
        callbacks: {
          label: function (tooltipItem) {
            return tooltipItem?.dataset?.label === 'Booking' ? `Booking: ${tooltipItem.raw}` : ` Sales: ${tooltipItem.raw}`;
          },
        },
      },
      legend: {
        display: false,
      },
    },
    scales: {
      x: {
        grid: {
          display: false,
        },
      },
      y: {
        grid: {
          display: true,
        },
        beginAtZero: true,
      },
    },
  };

  const handleYearChange = async (e) => {
    const year = e.target.value;
    setSelectedYear(year);
    await dashboardApi(startDate, endDate, year);
  };

  let currentYear = new Date().getFullYear()
  const yearArray = []
  let i = currentYear
  while (2024 <= i) {
    yearArray.push(i)
    i--
  }

  return (
    <>
      {loading && <Loader />}
      <section>
        <Container fluid>
          <Row className='vh-100'>
            <Col xs={12} md={12} lg={3} xl={2} className='shadow-right d-none d-lg-block'>
              <Sidbar />
            </Col>
            <Col xs={12} lg={9} xl={10} className='position-relative px-md-5'>
              <Header />
              <img src="../images/png/bg-imag.png" className='bg-imag' alt="" />
              <div className='pb-3'>
                <h1 className='title'>Dashboard</h1>
              </div>
              {
                <div className="row justify-content-between align-items-start">

                  {admin &&
                    <div className="col-12 col-md-5 col-xl-3 mb-3 mb-md-0">
                      <p className="fw-500 mb-1">Teacher Discount :</p>
                      <div className='d-flex align-items-center gap-2 mb-3'>
                        <input
                          className={`form-control border-1 shadow-none pl-black fw-500 ${isEditing ? "bg-transparent" : ""}`}
                          placeholder="Enter Teacher Discount"
                          type="text"
                          value={isEditing ? teacherDiscountPer : (teacherData ? teacherData?.teacherDiscountPer + "%" : "0%")}
                          onChange={handleChange}
                          disabled={!isEditing}
                          onKeyDown={(e) =>
                            ["e", "E", "+", "-", "."].includes(e.key) &&
                            e.preventDefault()
                          }
                        />
                        {!isEditing ? (
                          !selectRole ? (
                            <button title='Edit' className="secondary-button text-decoration-none" onClick={() => setIsEditing(true)} disabled={isEditingEmirates}>
                              Edit
                            </button>
                          ) : ""
                        ) : (
                          <button title='Save' className="secondary-button text-decoration-none" onClick={() => editTeacher()} >
                            Save
                          </button>
                        )}
                      </div>
                      <p className="fw-500 mb-1">Emirati Citizen Discount :</p>
                      <div className='d-flex align-items-center gap-2'>
                        <input
                          className={`form-control border-1 shadow-none pl-black fw-500 ${isEditingEmirates ? "bg-transparent" : ""}`}
                          placeholder="Enter Emirati Citizen Discount"
                          type="text"
                          value={isEditingEmirates ? emiratesIdDiscount : (teacherData ? teacherData?.emiratesIdDiscount + "%" : "0%")}
                          onChange={handleChanges}
                          disabled={!isEditingEmirates}
                          onKeyDown={(e) =>
                            ["e", "E", "+", "-", "%", "."].includes(e.key) &&
                            e.preventDefault()
                          }
                        />
                        {
                          !isEditingEmirates ? (
                            !selectRole ? (
                              <button title='Edit' className="secondary-button text-decoration-none" onClick={() => setIsEditingEmirates(true)} disabled={isEditing}>
                                Edit
                              </button>
                            ) : ""
                          ) : (
                            <button title='Save' className="secondary-button text-decoration-none" onClick={() => { editTeacher() }} >
                              Save
                            </button>
                          )
                        }
                      </div>
                    </div>
                  }
                  <div className={`col-12 col-md-7 ${!admin ? 'col-xl-12' : 'col-xl-9'}`}>
                    <div className='row justify-content-end btn-block g-3'>
                      {admin &&
                        <div className='col-12 col-md-6 col-xl-3'>
                          <Form.Select
                            className="select-bg cursor-pointer"
                            aria-label="Default select example"
                            value={selectRole}
                            onChange={(e) => { setSelectRole(e.target.value) }}
                          >
                            <option value={""}>Select Company</option>
                            {subAdminRoleData?.map((item, index) => (
                              <option key={index} value={item._id}>
                                {item.fullName}
                              </option>
                            ))}
                          </Form.Select>
                        </div>
                      }

                      <div className='col-12 col-md-6 col-xl-3'>
                        <DatePicker
                          selected={startDate}
                          placeholderText='MM/DD/YYYY'
                          onChange={handleDateChange}
                          startDate={startDate}
                          endDate={endDate}
                          selectsRange
                          className='form-control'
                        />
                      </div>

                    </div>
                  </div>
                </div>
              }

              <Row className='gy-4 gy-md-0 gx-md-4 mt-4'>
                {
                  admin && !selectRole &&
                  <Col xs={12} sm={12} md={6} lg={6} xl={3} xxl={3}>
                    <Card className='des-card h-100b cursor-pointer' onClick={() => redirectPage("user")}>
                      <Card.Body>
                        <p className='card-left-title'>Total No. Of Users</p>
                        <hr />
                        <h3 className='card-number'>{getAllData?.UsersData ? getAllData?.UsersData : " 0"}</h3>
                      </Card.Body>
                    </Card>
                  </Col>
                }

                <Col xs={12} sm={12} md={6} lg={6} xl={3} xxl={3}>
                  <Card className='des-card h-100 cursor-pointer' onClick={() => redirectPage("booth")}>
                    <Card.Body>
                      <div className='d-flex justify-content-between align-items-center gap-3'>
                      </div>
                      <Row className='align-items-center'>
                        <Col xs={12}>
                          {admin ? <p className='card-left-title'>Total No. Of Booth</p> : <p className='card-left-title'>My Booths</p>}
                        </Col>
                      </Row>
                      <hr />
                      <Row className='align-items-center'>
                        <Col xs={6}>
                          <h3 className='card-number mb-0'>{getAllData?.StudioData ? getAllData?.StudioData : " 0"}</h3>
                        </Col>
                      </Row>
                    </Card.Body>
                  </Card>
                </Col>

                <Col xs={12} sm={12} md={6} lg={6} xl={3} xxl={3}>
                  <Card className='des-card h-100 cursor-pointer' onClick={() => redirectPage("booking")}>
                    <Card.Body>
                      <div className='d-flex justify-content-between align-items-center gap-3'>
                      </div>
                      <Row className='align-items-center'>
                        <Col xs={12}>
                          {admin ? <p className='card-left-title'>Total No. Of Booking</p> : <p className='card-left-title'>My Bookings</p>}
                        </Col>
                      </Row>
                      <hr />
                      <Row className='align-items-center'>
                        <Col xs={6}>
                          <h3 className='card-number mb-0'>{getAllData?.bookingCount ? getAllData?.bookingCount : " 0"}</h3>
                        </Col>
                      </Row>
                    </Card.Body>
                  </Card>
                </Col>

                <Col xs={12} sm={12} md={6} lg={6} xl={3} xxl={3} className=''>
                  <Card className='des-card h-100 cursor-pointer' onClick={() => redirectPage("payment")}>
                    <Card.Body>
                      <div className='d-flex justify-content-between align-items-center gap-3'>
                      </div>
                      <Row className='align-items-center'>
                        <Col xs={12}>
                          {admin ? <p className='card-left-title'>Total Amount Of Booking</p> : <p className='card-left-title'>Total Sales</p>}
                        </Col>
                      </Row>
                      <hr />
                      <Row className='align-items-center'>
                        <Col xs={12}>
                          <h3 className='card-number mb-0'> {getAllData?.bookingAmount ? "AED " + getAllData?.bookingAmount : "0"}</h3>
                        </Col>
                      </Row>
                    </Card.Body>
                  </Card>
                </Col>
                {
                  admin && !selectRole &&
                  <>
                    <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6} className='mb-5 mt-3'>
                      <Card className='des-card h-100 cursor-pointer'>
                        <Card.Body>
                          <div className='d-flex justify-content-between'>
                            <div className='pb-2 fw-400'>
                              <h2 className='title'>User Chart</h2>
                            </div>
                            <div>
                              <Form.Select
                                aria-label="Select Year"
                                className=""
                                value={selectedYear}
                                onChange={handleYearChange}
                              >
                                {yearArray?.map(year => (
                                  <>
                                    <option key={year} value={year}>
                                      {year}
                                    </option>
                                  </>
                                ))}
                              </Form.Select>
                            </div>
                          </div>
                          {/* 
                          {chartData ? (
                            <Chart
                              options={chartData.options}
                              series={chartData.series}
                              type="line"
                              height={350}
                            />
                          ) : (
                            <p>Loading chart...</p>
                          )} */}
                          <div>
                            {chartData?.datasets ? (
                              <Line data={chartData} options={options} />
                            ) : (
                              <p>Loading chart...</p>
                            )}
                          </div>
                        </Card.Body>
                      </Card>
                    </Col>
                  </>
                }

                {
                  !admin &&
                  <Row>
                    <>
                      <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6} className='mb-5 mt-3'>
                        <Card className='des-card h-100 cursor-pointer'>
                          <Card.Body>
                            <div className='d-flex justify-content-between'>
                              <div className='pb-2 fw-400'>
                                <h2 className='title'>Booking Chart</h2>
                              </div>
                              <div>
                                <Form.Select
                                  aria-label="Select Year"
                                  className=""
                                  value={data.booking}
                                  onChange={(e) => setData({ ...data, booking: e.target.value })}
                                >
                                  <option value="week">Weekly</option>
                                  <option value="month">Monthly</option>
                                  <option value="year">Yearly</option>
                                </Form.Select>
                              </div>
                            </div>
                            <div>
                              {bookingChartData ? (
                                <Line data={bookingChartData} options={optionsData} />
                              ) : (
                                <p>Loading chart...</p>
                              )}
                            </div>
                          </Card.Body>
                        </Card>
                      </Col>
                      <Col xs={12} sm={12} md={12} lg={12} xl={6} xxl={6} className='mb-5 mt-3'>
                        <Card className='des-card h-100 cursor-pointer'>
                          <Card.Body>
                            <div className='d-flex justify-content-between'>
                              <div className='pb-2 fw-400'>
                                <h2 className='title'>Total Sales</h2>
                              </div>
                              <div>
                                <Form.Select
                                  aria-label="Select Year"
                                  className=""
                                  value={data.sells}
                                  onChange={(e) => setData({ ...data, sells: e.target.value })}
                                >
                                  <option value="week">Weekly</option>
                                  <option value="month">Monthly</option>
                                  <option value="year">Yearly</option>
                                </Form.Select>
                              </div>
                            </div>
                            <div>
                              {sellsChartData ? (
                                <Line data={sellsChartData} options={optionsData} />
                              ) : (
                                <p>Loading chart...</p>
                              )}
                            </div>
                          </Card.Body>
                        </Card>
                      </Col>
                    </>
                  </Row>
                }
              </Row>
            </Col>
          </Row>
        </Container>
      </section >
    </>
  )
}

export default Dashboard