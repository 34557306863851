// import { getServerURL } from "../helper/envConfig";
import { serverADMIN } from "../helper/envConfig";
import api from "../helper/api";
import { toast } from "react-toastify";
const COMMON_ERROR = "Please try again.!";
const baseUrl = serverADMIN();
// import { pf } from "../helper/envConfig";
// import { Auth } from '../App';
// const url = "http://10.0.0.103:3055";
// const serverURL = getServerURL();
// const icon = pf();
// const server =  serverADMIN();
// const server = `${process.env.REACT_APP_API_BASE_URL_ADMIN}api/admin/`;

const errorResponse = (error) => {
    if (error.response) {
        const { status, data, message } = error.response;
        if (status && [401, 403].includes(status)) {
            localStorage.clear();
            window.location.href = "/";
            toast.error(message);
        }
        return data;
    } else {
        return { status: 0, message: COMMON_ERROR };
    }
};
//Sign in:-
export const submitLogin = async (logindata) => {
    try {
        const { email, password } = logindata;
        const login = await api.post(`${baseUrl + "log-in"}`, { email, password });
        const { data } = login;
        return data;
    } catch (error) {
        return errorResponse(error);
    }
}
//Dashboard api
export const getDashboard = async (startDate, endDate, userId, year) => {
    try {
        year = parseInt(year)
        const dashboard = await api.postWithToken(`${baseUrl + "dashboard-details"}`, { startDate, endDate, userId, year });
        const { data } = dashboard;
        return data;
    } catch (error) {
        return errorResponse(error);
    }
}
//Post User api
export const postUser = async (formData) => {
    const user = await api.postWithToken(`${baseUrl + "add-user"}`, formData);
    try {
        const { data } = user;
        return data;
    } catch (error) {
        return errorResponse(error);
    }
}
//get user all
export const getUser = async (perPage, page, search, selectedUserRole, sortType, key) => {
    const user_role = selectedUserRole || '';
    try {
        const user = await api.getWithToken(`${baseUrl + `get-all-users?perPage=${perPage || 10}&page=${page || 1}&search=${search || ""}&user_role=${user_role}&sortType=${sortType || ""}&key=${key || "date"}`}`);
        const { data } = user;
        return data;
    } catch (error) {
        return errorResponse(error);
    }
}
//single data get
export const getSingleUser = async () => {
    try {
        const user = await api.getWithToken(`${baseUrl + "get-user"}`);
        const { data } = user;
        return data;
    } catch (error) {
        return errorResponse(error);
    }
}
// user delete
// export const deleteSingleUser = async (id) => {
//     try {
//         const url = `${baseUrl}delete-user/${id}`;
//         const user = await api.deleteWithToken(url);
//         const { data } = user;
//         return data;
//     } catch (error) {
//         return errorResponse(error);
//     }
// }
export const deleteSingleUser = async (id) => {
    try {
        const user = await api.deleteWithToken(`${baseUrl}delete-user/${id}`);
        const { data } = user;
        return data;
    } catch (error) {
        return errorResponse(error);
    }
}
//edit user
export const editUser = async (id, formData) => {
    try {
        const user = await api.patchWithToken(`${baseUrl}edit-user/${id}`, formData);
        const { data } = user;
        return data;
    } catch (error) {
        return errorResponse(error);
    }
}
// post service rental  api
export const getStudiorental = async (formData) => {
    try {
        const user = await api.postWithToken(`${baseUrl + "studio/add-studio-details"}`, formData);
        const { data } = user;
        return data;
    } catch (error) {
        return errorResponse(error);
    }
}
//get Service studio-rental details
export const getStudioRental = async (perPage, page, search, sortType, key, userId) => {
    try {
        const user = await api.getWithToken(`${baseUrl + `get-all-studio-details?perPage=${perPage || 10}&page=${page || 1}&search=${search || ""}&sortType=${sortType || ""}&key=${key || "date"} ${userId ? `&userId=${userId}` : ""}`}`);
        const { data } = user;
        return data;
    } catch (error) {
        return errorResponse(error);
    }
}

//detele funcanality
export const deletestudirental = async (id) => {
    try {
        const url = `${baseUrl}delete-studio-details/${id}`;
        const user = await api.deleteWithToken(url);
        const { data } = user;
        return data;
    } catch (error) {
        return errorResponse(error);
    }
}
//edit Functionality studio details
export const editStudio = async (id, formData) => {
    try {
        const user = await api.patchWithToken(`${baseUrl}edit-studio-details/${id}`, formData);
        const { data } = user;
        return data;
    } catch (error) {
        return errorResponse(error);
    }
}
//edit single data
export const SingleStudio = async (id) => {
    try {
        const url = `${baseUrl}get-studio-details/${id}`;
        const user = await api.getWithToken(url);
        const { data } = user;
        return data;
    } catch (error) {
        return errorResponse(error);
    }
}

//contact-us get data
export const getcontactUs = async () => {
    try {
        const user = await api.getWithToken(`${baseUrl + "get-contact-us"}`);
        const { data } = user;
        return data;
    } catch (error) {
        return errorResponse(error);
    }
}
//about us add funcanality
export const getAboutUs = async () => {
    try {
        const user = await api.getWithToken(`${baseUrl + "get-about-us"}`);
        const { data } = user;
        return data;
    } catch (error) {
        return errorResponse(error);

    }
}
//edit about us
export const EditAboutUs = async (editData) => {
    try {
        const user = await api.patchWithToken(`${baseUrl + "edit-about-us"}`, editData);
        const { data } = user;
        return data;
    } catch (error) {
        return errorResponse(error);
    }
}

//term and condition 
export const getTerms = async () => {
    try {
        const user = await api.getWithToken(`${baseUrl + "get-terms"}`);
        const { data } = user;
        return data;
    } catch (error) {
        return errorResponse(error);
    }
}

//post and condition 
export const postTerms = async (termsData) => {
    try {
        const user = await api.patchWithToken(`${baseUrl + "edit-about-us"}`, { terms: termsData });
        const { data } = user;
        return data;
    } catch (error) {
        return errorResponse(error);
    }
}

//get Contact us
export const addContactus = async () => {
    try {
        const user = await api.getWithToken(`${baseUrl + "get-contact-us"}`);
        const { data } = user;
        return data;
    } catch (error) {
        return errorResponse(error);
    }
}

//edit contact us
export const editContactus = async (id, editData) => {
    try {
        const user = await api.patchWithToken(`${baseUrl}edit-contact-us/${id}`, editData);
        const { data } = user;
        return data;
    } catch (error) {
        return errorResponse(error);
    }
}

//get booking data
export const addBookingData = async (perPage, page, search, sortType, key, userId) => {
    try {
        const user = await api.getWithToken(`${baseUrl}get-all-booking?perPage=${perPage || 10}&page=${page || 1}&search=${search || ""}&sortType=${sortType || "desc"}&key=${key || "date"} ${userId ? `&userId=${userId}` : ""}`);
        const { data } = user;
        return data;
    } catch (error) {
        return errorResponse(error);
    }
}


//post category 
export const addCategory = async (name) => {
    try {
        const user = await api.postWithToken(`${baseUrl + "add-studio-category"}`, name);
        const { data } = user;
        return data;
    } catch (error) {
        return errorResponse(error);
    }
}

export const getCategory = async (userId) => {
    try {
        const user = await api.getWithToken(`${baseUrl + `get-all-studio-category?${userId ? `&userId=${userId}` : ""}`}`);
        const { data } = user;
        return data;
    } catch (error) {
        return errorResponse(error);
    }
}

//delete category
export const deleteCategory = async (id) => {
    try {
        const url = `${baseUrl}delete-studio-category/${id}`;
        const user = await api.deleteWithToken(url);
        const { data } = user;
        return data;
    } catch (error) {
        return errorResponse(error);
    }
}

//edit category
export const editCategory = async (id, categoryData) => {
    try {
        const user = await api.patchWithToken(`${baseUrl}edit-studio-category/${id}`, categoryData);
        const { data } = user;
        return data;
    } catch (error) {
        return errorResponse(error);
    }
}

//get payment
export const getPayment = async (perPage, page, search, sortType, userId) => {
    try {
        const user = await api.getWithToken(`${baseUrl + `get-payment?perPage=${perPage || 10}&page=${page || 1}&search=${search || ""}&sortType=${sortType || ""} ${userId ? `&userId=${userId}` : ""}`}`);
        const { data } = user;

        return data;
    } catch (error) {
        return errorResponse(error);
    }
}

//subImage 
export const subImageApi = async (id, formData) => {
    try {
        const user = await api.patchWithToken(`${baseUrl}edit-sub-image/${id}`, formData);
        const { data } = user;
        return data;
    } catch (error) {
        return errorResponse(error);
    }
}

export const logOutApi = async () => {
    try {
        const user = await api.getWithToken(`${baseUrl}log-out`);
        const { data } = user;
        return data;
    } catch (error) {
        return errorResponse(error);
    }
}

//get sin touch
export const getintouch = async (page, perPage, search, sortType) => {
    try {
        const user = await api.getWithToken(`${baseUrl}get-in-touch?page=${page || 1}&perPage=${perPage || 10}&search=${search || ""}&sortType=${sortType || ""}`);
        const { data } = user;
        return data;
    } catch (error) {
        return errorResponse(error);
    }
}

//Equipments page post Api
export const addEquipments = async (formData) => {
    try {
        const result = await api.postWithToken(`${baseUrl}add-studio-category`, formData);
        const { data } = result;
        return data;
    } catch (error) {
        return errorResponse(error);
    }
}

//subImage 
// export const subImageApi = async (id, formData) => {
//     try {
//         const user = await api.patchWithToken(`${baseUrl}edit-sub-image/${id}`, formData);
//         const { data } = user;
//         return data;
//     } catch (error) {
//         return errorResponse(error);
//     }
// }


//Get Equipments
export const getEquipments = async (page, perPage, search, userId) => {
    try {
        const result = await api.getWithToken(`${baseUrl}get-all-studio-category?page=${page || 1}&perPage=${perPage || 10}&search=${search || ""}${userId ? `&userId=${userId}` : ""}`);
        const { data } = result;
        return data;
    } catch (error) {
        return errorResponse(error);
    }
}

//Edit Equipments
export const editEquipments = async (id, formData) => {
    // const { name, desc, status, userId } = editShowData;
    try {
        const result = await api.patchWithToken(`${baseUrl}edit-studio-category/${id}`, formData);
        const { data } = result;
        return data;
    } catch (error) {
        return errorResponse(error);
    }
}

//Booking Get user
export const getBooking = async (id, page, perPage) => {
    try {
        const result = await api.getWithToken(`${baseUrl}get-bookings-by-user/${id}?page=${page || 1}&perPage=${perPage || 10}`);
        const { data } = result;
        return data;
    } catch (error) {
        return errorResponse(error);
    }
}


export const getPaymentDetails = async (id, page, perPage) => {
    try {
        const result = await api.getWithToken(`${baseUrl}get-payments-by-user/${id}?page=${page || 1}&perPage=${perPage || 10}`);
        const { data } = result;
        return data;
    } catch (error) {
        return errorResponse(error);
    }
}

export const deleteEquipments = async (id) => {
    try {
        const user = await api.deleteWithToken(`${baseUrl}delete-studio-category/${id}`);
        const { data } = user;
        return data;
    } catch (error) {
        return errorResponse(error);
    }
}

export const addPricing = async (name, desc, weekday, userId) => {
    try {
        const result = await api.postWithToken(`${baseUrl}pricing`, { name, desc, weekday, userId });
        const { data } = result;
        return data;
    } catch (error) {
        return errorResponse(error);
    }
}

export const getPricing = async (page, perPage, search, userId) => {
    try {
        const result = await api.getWithToken(`${baseUrl}get-all-pricing?page=${page || 1}&perPage=${perPage || 10} ${search ? `&search=${search}` : ""} ${userId ? `&userId=${userId}` : ""}`);
        const { data } = result;
        return data;
    } catch (error) {
        return errorResponse(error);
    }
}

export const editPricing = async (id, editData) => {
    try {
        const result = await api.patchWithToken(`${baseUrl}edit-pricing/${id}`, editData);
        const { data } = result;
        return data;
    } catch (error) {
        return errorResponse(error);
    }
}

export const deletePrice = async (id) => {
    try {
        const user = await api.deleteWithToken(`${baseUrl}delete-pricing/${id}`);
        const { data } = user;
        return data;
    } catch (error) {
        return errorResponse(error);
    }
}


//Booths Is Active
export const boothsActiveFlow = async (id, isActive) => {
    try {
        const result = await api.patchWithToken(`${baseUrl}edit-studio-details/status/${id}`, isActive);
        const { data } = result;
        return data;
    } catch (error) {
        return errorResponse(error);
    }
}


//Add addCancelPolicy policy
export const addCancelPolicy = async (policyData) => {
    try {
        const result = await api.postWithToken(`${baseUrl}cancellation-policy`, policyData)
        const { data } = result;
        return data;
    } catch (error) {
        return errorResponse(error);
    }
}

//Get cancelPolicy
export const getCancelPolicy = async (page, perPage, search, userId) => {
    try {
        const result = await api.getWithToken(`${baseUrl}get-all-cancellation-policy?page=${page || 1}&perPage=${perPage || 10} ${search ? `&search=${search}` : ""} ${userId ? `&userId=${userId}` : ""}`);
        const { data } = result;
        return data;
    } catch (error) {
        return errorResponse(error);
    }
}

//Patch
export const editCancelPolicy = async (id, editPolicyData) => {
    try {
        const result = await api.patchWithToken(`${baseUrl}cancellation-policy/${id}`, editPolicyData);
        const { data } = result;
        return data;
    } catch (error) {
        return errorResponse(error);
    }
}

//delete policy
export const deleteCancelPolicy = async (id) => {
    try {
        const user = await api.deleteWithToken(`${baseUrl}cancellation-policy/${id}`);
        const { data } = user;
        return data;
    } catch (error) {
        return errorResponse(error);
    }
}

//techer descount

export const editTecaherDiscount = async (teacherDiscountPer, emiratesIdDiscount) => {
    try {
        const result = await api.patchWithToken(`${baseUrl}edit-admin-setting`, { teacherDiscountPer, emiratesIdDiscount });
        const { data } = result;
        return data;
    } catch (error) {
        return errorResponse(error);
    }
}

export const getTeacherDiscount = async () => {
    try {
        const result = await api.getWithToken(`${baseUrl}get-admin-setting`);
        const { data } = result;
        return data;
    } catch (error) {
        return errorResponse(error);
    }
}

export const getFeedBack = async (page, perPage, search) => {
    try {
        const result = await api.getWithToken(`${baseUrl}get-all-feedback?page=${page || 1}&perPage=${perPage || 10}&search=${search || ""}`);
        const { data } = result;
        return data;
    } catch (error) {
        return errorResponse(error);
    }
}
//delete Feedback
export const deleteFeedBack = async (id) => {
    try {
        const user = await api.deleteWithToken(`${baseUrl}feedback-delete/${id}`);
        const { data } = user;
        return data;
    } catch (error) {
        return errorResponse(error);
    }
}

//Booth Featured
export const editBoothFeatured = async (id, isFeatured) => {
    try {
        const result = await api.patchWithToken(`${baseUrl}edit-studio-details/featured/${id}`, isFeatured);
        const { data } = result;
        return data;
    } catch (error) {
        return errorResponse(error);
    }
}

//Booth Featured
export const teacherFeatured = async (id, is_featured) => {
    try {
        const result = await api.patchWithToken(`${baseUrl}/user-featured/${id}`, is_featured);
        const { data } = result;
        return data;
    } catch (error) {
        return errorResponse(error);
    }
}

//block user
export const userBlock = async (id, is_block) => {
    try {
        const result = await api.patchWithToken(`${baseUrl}/user-block/${id}`, is_block);
        const { data } = result;
        return data;
    } catch (error) {
        return errorResponse(error);
    }
}


//Add Notification
export const AddBookingNotification = async (notification) => {
    const { event, leadTime, timeIn, method, title, desc, userId } = notification;
    try {
        const result = await api.postWithToken(`${baseUrl}add-notification-setting`, { event, leadTime, timeIn, method, title, desc, userId });
        const { data } = result;
        return data;
    } catch (error) {
        return errorResponse(error);
    }
}

//Get Notification
export const getNotification = async (page, perPage, userId) => {
    try {
        const result = await api.getWithToken(`${baseUrl}get-all-notification-setting?page=${page || 1}&perPage=${perPage || 10} ${userId ? `&userId=${userId}` : ""}`);
        const { data } = result;
        return data;
    } catch (error) {
        return errorResponse(error);
    }
}

//Edit Notification
export const editNotificationApi = async (id, notification) => {
    const { event, leadTime, timeIn, method, title, desc, userId } = notification;
    try {
        const result = await api.patchWithToken(`${baseUrl}edit-notification-setting/${id}`, {
            event, leadTime, timeIn, method, title, desc, userId: userId._id
        });
        const { data } = result;
        return data;
    } catch (error) {
        return errorResponse(error);
    }
}

//delete Notification
export const deleteNotification = async (id) => {
    try {
        const user = await api.deleteWithToken(`${baseUrl}delete-notification-setting/${id}`);
        const { data } = user;
        return data;
    } catch (error) {
        return errorResponse(error);
    }
}

//Add broadcast
export const AddBroadCast = async (dataToSend) => {
    const { type, method, studioId, title, desc, isScheduled, scheduleTime } = dataToSend;
    try {
        const result = await api.postWithToken(`${baseUrl}add-broadcast-notification`, { type, method, studioId, title, desc, isScheduled, scheduleTime });
        const { data } = result;
        return data;
    } catch (error) {
        return errorResponse(error);
    }
}

//Get Notification
export const getBroadCast = async (page, perPage) => {
    try {
        const result = await api.getWithToken(`${baseUrl}get-all-broadcast-notification?page=${page || 1}&perPage=${perPage || 10}`);
        const { data } = result;
        return data;
    } catch (error) {
        return errorResponse(error);
    }
}

//Edit Notification
export const editBroadCastApi = async (id, broadCastData, studioId) => {
    const { type, method, title, desc, isScheduled, scheduleTime } = broadCastData;
    try {
        const result = await api.patchWithToken(`${baseUrl}edit-broadcast-notification/${id}`, { type, method, studioId, title, desc, isScheduled, scheduleTime, });
        const { data } = result;
        return data;
    } catch (error) {
        return errorResponse(error);
    }
}

//delete Notification
export const deleteBroadCast = async (id) => {
    try {
        const user = await api.deleteWithToken(`${baseUrl}delete-broadcast-notification/${id}`);
        const { data } = user;
        return data;
    } catch (error) {
        return errorResponse(error);
    }
}

//Get Equipments for sub admin
export const getSubAdmin = async () => {
    try {
        const result = await api.getWithToken(`${baseUrl}get-all-sub-admin`);
        const { data } = result;
        return data;
    } catch (error) {
        return errorResponse(error);
    }
}
//Get Equipments for sub admin
export const getSaltoUser = async (search) => {
    try {
        const result = await api.getWithToken(`${baseUrl}salto-user?search=${search}`);
        const { data } = result;
        return data;
    } catch (error) {
        return errorResponse(error);
    }
}

//add Guide page
export const AddGuide = async (formData) => {
    try {
        const result = await api.postWithToken(`${baseUrl}upload-manual`, formData);
        const { data } = result;
        return data;
    } catch (error) {
        return errorResponse(error);
    }
}


export const getGuide = async () => {
    try {
        const result = await api.getWithToken(`${baseUrl}get-manuals`);
        const { data } = result;
        return data;
    } catch (error) {
        return errorResponse(error);
    }
}

export const getChartData = async (type, value) => {
    try {
        const result = await api.postWithToken(`${baseUrl}sub-graph`, { [type]: value });
        const { data } = result;
        return data;
    } catch (error) {
        return errorResponse(error);
    }
}


//Edit Profile
export const editProfileApi = async (formData) => {
    try {
        const result = await api.putWithToken(`${baseUrl}update-profile`, formData);
        const { data } = result;
        return data;
    } catch (error) {
        return errorResponse(error);
    }
}


//Get partner request
export const getPartnerData = async (page, perPage, search, startDate, endDate) => {
    try {
        const result = await api.postWithToken(`${baseUrl}get-all-partner?page=${page || 1}&perPage=${perPage || 10}&search=${search || ""}`, { startDate, endDate });
        const { data } = result;
        return data;
    } catch (error) {
        return errorResponse(error);
    }
}


//delete partner request
export const deletePartnerRequest = async (id) => {
    try {
        const user = await api.deleteWithToken(`${baseUrl}delete-partner-request/${id}`);
        const { data } = user;
        return data;
    } catch (error) {
        return errorResponse(error);
    }
}

//Edit user
export const editUserApi = async (id, formData) => {
    // const { name, desc, status, userId } = editShowData;
    try {
        const result = await api.patchWithToken(`${baseUrl}edit-user/${id}`, formData);
        const { data } = result;
        return data;
    } catch (error) {
        return errorResponse(error);
    }
}


//Venue Type
export const boothAdd = async (formData, userId) => {
    try {
        const result = await api.postWithToken(`${baseUrl}add-booth-type`, formData, userId);
        const { data } = result;
        return data;
    } catch (error) {
        console.log('error: ', error);
    }
}

export const boothGet = async (page, perPage, search) => {
    try {
        const result = await api.getWithToken(`${baseUrl}get-all-booth-type?page=${page || 1}&perPage=${perPage || 10}&search=${search || ""}`)
        const { data } = result;
        return data;
    } catch (error) {
        console.log('error: ', error);
    }
}

//Edit api booth
export const boothEdit = async (id, formData) => {
    try {
        const result = await api.patchWithToken(`${baseUrl}edit-booth-type/${id}`, formData)
        const { data } = result;
        return data;
    } catch (error) {
        console.log('error: ', error);
    }
}


export const boothDelete = async (id) => {
    try {
        const result = await api.deleteWithToken(`${baseUrl}delete-booth-type/${id}`)
        const { data } = result;
        return data;
    } catch (error) {
        console.log('error: ', error);
    }
}

//recital hall Add api
export const recitalHallAdd = async (recitalType) => {
    try {
        const result = await api.postWithToken(`${baseUrl}add-recital-type`, { recitalType })
        const { data } = result
        return data
    } catch (error) {
        console.log('error: ', error);
    }
}

//recital hall get api
export const recitalHallGet = async (page, perPage, search) => {
    try {
        const result = await api.getWithToken(`${baseUrl}get-all-recital-type?page=${page || 1}&perPage=${perPage || 10}&search=${search || ""}`)
        const { data } = result;
        return data;
    } catch (error) {
        console.log('error: ', error);
    }
}

// recital hall edit
export const recitalHallEdit = async (id, key) => {
    try {
        const result = await api.patchWithToken(`${baseUrl}edit-recital-type/${id}`,  key);
        const { data: responseData } = result;
        return responseData;
    } catch (error) {
        console.log('error: ', error);
    }
}

export const recitalHallDelete = async (id) => {
    try {
        const result = await api.deleteWithToken(`${baseUrl}delete-recital-type/${id}`)
        const { data } = result;
        return data;
    } catch (error) {
        console.log('error: ', error);
    }
}

//Add-ons Add api
export const addOnsAdd = async (addOns, userId) => {
    const { title, price, description } = addOns
    try {
        const result = await api.postWithToken(`${baseUrl}add-ons`, { title, price, description, userId });
        const { data } = result;
        return data;
    } catch (error) {
        console.log('error: ', error);
    }
}

//GET Api
export const addOnsGet = async (page, perPage, search, userId) => {
    try {
        const result = await api.getWithToken(`${baseUrl}get-all-add-ons?page=${page || 1}&perPage=${perPage || 10}&search=${search || ""}${userId ? `&userId=${userId}` : ""}`)
        const { data } = result;
        return data;
    } catch (error) {
        console.log('error: ', error);
    }
}

export const addOnsEdit = async (id, editShowData) => {
    const { title, price, description, isActive } = editShowData
    try {
        const result = await api.patchWithToken(`${baseUrl}edit-add-ons/${id}`, { title, price, description, isActive })
        const { data } = result;
        return data;
    } catch (error) {
        console.log('error: ', error);
    }
}

export const addOnsDelete = async (id) => {
    try {
        const result = await api.deleteWithToken(`${baseUrl}delete-add-ons/${id}`)
        const { data } = result;
        return data;
    } catch (error) {
        console.log('error: ', error);
    }
}


//get All sub admin 
export const getPartner = async (page, perPage, search) => {
    try {
        const result = await api.getWithToken(`${baseUrl}get-all-partner?page=${page || 1}&perPage=${perPage || 10}&search=${search || ""}`);
        const { data } = result;
        return data;
    } catch (error) {
        return errorResponse(error);
    }
}

export const getPartnerDetails = async (id) => {
    try {
        const result = await api.getWithToken(`${baseUrl}get-partner-details/${id}`);
        const { data } = result;
        return data;
    } catch (error) {
        return errorResponse(error);
    }
}



// perPage, page, search
// ?perPage=${perPage || ""}&page=${page || ""}&search=${search || ""}