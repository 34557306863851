import React, { useEffect, useRef, useState } from 'react'
import Sidbar from '../layout/Sidbar'
import BroadCastNotificationTable from '../components/BroadCastNotificationTable';
import { Button, Col, Container, Form, Modal, Row } from 'react-bootstrap'
import Header from '../layout/Header';
import { AddBroadCast, getStudioRental } from "../controller/Api"
import { editBroadCastApi } from "../controller/Api"
import Select from 'react-select';
import { getBroadCast, deleteBroadCast } from "../controller/Api";
import { toast } from 'react-toastify';
import moment from 'moment';
import Loader from "../components/Loader";

const BroadCastNotification = () => {
    const [lgShow, setLgShow] = useState(false);
    const [removeModel, setRemoveModel] = useState(false);
    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(10);
    const [studioPage, setStudioPage] = useState(1);
    const [studioPerPage, setStudioPerPage] = useState(10);
    const [getStudio, setGetStudio] = useState([]);
    const [editModel, setEditModel] = useState(false);
    const [id, setId] = useState();
    const [getBroadCastData, setGetBroadCastData] = useState();
    const [studioId, setStudioId] = useState();
    const [loading, setLoading] = useState(false);
    const [broadCastData, setBroadCastData] = useState({
        type: "all",
        title: "",
        method: "",
        desc: "",
        isScheduled: false,
        scheduleTime: ""
    });

    const scheduleTime = new Date(broadCastData.scheduleTime);
    const currentDate = new Date();
    const handleClose = () => setRemoveModel(false);

    // Post Api call:-
    const addBroadCastFunction = async (e) => {
        try {
            e.preventDefault();
            if (!validationCheck()) return;
            const dataToSend = { ...broadCastData };
            if (broadCastData.type === "all") {
                delete dataToSend.scheduleTime;
            } else {
                if (broadCastData.type === "booth") {
                    dataToSend.studioId = studioId;
                }
            }
            const addNotification = await AddBroadCast(dataToSend);
            const { status, message } = addNotification;
            if (status === 1) {
                toast.success(message);
                setLgShow(false);
                getBroadCastFunction();
                setBroadCastData({
                    type: "",
                    method: "",
                    title: "",
                    desc: "",
                    isScheduled: false,
                    scheduleTime: ""
                });
            } else {
                toast.error(message);
            }
        } catch (error) {
            console.log(error);
        }
    }

    //Edit Api Broadcast
    const editApiFunction = async () => {
        try {
            if (!validationCheck()) return;
            const result = await editBroadCastApi(id, broadCastData, studioId);
            const { message, status } = result;
            if (status === 1) {
                getBroadCastFunction();
                setEditModel(false);
                setLgShow(false);
                toast.success(message);
            } else {
                toast.error(message);
            }
        } catch (error) {
            console.log(error);
        }
    }

    //Get Api
    const getBroadCastFunction = async () => {
        try {
            setLoading(true);
            const result = await getBroadCast(page, perPage);
            const { message, status } = result;
            if (status === 1) {
                setGetBroadCastData(result);
            } else {
                toast.error(message);
            }
        } catch (error) {
            console.log(error);
        }
        setLoading(false);
    }

    useEffect(() => {
        getBroadCastFunction();
    }, [page])

    //delete Api
    const deleteNotificationFunction = async () => {
        try {
            const result = await deleteBroadCast(id);
            const { status, message } = result;
            if (status === 1) {
                toast.success(message);
                setRemoveModel(false);
                getBroadCastFunction();
            } else {
                toast.error(message);
            }
        } catch (error) {
            console.log(error);
        }
    }

    //get Studio
    const getStudioFunction = async () => {
        try {
            const result = await getStudioRental(studioPerPage, studioPage);
            const { data } = result;
            const options = data?.length > 0 ? data?.filter(studio => studio.isActive === 1)?.map(studio => ({
                value: studio?._id,
                label: studio?.studio_name
            })) : [];
            setGetStudio(options);
        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        // window.addEventListener('scroll', handleScroll);
        return () => {
            getStudioFunction();
            // window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const handleScroll = () => {
        if ((window.innerHeight + window.scrollY) >= document.body.scrollHeight) {
            setStudioPage(prevPage => prevPage + 1);
        }
    };

    const handleSelectChange = selectedOption => {
        setStudioId(selectedOption.value);
    };

    const inputChange = (e) => {
        const { name, value, checked, type } = e.target;
        if (name === "type" && value === "all" && broadCastData.type === "booth") {
            setBroadCastData(prevState => ({
                ...prevState,
                // studioId: "",
                scheduleTime: "",
                [name]: value,
            }));
        } else {
            if (type === "checkbox") {
                setBroadCastData(prevState => ({
                    ...prevState,
                    [name]: checked
                }));
            } else {
                setBroadCastData(prevState => ({
                    ...prevState,
                    [name]: value
                }));
            }
        }
    };

    const handleEdit = (broadCast) => {
        setEditModel(true);
        setBroadCastData(prevBroadCast => ({
            ...prevBroadCast,
            ...broadCast,
            scheduleTime: moment.utc(broadCast?.scheduleTime).format("YYYY-MM-DDTHH:mm")
        }));
        setStudioId(broadCast?.studioId._id);
        setGetStudio(prevOptions => {
            const updatedOptions = [...prevOptions, {
                value: broadCast?.studioId?._id,
                label: broadCast?.studioId?.studio_name
            }];
            return updatedOptions;
        });
        setId(broadCast._id);
    }

    const validationCheck = () => {
        if (scheduleTime < currentDate) {
            toast.error("Please add the upcoming schedule date & time.");
            return;
        }
        if (!broadCastData.type.trim()) {
            toast.error("Type is required");
            return;
        }
        if (broadCastData.type === "booth" && !studioId) {
            toast.error("Booth is required");
            return;
        }
        if (!broadCastData.method.trim()) {
            toast.error("Broadcast method is required");
            return;
        }
        if (broadCastData.method.trim() === "Select Method") {
            toast.error("Broadcast method is required");
            return;
        }
        if (!broadCastData.title.trim()) {
            toast.error("Title is required");
            return;
        }
        if (!broadCastData.desc.trim()) {
            toast.error("Description is required");
            return;
        }

        return true;
    }

    const handleSave = async (e) => {
        e.preventDefault();
        if (editModel) {
            await editApiFunction();
        } else {
            await addBroadCastFunction(e);
        }
    }

    return (
        <>
            {loading && <Loader />}
            <section>
                <Container fluid>
                    <Row className="vh-100">
                        <Col
                            xs={12}
                            md={12}
                            lg={3}
                            xl={2}
                            className="shadow-right d-none d-lg-block" >
                            <Sidbar />
                        </Col>
                        <Col xs={12} lg={9} xl={10} className="position-relative px-md-5">
                            <Header />
                            <img src="../images/png/bg-imag.png" className="bg-imag" alt="" />
                            <Row className="justify-content-between align-items-center pb-3">
                                <Col md={6} lg={5} xl={7}>
                                    <h1 className="title">Broadcast Notifications</h1>
                                </Col>
                                <Col md={6} lg={7} xl={5} className="mt-3 mt-md-0">
                                    <Form>
                                        <div className="d-flex justify-content-end gap-3">
                                            {/* <div
                                                className="form-control d-flex align-items-center px-3"
                                                style={{ width: "350px" }}
                                            >
                                                <input
                                                    className="form-control border-0 shadow-none pl-black"
                                                    placeholder="Search"
                                                    type="text"
                                                />
                                                <img
                                                    className='cursor-pointer'
                                                    src="../images/svg/search.svg"
                                                    alt="Search"
                                                />
                                            </div> */}
                                            <div
                                                onClick={() => {
                                                    getStudioFunction();
                                                    setLgShow(true);
                                                    setEditModel(false);
                                                    setBroadCastData({
                                                        type: "all",
                                                        method: "",
                                                        title: "",
                                                        desc: "",
                                                        isScheduled: false,
                                                        scheduleTime: ""
                                                    });
                                                }}
                                                className="cursor-pointer"
                                            >
                                                <img
                                                    src="../images/svg/add.svg"
                                                    className="add-btn"
                                                    alt=""
                                                />
                                            </div>
                                        </div>
                                    </Form>
                                </Col>
                            </Row>
                            <BroadCastNotificationTable
                                page={page}
                                perPage={perPage}
                                setPage={setPage}
                                lgShow={lgShow}
                                setLgShow={setLgShow}
                                handleEdit={handleEdit}
                                getBroadCastFunction={getBroadCastFunction}
                                getBroadCastData={getBroadCastData}
                                setGetBroadCastData={setGetBroadCastData}
                                removeModel={removeModel} setRemoveModel={setRemoveModel}
                            />
                        </Col>
                    </Row>
                </Container>
            </section >
            <Modal
                size="xl"
                show={lgShow}
                onHide={() => {
                    setLgShow(false);
                    setGetStudio([]);
                }}
                centered
                className="moadl-main info-modal"
            >
                <Modal.Header
                    className="d-block position-relative border-0"
                    closeButton
                >
                    <Modal.Title className="text-center">{editModel ? "Edit Broadcast Notification" : "Add Broadcast Notification"}</Modal.Title>
                </Modal.Header>
                <Modal.Body className="mx-md-4 border-0 bg-transparent pb-0">
                    {
                        editModel ?
                            <div className="d-flex justify-content-end ">
                                <button
                                    className="primary-button" onClick={() => {
                                        setRemoveModel(true);
                                        setLgShow();
                                    }}>
                                    Delete
                                </button>
                            </div>
                            : ""
                    }
                    <Form className="mt-4">
                        <Row>
                            <Col xs={12} lg={12}>
                                <div className="form-control d-flex align-items-center px-2 mb-3 gap-2 px-0 text-black w-100 text-dark inputHight">
                                    <span className="text-nowrap fs-6 fw-500">Type :</span>
                                    <div className="mx-3 gap-2 d-flex">
                                        <input style={{
                                            height: '20px', width: '20px', marginTop: "3px", marginLeft: "0px",
                                            marginRight: "0px"
                                        }}
                                            type="radio"
                                            className="defaultCheckbox form-check-input"
                                            name="type"
                                            id="smallCheckbox"
                                            checked={broadCastData.type === "all"}
                                            onChange={inputChange}
                                            value="all"
                                        />
                                        <label htmlFor="smallCheckbox" className="checkbox-label fw-500">All User</label>
                                    </div>

                                    <div className="mx-3 gap-2 d-flex">
                                        <input style={{ height: '20px', width: '20px', marginTop: "3px" }}
                                            type="radio"
                                            className="defaultCheckbox form-check-input"
                                            name="type"
                                            id="smallCheckbox2"
                                            checked={broadCastData.type === "booth"}
                                            onChange={inputChange}
                                            value="booth"
                                        />
                                        <label htmlFor="smallCheckbox2" className="checkbox-label fw-500">Booth</label>
                                    </div>
                                </div>
                            </Col>

                            {broadCastData.type === "booth" ?
                                <Col md={6} sm={6} xxl={6}>
                                    <div className=' form-control d-flex align-items-center px-0 pt-0 mb-3 gap-2 border-0 text-black w-100 text-dark'>
                                        <Select
                                            options={getStudio}
                                            className={editModel ? 'w-100 text-dark text-white' : ''}
                                            name='studioId'
                                            onChange={handleSelectChange}
                                            placeholder="Select Booth"
                                            defaultValue={getStudio?.find(option => option?.value === studioId)}
                                            isDisabled={editModel ? true : false}
                                        />
                                    </div>
                                </Col> :
                                ""
                            }
                            <Col md={broadCastData.type === "booth" ? 6 : 12}
                                sm={broadCastData.type === "booth" ? 6 : 12}
                                xxl={broadCastData.type === "booth" ? 6 : 12}>
                                <div className="form-control d-flex align-items-center px-1 mb-3 gap-2 px-0">
                                    <Form.Select
                                        className='bg-transparent w-100 border-0 text-black px-2 w-100 text-dark'
                                        name='method'
                                        value={broadCastData.method}
                                        onChange={inputChange}
                                    >
                                        <option className=''>Select Method</option>
                                        <option value="email">Email Only</option>
                                        <option value="inApp">In-App Notification</option>
                                        <option value="both">Both</option>
                                    </Form.Select>
                                </div>
                            </Col>

                            <Col md={12}>
                                <div className="form-control d-flex align-items-center px-1 mb-3">
                                    <div className="d-flex w-100">
                                        <input
                                            className="form-control border-0 shadow-none px-2 text-black w-100"
                                            placeholder="Enter Title"
                                            name="title"
                                            rows={3}
                                            type="text"
                                            value={broadCastData.title}
                                            onChange={inputChange}
                                        />
                                    </div>
                                </div>
                            </Col>

                            <Col md={12}>
                                <div className="form-control d-flex align-items-center px-1 mb-3">
                                    <div className="d-flex w-100">
                                        <textarea
                                            className="form-control border-0 shadow-none px-2 text-black w-100"
                                            placeholder="Enter Description"
                                            name="desc"
                                            rows={3}
                                            type="text"
                                            onChange={inputChange}
                                            value={broadCastData.desc}
                                        />
                                    </div>
                                </div>
                            </Col>

                            {broadCastData.type === "booth" ?
                                <Col md={6}>
                                    <div className="form-control d-flex align-items-center px-2 mb-3 gap-2 text-black w-100 text-dark inputHight">
                                        <span className='px-2'>Schedule Time :</span>
                                        <input
                                            style={{ height: '20px', width: '20px', marginRight: "40px" }}
                                            type="checkbox"
                                            name="isScheduled"
                                            className='mr-3'
                                            checked={broadCastData.isScheduled}
                                            onChange={inputChange}
                                        />
                                    </div>
                                </Col> : ""
                            }
                            {
                                broadCastData.isScheduled && broadCastData.type === "booth" &&
                                <Col xl={6}>
                                    <div className='border-0 btn-block d-flex'>
                                        <input
                                            type="datetime-local"
                                            name="scheduleTime"
                                            style={{ padding: '12px' }}
                                            placeholder="Start Date"
                                            className='form-control border '
                                            value={broadCastData.scheduleTime}
                                            onChange={inputChange}
                                            min={new Date().toISOString().split(":", 2).join(":")}
                                        />
                                    </div>
                                </Col>
                            }
                        </Row>

                        <div className="d-flex justify-content-end mb-2">
                            <button
                                className="secondary-button text-decoration-none"
                                onClick={(e) => handleSave(e)} >
                                Save
                            </button>
                        </div>
                    </Form>
                </Modal.Body>
            </Modal>

            <Modal
                size="md"
                show={removeModel}
                onHide={() => setRemoveModel(false)}
                centered
                className="moadl-main delete-modal"
            >

                <Modal.Header
                    className="d-block position-relative border-0"
                    closeButton
                >
                    <br />
                    <Modal.Title className="text-center">Delete Broadcast Notification</Modal.Title>
                </Modal.Header>

                <Modal.Body className="mx-4 border-0">
                    <p>Are you sure you want to delete?</p>
                </Modal.Body>
                <Modal.Footer className="border-0">
                    <div className="d-flex m-auto gap-2 pb-3">
                        <button
                            className="secondary-button text-decoration-none"
                            onClick={deleteNotificationFunction}
                        >
                            Delete
                        </button>
                        <Button
                            className="primary-button"
                            onClick={() => handleClose()}
                        >
                            Cancel
                        </Button>
                    </div>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default BroadCastNotification