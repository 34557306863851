import React, { useEffect, useState } from 'react';
import { getBooking } from "../controller/Api";
import { Modal } from 'react-bootstrap';
import moment from "moment";
import { Row, Col, Table } from "react-bootstrap";
import TablePagination from '../components/TablePagination';
import Loader from "../components/Loader";

const BookingModel = ({ bookingModel, setBookingModel, userId }) => {
    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(10);
    const [booking, setBooking] = useState();
    const [loading, setLoading] = useState(false);

    const Booking = async () => {
        try {
            setLoading(true);
            const result = await getBooking(userId, page);
            const { status } = result;
            if (status === 1) {
                setBooking(result);
            }
        } catch (error) {
            console.log(error);
        }
        setLoading(false);
    }

    useEffect(() => {
        if (userId) {
            Booking();
        }
    }, [userId, page])

    return (
        <div>
            {loading && <Loader />}
            <Modal
                size="xl"
                show={bookingModel}
                onHide={() => setBookingModel(false)}
                centered
                dialogClassName="modal-90w"
                className="moadl-main delete-modal"
            >
                <Modal.Header
                    className="d-block position-relative border-0"
                    closeButton
                >
                    <Modal.Title className="text-center">Booking</Modal.Title>
                </Modal.Header>
                <Modal.Body className="mx-4 border-0">
                    <Row>
                        <Col>
                            <Table bordered responsive="xl">
                                <thead>
                                    <tr>
                                        <th className='border-start'>No </th>
                                        <th>Date </th>
                                        <th>User Name </th>
                                        <th>Booth Title</th>
                                        <th>Booking Date & Time </th>
                                        <th>Email</th>
                                        <th>Payment ID</th>
                                        <th>Total Price </th>
                                        <th>Status</th>
                                    </tr>
                                </thead>
                                {Boolean(booking?.data?.length) ?
                                    <tbody>
                                        {
                                            booking?.data?.map((element, index) => {
                                                const currentNumber = (page - 1) * perPage + index + 1;
                                                const { created_at, booked_by, booking_date_time, status, studio, bookings, studio_name, total_price,
                                                    useWalletAmount, paymentForTotalAmount } = element
                                                const a = booking_date_time ? moment(booking_date_time[0]).utc().format("YYYY-MM-DD") : "-";
                                                const startTime = booking_date_time?.length > 0 ? String(new Date(booking_date_time[0]).getUTCHours()).padStart(2, '0') + ":00" : "-";
                                                const endTime = booking_date_time?.length > 0 ? String(new Date(booking_date_time[booking_date_time.length - 1]).getUTCHours() + 1).padStart(2, '0') + ":00" : "-";
                                                const timeRange = `${startTime}-${endTime}`;
                                                const mergedDateTime = `${a}, ${timeRange}`;
                                                const amount = isNaN(paymentForTotalAmount) ? 0 : paymentForTotalAmount;
                                                const wallet = isNaN(useWalletAmount) ? 0 : useWalletAmount;
                                                const totalAmount = amount + wallet;
                                                return (
                                                    <>
                                                        <tr>
                                                            <td>{currentNumber}</td>
                                                            <td>{created_at ? moment(created_at).format("YYYY-MM-DD, hh:mm:ss") : "-"}</td>
                                                            <td>{booked_by?.fullName ? booked_by?.fullName : "-"}</td>
                                                            <td>{studio?.studio_name ? studio?.studio_name : "-"}</td>
                                                            <td>{mergedDateTime}</td>
                                                            <td>{booked_by?.email ? booked_by?.email : "-"}</td>
                                                            <td>
                                                                <ul className="list-unstyled mb-0">
                                                                    {bookings ? (
                                                                        bookings?.map((booking, index) => (
                                                                            <li key={index}>
                                                                                <span>{booking?.payment?.transaction_id || "-"}</span>
                                                                            </li>
                                                                        ))
                                                                    ) : (
                                                                        <li><span>-</span></li>
                                                                    )}
                                                                </ul>
                                                            </td>
                                                            {/* <td>{payment?.transaction_id ? payment?.transaction_id : "-"}</td> */}
                                                            <td>AED {totalAmount ? totalAmount : "0"}</td>
                                                            <td>
                                                                {status === 0
                                                                    ? "Pending"
                                                                    : status === 1
                                                                        ? "Paid"
                                                                        : status === 2
                                                                            ? "Refund"
                                                                            : "-"}
                                                            </td>
                                                        </tr>
                                                    </>
                                                );
                                            })
                                        }
                                    </tbody>
                                    :
                                    <tbody>
                                        <tr>
                                            <td colSpan={10}>
                                                <div className='nodata-table'>
                                                    <h3 className="text-center p-5">Data Not Found</h3>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                }
                            </Table>
                            <div>
                                {
                                    (
                                        booking && booking.data?.length >= 1 && (
                                            <div className='d-md-flex justify-content-end align-items-center '>
                                                <TablePagination
                                                    currentPage={booking?.page}
                                                    totalPages={booking?.pages}
                                                    onPageChange={(newPage) => {
                                                        setPage(newPage);
                                                    }}
                                                />
                                            </div>
                                        )
                                    )
                                }
                            </div>
                        </Col>
                    </Row>
                </Modal.Body>
            </Modal>
        </div>
    )
}

export default BookingModel