import React, { useEffect, useState } from 'react'
import { Col, Form, Modal, Row, Table } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { editCancelPolicy, deleteCancelPolicy } from "../controller/Api";
import TablePagination from './TablePagination';

const CancelationPolicyTable = ({ policyAdd, perPage, setPage, page, CancelPolicy, admin }) => {
    const [show, setShow] = useState(false);
    const handleShow = () => setShow(true);
    const handleClose = () => setShow(false);
    const [lgShow, setLgShow] = useState(false);
    const navigate = useNavigate();
    const [modelOpen, setModelOpen] = useState();
    const [id, setId] = useState();
    const [cancelPolicyModel, setCancelPolicyModel] = useState({});
    const [editPolicyData, setEditPolicyData] = useState({});
    const childPolicyAdd = policyAdd;
    // const [childPolicyAdd, setChildPolicyData] = useState(policyAdd)

    const handleInputChange = (e, i) => {
        const { name, value } = e.target;
        if (name == "name" || name == "desc") {
            setEditPolicyData(prevState => ({
                ...prevState,
                [name]: value
            }));
        } else {
            if (/^\d*\.?\d*$/.test(value)) {
                setEditPolicyData(prevState => {
                    const newSlots = [...prevState.slots];
                    newSlots[i][name] = Number(value);
                    return {
                        ...prevState,
                        slots: newSlots
                    };
                });
            }
        }
    };

    const handleAddSlot = () => {
        const newSlot = { time: 1, amountPercentage: 1 }
        const updatedSlot = { ...editPolicyData }
        updatedSlot.slots.push(newSlot)
        setEditPolicyData(updatedSlot);
    };

    const handleEdit = (element) => {
        setLgShow(true);
        setId(element._id);
        setEditPolicyData(element);
    }

    const handleInfo = (element) => {
        setCancelPolicyModel(element)
        setEditPolicyData(element);
    }

    //Input Change
    const deleteRow = (index) => {
        const updatedSlots = [...editPolicyData.slots];
        updatedSlots.splice(index, 1);
        setEditPolicyData(prevState => ({
            ...prevState,
            slots: updatedSlots
        }));
    };

    //Edit Policy
    const editCancelPolicyFlow = async (e) => {
        e.preventDefault();
        if (editPolicyData.name.trim() === "") {
            toast.error("Policy name is required");
            return;
        }
        if (editPolicyData.slots.some(slot => !slot.amountPercentage || isNaN(slot.amountPercentage) || slot.amountPercentage <= 0 || slot.amountPercentage > 100 || slot.time <= 0 || isNaN(slot.time))) {
            toast.error("Please enter a cancellation amount between 1 and 100, and a positive lead time");
            return;
        }
        try {
            const result = await editCancelPolicy(id, editPolicyData);
            const { message, status } = result;
            if (status === 1) {
                toast.success(message);
                CancelPolicy();
                handleClose(true);
                setLgShow(false);
            } else {
                toast.error(message);
                handleClose(false);
            }
        } catch (error) {
            console.log(error);
        }
    }

    //Delete Policy
    const handleDelete = async () => {
        try {
            const result = await deleteCancelPolicy(id);
            const { message, status } = result;
            if (status === 1) {
                toast.success(message);
                setModelOpen(false);
                handleClose();
                if (childPolicyAdd?.data?.length === 1) {
                    setPage(1);
                } else {
                    CancelPolicy();
                }
            } else {
                toast.error(message);
                setModelOpen(false);
            }
        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        if (!lgShow) {
            setEditPolicyData({
                name: "",
                desc: "",
                slots: [{
                    time: 1,
                    amountPercentage: 1
                }]
            });
        }
    }, [lgShow]);

    return (
        <>
            <Row>
                <Col>
                    <Table bordered responsive="xl">
                        <thead>
                            <tr>
                                <th className='number-width border'>No</th>
                                <th>Name</th>
                                <th>Description</th>
                                {admin && <th>Sub Admin</th>}
                                <th style={{ width: "100px" }}>Action</th>
                            </tr>
                        </thead>
                        { Boolean(childPolicyAdd?.data?.length) ?
                            <tbody>
                                {
                                    childPolicyAdd?.data?.map((element, index) => {
                                        const { name, desc, userId } = element;
                                        const currentNumber = (page - 1) * perPage + index + 1;
                                        return (
                                            <tr>
                                                <td>{currentNumber}</td>
                                                <td style={{ width: "150px" }}>{name}</td>
                                                <td style={{ width: "500px", wordBreak: "break-all", whiteSpace: "break-spaces" }}>{desc}</td>
                                                {admin && <td style={{ width: "150px", wordBreak: "break-all", whiteSpace: "break-spaces" }}>{userId?.fullName || "-"}</td>}
                                                <td>
                                                    <div className="d-flex justify-content-center align-items-center gap-3">
                                                        <div title='Info' className='cursor-pointer'>
                                                            <img src="./images/svg/info.svg" alt=""
                                                                onClick={() => { handleInfo(element); handleShow(); }} />
                                                        </div>
                                                        <div title='Edit' className='d-flex gap-3 cursor-pointer'>
                                                            <img src="./images/svg/edit.svg" alt=""
                                                                onClick={() => handleEdit(element)}
                                                            />
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                        );
                                    })}
                            </tbody>
                            :
                            <tbody>
                                <tr>
                                    <td colSpan={10}>
                                    <div className='nodata-table'>
                                            <h3 className="text-center p-5">Data Not Found</h3>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        }
                    </Table>
                    <div className='d-md-flex justify-content-between align-items-center'>
                        <button className='secondary-button text-decoration-none' onClick={() => navigate("/dashboard")} >
                            <svg
                                stroke="currentColor"
                                fill="currentColor"
                                strokeWidth={0}
                                viewBox="0 0 24 24"
                                height="2em"
                                width="2em"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path d="M12.707 17.293 8.414 13H18v-2H8.414l4.293-4.293-1.414-1.414L4.586 12l6.707 6.707z" />
                            </svg>
                            Back
                        </button>
                        {
                            childPolicyAdd?.data && childPolicyAdd?.data?.length >= 1 &&
                            <TablePagination
                                currentPage={childPolicyAdd?.page}
                                totalPages={childPolicyAdd?.pages}
                                onPageChange={(newPage) => {
                                    setPage(newPage);
                                }}
                            />
                        }
                    </div>
                </Col>
            </Row>

            <Modal
                size={"xl"}
                show={lgShow}
                onHide={() => setLgShow(false)}
                centered
                className='moadl-main info-modal'
            >
                <Modal.Header className='d-block position-relative border-0' closeButton>
                    <Modal.Title className='text-center'>Edit Cancellation Policy</Modal.Title>
                </Modal.Header>
                <Modal.Body className='mx-md-4 border-0 bg-transparent pb-0'>
                    <div className="d-flex justify-content-end ">
                        <button
                            className="primary-button"
                            onClick={() => {
                                setModelOpen(true);
                                handleClose();
                                setLgShow(false);
                            }}
                        >
                            Delete
                        </button>
                    </div>
                    <Form className='mt-4'>
                        <Row>
                            <Col md={12}>
                                <div className="form-control d-flex align-items-center px-1 mb-3">
                                    <div className="d-flex w-100">
                                        <input
                                            className="form-control border-0 shadow-none px-0 px-3 text-black w-100"
                                            placeholder="Enter Name"
                                            name="name"
                                            type="text"
                                            value={editPolicyData.name}
                                            onChange={handleInputChange}
                                        />
                                    </div>
                                </div>
                            </Col>
                            <Col md={12}>
                                <div className="form-control d-flex align-items-center px-1 mb-3">
                                    <div className="d-flex w-100">
                                        <textarea
                                            className="form-control border-0 shadow-none px-0 px-3 text-black w-100"
                                            placeholder="Enter Description"
                                            name="desc"
                                            rows={3}
                                            type="text"
                                            value={editPolicyData.desc}
                                            onChange={handleInputChange}
                                        />
                                    </div>
                                </div>
                            </Col>

                            <Col md={12} lg={10} xl={12}>
                                <div className="cursor-pointer d-flex justify-content-end mb-3">
                                    <img
                                        src="../images/svg/add.svg"
                                        onClick={handleAddSlot}
                                        className="add-btn"
                                        alt=""
                                    />
                                </div>
                                <div className="overflow-auto">
                                    <Table striped bordered hover>
                                        <thead>
                                            <tr>
                                                <th className="border-start">Lead Time(hours)</th>
                                                <th className="border-start">Refund to Wallet</th>
                                                <th className="border-start">Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                editPolicyData?.slots?.map((e, i) => {
                                                    return (
                                                        <tr>
                                                            <td>
                                                                <div className="form-control d-flex align-items-center px-1 mb-3" key={i}>
                                                                    <div className="d-flex w-100">
                                                                        <input
                                                                            className="form-control border-0 shadow-none px-0 px-3 text-black w-100 ms-2"
                                                                            placeholder="Enter Lead Time"
                                                                            name="time"
                                                                            type="text"
                                                                            value={e.time}
                                                                            onChange={(e) => {
                                                                                handleInputChange(e, i);
                                                                            }}
                                                                        />
                                                                        <span className="ms-2 fw-500 mt-1 m-lg-1" style={{ height: "12px" }}>hours</span>
                                                                    </div>
                                                                </div>
                                                            </td>

                                                            <td>
                                                                <div className="form-control d-flex align-items-center px-1 mb-3">
                                                                    <div className="d-flex w-100">
                                                                        <input
                                                                            className="form-control border-0 shadow-none px-0 px-3 text-black w-100 ms-2"
                                                                            placeholder="Enter Cancellation Amount"
                                                                            name="amountPercentage"
                                                                            type="text"
                                                                            value={e.amountPercentage}
                                                                            onChange={(e) => {
                                                                                handleInputChange(e, i);
                                                                            }}
                                                                        />
                                                                        <span className="ms-2 fw-500 mt-1 m-lg-1" style={{ height: "12px" }}>%</span>
                                                                    </div>
                                                                </div>
                                                            </td>

                                                            <td onClick={() => deleteRow(i)}>
                                                                <img className='cursor-pointer' src="./images/svg/delete.svg" alt="" />
                                                            </td>
                                                        </tr>
                                                    );
                                                })
                                            }
                                        </tbody >
                                    </Table>
                                </div>
                                <div className="d-flex justify-content-end mb-3">
                                    <button
                                        className="secondary-button text-decoration-none"
                                        onClick={(e) => editCancelPolicyFlow(e)}
                                    >
                                        Save
                                    </button>
                                </div>
                            </Col>
                        </Row>
                    </Form>
                </Modal.Body>

            </Modal >

            <Modal
                show={show}
                size="xl"
                onHide={handleClose}
                centered
                className='moadl-main info-modal'
            >
                <Modal.Header className='d-block position-relative border-0' closeButton>
                    <Modal.Title className='text-center'>Cancellation Policy</Modal.Title>
                </Modal.Header>
                <Modal.Body className='mx-md-4 border-0 bg-transparent pb-0'>

                    <Form className='mt-4'>
                        <Row>
                            <Col md={12}>
                                <div className="overflow-auto">
                                    <Table striped bordered hover>
                                        <thead>
                                            <tr>
                                                <th className="border-start" style={{ width: "50%" }}>Lead Time</th>
                                                <th className="border-start">Refund to Wallet</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                cancelPolicyModel?.slots?.sort((a, b) => b.amountPercentage - a.amountPercentage)?.map((e, i) => (
                                                    <tr key={i}>
                                                        <td>{e.time} hours</td>
                                                        <td>{`${e.amountPercentage}%`}</td>
                                                    </tr>
                                                ))}
                                        </tbody>
                                    </Table>
                                </div>
                            </Col>
                        </Row>
                    </Form>
                </Modal.Body>
                <Modal.Footer className='mx-md-4 border-0 bg-transparent pt-0'>
                </Modal.Footer>
            </Modal>

            <Modal
                size="md"
                show={modelOpen}
                onHide={() => setModelOpen(false)}
                centered className='moadl-main delete-modal'
            >
                <Modal.Header className='d-block position-relative border-0' closeButton>
                    <Modal.Title className='text-center'>Delete Policy</Modal.Title>
                </Modal.Header>
                <Modal.Body className='mx-4 border-0'>
                    <p>Are you sure you want to delete?</p>
                </Modal.Body>
                <Modal.Footer className='border-0'>
                    <div className='d-flex m-auto gap-2 pb-3'>
                        <button className="primary-button"
                            onClick={() => {
                                handleDelete(editPolicyData?._id);
                            }}> Delete
                        </button>
                        <button className="primary-button"
                            onClick={() => setModelOpen(false)}
                        >Cancel</button>
                    </div>
                </Modal.Footer>
            </Modal >
        </>
    )
}

export default CancelationPolicyTable