import React, { useContext, useEffect, useRef, useState } from 'react'
import { Col, Container, Form, Modal, Row } from 'react-bootstrap';
import Sidbar from '../layout/Sidbar';
import Header from '../layout/Header';
import { CartContext } from '../context/CartContext';
import Loader from "../components/Loader";
import BoothTable from '../components/BoothTable';
import { boothAdd, boothGet, getSubAdmin } from '../controller/Api';
import { toast } from 'react-toastify';

const Booth = () => {
    const { admin } = useContext(CartContext);
    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(10);
    const [search, setSearch] = useState();
    const ref = useRef(null);
    const [loading, setLoading] = useState(false);
    const [lgShow, setLgShow] = useState(false);
    const [listEquipments, setListEquipments] = useState([]);
    const [findSubAdmin, setFindSubAdmin] = useState([]);
    const [userId, setUserId] = useState();
    const [getBoothList, setGetBoothList] = useState();
    const [boothType, setBoothType] = useState({
        name: "",
        image: null
    });

    const inputValChange = (e) => {
        const { name, value, files } = e.target;
        setBoothType((oldVal) => {
            if (name === 'image') {
                const image = files[0];
                const previewUrl = URL.createObjectURL(image);
                return {
                    ...oldVal,
                    image: image,
                    previewUrl: previewUrl,
                };
            } else {
                return {
                    ...oldVal,
                    [name]: value,
                };
            }
        });
    };

    const inputHandle = (e) => {
        setSearch(e.target.value);
    }

    const boothTypeAdd = async () => {
        if(!boothType.name){
            toast.error("Booth name is required");
            return
        }
        if(!boothType.image){
            toast.error("Booth image is required");
            return
        }
        try {
            const formData = new FormData();
            formData.append('name', boothType.name);
            if (boothType.image) {
                formData.append('image', boothType.image);
            }
            const result = await boothAdd(formData, userId);
            const { status, message } = result;
            if (status === 1) {
                toast.success(message);
                setBoothType({ name: "", image: null });
                getBoothApi();
                setLgShow(false);
            } else {
                toast.error(message);
            }
        } catch (error) {
            console.log(error);
        }
    };


    useEffect(() => {
        const callback = (event) => {
            if (event.key === 'Enter' && ref.current) {
                event.preventDefault();
                ref.current?.click();
            }
        };
        document.addEventListener('keydown', callback);
        return () => {
            document.removeEventListener('keydown', callback);
        };
    }, []);

    const getBoothApi = async (searchVal) => {
        const pageVal = searchVal ? 1 : page;
        try {
            setLoading(true);
            const result = await boothGet(pageVal, perPage, search);
            const { message, status } = result;
            if (status === 1) {
                const { page } = result;
                setPage(page);
                setGetBoothList(result);
            } else {
                toast.error(message);
            }
        } catch (error) {
            console.log(error);
        }
        setLoading(false);
    }

    useEffect(() => {
        document.title = "Music Booth | Booth"
        getBoothApi();
    }, [page]);

    return (
        <>
            {loading && <Loader />}
            <section>
                <Container fluid>
                    <Row className="vh-100">
                        <Col
                            xs={12}
                            md={12}
                            lg={3}
                            xl={2}
                            className="shadow-right d-none d-lg-block"
                        >
                            <Sidbar />
                        </Col>
                        <Col xs={12} lg={9} xl={10} className="position-relative px-md-5">
                            <Header />
                            <img src="../images/png/bg-imag.png" className="bg-imag" alt="" />
                            <Row className="justify-content-between align-items-center pb-3">
                                <Col md={6} lg={5} xl={7}>
                                    <h1 className="title">Booth</h1>
                                </Col>
                                <Col md={6} lg={7} xl={5} className={`${admin ? "mt-3 mt-md-0 d-flex gap-2 justify-content-end" : "mt-3 mt-md-0"}`}>
                                    {/* {admin && (
                                        <Form.Select
                                            style={{ width: "200px", height: "50px" }}
                                            className="select-bg cursor-pointer"
                                            aria-label="Default select example"
                                            value={selectedSubAdminId}
                                            onChange={(e) => {
                                                setSelectedSubAdminId(e.target.value);
                                                setPage(1)
                                            }}
                                        >
                                            <option value={""}>Select Company</option>
                                            {findSubAdmin?.map((item, index) => (
                                                <option key={index} value={item._id}>
                                                    {item.fullName}
                                                </option>
                                            ))}
                                        </Form.Select>
                                    )} */}
                                    <Form>
                                        <div className="d-flex justify-content-end gap-3">
                                            <div className="form-control d-flex align-items-center px-3" style={{ width: "350px" }}>
                                            <input
                                                    className="form-control border-0 shadow-none pl-black"
                                                    placeholder="Search"
                                                    type="text"
                                                    value={search}
                                                    onChange={inputHandle}
                                                />
                                                <img
                                                    className='cursor-pointer'
                                                    ref={ref}
                                                    src="../images/svg/search.svg"
                                                    alt="Search"
                                                    onClick={getBoothApi}
                                                />
                                            </div>
                                            <div
                                                onClick={() => setLgShow(true)}
                                                className="cursor-pointer"
                                            >
                                                <img
                                                    src="../images/svg/add.svg"
                                                    className="add-btn"
                                                    alt=""
                                                />
                                            </div>
                                        </div>
                                    </Form>
                                </Col>
                            </Row>
                            <BoothTable
                                page={page}
                                perPage={perPage}
                                setPage={setPage}
                                boothType={boothType}
                                getBoothList={getBoothList}
                                getBoothApi={getBoothApi}
                                setGetBoothList={setGetBoothList}
                            />
                        </Col>
                    </Row>
                </Container>
            </section>

            <Modal
                size="xl"
                show={lgShow}
                onHide={() => {
                    setLgShow(false)
                    setBoothType({
                        name: "",
                        image: null,
                    })
                }}
                centered
                className='moadl-main info-modal'
            >
                <Modal.Header className='d-block position-relative border-0' closeButton>
                    <Modal.Title className='text-center'>Add Booth</Modal.Title>
                </Modal.Header>
                <Modal.Body className='mx-md-4 border-0 bg-transparent pb-0'>
                    <Form className='mt-4'>
                        <Row>
                            {/* {admin &&
                                <Col md={12}>
                                    <div className="">
                                        <Form.Select
                                            style={{ width: "200px", height: "50px" }}
                                            className="select-bg cursor-pointer mb-3"
                                            aria-label="Default select example"
                                            value={userId}
                                            onChange={(e) => { setUserId(e.target.value) }}
                                        >
                                            <option value={""}>Select Company</option>
                                            {findSubAdmin?.map((item, index) => (
                                                <option key={index} value={item._id}>
                                                    {item.fullName}
                                                </option>
                                            ))}
                                        </Form.Select>
                                    </div>
                                </Col>
                            } */}
                            <Col md={12}>
                                <div className='form-control d-flex align-items-center px-1 mb-3'>
                                    <input className='form-control border-0 shadow-none px-0 px-2 text-black w-100'
                                        placeholder='Enter Booth Type'
                                        type="text"
                                        name='name'
                                        value={boothType.name}
                                        onChange={inputValChange}
                                    />
                                </div>
                            </Col>
                            <Col md={12}>
                                <div className="form-control d-flex align-items-center px-1 mb-2">
                                    <div className="d-flex w-100">
                                        <input type="file"
                                            name='image'
                                            onChange={inputValChange}
                                        />
                                    </div>
                                </div>
                                {/* <p className="placeholder-black">The image ratio must be 40:45 for example, dimensions could be 400x450 pixels.</p>  */}
                                <p className="placeholder-black">The image should have a 40:45 aspect ratio. For example, the dimensions could be 400x450 pixels.</p> 
                            </Col>

                            {boothType.previewUrl && (
                                <Col md={12}>
                                    <div>
                                        <img
                                            style={{ height: "120px", width: "120px" }}
                                            src={boothType.previewUrl}
                                            alt="Preview"
                                        />
                                    </div>
                                </Col>
                            )}

                            {/* {imagePreviewUrl && (
                                <Col md={12}>
                                    <div>
                                        <img style={{ height: "120px", weight: "120px" }} src={imagePreviewUrl} alt="Preview" />
                                    </div>
                                </Col>
                            )} */}

                        </Row>
                    </Form>
                </Modal.Body>
                <Modal.Footer className='mx-md-4 border-0 bg-transparent pt-0'>
                    <button
                        className='secondary-button text-decoration-none'
                        onClick={boothTypeAdd}
                    >
                        Add
                    </button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default Booth