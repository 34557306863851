import axios from "axios";

const axiosInstance = axios.create();
axiosInstance.interceptors.response.use(
  response => response,
);

export default {
  get: async (URL) => axiosInstance.get(URL),
  delete: async (URL) => axiosInstance.delete(URL),
  post: async (URL, DATA) => axiosInstance.post(URL, DATA),
  getWithToken: async (URL) =>
    axiosInstance.get(URL, {
      headers: {
        Authorization: `${localStorage.getItem("token")}`,
      },
    }),
  deleteWithToken: async (URL) =>
    axiosInstance.delete(URL, {
      headers: {
        Authorization: `${localStorage.getItem("token")}`,
      },
    }),
  postWithToken: async (URL, DATA) =>
    axiosInstance.post(URL, DATA, {
      headers: {
        Authorization: `${localStorage.getItem("token")}`,
      },
    }),
  putWithToken: async (URL, DATA) =>
    axiosInstance.put(URL, DATA, {
      headers: {
        Authorization: ` ${localStorage.getItem("token")}`,
      },
    }),
  patchWithToken: async (URL, DATA) =>
    axiosInstance.patch(URL, DATA, {
      headers: {
        Authorization: ` ${localStorage.getItem("token")}`,
      },
    }),
};
