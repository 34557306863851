import { Suspense, Fragment } from "react"
import { BrowserRouter, Routes, Route } from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';
import './assets/font/css2.css'
import './assets/css/App.css'
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useRouter } from "./helper/route"
import DeleteAccountPage from "./pages/DeleteAccountPage";
// import route from "./helper/route"
// import Login from "./auth/Login";
// import Register from "./auth/Register";
// import Dashboard from "./pages/Dashboard";
// import UserDetails from "./pages/UserDetails";
// import Services from "./pages/Services";
// import Booking from "./pages/Booking";
// import StudioRental from "./pages/StudioRental";
// import Payment from "./pages/Payment";
// import ContactUs from "./pages/ContactUs";
function App() {
  const route = useRouter();
  return (
    <>
      {/* <BrowserRouter>
        <Routes>
          <Route path='/' element={<Login />} />
          <Route path='/register' element={<Register />} />
          <Route path='/dashboard' element={<Dashboard />} />
          <Route path='/user-details' element={<UserDetails />} />
          <Route path='/services' element={<Services />} />
          <Route path='/booking' element={<Booking />} />
          <Route path='/payment' element={<Payment />} />
          <Route path='/contact-us' element={<ContactUs />} />
          <Route path='/contact-us-edit' element={<ContactUsEdit />} />
          <Route path='/about-us' element={<AboutUs />} />
          <Route path='/about-us-edit' element={<AboutUsEdit />} />
          <Route path='/terms-condition' element={<TermsAndConditions />} />
          <Route path='/terms-condition-edit' element={<TermsAndConditionsEdit />} />
          <Route path='/studio-rental' element={<StudioRental />} />
        </Routes>
      </BrowserRouter> */}
      <Fragment>
        <BrowserRouter>
          <ToastContainer />
          <Suspense fallback={<div></div>}>
            <Routes>
              {
                route?.map((route, index) => {
                  return (
                    <Route key={index} exact path={route.path} element={route.element} />
                  );
                })
              }
              {/* <Route exact path={"/delete-account"} element={<DeleteAccountPage />} /> */}
            </Routes>
          </Suspense>
        </BrowserRouter>
      </Fragment>
    </>
  );
}

export default App;
