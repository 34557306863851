import React, { useState } from 'react'
import { Col, Form, Modal, Row, Table } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import TablePagination from "../components/TablePagination";
import { getPartnerDetails, deleteSingleUser, editUser } from '../controller/Api';
import { toast } from 'react-toastify';
import moment from 'moment';
import PhoneInput from 'react-phone-input-2';

const PartnersTable = ({ perPage, page, setPage, getPartnerData, getPartnerApiGet }) => {
    const [showInfo, setShowInfo] = useState(false);
    const [show, setShow] = useState(false);
    const [id, setId] = useState();
    const navigate = useNavigate();
    const [modelOpen, setModelOpen] = useState();
    const [partnerDetails, setPartnerDetails] = useState();
    const [editData, setEditData] = useState({
        company_name: '',
        address: '',
        phone_no: '',
        company_website: ''
    });
    const handleShow = () => setShow(true);
    const handleClose = () => setShow(false);
    const toggleInfo = () => {
        setShowInfo(prevShowInfo => !prevShowInfo);
    };

    const getPartnerDetailsApi = async (id) => {
        try {
            const result = await getPartnerDetails(id);
            const { data, status, message } = result;
            if (status === 1) {
                setPartnerDetails(data)
            } else {
                toast.error(message);
            }
        } catch (error) {
            console.log('error: ', error);
        }
    }

    //edit Partner
    const editPartner = async () => {
        try {
            if (!editData.company_name.trim()) {
                toast.error("Company name is required")
                return
            }
            if (!editData.company_website.trim()) {
                toast.error("Company website is required")
                return
            }
            if (!editData.address.trim()) {
                toast.error("address is required")
                return
            }
            if (!editData.phone_no.trim()) {
                toast.error("phone number is required")
                return
            }
            const formData = new FormData();
            formData.append('company_website', editData.company_website);
            formData.append('company_name', editData.company_name);
            formData.append('address', editData.address);
            formData.append('phone_no', editData.phone_no);

            // Object.entries(editData).forEach(([key, value]) => {
            //     formData.append(key, value);
            // });

            const result = await editUser(id, formData);
            const { status, message } = result;
            if (status === 1) {
                toast.success(message);
                getPartnerApiGet();
                setShow(false);
                setEditData({
                    company_name: '',
                    address: '',
                    phone_no: '',
                    company_website: ''
                });
            } else {
                toast.error(message);
            }

        } catch (error) {
            console.log('error: ', error);
        }
    };

    const handleEdit = (element) => {
        setEditData({
            company_name: element.company_name || '',
            address: element.address || '',
            phone_no: element.phone_no || '',
            company_website: element.company_website || ''
        });
    }

    const deleteApi = async (id) => {
        try {
            const result = await deleteSingleUser(id);
            const { message } = result;
            getPartnerApiGet();
            setModelOpen(false);
            toast.success(message);
        } catch (error) {
            toast.error(error);
        }
    }

    const inputValChange = (e) => {
        const { name, value } = e.target;
        setEditData((prev) => {
            return { ...prev, [name]: value }
        })
    }

    return (
        <>
            <Row>
                <Col>
                    <Table bordered responsive="xl">
                        <thead>
                            <tr>
                                <th className='number-width border'>No</th>
                                <th style={{ width: "200px", wordBreak: "break-all", whiteSpace: "break-spaces" }}> Company name </th>
                                <th style={{ width: "200px", wordBreak: "break-all", whiteSpace: "break-spaces" }}> Full Address</th>
                                <th style={{ width: "150px", wordBreak: "break-all", whiteSpace: "break-spaces" }}> Phone Number</th>
                                <th style={{ width: "100px", wordBreak: "break-all", whiteSpace: "break-spaces" }}> Website</th>
                                <th style={{ width: "200px", wordBreak: "break-all", whiteSpace: "break-spaces" }}> Sub Admin</th>
                                <th style={{ width: "200px", wordBreak: "break-all", whiteSpace: "break-spaces" }}> Onboarded on</th>
                                <th style={{ width: "100px", wordBreak: "break-all", whiteSpace: "break-spaces" }}> Action </th>
                            </tr>
                        </thead>
                        {Boolean(getPartnerData?.data?.length) ?
                            <tbody>
                                {
                                    getPartnerData?.data?.map((element, index) => {
                                        const { _id, fullName, phone_no, address, company_name, company_website, created_at } = element;
                                        const currentNumber = (page - 1) * perPage + index + 1;
                                        return (
                                            <tr>
                                                <td>{currentNumber}</td>
                                                <td>{company_name || "-"}</td>
                                                <td>{address || "-"}</td>
                                                <td>{"+" + phone_no || "-"}</td>
                                                <td><a href={company_website} target="_blank" className="link-href">{company_website || "-"}</a></td>
                                                <td>{fullName || "-"}</td>
                                                <td>{moment(created_at).format('YYYY-MM-DD, hh:mm:ss') || "-"}</td>
                                                <td>
                                                    <div className="d-flex justify-content-center align-items-center gap-3" title='Edit'>
                                                        <button
                                                            className="border-0 bg-transparent"
                                                            onClick={() => {
                                                                toggleInfo();
                                                                setId(_id);
                                                                getPartnerDetailsApi(_id);
                                                            }}
                                                        >
                                                            <svg width="10" height="24" viewBox="0 0 10 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M4.64865 23.5203C4.28527 23.445 3.90859 23.4006 3.55407 23.281C2.08724 22.7891 1.3782 21.5837 1.52887 19.9928C1.64852 18.7431 2.04736 17.5643 2.36643 16.3634C2.65891 15.26 2.96468 14.1609 3.23943 13.0575C3.37238 12.5346 3.41669 11.9984 3.31477 11.4577C3.23057 11.0101 2.97797 10.7398 2.53039 10.6423C1.90112 10.5049 1.28957 10.5626 0.682452 10.7575C0.46974 10.824 0.252596 10.8949 0 10.9791C0.106356 10.5537 0.194986 10.1637 0.301343 9.77375C0.314637 9.72057 0.376678 9.66739 0.429856 9.64524C1.43581 9.2597 2.4329 8.82984 3.50532 8.63928C4.56002 8.45316 5.60143 8.47532 6.58965 8.95835C7.6222 9.46354 8.16727 10.5537 8.11852 11.7015C8.05648 13.2525 7.52027 14.6794 7.13916 16.1507C6.891 17.1212 6.62067 18.0828 6.39024 19.0578C6.27502 19.5364 6.21298 20.0283 6.31047 20.5246C6.42569 21.0963 6.69158 21.34 7.26324 21.4375C8.02103 21.5704 8.73894 21.4153 9.44798 21.1672C9.51445 21.145 9.58535 21.1184 9.68728 21.0785C9.58535 21.504 9.49229 21.9028 9.38594 22.2972C9.37264 22.3415 9.3106 22.3903 9.26185 22.408C8.46418 22.7049 7.67094 23.0195 6.86441 23.2854C6.49216 23.4095 6.08889 23.445 5.70335 23.5159C5.35326 23.5203 5.00317 23.5203 4.64865 23.5203Z" fill="#292D32"></path><path d="M7.32516 6.16221C5.98241 6.1046 5.06509 5.59498 4.56432 4.50039C3.99266 3.25071 4.59091 1.79717 5.88935 1.16347C7.07256 0.582939 8.52166 0.875419 9.38581 1.87251C10.5646 3.23298 9.9752 5.33352 8.22475 5.95836C7.89239 6.08244 7.529 6.11789 7.32516 6.16221Z" fill="#292D32"></path></svg>
                                                        </button>
                                                        <button
                                                            className="border-0 bg-transparent"
                                                            onClick={() => {
                                                                handleShow();
                                                                setId(_id);
                                                                handleEdit(element);
                                                            }}
                                                        >
                                                            <img src="../images/png/edit.png" style={{ height: "18px" }} alt="" />
                                                        </button>
                                                    </div>
                                                </td>
                                            </tr>
                                        );
                                    })
                                }
                            </tbody>
                            :
                            <tbody>
                                <tr>
                                    <td colSpan={10}>
                                        <div className='nodata-table'>
                                            <h3 className="text-center p-5">Data Not Found</h3>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        }
                    </Table>
                    <div className='d-md-flex justify-content-between align-items-center pb-3'>
                        <button className='secondary-button text-decoration-none' onClick={() => navigate("/dashboard")} >
                            <svg
                                stroke="currentColor"
                                fill="currentColor"
                                strokeWidth={0}
                                viewBox="0 0 24 24"
                                height="2em"
                                width="2em"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path d="M12.707 17.293 8.414 13H18v-2H8.414l4.293-4.293-1.414-1.414L4.586 12l6.707 6.707z" />
                            </svg>
                            Back
                        </button>
                        {
                            getPartnerData?.data && getPartnerData?.data?.length >= 1 &&
                            <TablePagination
                                currentPage={getPartnerData?.page}
                                totalPages={getPartnerData?.pages}
                                onPageChange={(newPage) => {
                                    setPage(newPage);
                                }}
                            />
                        }
                    </div>
                </Col>
            </Row>

            {/* info */}
            < Modal
                show={showInfo}
                size="lg"
                className="moadl-main info-modal"
                centered
                onHide={() => { setShowInfo(false) }}
            >
                <Modal.Header
                    className="d-block position-relative border-0"
                    closeButton
                >
                    <Modal.Title className="text-center">Info</Modal.Title>
                </Modal.Header>

                <Modal.Body className="mx-4 border-0 mb-4">
                    <Row className="modal-body-list">
                        <Col className='col-3'>Booth : </Col>
                        <Col className='col-9 fw-bold' style={{ textTransform: 'capitalize' }}>{partnerDetails?.booth || "0"}</Col>
                        <Col className='col-3'>Booking :</Col>
                        <Col className='col-9 fw-bold' style={{ textTransform: 'capitalize' }}>{partnerDetails?.bookings || "0"}</Col>
                        <Col className='col-3'>Booking amount :</Col>
                        <Col className='col-9 fw-bold'>{partnerDetails?.bookingsAmount || "0"}</Col>
                    </Row>
                </Modal.Body>
            </Modal>

            {/* Edit Recital hall  */}
            <Modal
                show={show}
                size="xl"
                onHide={handleClose}
                centered
                className='moadl-main info-modal'
            >
                <Modal.Header className='d-block position-relative border-0' closeButton>
                    <Modal.Title className='text-center'>Edit Partners</Modal.Title>
                </Modal.Header>
                <Modal.Body className='mx-md-4 border-0 bg-transparent pb-0'>
                    <div className="d-flex justify-content-end ">
                        <button
                            className="primary-button"
                            onClick={() => {
                                setModelOpen(true)
                                handleClose();
                            }}>
                            Delete
                        </button>
                    </div>
                    <Form className='mt-4'>
                        <Row>
                            <Col md={12}>
                                <div className='form-control d-flex align-items-center px-1 mb-3'>
                                    <input className='form-control border-0 shadow-none px-0 px-2 text-black w-100'
                                        placeholder='Enter company name'
                                        type="text"
                                        name='company_name'
                                        value={editData?.company_name}
                                        onChange={inputValChange}
                                    />

                                </div>
                            </Col>
                            <Col md={12}>
                                <div className='form-control d-flex align-items-center px-1 mb-3'>
                                    <input className='form-control border-0 shadow-none px-0 px-2 text-black w-100'
                                        placeholder='Enter Address'
                                        type="text"
                                        name='address'
                                        value={editData?.address}
                                        onChange={inputValChange}
                                    />
                                </div>
                            </Col>
                            {/* <Col md={12}>
                                <div className='form-control d-flex align-items-center px-1 mb-3'>
                                    <input className='form-control border-0 shadow-none px-0 px-2 text-black w-100'
                                        placeholder='Enter phone no'
                                        type="text"
                                        name='phone_no'
                                        value={editData?.phone_no}
                                        onChange={inputValChange}
                                    />

                                </div>
                            </Col> */}

                            <Col md={12}>
                                <div className='form-control d-flex align-items-center px-3 mb-3 number w-100'>
                                    <PhoneInput
                                        inputClass="form-control border-0 shadow-none pl-black"
                                        country="ae"
                                        name="phone_no"
                                        value={editData?.phone_no}
                                        onChange={(e) => setEditData((oldVal) => ({
                                            ...oldVal,
                                            phone_no: e
                                        }))}
                                        enableSearch={true}
                                        disableSearchIcon={false}
                                    />
                                </div>
                            </Col>

                            <Col md={12}>
                                <div className='form-control d-flex align-items-center px-1 mb-3'>
                                    <input className='form-control border-0 shadow-none px-0 px-2 text-black w-100'
                                        placeholder='Enter Website'
                                        type="text"
                                        name='company_website'
                                        value={editData?.company_website}
                                        onChange={inputValChange}
                                    />

                                </div>
                            </Col>
                        </Row>
                    </Form>
                </Modal.Body>
                <Modal.Footer className='mx-md-4 border-0 bg-transparent pt-0'>
                    <button
                        onClick={editPartner}
                        className='secondary-button text-decoration-none' >
                        Save
                    </button>
                </Modal.Footer>
            </Modal>


            {/* Delete Recital hall  */}
            <Modal
                size="md"
                show={modelOpen}
                onHide={() => setModelOpen(false)}
                centered className='moadl-main delete-modal'
            >
                <Modal.Header className='d-block position-relative border-0' closeButton>
                    <Modal.Title className='text-center'>Delete Partners</Modal.Title>
                </Modal.Header>
                <Modal.Body className='mx-4 border-0'>
                    <p>Are you sure you want to delete?</p>
                </Modal.Body>
                <Modal.Footer className='border-0'>
                    <div className='d-flex m-auto gap-2 pb-3'>
                        <button className="primary-button" onClick={() => {
                            deleteApi(id);
                        }}>Delete</button>
                        <button className="primary-button" onClick={() => setModelOpen(false)}>Cancel</button>
                    </div>
                </Modal.Footer>
            </Modal >
        </>
    )
}

export default PartnersTable