export const getServerURL = () => {
    return process.env.REACT_APP_API_BASE_URL_CLIENT;
  };
  
export const serverADMIN = () => {
    // return `${process.env.REACT_APP_API_BASE_URL_ADMIN}api/admin/`;
    return `${process.env.REACT_APP_API_BASE_URL_ADMIN}/api/admin/`;
  };
  
  export const pf = () => {
    return `${process.env.REACT_APP_API_BASE_URL_ADMIN}/`;
  };
