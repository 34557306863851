import { useState, useEffect, useContext } from 'react';
import { Container, Row, Col, Table } from 'react-bootstrap';
import TablePagination from './TablePagination';
import Modal from 'react-bootstrap/Modal';
import { useNavigate } from 'react-router-dom';
import moment from "moment";
import { CartContext } from "../context/CartContext";
import Loader from './Loader';
import { BiSortAlt2 } from 'react-icons/bi';

const PaymentTable = ({ page, setPage, perPage, paymentData, paymentOrder, admin }) => {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [info, setInfo] = useState();
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    
    const handleInfo = (element) => {
        setInfo(element);
    }

    return (
        <>
            {loading && <Loader />}
            <Row>
                <Col>
                    <Table bordered responsive="xl">
                        <thead>
                            <tr>
                                <th className='number-width border'>No</th>
                                <th>Payment ID</th>
                                <th>Paid Type</th>
                                <th>Name </th>
                                <th>Paid By </th>
                                <th>Payment Amount </th>
                                <th>Payment Date <BiSortAlt2 className="fs-4 cursor-pointer" onClick={paymentOrder}></BiSortAlt2></th>
                                {/* {<th>Sub Admin</th>} */}
                                <th>Actions</th>
                            </tr>
                        </thead>
                        {Boolean(paymentData?.data?.length) ?
                            <tbody>
                                {
                                    paymentData?.data?.map((element, index) => {
                                        const { customer_details, payment_amount, payment_date, payment_info, transaction_id, } = element;
                                        const formattedDateTime = payment_date ? moment(payment_date).format("YYYY-MM-DD, HH:mm:ss") : "-";
                                        const currentNumber = (page - 1) * perPage + index + 1;
                                        return (
                                            <tr>
                                                <td>{currentNumber}</td>
                                                <td>{transaction_id || "-"}</td>
                                                <td>{payment_info?.payment_method || "-"}</td>
                                                <td>{customer_details?.name || "-"}</td>
                                                <td>{customer_details?.email || "-"}</td>
                                                <td>{payment_amount ? `AED ${payment_amount}` : "AED 0"}</td>
                                                <td>{formattedDateTime}</td>
                                                <td>
                                                    <div className='d-flex justify-content-center align-items-center gap-3'>
                                                        <div title='Info'>
                                                            <span className='cursor-pointer'
                                                                onClick={() => {
                                                                    handleShow();
                                                                    handleInfo(element);
                                                                }}>
                                                                <svg width="10" height="24" viewBox="0 0 10 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                    <path d="M4.64865 23.5203C4.28527 23.445 3.90859 23.4006 3.55407 23.281C2.08724 22.7891 1.3782 21.5837 1.52887 19.9928C1.64852 18.7431 2.04736 17.5643 2.36643 16.3634C2.65891 15.26 2.96468 14.1609 3.23943 13.0575C3.37238 12.5346 3.41669 11.9984 3.31477 11.4577C3.23057 11.0101 2.97797 10.7398 2.53039 10.6423C1.90112 10.5049 1.28957 10.5626 0.682452 10.7575C0.46974 10.824 0.252596 10.8949 0 10.9791C0.106356 10.5537 0.194986 10.1637 0.301343 9.77375C0.314637 9.72057 0.376678 9.66739 0.429856 9.64524C1.43581 9.2597 2.4329 8.82984 3.50532 8.63928C4.56002 8.45316 5.60143 8.47532 6.58965 8.95835C7.6222 9.46354 8.16727 10.5537 8.11852 11.7015C8.05648 13.2525 7.52027 14.6794 7.13916 16.1507C6.891 17.1212 6.62067 18.0828 6.39024 19.0578C6.27502 19.5364 6.21298 20.0283 6.31047 20.5246C6.42569 21.0963 6.69158 21.34 7.26324 21.4375C8.02103 21.5704 8.73894 21.4153 9.44798 21.1672C9.51445 21.145 9.58535 21.1184 9.68728 21.0785C9.58535 21.504 9.49229 21.9028 9.38594 22.2972C9.37264 22.3415 9.3106 22.3903 9.26185 22.408C8.46418 22.7049 7.67094 23.0195 6.86441 23.2854C6.49216 23.4095 6.08889 23.445 5.70335 23.5159C5.35326 23.5203 5.00317 23.5203 4.64865 23.5203Z" fill="#292D32" />
                                                                    <path d="M7.32516 6.16221C5.98241 6.1046 5.06509 5.59498 4.56432 4.50039C3.99266 3.25071 4.59091 1.79717 5.88935 1.16347C7.07256 0.582939 8.52166 0.875419 9.38581 1.87251C10.5646 3.23298 9.9752 5.33352 8.22475 5.95836C7.89239 6.08244 7.529 6.11789 7.32516 6.16221Z" fill="#292D32" />
                                                                </svg>
                                                            </span>
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                        );
                                    })
                                }
                            </tbody>
                            :
                            <tbody>
                                <tr>
                                    <td colSpan={10}>
                                    <div className='nodata-table'>
                                            <h3 className="text-center p-5">Data Not Found</h3>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        }
                    </Table>

                    <div className='d-md-flex justify-content-between align-items-center'>
                        <button className='secondary-button text-decoration-none' onClick={() => navigate("/dashboard")} >
                            <svg
                                stroke="currentColor"
                                fill="currentColor"
                                strokeWidth={0}
                                viewBox="0 0 24 24"
                                height="2em"
                                width="2em"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path d="M12.707 17.293 8.414 13H18v-2H8.414l4.293-4.293-1.414-1.414L4.586 12l6.707 6.707z" />
                            </svg>
                            Back
                        </button>
                        {
                            paymentData && paymentData.data?.length >= 1 &&
                            <TablePagination
                                currentPage={paymentData?.page}
                                totalPages={paymentData?.pages}
                                onPageChange={(newPage) => {
                                    setPage(newPage);
                                }}
                            />
                        }
                    </div>
                </Col>
            </Row>
            {/* Info moadl */}
            <Modal show={show} size="lg" className='moadl-main info-modal' centered onHide={handleClose}>
                <Modal.Header className='d-block position-relative border-0' closeButton>
                    <Modal.Title className='text-center'>Info</Modal.Title>
                </Modal.Header>
                <Modal.Body className='mx-4 border-0 mb-4'>
                    <Row>
                        <Col>
                            <ul className='modal-body-list ps-3 pt-3'>
                                <li><span>Payment Id:</span><p>{info?.transaction_id ? info?.transaction_id : "-"}</p></li>
                                <li><span>User Name:</span><p>{info?.paid_by?.fullName ? info?.paid_by?.fullName : "-"}</p></li>
                                <li><span>Payment Method:</span><p >{info?.payment_info?.payment_method || "-"}</p></li>
                                <li><span>Card Type:</span><p>{info?.payment_info?.card_type || "-"}</p></li>
                                <li><span>Payment Amount:</span><p>AED {info?.payment_amount || "-"}  </p></li>
                                <li><span>Status:</span><p style={{ textTransform: 'capitalize' }}>{info?.payment_status || "-"}</p></li>
                                <li><span>Card Number:</span><p>{info?.payment_info?.payment_description || "-"}</p></li>
                            </ul>
                        </Col>
                    </Row>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default PaymentTable