import React, { useContext, useEffect, useRef, useState } from 'react'
import { Col, Container, Form, Modal, Row, Table, Tooltip } from 'react-bootstrap';
import GetInTouchTable from '../components/GetInTouchTable';
import Sidbar from '../layout/Sidbar';
import Header from '../layout/Header';
import { getPartnerData } from '../controller/Api';
import { CartContext } from '../context/CartContext';
import Loader from "../components/Loader";
import PartnerRequestTable from '../components/PartnerRequestTable';
import DatePicker from 'react-datepicker';

const PartnerRequest = () => {
    const [partnerRequest, setIsOpenPartnerRequest] = useState();
    const [loading, setLoading] = useState(false);
    const [page, setPage] = useState(false);
    const [perPage, setPerPage] = useState(false);
    const [search, setSearch] = useState();
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const ref = useRef(null);

    const getPartnerDataApi = async (searchVal, startDate, endDate) => {
        const pageVal = searchVal ? 1 : page;
        try {
            setLoading(true);
            const result = await getPartnerData(pageVal, perPage, search, startDate, endDate);
            const { status } = result;
            if (status === 1) {
                const { page } = result;
                setPage(page);
                setIsOpenPartnerRequest(result);
            }
        }
        catch (error) {
            console.log(error);
        }
        setLoading(false);
    }

    const inputHandle = (e) => {
        setSearch(e.target.value);
    }

    useEffect(() => {
        getPartnerDataApi();
    }, [page]);

    useEffect(() => {
        const callback = (event) => {
            if (event.key === 'Enter') {
                setPage(1);
                event.preventDefault();
                ref.current?.click();
            }
        };
        document.addEventListener('keydown', callback);
        return () => {
            document.removeEventListener('keydown', callback);
        };
    }, [search]);

    useEffect(() => {
        document.title = "Music Booth | Partner Request"
    }, []);

    const handleDateChange = (dates) => {
        const [start, end] = dates;
        setStartDate(start);
        setEndDate(end);
        getPartnerDataApi(search, start, end);
    };

    return (
        <>
            {loading && <Loader />}
            <section>
                <Container fluid>
                    <Row className="vh-100">
                        <Col
                            xs={12}
                            md={12}
                            lg={3}
                            xl={2}
                            className="shadow-right d-none d-lg-block"
                        >
                            <Sidbar />
                        </Col>
                        <Col xs={12} lg={9} xl={10} className="position-relative px-md-5">
                            <Header />
                            <img src="../images/png/bg-imag.png" className="bg-imag" alt="" />
                            <Row className="justify-content-between align-items-center pb-3">
                                <Col md={6} lg={5} xl={7}>
                                    <h1 className="title">Partner Request</h1>
                                </Col>
                                <Col md={6} lg={7} xl={5} className="mt-3 mt-md-0">
                                    <div className='d-flex gap-2'>
                                        <div className='col-6 col-md-6 col-xl-5'>
                                            <DatePicker
                                                selected={startDate}
                                                placeholderText='MM/DD/YYYY'
                                                onChange={handleDateChange}
                                                startDate={startDate}
                                                endDate={endDate}
                                                selectsRange
                                                className='form-control custom-date-picker'
                                                style={{ height: '50px' }}
                                            />
                                        </div>
                                        <div className='d-flex justify-content-end gap-3'>
                                            <div className='form-control d-flex align-items-center px-3' style={{ height: "45px", width: "350px" }}>
                                                <input className='form-control border-0 shadow-none pl-black'
                                                    placeholder="Search"
                                                    type="text"
                                                    value={search}
                                                    onChange={inputHandle}
                                                />
                                                <img className='cursor-pointer' src="../images/svg/search.svg" ref={ref}
                                                    onClick={() => getPartnerDataApi("search")}
                                                    alt="Search" />
                                            </div>
                                        </div>

                                    </div>
                                </Col>
                            </Row>
                            <PartnerRequestTable
                                partnerRequest={partnerRequest}
                                page={page}
                                setPage={setPage}
                                perPage={perPage}
                                setPerPage={setPerPage}
                                getPartnerDataApi={getPartnerDataApi}
                            // getInTouchOrder={getInTouchOrder}
                            />
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    )
}

export default PartnerRequest