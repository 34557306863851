import React, { useState } from 'react'
import { Col, Form, Modal, Row, Table } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import TablePagination from './TablePagination';
import { deletePrice, editPricing } from "../controller/Api";
import { toast } from 'react-toastify';

const PricingTable = ({ setPage, setPerPage, perPage, page, getPricingFlow, pricingAdd, admin }) => {
    const [show, setShow] = useState(false);
    const [pricingModel, setPricingModel] = useState();
    const [editData, setEditData] = useState();
    const handleShow = () => setShow(true);
    const handleShow1 = () => setShow(true);
    const handleClose = () => setShow(false);
    const [lgShow, setLgShow] = useState(false);
    const [weekDayTitle, setWeekDayTitle] = useState("Monday");
    const [dayIndex, setDayIndex] = useState(0);
    const [modelOpen, setModelOpen] = useState();
    const navigate = useNavigate();

    //PricingEdit:-
    const pricingTableEdit = async (e) => {
        e.preventDefault();
        if (editData.name.trim() === "") {
            toast.error("Pricing name is required");
            return;
        }
        try {
            const result = await editPricing(editData._id, editData);
            const { message, status } = result;
            if (status === 1) {
                toast.success(message);
                getPricingFlow();
                handleClose(true);
                setLgShow(false);
            } else {
                toast.error(message);
            }
        } catch (error) {
            console.log(error);
        }
    }

    //Pricing Delete
    const handleDelete = async (id) => {
        try {
            const result = await deletePrice(id);
            const { message, status } = result;
            if (status === 1) {
                toast.success(message);
                setModelOpen(false);
                handleClose();
                if (pricingAdd?.data?.length === 1) {
                    setPage(1);
                } else {
                    getPricingFlow();
                }
            } else {
                toast.error(message);
                setModelOpen(false);
            }
        } catch (error) {
            console.log(error);
        }
    }

    const handleEdit = (element) => {
        setPricingModel(element);
    }

    const handleEditData = (element) => {
        setEditData(element);
        setLgShow(true);
        setWeekDayTitle("Monday");
    }

    const inputChange = (e) => {
        const { name, value } = e.target;
        setEditData({ ...editData, [name]: value });
    }
    const openTableDays = (e, day, index) => {
        e.preventDefault();
        setWeekDayTitle(day);
        setDayIndex(index);
    };

    const inputValChange = (e, key) => {
        const { name, value } = e.target;
        if (name === "price" && /^\d*\.?\d*$/.test(value)) {
            const updatedWeekdaySlots = { ...editData };
            updatedWeekdaySlots.weekday[dayIndex].slots[key][name] = value;
            setEditData(updatedWeekdaySlots)
        }
        else if (name !== "price") {
            const updatedWeekdaySlots = { ...editData };
            updatedWeekdaySlots.weekday[dayIndex].slots[key][name] = value;
            setEditData(updatedWeekdaySlots);
        }
    };

    const handleAddSlot = () => {
        const newSlot = { startTime: "00:00", endTime: "00:00", price: 0 };
        const updatedWeekdaySlots = { ...editData };
        updatedWeekdaySlots.weekday[dayIndex]?.slots.push(newSlot);
        setEditData(updatedWeekdaySlots);
    };

    const deleteRow = (i) => {
        const updatedWeekdaySlots = { ...editData };
        updatedWeekdaySlots.weekday[dayIndex].slots.splice(i, 1);
        setEditData(updatedWeekdaySlots)
    };

    const timeSlot = [
        "00:00",
        "01:00",
        "02:00",
        "03:00",
        "04:00",
        "05:00",
        "06:00",
        "07:00",
        "08:00",
        "09:00",
        "10:00",
        "11:00",
        "12:00",
        "13:00",
        "14:00",
        "15:00",
        "16:00",
        "17:00",
        "18:00",
        "19:00",
        "20:00",
        "21:00",
        "22:00",
        "23:00",
        "24:00"
    ];

    return (
        <>
            {/* {loading && <Loader />} */}
            <Row>
                <Col>
                    <Table bordered responsive="xl">
                        <thead>
                            <tr>
                                <th className='number-width border'>No</th>
                                <th style={{ width: "400px", wordBreak: "break-all", whiteSpace: "break-spaces" }}>Name</th>
                                <th style={{ width: "800px", wordBreak: "break-all", whiteSpace: "break-spaces" }}>Description</th>
                                {admin && <th style={{ width: "300px", wordBreak: "break-all", whiteSpace: "break-spaces" }}>Sub Admin</th>}
                                <th>Action</th>
                            </tr>
                        </thead>
                        {Boolean(pricingAdd?.data?.length) ?
                            <tbody>
                                {
                                    pricingAdd?.data?.map((element, index) => {
                                        const { name, desc, userId } = element;
                                        const currentNumber = (page - 1) * perPage + index + 1;
                                        return (
                                            <tr>
                                                <td>{currentNumber}</td>
                                                <td>{name}</td>
                                                <td style={{ width: "900px", wordBreak: "break-all", whiteSpace: "break-spaces" }}>{desc}</td>
                                                {admin && <td style={{ width: "300px", wordBreak: "break-all", whiteSpace: "break-spaces" }}>{userId?.fullName || "-"}</td>}
                                                <td>
                                                    <div className="d-flex justify-content-center align-items-center gap-3">
                                                        <div title='Info' className='cursor-pointer'>
                                                            <img src="./images/svg/info.svg" alt=""
                                                                onClick={() => {
                                                                    handleShow();
                                                                    handleEdit(element);
                                                                }} />
                                                        </div>
                                                        <div title='Edit' className='d-flex gap-3 cursor-pointer'>
                                                            <img src="./images/svg/edit.svg" alt=""
                                                                onClick={() => {
                                                                    handleEditData(element);
                                                                }}
                                                            />
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                        );
                                    })}
                            </tbody>
                            :
                            <tbody>
                                <tr>
                                    <td colSpan={10}>
                                    <div className='nodata-table'>
                                            <h3 className="text-center p-5">Data Not Found</h3>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        }
                    </Table>
                    <div className='d-md-flex justify-content-between align-items-center pb-3'>
                        <button className='secondary-button text-decoration-none' onClick={() => navigate("/dashboard")} >
                            <svg
                                stroke="currentColor"
                                fill="currentColor"
                                strokeWidth={0}
                                viewBox="0 0 24 24"
                                height="2em"
                                width="2em"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path d="M12.707 17.293 8.414 13H18v-2H8.414l4.293-4.293-1.414-1.414L4.586 12l6.707 6.707z" />
                            </svg>
                            Back
                        </button>
                        {
                            pricingAdd?.data && pricingAdd?.data?.length >= 1 &&
                            <TablePagination
                                currentPage={pricingAdd?.page}
                                totalPages={pricingAdd?.pages}
                                onPageChange={(newPage) => {
                                    setPage(newPage);
                                }}
                            />
                        }
                    </div>
                </Col >
            </Row>
            <Modal
                show={show}
                size="xl"
                onHide={handleClose}
                centered
                className='moadl-main info-modal'
            >
                <Modal.Header className='d-block position-relative border-0' closeButton>
                    <Modal.Title className='text-center'>Pricing</Modal.Title>
                </Modal.Header>
                <Modal.Body className='mx-md-4 border-0 bg-transparent pb-0'>

                    <Form className='mt-4'>
                        <Row>
                            <Col md={12}>

                                <div className="overflow-auto">
                                    <Table striped bordered hover>
                                        <thead>
                                            <tr>
                                                <th className="border-start">Days</th>
                                                <th className="border-start">Time Slots</th>
                                                <th className="price-input border-start">Price</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {pricingModel?.weekday?.map((day, i) => (
                                                <tr key={i}>
                                                    <td>{day?.day?.charAt(0).toUpperCase() + day?.day.slice(1)}</td>
                                                    <td>
                                                        <ul className="list-unstyled mb-0">
                                                            {day.slots.map((slot, j) => (
                                                                <li key={j}>
                                                                    <span>{slot?.startTime} - {slot?.endTime}</span>
                                                                </li>
                                                            ))}
                                                        </ul>
                                                    </td>
                                                    <td>
                                                        <ul className="list-unstyled mb-0">
                                                            {day.slots.map((slot, j) => (
                                                                <li key={j}>AED {slot?.price}</li>
                                                            ))}
                                                        </ul>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </Table>
                                </div>
                            </Col>
                        </Row>
                    </Form>
                </Modal.Body>
                <Modal.Footer className='mx-md-4 border-0 bg-transparent pt-0'>

                </Modal.Footer>
            </Modal >

            <Modal
                size="xl"
                show={lgShow}
                onHide={() => {
                    setLgShow(false);
                }}
                centered
                className="moadl-main info-modal"
            >
                <Modal.Header
                    className="d-block position-relative border-0"
                    closeButton
                >
                    <Modal.Title className="text-center">Edit Pricing</Modal.Title>
                </Modal.Header>

                <Modal.Body className="mx-md-4 border-0 bg-transparent pb-0">
                    <div className="d-flex justify-content-end ">
                        <button
                            className="primary-button" onClick={() => {
                                setModelOpen(true);
                                handleClose();
                                setLgShow(false);
                            }}>
                            Delete
                        </button>
                    </div>
                    <Form className="mt-4">
                        <Row>
                            <Col md={12}>
                                <div className="form-control d-flex align-items-center px-1 mb-3">
                                    <input
                                        className="form-control border-0 shadow-none px-0 px-3 text-black w-100"
                                        placeholder="Enter name"
                                        type="text"
                                        name="name"
                                        value={editData?.name}
                                        onChange={inputChange}
                                    />
                                </div>
                            </Col>
                            <Col md={12}>
                                <div className="form-control d-flex align-items-center px-1 mb-3">
                                    <div className="d-flex w-100">
                                        <div className="mt-2"></div>
                                        <textarea
                                            className="form-control border-0 shadow-none px-0 px-3 text-black w-100"
                                            placeholder="Enter description"
                                            name="desc"
                                            rows={8}
                                            type="text"
                                            value={editData?.desc}
                                            onChange={inputChange}
                                        />
                                    </div>
                                </div>
                            </Col>

                            <Col xs={12} lg={12}>
                                <div className="d-flex flex-wrap align-items-center text-center text-nowrap gap-2 mb-4 py-2">
                                    <span className="text-nowrap fs-6">Weekday:</span>
                                    {editData?.weekday && editData.weekday?.length > 0 && editData?.weekday?.map((days, index) => (
                                        <div key={index} className="custom-checked py-1">
                                            <button
                                                className={`days-button fw-500 ${days.day === weekDayTitle ? "text-black" : ""}`}
                                                onClick={(e) => openTableDays(e, days.day, index)}
                                            >
                                                {days.day.charAt(0).toUpperCase() + days.day.slice(1)}
                                            </button>
                                        </div>
                                    ))}
                                </div>

                            </Col>

                            <Col md={12} lg={10} xl={12}>
                                <div className="cursor-pointer d-flex justify-content-end mb-3">
                                    <div className="cursor-pointer d-flex justify-content-end mb-3">

                                        {weekDayTitle?.length > 1 ?
                                            <img
                                                src="../images/svg/add.svg"
                                                onClick={(e) => handleAddSlot(e)}
                                                className="add-btn"
                                                alt=""
                                            />
                                            : ""}
                                    </div>
                                </div>
                                {/* <p>{weekDayTitle}</p> */}
                                <div className="overflow-auto">
                                    <Table striped bordered hover>
                                        <thead>
                                            <tr>
                                                <th className="border-start">Index</th>
                                                <th className="border-start">Start Time</th>
                                                <th className="border-start">End Time</th>
                                                <th className="price-input border-start">Price</th>
                                                <th className="price-input border-start">Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {editData?.weekday && editData?.weekday.length > 0 && editData?.weekday?.map((days, index) => {
                                                if (days.day === weekDayTitle) {
                                                    return days?.slots?.map((slot, i) => {
                                                        return (
                                                            <tr key={i}>
                                                                <td>{i + 1}</td>
                                                                <td>
                                                                    <Form.Select
                                                                        aria-label="Default select example"
                                                                        className="select-bg bg-transparent w-100"
                                                                        name="startTime"
                                                                        value={slot.startTime}
                                                                        onChange={(e) => {
                                                                            inputValChange(e, i);
                                                                        }}
                                                                    >
                                                                        <option value={""}>
                                                                            Studio Start time
                                                                        </option>
                                                                        {timeSlot.map((v, i) => (
                                                                            <option key={i} value={v}>
                                                                                {v}
                                                                            </option>
                                                                        ))}
                                                                    </Form.Select>
                                                                </td>
                                                                <td>
                                                                    <Form.Select
                                                                        aria-label="Default select example"
                                                                        className="select-bg bg-transparent w-100"
                                                                        name="endTime"
                                                                        value={slot.endTime}
                                                                        onChange={(e) => {
                                                                            inputValChange(e, i);
                                                                        }}
                                                                    >
                                                                        <option value={""}>Studio End time</option>
                                                                        {timeSlot.map((v, i) => (
                                                                            <option key={i} value={v}>
                                                                                {v}
                                                                            </option>
                                                                        ))}
                                                                    </Form.Select>
                                                                </td>
                                                                <td>
                                                                    <input
                                                                        type="text"
                                                                        className="form-control shadow-none border time-input"
                                                                        name="price"
                                                                        onChange={(e) => {
                                                                            inputValChange(e, i);
                                                                        }}
                                                                        value={slot.price}
                                                                    />
                                                                </td>
                                                                <td className='cursor-pointer' onClick={() => deleteRow(i)}>
                                                                    <img src="./images/svg/delete.svg" alt="" />
                                                                </td>
                                                            </tr>
                                                        );
                                                    });
                                                }
                                            })}
                                        </tbody>
                                    </Table>
                                </div>
                                <div className="d-flex justify-content-end">
                                    <button
                                        className="secondary-button text-decoration-none"
                                        onClick={pricingTableEdit}
                                    >
                                        Save
                                    </button>
                                </div>
                            </Col>
                        </Row>
                    </Form>
                </Modal.Body>
                <Modal.Footer className="mx-md-4 border-0 bg-transparent pt-0">
                    {/* <button
                        // ref={ref}
                        className="secondary-button text-decoration-none"
                    >
                        Add
                    </button> */}
                </Modal.Footer>
            </Modal>


            <Modal
                size="md"
                show={modelOpen}
                onHide={() => setModelOpen(false)}
                centered className='moadl-main delete-modal'
            >
                <Modal.Header className='d-block position-relative border-0' closeButton>
                    <Modal.Title className='text-center'>Delete Price</Modal.Title>
                </Modal.Header>
                <Modal.Body className='mx-4 border-0'>
                    <p>Are you sure you want to delete?</p>
                </Modal.Body>
                <Modal.Footer className='border-0'>
                    <div className='d-flex m-auto gap-2 pb-3'>
                        <button className="primary-button" onClick={() => {
                            handleDelete(editData._id);
                        }}>
                            Delete
                        </button>
                        <button className="primary-button"
                            onClick={() => setModelOpen(false)}>
                            Cancel</button>
                    </div>
                </Modal.Footer>
            </Modal >
        </>
    )
}

export default PricingTable