import React, { useContext, useEffect, useRef, useState } from 'react'
import { Col, Container, Form, Modal, Row } from 'react-bootstrap';
import Sidbar from '../layout/Sidbar';
import Header from '../layout/Header';
import { CartContext } from '../context/CartContext';
import Loader from "../components/Loader";
import BoothTable from '../components/BoothTable';
import { addOnsAdd, addOnsGet, getSubAdmin } from '../controller/Api';
import { toast } from 'react-toastify';
import AddOnsTable from '../components/AddOnsTable';

const AddOns = () => {
    const { admin } = useContext(CartContext);
    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(10);
    const [search, setSearch] = useState();
    const [loading, setLoading] = useState(false);
    const [lgShow, setLgShow] = useState(false);
    const [findSubAdmin, setFindSubAdmin] = useState([]);
    const [selectedSubAdminId, setSelectedSubAdminId] = useState();
    const [userId, setUserId] = useState();
    const ref = useRef(null);
    const [addOns, setAddOns] = useState({
        title: "",
        price: "",
        description: ""
    });
    const [addOnsList, setAddOnsList] = useState();

    const inputValChange = (e) => {
        const { value, name } = e.target;
        setAddOns(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const inputHandle = (e) => {
        setSearch(e.target.value);
    }

    const addOnsAddApi = async () => {

        if (!addOns.title.trim()) {
            toast.error("Title is required");
            return
        }
        if (!addOns.price.trim()) {
            toast.error("Price is required");
            return
        }
        try {
            const result = await addOnsAdd(addOns, userId);
            const { status, message } = result;
            if (status === 1) {
                toast.success(message);
                setAddOns({ description: "", title: "", price: "" });
                setUserId("");
                setLgShow(false);
                addOnsGetApi();
            } else {
                toast.error(message);
            }
        } catch (error) {
            console.log(error);
        }
    };

    const getSubAdminBooth = async () => {
        try {
            const result = await getSubAdmin();
            const { data, status } = result;
            if (status === 1) {
                setFindSubAdmin(data)
            }
        } catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        const callback = (event) => {
            if (event.key === 'Enter' && ref.current) {
                event.preventDefault();
                ref.current?.click();
            }
        };
        document.addEventListener('keydown', callback);
        return () => {
            document.removeEventListener('keydown', callback);
        };
    }, []);

    // useEffect(() => {
    //     showEquipments();
    //     document.title = "Music Booth | Equipments"
    //     admin && getSubAdminData();
    // }, [page, selectedSubAdminId]);

    const addOnsGetApi = async (searchVal) => {
        const pageVal = searchVal ? 1 : page;
        try {
            setLoading(true);
            const result = await addOnsGet(pageVal, perPage, search, selectedSubAdminId);
            const { message, status } = result;
            if (status === 1) {
                const { page } = result;
                setPage(page);
                setAddOnsList(result);
            } else {
                toast.error(message);
            }
        } catch (error) {
            console.log(error);
        }
        setLoading(false);
    }

    useEffect(() => {
        admin && getSubAdminBooth();
        document.title = "Music Booth | Booth"
        addOnsGetApi();
    }, [page, selectedSubAdminId]);

    // useEffect(() => {
    //     addOnsGetApi()
    // }, [])

    return (
        <>
            {loading && <Loader />}
            <section>
                <Container fluid>
                    <Row className="vh-100">
                        <Col
                            xs={12}
                            md={12}
                            lg={3}
                            xl={2}
                            className="shadow-right d-none d-lg-block"
                        >
                            <Sidbar />
                        </Col>
                        <Col xs={12} lg={9} xl={10} className="position-relative px-md-5">
                            <Header />
                            <img src="../images/png/bg-imag.png" className="bg-imag" alt="" />
                            <Row className="justify-content-between align-items-center pb-3">
                                <Col md={6} lg={5} xl={7}>
                                    <h1 className="title">Add-Ons</h1>
                                </Col>
                                <Col md={6} lg={7} xl={5} className={`${admin ? "mt-3 mt-md-0 d-flex gap-2 justify-content-end" : "mt-3 mt-md-0"}`}>
                                    {admin && (
                                        <Form.Select
                                            style={{ width: "200px", height: "50px" }}
                                            className="select-bg cursor-pointer"
                                            aria-label="Default select example"
                                            value={selectedSubAdminId}
                                            onChange={(e) => {
                                                setSelectedSubAdminId(e.target.value);
                                                setPage(1)
                                            }}
                                        >
                                            <option value={""}>Select Company</option>
                                            {findSubAdmin?.map((item, index) => (
                                                <option key={index} value={item._id}>
                                                    {item.fullName}
                                                </option>
                                            ))}
                                        </Form.Select>
                                    )}
                                    <Form>
                                        <div className="d-flex justify-content-end gap-3">
                                            <div className="form-control d-flex align-items-center px-3" style={{ width: "350px" }}>
                                                <input
                                                    className="form-control border-0 shadow-none pl-black"
                                                    placeholder="Search"
                                                    type="text"
                                                    value={search}
                                                    onChange={inputHandle}
                                                />
                                                <img
                                                    className='cursor-pointer'
                                                    ref={ref}
                                                    src="../images/svg/search.svg"
                                                    alt="Search"
                                                    onClick={addOnsGetApi}
                                                />
                                            </div>
                                            <div
                                                onClick={() => setLgShow(true)}
                                                className="cursor-pointer"
                                            >
                                                <img
                                                    src="../images/svg/add.svg"
                                                    className="add-btn"
                                                    alt=""
                                                />
                                            </div>
                                        </div>
                                    </Form>
                                </Col>
                            </Row>

                            <AddOnsTable
                                page={page}
                                perPage={perPage}
                                setPage={setPage}
                                addOnsList={addOnsList}
                                addOnsGetApi={addOnsGetApi}
                                admin={admin}
                                setAddOnsList={setAddOnsList}
                            />
                        </Col>
                    </Row>
                </Container>
            </section>

            <Modal
                size="xl"
                show={lgShow}
                onHide={() => {
                    setLgShow(false);
                    setUserId("");
                    setAddOns({ description: "", title: "", price: "" });
                }}
                centered
                className='moadl-main info-modal'
            >
                <Modal.Header className='d-block position-relative border-0' closeButton>
                    <Modal.Title className='text-center'>Add Add-Ons</Modal.Title>
                </Modal.Header>
                <Modal.Body className='mx-md-4 border-0 bg-transparent pb-0'>
                    <Form className='mt-4'>
                        <Row>
                            {admin &&
                                <Col md={12}>
                                    <div className="">
                                        <Form.Select
                                            style={{ width: "200px", height: "50px" }}
                                            className="select-bg cursor-pointer mb-3"
                                            aria-label="Default select example"
                                            value={userId}
                                            onChange={(e) => { setUserId(e.target.value) }}
                                        >
                                            <option value={""}>Select Company</option>
                                            {findSubAdmin?.map((item, index) => (
                                                <option key={index} value={item._id}>
                                                    {item.fullName}
                                                </option>
                                            ))}
                                        </Form.Select>
                                    </div>
                                </Col>
                            }
                            <Col md={12}>
                                <div className='form-control d-flex align-items-center px-1 mb-3'>
                                    <input className='form-control border-0 shadow-none px-0 px-2 text-black w-100'
                                        placeholder='Enter Title'
                                        type="text"
                                        name='title'
                                        value={addOns.title}
                                        onChange={inputValChange}
                                    />
                                </div>
                            </Col>
                            <Col md={12}>
                                <div className='form-control d-flex align-items-center px-1 mb-3'>
                                    <input className='form-control border-0 shadow-none px-0 px-2 text-black w-100'
                                        placeholder='Enter price'
                                        type="number"
                                        name='price'
                                        value={addOns.price}
                                        onChange={inputValChange}
                                    />
                                </div>
                            </Col>
                            <Col md={12}>
                                <div className='form-control d-flex align-items-center px-1 mb-3'>
                                    <input className='form-control border-0 shadow-none px-0 px-2 text-black w-100'
                                        placeholder='Enter Description'
                                        type="text"
                                        name='description'
                                        value={addOns.description}
                                        onChange={inputValChange}
                                    />
                                </div>
                            </Col>
                        </Row>
                    </Form>
                </Modal.Body>
                <Modal.Footer className='mx-md-4 border-0 bg-transparent pt-0'>
                    <button
                        className='secondary-button text-decoration-none'
                        onClick={addOnsAddApi}
                    >
                        Add
                    </button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default AddOns