import React, { useEffect, useState } from 'react'
import Sidbar from '../layout/Sidbar'
import { Button, Col, Container, Form, Modal, Row } from 'react-bootstrap'
import Header from '../layout/Header'
import NotificationTable from '../components/NotificationTable'
import { AddBookingNotification, getNotification, editNotificationApi, deleteNotification, getSubAdmin } from "../controller/Api"
import { toast } from 'react-toastify'
import Loader from "../components/Loader";
import { useContextData } from '../context/CartContext'

const Notification = () => {
    const { admin } = useContextData();
    const [notification, setNotification] = useState({
        event: "",
        timeIn: "hours",
        leadTime: "",
        method: "",
        title: "",
        desc: "",
        userId: ""
    });
    const handleClose = () => setRemoveModel(false);
    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(10);
    const [lgShow, setLgShow] = useState(false);
    const [editModelOpen, setEditModelOpen] = useState(false);
    const [removeModel, setRemoveModel] = useState(false);
    const [getNotifications, setGetNotifications] = useState();
    const [id, setId] = useState();
    const [loading, setLoading] = useState(false);
    const [selectedSubAdminId, setSelectedSubAdminId] = useState();
    const [findSubAdmin, setFindSubAdmin] = useState([]);

    const inputChange = (e) => {
        const { value, name } = e.target;
        setNotification({
            ...notification,
            [name]: value
        });
    };

    const validationCheck = () => {
        if (!notification.event) {
            toast.error("Trigger Event is required");
            return;
        }
        if (!notification.leadTime) {
            toast.error("Lead Time is required");
            return;
        }
        if (!notification.timeIn) {
            toast.error("Time In is required");
            return;
        }
        if (!(notification.leadTime > 0)) {
            toast.error("Hours and Minutes can't be negative");
            return;
        }
        if (!notification.method) {
            toast.error("Method is required");
            return;
        }
        if (!notification.title.trim()) {
            toast.error("Title is required");
            return;
        }

        if (!notification.desc.trim()) {
            toast.error("Description is required");
            return;
        }

        return true;
    }
    // Post Api call
    const NotificationAdd = async (e) => {
        try {
            if (!validationCheck()) return;
            e.preventDefault();
            const addNotification = await AddBookingNotification(notification);
            const { status, message } = addNotification;
            if (status === 1) {
                toast.success(message);
                setLgShow(false);
                getNotificationFunction();
                setNotification({
                    event: "",
                    leadTime: "",
                    timeIn: "",
                    method: "",
                    title: "",
                    desc: "",
                    userId: ""
                })
            } else {
                toast.error(message);
            }
        } catch (error) {
            console.log(error);
        }
    }

    //Get Api
    const getNotificationFunction = async () => {
        try {
            setLoading(true);
            const result = await getNotification(page, perPage, selectedSubAdminId);
            const { message, status } = result;
            if (status === 1) {
                setGetNotifications(result);
            } else {
                toast.error(message);
            }
        } catch (error) {
            console.log(error);
        }
        setLoading(false);
    }

    const getSubAdminNotification = async () => {
        try {
            const result = await getSubAdmin();
            const { data, status } = result;
            if (status === 1) {
                setFindSubAdmin(data);
            }
        } catch (error) {
            console.log(error);
        }
    }

    //Edit Api
    const editNotificationFunction = async () => {
        try {
            if (!validationCheck()) return;
            const result = await editNotificationApi(id, notification);
            const { message, status } = result;
            if (status === 1) {
                getNotificationFunction();
                setEditModelOpen(false);
                setLgShow(false);
                toast.success(message);
            } else {
                toast.error(message);
            }
        } catch (error) {
            console.log(error);
        }
    }

    //delete Api
    const deleteNotificationFunction = async () => {
        try {
            const result = await deleteNotification(id);
            const { status, message } = result;
            if (status === 1) {
                toast.success(message);
                getNotificationFunction();
                setRemoveModel(false);
            } else {
                toast.error(message);
            }
        } catch (error) {
            console.log(error);
        }
    }

    const handleSave = async (e) => {
        e.preventDefault();
        if (editModelOpen) {
            await editNotificationFunction();
        } else {
            await NotificationAdd(e);
        }
    }

    const handleEdit = (notificationData) => {
        setEditModelOpen(true);
        setNotification(notificationData)
        setId(notificationData?._id);
    }

    useEffect(() => {
        getNotificationFunction();
        admin && getSubAdminNotification();
    }, [page, selectedSubAdminId]);

    // useEffect(() => {
    // }, []);

    return (
        <div>
            {loading && <Loader />}
            <section>
                <Container fluid>
                    <Row className="vh-100">
                        <Col
                            xs={12}
                            md={12}
                            lg={3}
                            xl={2}
                            className="shadow-right d-none d-lg-block"
                        >
                            <Sidbar />
                        </Col>
                        <Col xs={12} lg={9} xl={10} className="position-relative px-md-5">
                            <Header />
                            <img src="../images/png/bg-imag.png" className="bg-imag" alt="" />
                            <Row className="justify-content-between align-items-center pb-3">
                                <Col md={6} lg={5} xl={7}>
                                    <h1 className="title">Booking Notifications</h1>
                                </Col>
                                <Col md={6} lg={7} xl={5} className="mt-3 mt-md-0 d-flex justify-content-end gap-2">
                                    {admin &&
                                        <Form.Select
                                            style={{ width: "200px", height: "50px" }}
                                            className="select-bg cursor-pointer"
                                            aria-label="Default select example"
                                            value={selectedSubAdminId}
                                            onChange={(e) => setSelectedSubAdminId(e.target.value)}
                                        >
                                            <option value={""}>Select Company</option>
                                            {findSubAdmin?.map((item, index) => (
                                                <option key={index} value={item._id}>
                                                    {item.fullName}
                                                </option>
                                            ))}
                                        </Form.Select>
                                        }
                                    <Form>
                                        <div className="d-flex justify-content-end gap-3">
                                            <div
                                                onClick={() => {
                                                    setLgShow(true);
                                                    setEditModelOpen(false);
                                                    setNotification({
                                                        event: "",
                                                        leadTime: "",
                                                        timeIn: "hours",
                                                        method: "",
                                                        title: "",
                                                        desc: ""
                                                    });
                                                }}
                                                className="cursor-pointer"
                                            >
                                                <img
                                                    src="../images/svg/add.svg"
                                                    className="add-btn"
                                                    alt=""
                                                />
                                            </div>
                                        </div>
                                    </Form>
                                </Col>
                            </Row>
                            <NotificationTable
                                admin={admin}
                                perPage={perPage}
                                setPage={setPage}
                                page={page}
                                lgShow={lgShow}
                                setLgShow={setLgShow}
                                editModelOpen={editModelOpen}
                                setEditModelOpen={setEditModelOpen}
                                handleEdit={handleEdit}
                                removeModel={removeModel}
                                getNotifications={getNotifications}
                                getNotificationFunction={getNotificationFunction}
                            />
                        </Col>
                    </Row>
                </Container>
            </section >

            <Modal
                size="xl"
                show={lgShow}
                onHide={() => {
                    setLgShow(false);
                }}
                centered
                className="moadl-main info-modal"
            >
                <Modal.Header
                    className="d-block position-relative border-0"
                    closeButton
                >
                    <Modal.Title className="text-center">{editModelOpen ? "Edit Notification" : "Add Notification"}</Modal.Title>
                </Modal.Header>
                <Modal.Body className="mx-md-4 border-0 bg-transparent pb-0">
                    {
                        editModelOpen ?
                            <div className="d-flex justify-content-end ">
                                <button
                                    className="primary-button" onClick={() => {
                                        setRemoveModel(true);
                                        setLgShow();
                                    }}>
                                    Delete
                                </button>
                            </div>
                            : ""
                    }
                    <Form className="mt-4">
                        <Row>
                            <Col md={12} sm={12} xxl={12}>
                                <div className="form-control d-flex align-items-center px-1 mb-3 gap-2 text-black w-100 text-dark">
                                    <Form.Select
                                        name="event"
                                        className='w-100 border-0 text-black w-100 text-dark px-2'
                                        value={notification.event}
                                        onChange={inputChange}
                                        disabled={editModelOpen}
                                    >
                                        <option className='text-black w-100 text-dark'>Select Booking Time</option>
                                        <option className='text-black w-100 text-dark' value="startTimeOfBooking">Start Time Of Booking</option>
                                        <option className="text-black w-100 text-dark" value="endTimeOfBooking">End Time Of Booking</option>
                                    </Form.Select>
                                </div>
                            </Col>


                            <Col xs={12} lg={12}>
                                <div className="form-control d-flex align-items-center px-2 mb-3 gap-2 text-black w-100 text-dark">
                                    <span className="text-nowrap fs-6 px-1 fw-500">Time In :</span>
                                    <div className="mx-3 gap-2 d-flex">
                                        <input
                                            style={{ height: '20px', width: '20px', marginTop: "3px", marginLeft: "0px", marginRight: "0px" }}
                                            type="radio"
                                            className="defaultCheckbox form-check-input"
                                            name="timeIn"
                                            id="smallCheckbox"
                                            checked={notification?.timeIn === "hours" ? true : false}
                                            onChange={inputChange}
                                            value="hours"
                                        />
                                        <label htmlFor="smallCheckbox" className="checkbox-label fw-500">Hours</label>
                                    </div>

                                    <div className="mx-3 gap-2 d-flex">
                                        <input
                                            style={{ height: '20px', width: '20px', marginTop: "3px" }}
                                            type="radio"
                                            className="defaultCheckbox form-check-input px-2"
                                            name="timeIn"
                                            id="smallCheckbox2"
                                            checked={notification?.timeIn === "minutes" ? true : false}
                                            onChange={inputChange}
                                            value="minutes"
                                        />
                                        <label htmlFor="smallCheckbox2" className="checkbox-label fw-500">Minutes</label>
                                    </div>
                                </div>
                            </Col>

                            <Col md={12}>
                                <div className="form-control d-flex align-items-center px-1 mb-3 ">
                                    <input
                                        className="form-control border-0 shadow-none px-2 text-black w-100 text-dark"
                                        placeholder="Lead Time"
                                        type="number"
                                        name="leadTime"
                                        onKeyDown={(e) => ["e", "E", "+", "-", "."].includes(e.key) && e.preventDefault()}
                                        value={notification.leadTime}
                                        onChange={inputChange}
                                    />
                                </div>
                            </Col>

                            <Col md={12} sm={12} xxl={12}>
                                <div className="form-control d-flex align-items-center px-1 mb-3 gap-2 px-0">
                                    <Form.Select
                                        className='bg-transparent w-100 border-0 text-black px-2 w-100 text-dark'
                                        name='method'
                                        value={notification.method}
                                        onChange={inputChange}
                                    >
                                        <option className=''>Select Method</option>
                                        <option value="email">Email Only</option>
                                        <option value="inApp">In-App Notification </option>
                                        <option value="both">Both</option>
                                    </Form.Select>
                                </div>
                            </Col>

                            <Col md={12}>
                                <div className="form-control d-flex align-items-center px-1 mb-3">
                                    <div className="d-flex w-100">
                                        <input
                                            className="form-control border-0 shadow-none px-2 text-black w-100"
                                            placeholder="Enter Title"
                                            name="title"
                                            rows={3}
                                            type="text"
                                            value={notification.title}
                                            onChange={inputChange}
                                        />
                                    </div>
                                </div>
                            </Col>

                            <Col md={12}>
                                <div className="form-control d-flex align-items-center px-1 mb-3">
                                    <div className="d-flex w-100">
                                        <textarea
                                            className="form-control border-0 shadow-none px-2 text-black w-100"
                                            placeholder="Enter Description"
                                            name="desc"
                                            rows={3}
                                            type="text"
                                            onChange={inputChange}
                                            value={notification.desc}
                                        />
                                    </div>
                                </div>
                            </Col>

                            {admin && !editModelOpen &&
                                <Col>
                                    <Form.Select
                                        style={{ width: "200px", height: "50px" }}
                                        className="select-bg cursor-pointer"
                                        aria-label="Default select example"
                                        value={notification.userId}
                                        onChange={inputChange}
                                        name='userId'
                                    >
                                        <option value={""}>Select Company</option>
                                        {findSubAdmin?.map((item, index) => (
                                            <option key={index} value={item._id}>
                                                {item.fullName}
                                            </option>
                                        ))}
                                    </Form.Select>
                                </Col>
                            }
                        </Row>

                        <div className="d-flex justify-content-end mb-2">
                            <button
                                className="secondary-button text-decoration-none"
                                onClick={(e) => handleSave(e)} >
                                Save
                            </button>
                        </div>
                    </Form>
                </Modal.Body>
            </Modal>

            <Modal
                size="md"
                show={removeModel}
                onHide={() => setRemoveModel(false)}
                centered
                className="moadl-main delete-modal"
            >
                <Modal.Header
                    className="d-block position-relative border-0"
                    closeButton
                >
                    <Modal.Title className="text-center">Delete Notification</Modal.Title>
                </Modal.Header>
                <Modal.Body className="mx-4 border-0">
                    <p>Are you sure you want to delete?</p>
                </Modal.Body>
                <Modal.Footer className="border-0">
                    <div className="d-flex m-auto gap-2 pb-3">
                        <button
                            className="secondary-button text-decoration-none"
                            onClick={deleteNotificationFunction}
                        >
                            Delete
                        </button>
                        <Button
                            className="primary-button"
                            onClick={() => handleClose()}
                        >
                            Cancel
                        </Button>
                    </div>
                </Modal.Footer>
            </Modal>
        </div >
    )
}

export default Notification
