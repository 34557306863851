import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useContextData } from '../context/CartContext';

function PrivateRoute({ children, ...rest }) {
    const { token, user } = useContextData();
    let location = useLocation();
    let isLoggedIn = token && user._id;

    return isLoggedIn ? children : <Navigate to="/" state={{ from: location }} replace />;
}

export default PrivateRoute;