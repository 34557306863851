import { useState, useEffect, useRef } from 'react';
import { Container, Row, Col, Form } from 'react-bootstrap';
import Sidbar from '../layout/Sidbar';
import Header from '../layout/Header';
import BookingTable from '../components/BookingTable';
import { addBookingData, getSubAdmin } from "../controller/Api";
import Loader from "../components/Loader";
import * as XLSX from 'xlsx';
import moment from 'moment';
import { useContextData } from '../context/CartContext';

function Booking() {
    const { admin } = useContextData()
    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(10);
    const [search, setSearch] = useState("");
    const [loading, setLoading] = useState(false);
    const [bookingData, setBookingData] = useState();
    const [sortType, setSortType] = useState();
    const [sortByKey, setSortByKey] = useState("");
    const [selectedSubAdminId, setSelectedSubAdminId] = useState();
    const [findSubAdmin, setFindSubAdmin] = useState([]);
    const ref = useRef(null);

    const getBookingData = async (searchVal, sortByKey) => {
        const pageVal = searchVal ? 1 : page;
        try {
            setLoading(true);
            const result = await addBookingData(perPage, pageVal, search, sortType, sortByKey, selectedSubAdminId);
            const { status } = result;
            if (status === 1) {
                const { page } = result;
                setPage(page);
                setBookingData(result);
            }
        } catch (error) {
            console.log(error);
        }
        setLoading(false);
    }

    //get sub admin data
    const getSubAdminBooking = async () => {
        try {
            const result = await getSubAdmin();
            const { status, data } = result;
            if (status === 1) {
                setFindSubAdmin(data)
            }
        } catch (error) {
            console.log(error);
        }
    }

    const inputhandle = (e) => {
        setSearch(e.target.value);
    }
    const handleSort = (key) => {
        const newSortType = sortType === 'asc' ? 'desc' : 'asc';
        setSortType(newSortType);
        setSortByKey(key);
    }

    useEffect(() => {
        const callback = (event) => {
            if (event.key === 'Enter') {
                event.preventDefault();
                ref.current.click();
            }
        };
        document.addEventListener('keydown', callback);
        return () => {
            document.removeEventListener('keydown', callback);
        };
    }, []);

    useEffect(() => {
        getBookingData(null, sortByKey);
    }, [page, perPage, sortByKey, sortType, selectedSubAdminId])

    useEffect(() => {
        document.title = "Music Booth | Booking"
        admin && getSubAdminBooking();
    }, []);

    const excelDataShow = (bookingData) => {
        return bookingData?.data?.map((element, index) => {
            const { _id, created_at, booked_by, booking_date_time, bookings, status, studio, company,
                useWalletAmount } = element;


            const onlinePaymentIds = bookings
                ? bookings
                    .filter(booking => booking?.payment)
                    .map(booking => booking?.payment?.transaction_id)
                : [];

            const onlinePaymentAmount = bookings
                ? bookings
                    .filter(booking => booking?.payment)
                    .map(booking => booking?.payment?.payment_amount)
                : [];

            const startTime = booking_date_time?.length > 0 ? String(new Date(booking_date_time[0]).getUTCHours()).padStart(2, '0') + ":00" : "-";
            const endTime = booking_date_time?.length > 0 ? String(new Date(booking_date_time[booking_date_time.length - 1]).getUTCHours() + 1).padStart(2, '0') + ":00" : "-";
            const timeRange = `${startTime}-${endTime}`;
            const a = booking_date_time ? moment(booking_date_time[0]).utc().format("YYYY-MM-DD") : "-";
            const mergedDateTime = `${a}`;
            const formattedCreatedAt = moment(created_at).format('YYYY-MM-DD');

            const paymentStatus = status === 0
                ? "Pending"
                : status === 1
                    ? "Paid"
                    : status === 2
                        ? "Refund"
                        : "-"
            return {
                Date: formattedCreatedAt || "",
                Booking_Id: _id || "",
                Booth_Title: studio?.studio_name || "",
                User_Name: booked_by?.fullName || "",
                Email: booked_by?.email || "",
                Booking_Date: mergedDateTime || "",
                From: startTime || "",
                To: endTime || "",
                Status: paymentStatus || "",
                Company_Name: company.fullName || "",
                Online_Payment_Id: onlinePaymentIds.join(", ") || "",
                Paid_By_Online: !onlinePaymentAmount.length ? "0 AED" : `${onlinePaymentAmount} AED`,
                Paid_By_Wallet: useWalletAmount === 0 ? "0 AED" : `${useWalletAmount} AED`,
            };
        }) || [];
    };

    const convertToSheet = (bookingData) => {
        const sheet = XLSX.utils.json_to_sheet(bookingData);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, sheet, "Sheet1");
        return workbook;
    };

    const downloadExcel = (e, bookingData) => {
        e.preventDefault();
        const result = excelDataShow(bookingData);
        const workbook = convertToSheet(result);
        const today = new Date();
        const formattedDate = today.toISOString().split('T')[0];
        const filename = `Music Booth Booking Details - ${formattedDate}.xlsx`;
        XLSX.writeFile(workbook, filename);
    };
    return (
        <>
            {loading && <Loader />}
            <section>
                <Container fluid>
                    <Row className='vh-100'>
                        <Col xs={12} md={12} lg={3} xl={2} className='shadow-right d-none d-lg-block'>
                            <Sidbar />
                        </Col>
                        <Col xs={12} lg={9} xl={10} className='position-relative px-md-5'>
                            <Header />
                            <img src="../images/png/bg-imag.png" className='bg-imag' alt="" />
                            <Row className='justify-content-between align-items-center pb-3 '>
                                <Col md={6} lg={4} xl={4}>
                                    <h1 className='title'>Bookings Overview</h1>
                                </Col>
                                <Col md={6} lg={8} xl={8} className='mt-3 mt-md-0 d-flex justify-content-end gap-2'>
                                    {admin &&
                                        <Form.Select
                                            style={{ width: "200px", height: "50px" }}
                                            className="select-bg cursor-pointer"
                                            aria-label="Default select example"
                                            value={selectedSubAdminId}
                                            onChange={(e) => setSelectedSubAdminId(e.target.value)}
                                        >
                                            <option value={""}>Select Company</option>
                                            {findSubAdmin?.map((item, index) => (
                                                <option key={index} value={item._id}>
                                                    {item.fullName}
                                                </option>
                                            ))}
                                        </Form.Select>
                                    }

                                    <Form className='d-flex align-content-center gap-3 justify-content-end'>
                                        <div className='d-flex justify-content-end gap-3'>
                                            <div className='form-control d-flex align-items-center px-3' style={{ height: "50px", width: "350px" }}>
                                                <input className='form-control border-0 shadow-none pl-black'
                                                    placeholder='Search'
                                                    type="text"
                                                    value={search}
                                                    onChange={inputhandle}
                                                />
                                                <img className='cursor-pointer' ref={ref} onClick={() => getBookingData("search", null)} src="../images/svg/search.svg" alt="Search" />
                                            </div>
                                        </div>
                                        <div className='d-flex justify-content-end '>
                                            <button style={{ width: "200px", height: "50px" }}
                                                onClick={(e) => downloadExcel(e, bookingData)}
                                                className='secondary-button text-decoration-none'>
                                                Export to Excel
                                            </button>
                                        </div>
                                        <div className="">
                                            <Form.Select style={{ height: "50px" }}
                                                className="select-bg cursor-pointer"
                                                aria-label="Default select example"
                                                value={perPage}
                                                onChange={(e) => setPerPage(e.target.value)}
                                            >
                                                <option value={10}>10</option>
                                                <option value={20}>20</option>
                                                <option value={30}>30</option>
                                                <option value={50}>50</option>
                                                <option value={100}>100</option>
                                            </Form.Select>
                                        </div>
                                    </Form>
                                </Col>
                            </Row>
                            <BookingTable
                                admin={admin}
                                setPage={setPage}
                                search={search}
                                getBookingData={getBookingData}
                                bookingData={bookingData}
                                setBookingData={setBookingData}
                                perPage={perPage}
                                page={page}
                                setLoading={setLoading}
                                setSortType={setSortType}
                                sortType={sortType}
                                handleSort={handleSort}
                            />
                        </Col>
                    </Row>
                </Container>
            </section>
        </>
    )
}

export default Booking