import React, { useState } from 'react'
import { Col, Form, Modal, Row, Table } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import TablePagination from '../components/TablePagination';

const NotificationTable = ({ perPage, setPage, page, setLgShow, handleEdit, getNotifications, admin }) => {
    const navigate = useNavigate();
    const [showInfo, setShowInfo] = useState(false);
    const [infoData, setInfoData] = useState(false);
    const handleClose = () => setShowInfo(false);
    // const [id, setId] = useState();
    const handleInfo = (notificationData) => {
        setInfoData(notificationData);
    }

    function handleNotification(infoData) {
        if (infoData.method === "email") {
            return "Email Only";
        } else if (infoData.method === "inApp") {
            return "In-App Notification";
        } else {
            return "Both";
        }
    }

    const methods = handleNotification(infoData);

    return (
        <>
            <Row>
                <Col>
                    <Table bordered responsive="xl">
                        <thead>
                            <tr>
                                <th className='number-width border'>No</th>
                                <th>Trigger Event</th>
                                <th>Lead Time</th>
                                <th>Method</th>
                                <th>Title</th>
                                <th>Description</th>
                                {admin && <th>Sub Admin</th>}
                                <th>Action</th>
                            </tr>
                        </thead>
                        {Boolean(getNotifications?.data?.length) ?
                            <tbody>
                                {getNotifications?.data?.length && getNotifications?.data?.map((notificationData, index) => {
                                    const currentNumber = (page - 1) * perPage + index + 1;
                                    function handleNotificationMethod(notificationData) {
                                        if (notificationData.method === "email") {
                                            return "Email Only";
                                        } else if (notificationData.method === "inApp") {
                                            return "In-App Notification";
                                        } else {
                                            return "Both";
                                        }
                                    }
                                    const notificationMethod = handleNotificationMethod(notificationData);

                                    return (
                                        <tr>
                                            <td>{currentNumber}</td>
                                            <td>{notificationData.event.replace(/\b\w/g, (char) => char.toUpperCase()).replace(/([a-z])([A-Z])/g, '$1 $2')}</td>
                                            <td>{`${notificationData.leadTime + " " + notificationData.timeIn}` || "-"}</td>
                                            <td>{notificationMethod}</td>
                                            <td>{notificationData.title || "-"}</td>
                                            <td className='notified'>{notificationData?.desc || "-"}</td>
                                            {admin && <td>{notificationData.userId?.fullName || "-"}</td>}
                                            <td>
                                                <div className="d-flex justify-content-center align-items-center gap-3">
                                                    <div title='Info' className='cursor-pointer'>
                                                        <img src="./images/svg/info.svg" alt=""
                                                            onClick={() => {
                                                                setShowInfo(true);
                                                                handleInfo(notificationData);
                                                            }}
                                                        />
                                                    </div>

                                                    <div title='Edit' className='d-flex gap-3 cursor-pointer'>
                                                        <img src="./images/svg/edit.svg" alt=""
                                                            onClick={() => {
                                                                handleEdit(notificationData);
                                                                setLgShow(true);
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    );
                                })
                                }
                            </tbody>
                            :
                            <tbody>
                                <tr>
                                    <td className="bg-transparent" colSpan={10}>
                                    <div className='nodata-table'>
                                            <h3 className="text-center p-5">Data Not Found</h3>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        }
                    </Table>
                    <div className='d-md-flex justify-content-between align-items-center'>
                        <button className='secondary-button text-decoration-none' onClick={() => navigate("/dashboard")} >
                            <svg
                                stroke="currentColor"
                                fill="currentColor"
                                strokeWidth={0}
                                viewBox="0 0 24 24"
                                height="2em"
                                width="2em"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path d="M12.707 17.293 8.414 13H18v-2H8.414l4.293-4.293-1.414-1.414L4.586 12l6.707 6.707z" />
                            </svg>
                            Back
                        </button>
                        {
                            getNotifications?.data && getNotifications?.data?.length >= 1 &&
                            <TablePagination
                                currentPage={getNotifications?.page}
                                totalPages={getNotifications?.pages}
                                onPageChange={(newPage) => {
                                    setPage(newPage);
                                }}
                            />
                        }
                    </div>
                </Col>
            </Row>

            <Modal
                show={showInfo}
                size="xl"
                onHide={handleClose}
                centered
                className='moadl-main info-modal'
            >
                <Modal.Header className='d-block position-relative border-0' closeButton>
                    <Modal.Title className='text-center'>Notification</Modal.Title>
                </Modal.Header>
                <Modal.Body className='mx-md-4 border-0 bg-transparent pb-0'>
                    <Form className='mt-4'>
                        <Row>
                            <Col md={12}>
                                <div className="overflow-auto">
                                    <Table striped bordered hover>
                                        <thead>
                                            <tr>
                                                <th style={{ width: "300px" }} className='number-width border'>Trigger Event</th>
                                                <th>Lead Time</th>
                                                <th>Method</th>
                                                <th>Title</th>
                                                <th>Description</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                {/* <td>{infoData.event}</td> */}
                                                <td>{infoData.event?.replace(/\b\w/g, (char) => char.toUpperCase())?.replace(/([a-z])([A-Z])/g, '$1 $2')}</td>
                                                <td>{`${infoData.leadTime + " " + infoData.timeIn}` || "-"}</td>
                                                <td>{methods}</td>
                                                <td>{infoData.title}</td>
                                                <td>{infoData.desc}</td>
                                            </tr>
                                        </tbody>
                                    </Table>
                                </div>
                            </Col>
                        </Row>
                    </Form>
                </Modal.Body>
                <Modal.Footer className='mx-md-4 border-0 bg-transparent pt-0'>

                </Modal.Footer>
            </Modal >
        </>
    )
}

export default NotificationTable