import React, { useState, useRef, useEffect } from "react";
import { Button, Dropdown } from "react-bootstrap";
import Offcanvas from "react-bootstrap/Offcanvas";
import Sidbar from "./Sidbar";
import { useNavigate } from "react-router-dom";
import { Row, Col, Form } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import { toast } from "react-toastify";
import { pf } from "../helper/envConfig";
import { useContextData } from "../context/CartContext";
import { logOutApi, editProfileApi } from "../controller/Api";

function Header() {
  const { user, setUser, setToken, admin } = useContextData();
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const navigate = useNavigate();
  const images = pf();
  const url = images;
  const [logoutModelOpen, setLogOutModelOpen] = useState(false);
  const [profile, setProfile] = useState(localStorage.getItem("profile_image"));
  const roles = ["client", "admin", "user"];
  const [profileEditModel, setProfileEditModel] = useState(false);
  const [editShowData, setEditShowData] = useState({
    fullName: "",
    company_website: "",
    company_name: "",
    oldPassword: "",
    newPassword: "",
    confirm: "",
    profile_image: null
  });

  const [selectedImage, setSelectedImage] = useState("");
  const fileInputRef = useRef(null);

  const inputValChange = (e) => {
    const { name, value } = e.target;
    setEditShowData((oldVal) => ({
      ...oldVal,
      [name]: value
    }));
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const previewUrl = URL.createObjectURL(file);
      setSelectedImage(previewUrl);
      setEditShowData((oldVal) => ({
        ...oldVal,
        profile_image: file
      }));
    }
  };

  useEffect(() => {
    setEditShowData({
      fullName: user?.fullName || "",
      company_website: user?.company_website || "",
      company_name: user?.company_name || "",
      oldPassword: "",
      newPassword: "",
      confirm: "",
      profile_image: user?.profile_image || null
    })
  }, [profileEditModel])

  const handleImageClick = () => {
    fileInputRef.current.click();
  };

  const Logout = async () => {
    try {
      const res = await logOutApi();
      if (res.status === 1) {
        localStorage.clear();
        toast.success("logged out successfully!");
        setUser({});
        setToken()
        navigate("/");
      }
      else {
        toast.error("error");
      }
    } catch (error) {
      toast.error(error);
    }
  };

  const handleEdit = (element) => {
    // setInfoData(element);
    setEditShowData(element);
  };

  const isValidURL = (url) => {
    const urlPattern = /^(ftp|http|https):\/\/[^ "]+$/;
    return urlPattern.test(url);
  };

  const userEditSubmit = async () => {
    if (editShowData.newPassword !== editShowData.confirm) {
      toast.error("Confirm Passwords Do Not Match");
      return;
    }

    if (!isValidURL(editShowData.company_website)) {
      toast.error("Please enter a valid URL");
      return;
    }

    const formData = new FormData();
    formData.append('company_website', editShowData.company_website);
    formData.append('company_name', editShowData.company_name);
    formData.append('oldPassword', editShowData.oldPassword);
    formData.append('newPassword', editShowData.newPassword);

    if (editShowData.profile_image) {
      formData.append('profile_image', editShowData.profile_image);
    }
    const result = await editProfileApi(formData);
    const { status, message, data } = result;
    if (status === 1) {
      toast.success(message);
      setProfileEditModel(false);
      setUser(data);
      setEditShowData({
        oldPassword: "",
        newPassword: "",
        confirm: "",
      })
      const userDataToStore = {
        email: data.email,
        fullName: data.fullName,
        user_role: data.user_role,
        _id: data._id,
        teacher_verified: data.teacher_verified,
        profile_image: data.profile_image,
        company_name: data.company_name,
        company_website: data.company_website,
      };
      localStorage.setItem("user", JSON.stringify(userDataToStore));
    } else {
      toast.error(message);
    }
  };

  return (
    <>
      <header className="d-flex justify-content-between align-items-center pt-4 custom-drop">
        <div onClick={handleShow}>
          <img
            src="../images/png/menu.png"
            className="toggle"
            width={35}
            height={35}
            alt=""
          />
        </div>

        <Dropdown>
          <Dropdown.Toggle className="bg-transparent border-0">
            <div className="d-flex align-items-center gap-3">
              <img className="profile-image" src={user.profile_image ? url + user.profile_image : "../images/jpg/user.jpg"} />
              <p className="user-name">{"Hello, " + user.fullName}</p>
            </div>
          </Dropdown.Toggle>

          <Dropdown.Menu className="mt20 shadow border-0 user-dropdown-menu">
            {/* Profile */}
            <Dropdown.Item onClick={() => setProfileEditModel(true)} className="d-flex align-items-center gap-2">
              <img src="./images/png/use.png" style={{ height: "20px", weight: "20px" }} alt="" />
              Profile
            </Dropdown.Item>

            <Dropdown.Item onClick={() => setLogOutModelOpen(true)} className="d-flex align-items-center gap-2 mt-2">
              <svg fill="#000000" height="25px" width="25px" viewBox="0 0 490.3 490.3" xmlns="http://www.w3.org/2000/svg">
                <g>
                  <g>
                    <path d="M0,121.05v248.2c0,34.2,27.9,62.1,62.1,62.1h200.6c34.2,0,62.1-27.9,62.1-62.1v-40.2c0-6.8-5.5-12.3-12.3-12.3 s-12.3,5.5-12.3,12.3v40.2c0,20.7-16.9,37.6-37.6,37.6H62.1c-20.7,0-37.6-16.9-37.6-37.6v-248.2c0-20.7,16.9-37.6,37.6-37.6h200.6 c20.7,0,37.6,16.9,37.6,37.6v40.2c0,6.8,5.5,12.3,12.3,12.3s12.3-5.5,12.3-12.3v-40.2c0-34.2-27.9-62.1-62.1-62.1H62.1 C27.9,58.95,0,86.75,0,121.05z" />
                    <path d="M385.4,337.65c2.4,2.4,5.5,3.6,8.7,3.6s6.3-1.2,8.7-3.6l83.9-83.9c4.8-4.8,4.8-12.5,0-17.3l-83.9-83.9 c-4.8-4.8-12.5-4.8-17.3,0s-4.8,12.5,0,17.3l63,63H218.6c-6.8,0-12.3,5.5-12.3,12.3c0,6.8,5.5,12.3,12.3,12.3h229.8l-63,63 C380.6,325.15,380.6,332.95,385.4,337.65z" />
                  </g>
                </g>
              </svg>
              Log out
            </Dropdown.Item>


          </Dropdown.Menu>
        </Dropdown>

      </header>

      <Offcanvas className="offcanvas-sidbar" show={show} onHide={handleClose}>
        <Offcanvas.Header className="border-bottom" closeButton>
          <img
            src="../images/logo/logo.png"
            className="mobaile-sidbarlogo"
            alt=""
          />
        </Offcanvas.Header>
        <Offcanvas.Body>
          <Sidbar />
        </Offcanvas.Body>
      </Offcanvas>

      {/* Edit user modal */}
      <Modal
        size="lg"
        show={profileEditModel}
        onHide={() => {
          setProfileEditModel(false);
          setEditShowData({
            oldPassword: "",
            newPassword: "",
            confirm: "",
          })
        }}
        centered
        className="moadl-main info-modal"
      >
        <Modal.Header
          className="d-block position-relative border-0"
          closeButton
        >
          <Modal.Title className="text-center">Edit Profile</Modal.Title>
        </Modal.Header>

        <Modal.Body className="mx-md-4 border-0 bg-transparent pb-0">
          <div className="position-relative">
            <div className="d-flex justify-content-center m-auto edit-user">
              <img
                src={selectedImage || (user.profile_image ? `${url + user.profile_image}` : "../images/png/menu.png")}
                onClick={handleImageClick}
                style={{ cursor: "pointer" }}
                alt="Profile"
              />
            </div>
            <input
              type="file"
              accept=".jpg, .jpeg, .png"
              ref={fileInputRef}
              name="profile_image"
              style={{ display: "none" }}
              onChange={handleFileChange}
            />
            {/* <img
              src={user.profile_image ? `${url + user.profile_image}` : "../images/png/menu.png"}
              className="camer position-absolute top-50 start-50 translate-middle"
              alt="Change profile"
              onClick={handleImageClick}
              style={{ cursor: "pointer" }}
            /> */}
          </div>

          <Form className="mt-4">
            <Row>
              <Col md={12}>
                <span className="fw-600">Name</span>
                <div className="form-control d-flex align-items-center px-3 mb-3">
                  <input
                    className="form-control border-0 shadow-none pl-black"
                    placeholder="User Name"
                    type="text"
                    name="fullName"
                    value={editShowData.fullName}
                    disabled
                  />
                </div>
              </Col>

              <Col md={12}>
                <span className="fw-600">Website Name</span>
                <div className="form-control d-flex align-items-center px-3 mb-3">
                  <input
                    className="form-control border-0 shadow-none pl-black"
                    placeholder="Add Website Name"
                    type="text"
                    name="company_website"
                    value={editShowData.company_website}
                    onChange={inputValChange}
                  />
                </div>
              </Col>

              <Col md={12}>
                <span className="fw-600">Company Name</span>
                <div className="form-control d-flex align-items-center px-3 mb-3">
                  <input
                    className="form-control border-0 shadow-none pl-black"
                    placeholder="Add Company Name"
                    type="url"
                    name="company_name"
                    value={editShowData.company_name}
                    onChange={inputValChange}
                  />
                </div>
              </Col>
              <Col md={12}>
                <span className="fw-600">Old Password </span>
                <div className="form-control d-flex align-items-center px-3 mb-3">
                  <input
                    className="form-control border-0 shadow-none pl-black"
                    placeholder="Add Old Password"
                    type="password"
                    name="oldPassword"
                    value={editShowData.oldPassword}
                    onChange={inputValChange}
                  />
                </div>
              </Col>
              <Col md={12}>
                <span className="fw-600">New Password </span>
                <div className="form-control d-flex align-items-center px-3 mb-3">
                  <input
                    className="form-control border-0 shadow-none pl-black"
                    placeholder="Add New Password"
                    type="password"
                    name="newPassword"
                    value={editShowData.newPassword}
                    onChange={inputValChange}
                  />
                </div>
              </Col>

              <Col md={12}>
                <span className="fw-600">Confirm Password </span>
                <div className="form-control d-flex align-items-center px-3 mb-3">
                  <input
                    className="form-control border-0 shadow-none pl-black"
                    placeholder="Confirm Password"
                    type="password"
                    name="confirm"
                    value={editShowData.confirm}
                    onChange={inputValChange}
                  />
                </div>
              </Col>
            </Row>
          </Form>
        </Modal.Body>

        <Modal.Footer className="mx-md-4 border-0 bg-transparent pt-0">
          <Button className="secondary-button"
            onClick={userEditSubmit}
          >
            Save
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        size="md"
        centered
        show={logoutModelOpen}
        onHide={() => setLogOutModelOpen(false)}
        className="moadl-main delete-modal"
      >
        <Modal.Header
          className="d-block position-relative border-0"
          closeButton
        >
          <Modal.Title className="text-center">Log Out</Modal.Title>
        </Modal.Header>
        <Modal.Body className="mx-4 border-0">
          <p className="text-center">Are you sure you want to log out from your account?</p>
        </Modal.Body>
        <Modal.Footer className="border-0">
          <div className="d-flex m-auto gap-2 pb-3">
            <button
              className="secondary-button text-decoration-none"
              onClick={Logout}
            >
              Log Out
            </button>
            <Button
              className="primary-button"
              onClick={() => setLogOutModelOpen(false)}
            >
              Cancel
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default Header;
