import React, { useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import { AddGuide, getGuide } from "../controller/Api";
import { pf } from "../helper/envConfig";
import { FaTimes } from "react-icons/fa"; // Import close icon from react-icons/fa
import { CartContext } from '../context/CartContext';
import Loader from "./Loader";
const PartnerGuide = () => {
    const { admin } = useContext(CartContext);
    const [serviceData, setServiceData] = useState({});
    const [selectedImages, setSelectedImages] = useState([]);
    const [getImage, setGetImage] = useState([]);
    const [deletedFiles, setDeletedFiles] = useState([])
    const [loading, setLoading] = useState(false);
    const images = pf();
    const url = images;

    // Handle file selection
    const handleFileChange = (event) => {
        const files = event.target.files;
        const maxFiles = event.target.getAttribute("data-max_length");

        if (maxFiles && files?.length > parseInt(maxFiles)) {
            toast.error(`Please select up to ${maxFiles} images.`);
            return;
        }
        const existingFiles = serviceData[event.target.name] || [];
        const updatedFiles = existingFiles.concat(Array.from(files));
        setServiceData({
            ...serviceData,
            [event.target.name]: updatedFiles,
        });

        setSelectedImages(updatedFiles);
    };

    // Handle file upload
    const handleAddClick = async () => {
        // if (selectedImages.length === 0) {
        //     toast.error("Please select files to upload.");
        //     return;
        // }
        try {
            const formData = new FormData();
            if (selectedImages.length > 0) {
                selectedImages.forEach((file, index) => {
                    formData.append("manual", file);
                });
            }
            if (deletedFiles.length > 0) {
                deletedFiles.forEach((id, index) => {
                    formData.append(`deleteFiles[${index}]`, id)
                })
            }
            const data = await AddGuide(formData);
            const { message, status } = data;
            if (status === 1) {
                toast.success(message);
                setSelectedImages([]);
                setServiceData({});
                getPartnerGuideData();
            } else {
                console.log(message);
            }
        } catch (error) {
            toast.error('Failed to upload files. Please try again.');
        }
    };

    const getPartnerGuideData = async () => {
        try {
            setLoading(true);
            const result = await getGuide();
            const { data, message, status } = result;
            if (status === 1) {
                setGetImage(data.files);
            } else {
                toast.error(message);
            }
        } catch (error) {
            toast.error('Failed to fetch data. Please try again.');
        }
        setLoading(false);
    };

    const removeFile = (index) => {
        const newFiles = [...getImage];
        const newDelete = [...deletedFiles]
        newDelete.push(newFiles[index]._id);
        newFiles.splice(index, 1);
        setDeletedFiles(newDelete)
        setGetImage(newFiles);
    };

   

    useEffect(() => {
        getPartnerGuideData();
    }, []);

    return (
        <>
            {loading && <Loader />}
            {admin &&
                <div className="col-6">
                    <h5 style={{ fontWeight: "600" }}>Upload Files</h5>
                    <div className="partner-guide">
                        <input
                            type="file"
                            name="manual"
                            onChange={handleFileChange}
                            multiple
                        />
                        <span>Choose File</span>
                    </div>

                    {selectedImages.length > 0 && (
                        <div>
                            <p>Selected Files:</p>
                            <ul>
                                {selectedImages.map((file, index) => (
                                    <li key={index} className="selected-file-item">
                                        <span>{file.name}</span>
                                        <FaTimes
                                            className="close-icon"
                                            onClick={() => {
                                                const updatedFiles = selectedImages.filter((_, i) => i !== index);
                                                setSelectedImages(updatedFiles);
                                                setServiceData({
                                                    ...serviceData,
                                                    manual: updatedFiles,
                                                });
                                            }}
                                        />
                                    </li>
                                ))}
                            </ul>
                        </div>
                    )}
                    <button onClick={handleAddClick} className="btn secondary-button mt-3">
                        Add
                    </button>
                </div >
            }

            <div className="col-12">
                {/* <div className="d-flex flex-wrap gap-3 mt-4">
                    {getImage?.map((img, index) => (
                        <div key={index} className="image-item position-relative">
                            <a href={url + img.filePath} target="_blank" rel="noopener noreferrer">
                                Document {index + 1}
                            </a>
                            <p className="image-name">{img.name}</p>
                            {admin &&
                                <div className="closee-image shadow d-flex justify-content-center align-items-center">
                                    <img
                                        src="../images/jpg/closee.jpg"
                                        className="cursor-pointer"
                                        alt=""
                                        height={20}
                                        width={20}
                                        name="deleteFiles"
                                        onClick={() => removeFile(index)}
                                    />
                                </div>
                            }
                        </div>
                    ))}
                </div> */}
                <div className="d-flex flex-wrap gap-3 mt-4">
                    {getImage?.map((img, index) => {
                        const fileExtension = img.filePath.split('.').pop().toLowerCase();

                        return (
                            <div key={index} className="image-item position-relative d-flex justify-content-between flex-column">
                                <div>
                                    {fileExtension === 'jpg' || fileExtension === 'png' || fileExtension === 'svg' ? (
                                        <img src={url + img.filePath} height={100} width={100} alt={img.name} />
                                    ) : (
                                        <img src="../images/png/pdf-file.png" height={100} width={100} alt="PDF Icon" />
                                    )}
                                    <p className="image-name text-center">{img.name}</p>
                                </div>
                                <div className="text-center mt-3">
                                    <a
                                        href={url + img.filePath}
                                        download={img.filePath}
                                        target="_blank"
                                        className="btn btn-secondary d-flex justify-content-center align-items-center fixed-height border-0"
                                    >
                                        Download
                                    </a>
                                </div>
                                {admin && (
                                    <div className="closee-image shadow d-flex justify-content-center align-items-center position-absolute top-0 end-0">
                                        <img
                                            src="../images/jpg/closee.jpg"
                                            className="cursor-pointer"
                                            alt="Delete"
                                            height={20}
                                            width={20}
                                            name="deleteFiles"
                                            onClick={() => removeFile(index)}
                                        />
                                    </div>
                                )}
                            </div>
                        );
                    })}
                </div>

                {admin && <button onClick={handleAddClick} className="btn secondary-button mt-3">
                    Save
                </button>
                }
            </div>
        </>
    );
};

export default PartnerGuide;
