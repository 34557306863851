import React, { useState, useEffect, useRef, useContext } from "react";
import { Container, Row, Col, Form } from "react-bootstrap";
import Sidbar from "../layout/Sidbar";
import Header from "../layout/Header";
import PaymentTable from "../components/PaymentTable";
import { getPayment, getSubAdmin } from '../controller/Api';
import Loader from "../components/Loader";
import * as XLSX from 'xlsx';
import moment from "moment";
import { useContextData } from '../context/CartContext';

function Payment() {
  const { admin } = useContextData();
  const [paymentData, setPaymentData] = useState();
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [sortType, setSortType] = useState("asc");
  const [loading, setLoading] = useState(false);
  const [selectedSubAdminId, setSelectedSubAdminId] = useState();
  const [findSubAdmin, setFindSubAdmin] = useState([]);

  const ref = useRef(null);

  const PaymentGet = async (searchVal) => {
    const pageVal = searchVal ? 1 : page;
    try {
      setLoading(true);
      const payment = await getPayment(perPage, pageVal, search, sortType, selectedSubAdminId);
      const { status } = payment
      if (status === 1) {
        setPaymentData(payment)
      }
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  }

  //get sub admin data
  const getSubAdminBooking = async () => {
    try {
      const result = await getSubAdmin();
      const { status, data } = result;
      if (status === 1) {
        setFindSubAdmin(data)
      }
    } catch (error) {
      console.log(error);
    }
  }


  const inputhandle = (e) => {
    setSearch(e.target.value);
  }

  useEffect(() => {
    const callback = (event) => {
      if (event.key === 'Enter') {
        event.preventDefault();
        ref.current.click();
      }
    };
    document.addEventListener('keydown', callback);
    return () => {
      document.removeEventListener('keydown', callback);
    };
  }, []);

  useEffect(() => {
    PaymentGet();
  }, [page, perPage, selectedSubAdminId]);

  useEffect(() => {
    admin && getSubAdminBooking();
    document.title = "Music Booth | Payment"
  }, []);

  const paymentOrder = () => {
    const newSortType = sortType === 'asc' ? 'desc' : 'asc';
    setSortType(newSortType);
    PaymentGet();
  }

  const excelDataShow = (paymentData) => {
    return paymentData?.data?.map((element) => {
      const { _id, booking_detail, created_at, customer_details, paid_by, payment_amount, payment_info, payment_status, transaction_id } = element;
      const formattedCreatedAt = moment(created_at).format('YYYY-MM-DD');
      return {
        Date: formattedCreatedAt || "",
        Id: _id || "",
        Booking_Id: booking_detail || "",
        Paid_by: paid_by?.fullName || "",
        Email: paid_by?.email || "",
        Street1: customer_details?.street1 || "",
        City: customer_details?.city || "",
        State: customer_details?.state || "",
        Country: customer_details?.country || "",
        Ip: customer_details?.ip || "",
        Payment_Amount: payment_amount ? `${payment_amount} AED` : "0 AED",
        Card_Type: payment_info?.card_type || "",
        Card_Number: payment_info?.payment_description || "",
        Card_Scheme: payment_info?.card_scheme || "",
        Expiry_Month: payment_info?.expiryMonth || "",
        Expiry_Year: payment_info?.expiryYear || "",
        Payment_Status: payment_status || "",
        Payment_Id: transaction_id || ""
      };
    }) || [];
  };
  const convertToSheet = (paymentData) => {
    const sheet = XLSX.utils.json_to_sheet(paymentData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, sheet, "Sheet1");
    return workbook;
  };
  const downloadExcel = (e, paymentData) => {
    e.preventDefault();
    const result = excelDataShow(paymentData);
    const workbook = convertToSheet(result);
    const today = new Date();
    const formattedDate = today.toISOString().split('T')[0];
    const filename = `Music Booth Payment Details - ${formattedDate}.xlsx`;
    XLSX.writeFile(workbook, filename);
    // XLSX.writeFile(workbook, "Payment details.xlsx");
  };

  return (
    <>
      {loading && <Loader />}
      <section>
        <Container fluid>
          <Row className="vh-100">
            <Col
              xs={12}
              md={12}
              lg={3}
              xl={2}
              className="shadow-right d-none d-lg-block"
            >
              <Sidbar />
            </Col>
            <Col xs={12} lg={9} xl={10} className="position-relative px-md-5">
              <Header />
              <img src="../images/png/bg-imag.png" className="bg-imag" alt="" />
              <Row className="justify-content-between align-items-center pb-3">
                <Col md={6} lg={5} xl={4}>
                  <h1 className="title">Payment Overview</h1>
                </Col>

                <Col md={6} lg={7} xl={8} className="mt-3 mt-md-0 d-flex gap-2 justify-content-end">
                  {admin && <Form.Select
                    style={{ width: "200px", height: "50px" }}
                    className="select-bg cursor-pointer"
                    aria-label="Default select example"
                    value={selectedSubAdminId}
                    onChange={(e) => setSelectedSubAdminId(e.target.value)}
                  >
                    <option value={""}>Select Company</option>
                    {findSubAdmin?.map((item, index) => (
                      <option key={index} value={item._id}>
                        {item.fullName}
                      </option>
                    ))}
                  </Form.Select>
                  }
                  <Form className='d-flex align-content-center gap-3 justify-content-end'>
                    <div className='d-flex justify-content-end gap-3'>
                      <div className='form-control d-flex align-items-center px-3' style={{ height: "50px", width: "350px" }}>
                        <input className='form-control border-0 shadow-none pl-black'
                          placeholder="Search"
                          type="text"
                          value={search}
                          onChange={inputhandle}
                        />
                        <img
                          className='cursor-pointer'
                          ref={ref}
                          src="../images/svg/search.svg"
                          alt="Search"
                          onClick={() => PaymentGet("search")}
                        />
                      </div>
                    </div>
                    <div className='d-flex justify-content-end '>
                      <button style={{ width: "200px" }}
                        onClick={(e) => downloadExcel(e, paymentData)}
                        className='secondary-button text-decoration-none'>
                        Export to Excel
                      </button>
                    </div>

                    <div className="">
                      <Form.Select style={{ height: "50px" }}
                        className="select-bg cursor-pointer"
                        aria-label="Default select example"
                        value={perPage}
                        onChange={(e) => setPerPage(e.target.value)}
                      >
                        <option value={10}>10</option>
                        <option value={20}>20</option>
                        <option value={30}>30</option>
                        <option value={50}>50</option>
                        <option value={100}>100</option>
                      </Form.Select>
                    </div>
                  </Form>
                </Col>
              </Row>
              <PaymentTable
                admin={admin}
                PaymentGet={PaymentGet}
                page={page}
                setPage={setPage}
                perPage={perPage}
                setPerPage={setPerPage}
                setPaymentData={setPaymentData}
                paymentData={paymentData}
                setSortType={setSortType}
                paymentOrder={paymentOrder}
              />
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
}

export default Payment;
