import React, { useContext, useEffect, useRef } from 'react'
import { Col, Container, Form, Modal, Row, Toast } from 'react-bootstrap'
import Header from '../layout/Header'
import Sidbar from '../layout/Sidbar'
import { useState } from 'react'
import TeacherTable from '../components/TeacherTable'
import { getUser } from '../controller/Api'
import { CartContext } from '../context/CartContext'
import { toast } from 'react-toastify'
import Loader from "../components/Loader";

const Teacher = () => {
    const [userDataShow, setUserDataShow] = useState();
    const [lgShow, setLgShow] = useState(false);
    const ref = useRef(null);
    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(10);
    const [search, setSearch] = useState("");
    const [showw, setShoww] = useState(false);
    const [loading, setLoading] = useState(false);
    const [sortType, setSortType] = useState("asc");

    const getDataUser = async (key, searchVal) => {
        const pageVal = searchVal ? 1 : page
        try {
            setLoading(true);
            const user = await getUser(perPage, pageVal, search, "teacher", sortType, key);
            const { status } = user;
            if (status === 1) {
                setUserDataShow(user);
                setPage(pageVal);
            } else {
                console.log("error");
            }
        } catch (error) {
            toast.error(error);
        }
        setLoading(false);
    };

    const userOrder = (key) => {
        const order = sortType === 'asc' ? 'desc' : 'asc';
        setSortType(order);
        getDataUser(key);
    }

    const inputHandle = (e) => {
        setSearch(e.target.value);
    }

    useEffect(() => {
        getDataUser();
        document.title = "Music Booth | Teacher Verification"
    }, [page]);

    useEffect(() => {
        const callback = (event) => {
            if (event.key === 'Enter') {
                event.preventDefault();
                ref.current?.click();
            }
        };
        document.addEventListener('keydown', callback);
        return () => {
            document.removeEventListener('keydown', callback);
        };
    }, []);

    return (
        <>
            {loading && <Loader />}
            <section>
                <Container fluid>
                    <Row className="vh-100">
                        <Col
                            xs={12}
                            md={12}
                            lg={3}
                            xl={2}
                            className="shadow-right d-none d-lg-block"
                        >
                            <Sidbar />
                        </Col>
                        <Col xs={12} lg={9} xl={10} className="position-relative px-md-5">
                            <Header />
                            <img src="../images/png/bg-imag.png" className="bg-imag" alt="" />
                            <Row className="justify-content-between align-items-center pb-3">
                                <Col md={6} lg={5} xl={7}>
                                    <h1 className="title">Teacher Verification</h1>
                                </Col>
                                <Col md={6} lg={7} xl={5} className="mt-3 mt-md-0">
                                    <Form>
                                        <div className="d-flex justify-content-end gap-3">
                                            <div className="form-control d-flex align-items-center px-3" style={{ width: "350px" }}>
                                                <input
                                                    className="form-control border-0 shadow-none pl-black"
                                                    placeholder="Search"
                                                    type="text"
                                                    value={search}
                                                    onChange={inputHandle}
                                                />
                                                <img
                                                    className='cursor-pointer'
                                                    ref={ref}
                                                    src="../images/svg/search.svg" alt="Search" onClick={() => getDataUser("data", "search")} />
                                            </div>
                                        </div>
                                    </Form>
                                </Col>
                            </Row>
                            <TeacherTable
                                getDataUser={getDataUser}
                                userDataShow={userDataShow}
                                page={page}
                                setPage={setPage}
                                perPage={perPage}
                                setPerPage={setPerPage}
                                setShoww={setShoww}
                                userOrder={userOrder}
                            />
                        </Col>
                    </Row>
                </Container>

            </section>
            <Modal
                size="xl"
                show={lgShow}
                onHide={() => {
                    setLgShow(false)
                    // setUserData({
                    //     fullName: "",
                    //     email: "",
                    //     password: "",
                    //     user_role: "",
                    //     is_block: "",
                    //     phone_no: "",
                    //     address: "",
                    //     profile_image: ""
                    // });
                    // setSelectedImage({ profile_image: "" })
                }}
                centered
                className='moadl-main info-modal'
            >
                <Modal.Header className='d-block position-relative border-0' closeButton>
                    <Modal.Title className='text-center'>Add Pricing</Modal.Title>
                </Modal.Header>
                <Modal.Body className='mx-md-4 border-0 bg-transparent pb-0'>
                    <Form className='mt-4'>
                        <Row>
                            <Col md={12}>
                                <div className='form-control d-flex align-items-center px-1 mb-3'>
                                    <input className='form-control border-0 shadow-none pl-black' placeholder='Enter name'
                                        type="text" name='fullName'
                                    // value={userData.fullName} 
                                    // onChange={inputValChange} 
                                    />
                                </div>
                            </Col>
                            <Col md={12}>
                                <div className="form-control d-flex align-items-center px-1 mb-3">
                                    <div className="d-flex w-100">
                                        <div className="mt-2">

                                        </div>
                                        <textarea
                                            className="form-control border-0 shadow-none px-0 px-3 text-black w-100"
                                            placeholder="Enter description"
                                            name="desc"
                                            rows={3}
                                            type="text"
                                        //   onChange={inputValChange}
                                        //   onKeyDown={handleKeyDown}
                                        //   value={serviceData.desc}
                                        />
                                    </div>
                                </div>
                            </Col>

                            <Col md={12}>
                                <div className=''>
                                    <select aria-label="Default select example"
                                        class="select-bg-booking form-select"
                                    // value={selectedUserRole}
                                    // onChange={(e) => setSelectedUserRole(e.target.value)}
                                    >
                                        <option value="">All (generic)</option>
                                        <option value="">Teacher (discounted)</option>
                                    </select>
                                </div>
                            </Col>

                            <Col xs={12} lg={12}>
                                <div className="d-flex align-items-center text-center text-nowrap gap-2 mb-4 py-2">
                                    <span className="text-nowrap fs-6">Day:</span>
                                    <div className="mx-2 gap-2 d-flex custom-checked py-1">
                                        <input
                                            style={{ height: "20px", width: "20px", marginTop: "3px", marginLeft: "0px", marginRight: "0px" }}
                                            type="checkbox"
                                            className=""
                                            name="booth_options"
                                            id="smallCheckbox"
                                            value="Small"
                                        //   onChange={handleCheckboxChange}
                                        //   checked={checkedOptions?.includes("Small")}
                                        />
                                        <label htmlFor="smallCheckbox" className="checkbox-label fw-500">Sunday</label>
                                    </div>
                                    <div className="mx-2 gap-1 d-flex custom-checked py-2">
                                        <input
                                            style={{ height: "20px", width: "20px", marginTop: "3px", marginLeft: "0px", marginRight: "0px" }}
                                            type="checkbox"
                                            className=""
                                            id="smallCheckbox1"
                                            name="booth_options"
                                            value="Medium"
                                        //   onChange={handleCheckboxChange}
                                        //   checked={checkedOptions?.includes("Medium")}
                                        />
                                        <label htmlFor="smallCheckbox1" className="checkbox-label fw-500">Monday</label>
                                    </div>
                                    <div className="mx-2 gap-2 d-flex custom-checked py-1">
                                        <input
                                            style={{ height: "20px", width: "20px", marginTop: "3px", marginLeft: "0px", marginRight: "0px" }}
                                            type="checkbox"
                                            className=""
                                            name="booth_options"
                                            id="smallCheckbox2"
                                            value="Large"
                                        //   onChange={handleCheckboxChange}
                                        //   checked={checkedOptions?.includes("Large")}
                                        />
                                        <label htmlFor="smallCheckbox2" className="checkbox-label fw-500">TueDay</label>
                                    </div>
                                    <div className="mx-2 gap-2 d-flex custom-checked py-1">
                                        <input
                                            style={{ height: "20px", width: "20px", marginTop: "3px", marginLeft: "0px", marginRight: "0px" }}
                                            type="checkbox"
                                            className=""
                                            name="booth_options"
                                            id="smallCheckbox2"
                                            value="Large"
                                        //   onChange={handleCheckboxChange}
                                        //   checked={checkedOptions?.includes("Large")}
                                        />
                                        <label htmlFor="smallCheckbox2" className="checkbox-label fw-500">WedDay</label>
                                    </div>
                                    <div className="mx-2 gap-2 d-flex custom-checked py-1">
                                        <input
                                            style={{ height: "20px", width: "20px", marginTop: "3px", marginLeft: "0px", marginRight: "0px" }}
                                            type="checkbox"
                                            className=""
                                            name="booth_options"
                                            id="smallCheckbox2"
                                            value="Large"
                                        //   onChange={handleCheckboxChange}
                                        //   checked={checkedOptions?.includes("Large")}
                                        />
                                        <label htmlFor="smallCheckbox2" className="checkbox-label fw-500">ThurDay</label>
                                    </div>
                                    <div className="mx-2 gap-2 d-flex custom-checked py-1">
                                        <input
                                            style={{ height: "20px", width: "20px", marginTop: "3px", marginLeft: "0px", marginRight: "0px" }}
                                            type="checkbox"
                                            className=""
                                            name="booth_options"
                                            id="smallCheckbox2"
                                            value="Large"
                                        //   onChange={handleCheckboxChange}
                                        //   checked={checkedOptions?.includes("Large")}
                                        />
                                        <label htmlFor="smallCheckbox2" className="checkbox-label fw-500">Friday</label>
                                    </div>
                                    <div className="mx-2 gap-2 d-flex custom-checked py-1">
                                        <input
                                            style={{ height: "20px", width: "20px", marginTop: "3px", marginLeft: "0px", marginRight: "0px" }}
                                            type="checkbox"
                                            className=""
                                            name="booth_options"
                                            id="smallCheckbox2"
                                            value="Large"
                                        //   onChange={handleCheckboxChange}
                                        //   checked={checkedOptions?.includes("Large")}
                                        />
                                        <label htmlFor="smallCheckbox2" className="checkbox-label fw-500">Saturday</label>
                                    </div>
                                </div>
                            </Col>

                            <Col md={12}>
                                <div className='form-control d-flex align-items-center px-1 mb-3'>
                                    <input className='form-control border-0 shadow-none pl-black'
                                        placeholder='Enter Price'
                                        type="text"
                                        name='fullName'
                                    // value={userData.fullName} 
                                    // onChange={inputValChange} 
                                    />
                                </div>
                            </Col>
                        </Row>
                    </Form>

                </Modal.Body>
                <Modal.Footer className='mx-md-4 border-0 bg-transparent pt-0'>
                    <button
                        // ref={ref}
                        className='secondary-button text-decoration-none' >
                        Add
                    </button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default Teacher