import { React, useState, useRef, useContext, useEffect } from "react";
import { Container, Row, Col, Table, Form, Button, Dropdown } from "react-bootstrap";
import Sidbar from "../layout/Sidbar";
import Header from "../layout/Header";
import Modal from "react-bootstrap/Modal";
import StudioRentalTable from "../components/StudioRentalTable";
import { toast } from "react-toastify";
import { CartContext, useContextData } from "../context/CartContext";
import { getStudiorental, getPricing, getCancelPolicy, getEquipments, getStudioRental, getSubAdmin, getSaltoUser, boothGet, recitalHallGet, addOnsGet } from "../controller/Api";
import Loader from "../components/Loader";
import Services from "./Services";
import {
  GoogleMap,
  MarkerF,
  useLoadScript,
} from "@react-google-maps/api";
import Select from 'react-select';
import moment from "moment";

function StudioRental() {
  const { admin, user } = useContextData();
  const { setStudioData, setPage, perPage, page } = useContext(CartContext);
  const fileInputRef = useRef(null);
  const searchRef = useRef(null);
  const [serviceData, setServiceData] = useState({
    studio_name: "",
    address: "",
    title: "",
    desc: "",
    start_time: "",
    isActive: true,
    end_time: "",
    Latitude: "",
    Longitude: "",
    sub_images: [],
    terms: "",
    min_booking_time: 1
  });
  const [lgShow, setLgShow] = useState(false);
  const [selectedImage, setSelectedImage] = useState();
  const [search, setSearch] = useState("");
  const [selectedImages, setSelectedImages] = useState([]);
  const [categories, setCategories] = useState([]);
  const [allCategories, setAllCategories] = useState([]);
  const [addCategories, setAddCategories] = useState({});
  const [allItems, setAllItems] = useState([]);
  const [loading, setLoading] = useState(false);
  const [newCheckboxValue, setNewCheckboxValue] = useState("");
  const [selectedSizes, setSelectedSizes] = useState([]);
  const [latitude, setLatitude] = useState(25.141921);
  const [longitude, setLongitude] = useState(55.223119);
  const [sortType, setSortType] = useState("desc");
  const [selectedName, setSelectedName] = useState('');
  const [id, setId] = useState();
  const [pricingData, setPricingData] = useState([]);
  const [policyData, setPolicyData] = useState([]);
  const [selectPolicy, setSelectPolicy] = useState();
  const [cancelPolicyId, setCancelPolicyId] = useState();
  const [searchLocation, setSearchLocation] = useState("");
  const [sortByKey, setSortByKey] = useState("");
  const [selectedSubAdminId, setSelectedSubAdminId] = useState();
  const [selectedSubAdmin, setSelectedSubAdmin] = useState();
  const [findSubAdmin, setFindSubAdmin] = useState();
  const [saltoUser, setSaltoUser] = useState([]);
  const [selectSaltoUser, setSelectSaltoUser] = useState("");
  const [displayUser, setDisplayUser] = useState({});
  const [editDataModel, setEditDataModel] = useState(false);
  const [venueType, setVenueType] = useState("booth");
  const [availableBooths, setAvailableBooths] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [selectedBooth, setSelectedBooth] = useState({});
  const [recitalHall, setRecitalHall] = useState([]);
  // const [recitalTypes, setRecitalTypes] = useState([]);
  const [recitalTypes, setRecitalTypes] = useState(recitalHall.map(() => ({ value: '' })));

  const [availableAddOns, setAvailableAddOns] = useState([]);
  const [selectedAddOns, setSelectedAddOns] = useState([]);
  const [selectedAddOn, setSelectedAddOn] = useState({});

  //Add studio
  const addStudioRental = async (e) => {
    e.preventDefault();
    if (venueType === "booth" && selectedCategories.length === 0) {
      toast.error("Booth is required");
      return;
    }
    if (venueType === "recital" && recitalHall.length === 0) {
      toast.error("Recital hall is required");
      return;
    }
    if (serviceData.studio_name.trim() === "") {
      toast.error("Title is required");
      return;
    }
    if (serviceData.address.trim() === "") {
      toast.error("Location is required");
      return;
    }
    if (serviceData.desc.trim() === "") {
      toast.error("Description is required");
      return;
    }
    if (!serviceData.start_time) {
      toast.error("Start time is required");
      return;
    }
    if (!serviceData.end_time) {
      toast.error("End time is required");
      return;
    }
    if (serviceData.start_time > serviceData.end_time) {
      toast.error("Please select a start time of at least 60 minutes");
      return;
    }
    if (!id) {
      toast.error("Pricing is required");
      return;
    }
    if (!cancelPolicyId) {
      toast.error("Cancellation policy is required");
      return;
    }
    if (venueType === "booth") {
      if (!newCheckboxValue) {
        toast.error("Booth size field is required");
        return;
      }
    }
    if (!serviceData.main_image) {
      toast.error("Image is required");
      return;
    }
    try {
      setLoading(true);
      const formData = new FormData();
      formData.append("studio_name", serviceData.studio_name);
      formData.append("address", serviceData?.address);
      formData.append("desc", serviceData.desc);
      formData.append("start_time", serviceData.start_time);
      formData.append("end_time", serviceData.end_time);
      formData.append("isActive", serviceData.isActive ? 1 : 0);
      formData.append("directions[lat]", coordinates?.lat);
      formData.append("directions[lon]", coordinates?.lng);
      formData.append("venueType", venueType);
      venueType == "booth" && formData.append("booth_options", newCheckboxValue);
      formData.append("pricingId", id);
      formData.append("cancelPolicyId", cancelPolicyId);
      if (saltoUser.length > 0) {
        formData.append('lock', JSON.stringify(...saltoUser));
      } else {
        formData.delete("lock");
      }

      if (admin && selectedSubAdmin !== undefined) {
        admin && formData.append("userId", selectedSubAdmin);
      }

      formData.append("min_booking_time", serviceData.min_booking_time || "1");
      formData.append("main_image", serviceData.main_image);
      serviceData.terms && formData.append("terms", serviceData.terms);

      allItems.forEach((allItems, index) => {
        formData.append(`charactery_item[${index}]`, allItems);
      });

      //---------
      selectedCategories.forEach((allItems, index) => {
        formData.append(`boothTypes[${index}]`, allItems?._id);
      });

      categories.forEach((category, index) => {
        formData.append(`categories[${index}]`, category?._id);
      });

      selectedAddOns.forEach((category, index) => {
        formData.append(`addOns[${index}]`, category?._id);
      });

      recitalTypes.forEach((recitalType, index) => {
        formData.append(`recitalTypes[${index}][key]`, recitalType.key || '');
        formData.append(`recitalTypes[${index}][value]`, recitalType.value || '');
      });


      if (Array.isArray(serviceData.sub_images)) {
        serviceData.sub_images.forEach((subImage, index) => {
          formData.append(`sub_images`, subImage);
        });
      } else {
        formData.append("sub_images", serviceData?.sub_images);
      }

      const result = await getStudiorental(formData);
      const { status, message } = result;
      if (status === 1) {
        toast.success(message);
        setLgShow(false);
        getStudioData();
        setServiceData({
          studio_name: "",
          address: "",
          title: "",
          desc: "",
          start_time: "",
          end_time: "",
          Latitude: "",
          Longitude: "",
          sub_images: [],
          booth_options: [],
        });
        setDisplayUser("")
        setSelectSaltoUser("")
        setSelectedName("")
        setSelectPolicy("");
        setSelectedSubAdmin();
        setSelectedImage("");
        setAllItems([]);
        setSelectedImages([]);
        setCategories([]);
        setNewCheckboxValue();
        setSelectedSizes([]);
        setLatitude();
        setLongitude();
        setSelectedCategories([]);
        setVenueType("");
        setRecitalTypes([]);
        setSelectedAddOns([])
      } else {
        toast.error(message);
      }
    } catch (error) {
      toast.error(error);
    }
    setLoading(false);
  };

  // Function to fetch pricing data
  const getPricingFlow = async () => {
    try {
      const result = await getPricing(null, null, null, selectedSubAdmin ? selectedSubAdmin : user._id);
      const { data, status, message } = result;
      if (status === 1) {
        setPricingData(data);
      } else {
        toast.error(message);
      }
    } catch (error) {
      console.log(error);
    }
  };

  // Function to  getCancellationPolicy Policy data
  const getCancellationPolicy = async () => {
    try {
      const result = await getCancelPolicy(null, null, null, selectedSubAdmin ? selectedSubAdmin : user._id);
      const { data, status, message } = result;
      if (status === 1) {
        setPolicyData(data);
      } else {
        toast.error(message);
      }
    } catch (error) {
      console.log(error);
    }
  };

  //get category
  const getConcertCategories = async () => {
    try {
      const concertCategoryResponse = await getEquipments(null, null, null, selectedSubAdmin);
      const { status, message, data } = concertCategoryResponse;
      if (status === 1) {
        setAllCategories(data);
      } else {
        toast.error(message);
      }
    } catch (error) {
      toast.error(error);
    }
  };

  //recital hall
  const recitalHallApi = async () => {
    try {
      const result = await recitalHallGet();
      const { data } = result;
      setRecitalHall(data);
    } catch (error) {
      console.log(error);
    }
  }

  //add Ons
  const addOnsGetApi = async () => {
    try {
      const result = await addOnsGet(null, null, null, selectedSubAdmin);
      const { data } = result;
      const activeAddOns = data.filter((v) => v.isActive === true);
      setAvailableAddOns(activeAddOns);
    } catch (error) {
      console.log(error);
    }
  }

  //get studio
  const getStudioData = async (sortByKey, searchVal) => {
    try {
      setLoading(true);
      const pageVal = searchVal ? 1 : page;
      // let searchData = searchVal.length > 1 ? 1 : page;
      const res = await getStudioRental(perPage, pageVal, search, sortType, sortByKey, selectedSubAdminId);
      const { status } = res;
      if (status === 1) {
        const { page } = res;
        setStudioData(res);
        setPage(page);
      } else {
        console.log("error");
      }
    } catch (error) {
      toast.error(error);
    }
    setLoading(false);
  };

  const getSubAdminBooth = async () => {
    try {
      const result = await getSubAdmin();
      const { data, status } = result;
      if (status === 1) {
        setFindSubAdmin(data)
      }
    } catch (error) {
      console.log(error);
    }
  }

  //get Equipments
  const changeCategory = (value) => {
    setCategories(categories.filter((v) => value._id !== v._id));
    setAllCategories([...allCategories, value]);
  };

  //InputChange
  const inputValChange = (e) => {
    const { name, value } = e.target;
    setServiceData((oldVal) => {
      return {
        ...oldVal,
        [name]: value,
      };
    });
  };

  //Pricing Change
  const handleNameChange = (e) => {
    const getName = pricingData.find(item => item._id === e.target.value);
    setSelectedName(getName?.name);
    setId(e.target.value);
  };
  const selectedPricing = pricingData.find(item => item.name === selectedName);

  //Cancel Policy Change
  const selectedPolicy = policyData.find(item => item.name === selectPolicy);
  const handleChange = (e) => {
    const getName = policyData.find(item => item._id === e.target.value);
    setSelectPolicy(getName?.name);
    setCancelPolicyId(e.target.value);
  };

  //Enter Key Code Next line Move
  const handleKeyDown = (event) => {
    if (event.keyCode === 13) {
      event.preventDefault();
      const textareaName = event.target.name;
      const currentValue = serviceData[textareaName];
      const cursorPosition = event.target.selectionStart;
      const newValue =
        currentValue?.substring(0, cursorPosition) +
        '\n' +
        currentValue?.substring(cursorPosition);
      setServiceData({ ...serviceData, [textareaName]: newValue });
    }
  };

  //Booth Type
  const venueTypeChange = (e) => {
    const selectedValue = e.target.value;
    setVenueType(selectedValue);
    setSelectedCategories([]);
    setRecitalTypes([])
    // setSelectedCategories({});
  };

  //Studio size Change
  const handleCheckboxChange = (e) => {
    const selectedValue = e.target.value;
    setNewCheckboxValue(selectedValue);
  };

  const changeInput = (e) => {
    setSearchLocation(e.target.value);
  }

  const handleRecitalTypeChange = (e, index, key) => {
    const { value } = e.target;
    setRecitalTypes(prev => {
      const updated = [...prev];
      updated[index] = { key, value };
      return updated;
    });
  };

  const selectChange = (e) => {
    setSelectedSubAdmin(e.target.value);
    setAddCategories({});
    setCategories([]);
    setPricingData([]);
    setPolicyData([]);
  }

  const inputValChanges = (e) => {
    const { name, value, type, checked } = e.target;
    const val = type === "checkbox" ? checked : value;
    setServiceData((prevData) => ({
      ...prevData,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  //single image
  const handleImageClick = () => {
    fileInputRef.current?.click();
  };

  const changeImage = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setSelectedImage(reader.result);
      };
      reader.readAsDataURL(file);
    }
    setServiceData({
      ...serviceData,
      [event.target.name]: file,
    });
    event.target.value = null;
  };

  //multiple image
  const handleFileChange = (event) => {
    const files = event.target.files;
    const maxFiles = event.target.getAttribute("data-max_length");
    if (maxFiles && files?.length > parseInt(maxFiles)) {
      toast.error(`Please select up to ${maxFiles} images.`);
      return;
    }
    const existingFiles = serviceData[event.target.name] || [];
    const updatedFiles = existingFiles.concat(Array.from(files));
    setServiceData({
      ...serviceData,
      [event.target.name]: updatedFiles,
    });
    setSelectedImages(updatedFiles);
  };

  const inputHandle = (e) => {
    setSearch(e.target.value);
  };

  const removeImage = (index) => {
    const updatedSelectedImages = selectedImages.filter((_, i) => i !== index);
    setSelectedImages(updatedSelectedImages);
    const updatedServiceData = { ...serviceData };
    for (const key in updatedServiceData) {
      if (Array.isArray(updatedServiceData[key])) {
        updatedServiceData[key] = updatedServiceData[key].filter((_, i) => i !== index);
      }
    }
    setServiceData(updatedServiceData);
  };

  useEffect(() => {
    setAllCategories(
      allCategories.filter(
        (value2) => !categories?.some((value1) => value2._id === value1._id)
      )
    );
  }, [categories]);

  useEffect(() => {
    const callback = (event) => {
      if (event.key === 'Enter') {
        event.preventDefault();
        searchRef.current?.click();
      }
    };
    document.addEventListener('keydown', callback);
    return () => {
      document.removeEventListener('keydown', callback);
    };
  }, [searchRef]);

  useEffect(() => {
    getStudioData(sortByKey);
    admin && getSubAdminBooth();
    getConcertCategories();
    getPricingFlow();
    getCancellationPolicy();
    recitalHallApi();
    addOnsGetApi();
    // getBoothApi();
  }, [sortType, sortByKey, selectedSubAdminId, selectedSubAdmin]);

  let timeSlot = [
    "01:00",
    "02:00",
    "03:00",
    "04:00",
    "05:00",
    "06:00",
    "07:00",
    "08:00",
    "09:00",
    "10:00",
    "11:00",
    "12:00",
    "13:00",
    "14:00",
    "15:00",
    "16:00",
    "17:00",
    "18:00",
    "19:00",
    "20:00",
    "21:00",
    "22:00",
    "23:00",
    "24:00"
  ];

  //asc-desc
  const boothOrder = (key) => {
    const order = sortType === 'asc' ? 'desc' : 'asc';
    setSortType(order);
    setSortByKey(key);
  }

  //Map Function
  const getAddress = async () => {
    try {
      const apiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;
      const response = await fetch(`https://maps.googleapis.com/maps/api/geocode/json?address=${searchLocation}&key=${apiKey}`);
      const data = await response.json();
      const { status } = data;
      if (status === "OK") {
        const findLocation = await data.results[0]?.geometry.location;
        setCoordinates({
          lat: findLocation.lat,
          lng: findLocation.lng
        });

        setCenter({
          lat: findLocation.lat,
          lng: findLocation.lng
        });
      }
      else {
        console.error('No results found');
      }
    } catch (error) {
      console.error('Error fetching geocoder data:', error);
    }
  };

  const { isLoaded } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY,
  });

  const [coordinates, setCoordinates] = useState({
    lat: 25.141921,
    lng: 55.223119
  });

  const [center, setCenter] = useState({
    lat: 25.141921,
    lng: 55.223119
  });

  const containerStyle = {
    width: "100%",
    height: "300px",
  };

  const getSaltoUserFunction = async () => {
    try {
      const result = await getSaltoUser(selectSaltoUser);
      const { data, status, message } = result;
      if (status === 1) {
        setSaltoUser(data);
      } else {
        toast.error(message)
      }
    } catch (error) {
      console.log(error);
    }
  }

  const handleChanges = () => {
    setDisplayUser(saltoUser[0]);
  };

  const handleKeyDowns = async (e) => {
    if (e.key === 'Enter') {
      await getSaltoUserFunction();
    }
  };

  const mapOptions = {
    streetViewControl: false,

  };

  // const handleClose = () => {
  //   // setShow(false);
  //   // setEditMode(false);
  //   // setSelectedImage(null);
  //   setEditDataModel(false);
  // };

  const handleModel = () => {
    setLgShow(true);
    setEditDataModel(true);
  }

  // Fetch booths on component mount
  useEffect(() => {
    fetchBooths();
  }, []);

  // booth data
  const fetchBooths = async () => {
    try {
      const result = await boothGet();
      const { status, message, data } = result;
      const boothData = data.filter((e, i) => { return e.type === "booth" && e.isActive === true });
      if (status === 1) {
        setAvailableBooths(boothData);
      } else {
        toast.error(message);
      }
    } catch (error) {
      toast.error(error);
    }
  };

  // Filter available booths to exclude those already selected
  const filteredBooths = availableBooths?.filter(booth =>
    !selectedCategories?.some(cat => cat._id === booth._id)
  )

  // Handle adding a category
  const handleAddCategory = () => {
    if (selectedBooth?.name && !selectedCategories?.some(cat => cat._id === selectedBooth._id)) {
      setSelectedCategories([...selectedCategories, selectedBooth]);
      setSelectedBooth({});
    }
  };

  // Handle deleting a category
  const handleDeleteCategory = (categoryToDelete) => {
    setSelectedCategories(selectedCategories?.filter(cat => cat._id !== categoryToDelete._id));
  };

  //add ons
  const handleAddAddOn = (e) => {
    e.preventDefault();
    if (selectedAddOn?.title) {
      setSelectedAddOns([...selectedAddOns, selectedAddOn]);
      setSelectedAddOn({});
    } else {
    }
  };

  const handleRemoveAddOn = (addOn) => {
    setSelectedAddOns(selectedAddOns.filter(addOnItem => addOnItem._id !== addOn._id));
    // toast.success("Add-on removed successfully!");
  };

  // Filter available booths to exclude those already selected
  const filteredAddOns = availableAddOns?.filter(booth =>
    !selectedAddOns?.some(cat => cat._id === booth._id)
  )

  return (
    <>
      {loading && <Loader />}
      <section>
        <Container fluid>
          <Row className="vh-100">
            <Col
              xs={12}
              md={12}
              lg={3}
              xl={2}
              className="shadow-right d-none d-lg-block"
            >
              <Sidbar />
            </Col>
            <Col xs={12} lg={9} xl={10} className="position-relative px-md-5">
              <Header />
              <Services />
              <img src="../images/png/bg-imag.png" className="bg-imag" alt="" />
              <Row className="align-items-center pb-3 ">
                <Col md={6} lg={5} xl={7}>
                  <h1 className="title">Booths Overview</h1>
                </Col>
                <Col md={6} lg={7} xl={5} className={`${admin ? "mt-3 mt-md-0 d-flex gap-2 justify-content-end" : "mt-3 mt-md-0"}`}>
                  {admin &&
                    <Form.Select
                      style={{ width: "200px", height: "50px" }}
                      className="select-bg cursor-pointer"
                      aria-label="Default select example"
                      value={selectedSubAdminId}
                      onChange={(e) => { setSelectedSubAdminId(e.target.value); setPage(1); }}
                    >
                      <option value={""}>Select Company</option>
                      {findSubAdmin?.map((item, index) => (
                        <option key={index} value={item._id}>
                          {item.fullName}
                        </option>
                      ))}
                    </Form.Select>
                  }

                  <Form>
                    <div className="d-flex justify-content-end gap-3">
                      <div className="form-control d-flex align-items-center px-3" style={{ width: "350px" }}>
                        <input
                          className="form-control border-0 shadow-none pl-black"
                          placeholder="Search"
                          type="text"
                          value={search}
                          onChange={inputHandle}
                        />
                        <img className='cursor-pointer'
                          ref={searchRef}
                          onClick={() => getStudioData("date", "search")} src="../images/svg/search.svg" alt="Search" />
                      </div>
                      <div
                        onClick={() => {
                          setLgShow(true)
                          getConcertCategories();
                        }}
                        className="cursor-pointer"
                      >
                        <img
                          src="../images/svg/add.svg"
                          className="add-btn"
                          alt=""
                        />
                      </div>
                    </div>
                  </Form>
                </Col>

              </Row>
              {/* </div> */}
              <StudioRentalTable
                admin={admin}
                timeSlot={timeSlot}
                search={search}
                boothOrder={boothOrder}
                sortType={sortType}
                getStudioData={getStudioData}
                pricingData={pricingData}
                setPricingData={setPricingData}
                policyData={policyData}
                setPolicyData={setPolicyData}
                selectedSubAdmin={selectedSubAdmin}
                getPricingFlow={getPricingFlow}
                getSaltoUserFunction={getSaltoUserFunction}
                handleKeyDowns={handleKeyDowns}
                handleChanges={handleChanges}
                setSelectSaltoUser={setSelectSaltoUser}
                saltoUser={saltoUser}
                displayUser={displayUser}
                mapOptions={mapOptions}
                recitalHall={recitalHall}
                availableAddOns={availableAddOns}
                availableBooths={availableBooths}
              />
            </Col>
          </Row>
        </Container>
      </section>

      {/* Add Studio Details modal */}

      <Modal
        size="xl"
        show={lgShow}
        onHide={() => {
          setSelectSaltoUser("");
          setSaltoUser([]);
          setDisplayUser("");
          setSelectedName("");
          setSelectPolicy("");
          setSelectedImage("");
          setAllItems([]);
          setSelectedImages([]);
          setCategories([]);
          setSelectedSubAdmin();
          setNewCheckboxValue();
          setSelectedSizes([]);
          setLatitude();
          setLongitude();
          handleModel();
          setSelectedCategories([]);
          setVenueType("booth");
          setRecitalTypes([]);
          setSelectedCategories([]);
          setSelectedAddOns([]);
        }}
        centered
        className="moadl-main info-modal"
      >
        <Modal.Header
          className="d-block position-relative border-0"
          closeButton
        >
          <Modal.Title className="text-center">Add Music Booth</Modal.Title>
        </Modal.Header>
        <Modal.Body className="mx-md-4 border-0 bg-transparent pb-0 pt-0">
          <Form className="mt-4 text-black">
            <Row className="g-3">
              <Col xs={6}>
                {admin &&
                  <Form.Select
                    style={{ height: "50px" }}
                    className="select-bg cursor-pointer"
                    aria-label="Default select example"
                    value={selectedSubAdmin}
                    onChange={selectChange}
                  >
                    <option value={""}>Select Company</option>
                    {findSubAdmin?.map((item, index) => (
                      <option key={index} value={item._id}>
                        {item.fullName}
                      </option>
                    ))}
                  </Form.Select>
                }
              </Col>

              <Col xs={6}>
                <Select
                  placeholder="Search Smart Lock user associated with booth"
                  onChange={handleChanges}
                  onKeyDown={(e) => handleKeyDowns(e)}
                  options={saltoUser?.map(user => ({ value: user.uid, label: user.name }))}
                  onInputChange={(e) => {
                    setSelectSaltoUser(e)
                  }
                  }
                />
                {displayUser && Object.keys(displayUser).length > 0 && (
                  <div className="mt-2 p-2">
                    <p>Smart Lock UserName: {displayUser?.name}</p>
                    <p>Smart Lock PhoneNo : {displayUser?.phoneNo}</p>
                    <p>Expire Date : {moment(displayUser.keyExpireDate).format("DD-MM-YYYY hh:mm a")}</p>
                  </div>
                )}
              </Col>

              <Col xs={12} lg={12}>
                <div className="form-control d-flex align-items-center px-3">
                  <span>Venue Type:</span>
                  <div className="mx-3 gap-2 custom-checked d-flex">
                    <input style={{
                      height: '20px', width: '20px', marginTop: "3px", marginLeft: "0px",
                      marginRight: "0px"
                    }}
                      type="radio"
                      className="defaultCheckbox form-check-input"
                      name="boothTypes"
                      value="booth"
                      id="smallCheckbox6"
                      onChange={venueTypeChange}
                      checked={venueType === "booth"}
                    />
                    <label htmlFor="smallCheckbox6" className="checkbox-label fw-400">
                      Booth
                    </label>
                  </div>

                  <div className="mx-3 gap-2 d-flex custom-checked">
                    <input style={{
                      height: '20px', width: '20px', marginTop: "3px", marginLeft: "0px",
                      marginRight: "0px"
                    }}
                      type="radio"
                      className="defaultCheckbox form-check-input"
                      name="recitalTypes"
                      value="recital"
                      id="smallCheckbox11"
                      onChange={venueTypeChange}
                      checked={venueType === "recital"}
                    />
                    <label htmlFor="smallCheckbox11" className="checkbox-label fw-400">
                      Recital Hall
                    </label>
                  </div>
                </div>
              </Col>

              {
                venueType === "booth" &&
                <Col xs={12}>
                  <div className="d-flex flex-wrap align-items-center gap-3 studio-category">
                    <Dropdown style={{ width: "250px" }}>
                      <Dropdown.Toggle
                        className="dropdown studio-add-dropdown"
                        variant="white"
                        id="dropdown-basic"
                      >
                        {selectedBooth.name || "Choose Booth"}
                      </Dropdown.Toggle>
                      <Dropdown.Menu style={{ padding: 0, width: "250px", border: "1px" }}>
                        {filteredBooths.length > 0 ? (
                          filteredBooths.map((booth) => (
                            <Dropdown.Item
                              className="studio-add-dropdown-item"
                              key={booth._id}
                              onClick={() => setSelectedBooth(booth)}
                            >
                              {booth.name}
                            </Dropdown.Item>
                          ))
                        ) : (
                          <Dropdown.Item>{"No Data"}</Dropdown.Item>
                        )}
                      </Dropdown.Menu>
                    </Dropdown>
                    <div className="edit-btn-main2">
                      <Button className="me-2" onClick={handleAddCategory}>
                        Add
                      </Button>
                    </div>
                  </div>
                  <div className="category">
                    <div className="category-btn mt-3">
                      {selectedCategories.length > 0 && selectedCategories?.map((category) => (
                        <div className="closeSpan" key={category._id}>
                          <span>{category.name}</span>
                          <button className="closeBtn" onClick={() => handleDeleteCategory(category)}>
                            <img src="/images/svg/delete.svg" alt="Delete" />
                          </button>
                        </div>
                      ))}
                    </div>
                  </div>
                </Col>
              }

              {
                venueType === "recital" &&
                recitalHall.map((val, index) => (
                  <Col key={index} xs={12} sm={6} md={4} lg={3} className="custom-field">
                    <p>{val.name.key} :</p>
                    <div className="form-control d-flex align-items-center px-1">
                      <input
                        className="form-control border-0 shadow-none px-0 px-3 text-black"
                        placeholder={`Enter ${val.name.key} ...`}
                        type="text"
                        value={recitalTypes[index]?.value || ''} // Ensure this is properly set
                        onChange={(e) => handleRecitalTypeChange(e, index, val.name.key)} // Make sure this function updates state correctly
                      />
                    </div>
                  </Col>
                ))
              }

              <Col xs={12}>
                <div className="form-control d-flex align-items-center px-1">
                  <input
                    className="form-control border-0 shadow-none px-0 px-3 text-black"
                    placeholder="Enter Title"
                    type="text"
                    name="studio_name"
                    value={serviceData?.studio_name}
                    onChange={inputValChange}
                  />
                </div>
              </Col>

              <Col xs={12} lg={12} xl={12}>
                <div className="d-flex align-items-center gap-2">
                  <div className="form-control d-flex align-items-center px-3">
                    <input
                      className="form-control border-0 shadow-none px-0 text-black"
                      placeholder="Enter Location Name"
                      type="text"
                      name="address"
                      value={serviceData?.address}
                      onChange={inputValChange}
                    />
                  </div>
                  <div className="edit-btn-main2">
                  </div>
                </div>
              </Col>

              <Col md={12}>
                <div className="form-control d-flex align-items-center px-1 d-flex w-100">
                  <textarea
                    className="form-control border-0 shadow-none px-0 px-3 text-black w-100"
                    placeholder="Enter Description"
                    name="desc"
                    rows={3}
                    type="text"
                    onChange={inputValChange}
                    onKeyDown={handleKeyDown}
                    value={serviceData.desc}
                  />
                </div>
              </Col>

              {/* //add ons */}
              <Row>
                <Col xs={12} className="mt-4">
                  <div className="d-flex flex-wrap align-items-center gap-3 studio-category">
                    <Dropdown style={{ width: "250px" }}>
                      <Dropdown.Toggle
                        className="dropdown studio-add-dropdown"
                        variant="white"
                        id="dropdown-basic"
                      >
                        {selectedAddOn.title || "Choose Add-ons"}
                      </Dropdown.Toggle>
                      <Dropdown.Menu style={{ padding: 0, width: "250px", border: "1px" }}>
                        {filteredAddOns.length > 0 ? (
                          filteredAddOns.map((val) => (
                            <Dropdown.Item
                              key={val._id}
                              className="studio-add-dropdown-item"
                              onClick={() => setSelectedAddOn(val)}
                            >
                              {val.title}
                            </Dropdown.Item>
                          ))
                        ) : (
                          <Dropdown.Item>{"No Data"}</Dropdown.Item>
                        )}
                      </Dropdown.Menu>
                    </Dropdown>
                    <div className="edit-btn-main2">
                      <Button
                        className="me-2"
                        onClick={handleAddAddOn}
                      >
                        Add
                      </Button>
                    </div>
                  </div>
                  <div className="category">
                    <div className="category-btn mt-3">
                      {selectedAddOns.length > 0 &&
                        selectedAddOns.map((v) => (
                          <div className="closeSpan" key={v._id}>
                            <span>{v.title}</span>
                            <button
                              className="closeBtn"
                              onClick={() => handleRemoveAddOn(v)}
                            >
                              <img src="/images/svg/delete.svg" alt="delete" />
                            </button>
                          </div>
                        ))}
                    </div>
                  </div>
                </Col>
              </Row>

              <Row xs={12} className="my-3">
                <Form.Label style={{ fontWeight: "500" }}>Location Timing</Form.Label>
                <Col md={12} lg={6}>
                  <div className="form-control d-flex align-items-center">
                    <Form.Select
                      aria-label="Default select example"
                      className="select-bg form-control border-0 shadow-none pl-black pl-0 selectOption pr-0 location-ip"
                      name="start_time"
                      onChange={inputValChange}
                      style={{ color: Boolean(serviceData?.start_time) ? "#000" : "#9fa1a2" }}
                    >
                      <option value={""} className="selectOption text-black">Booth start time</option>
                      <option value={"00:00"} className="selectOption text-black">{"00:00"}</option>
                      {timeSlot.map((v, i) => {
                        return (
                          <option key={i} value={v} className="text-black">
                            {v}
                          </option>
                        );
                      })}
                    </Form.Select>
                  </div>
                </Col>

                <Col xs={12} lg={6}>
                  <div className="form-control d-flex align-items-center">
                    <Form.Select
                      aria-label="Default select example"
                      className="select-bg form-control border-0 shadow-none pl-black pl-0 selectOption pr-0 location-ip"
                      name="end_time"
                      onChange={inputValChange}
                      style={{ color: Boolean(serviceData?.end_time) ? "#000" : "#9fa1a2" }}
                    >
                      <option value={""} className="selectOption text-black ">Booth end time</option>
                      <option value={"00:00"} className="selectOption text-black">{"00:00"}</option>
                      {timeSlot.map((v, i) => {
                        return (
                          <option key={i} value={v} className="text-black">
                            {v}
                          </option>
                        );
                      })}
                    </Form.Select>
                  </div>
                </Col>
              </Row>

              <Row>
                <Col xs={12}>
                  <div className="d-flex flex-wrap align-items-center gap-3 studio-category">
                    <Dropdown className="" style={{ width: "250px" }}>
                      <Dropdown.Toggle
                        className="dropdown studio-add-dropdown"
                        variant="white"
                        id="dropdown-basic"
                      >
                        {addCategories.name || "Choose Equipments"}

                      </Dropdown.Toggle>
                      <Dropdown.Menu style={{ padding: 0, width: "250px", border: "1px" }}>
                        {allCategories?.length > 0 ? (
                          allCategories
                            .filter(val => val.status === 1)
                            .map((val, index) => (
                              <Dropdown.Item
                                className="studio-add-dropdown-item"
                                onClick={() => setAddCategories(val)}
                              >
                                {val.name}
                              </Dropdown.Item>
                            ))
                        ) : (
                          <Dropdown.Item>{"No Data"}</Dropdown.Item>
                        )}
                      </Dropdown.Menu>
                    </Dropdown>
                    <div className="edit-btn-main2">
                      <Button className="me-2"
                        onClick={(e) => {
                          e.preventDefault();
                          if (addCategories?.name) {
                            setCategories([...categories, addCategories]);
                            setAddCategories({});
                          }
                        }}>
                        Add
                      </Button>
                    </div>
                  </div>
                  <div className="category">
                    <div className="category-btn mt-3">
                      {
                        categories?.length > 0 &&
                        categories?.map((v, i) => {
                          return (
                            <div class="closeSpan" key={v._id}>
                              <span className="">{v.name}</span>
                              <button className={"closeBtn"} onClick={() => changeCategory(v)} >
                                <img src="/images/svg/delete.svg" />
                              </button>
                            </div>
                          );
                        })
                      }
                    </div>
                  </div>
                </Col>

                <Form.Label style={{ fontWeight: "500" }}>Pricing</Form.Label>
                <Col xs={12} lg={12}>
                  <div className="form-control d-flex align-items-center px-0 mb-3">
                    <Form.Select
                      defaultValue={selectedName}
                      onChange={handleNameChange}
                      aria-label="Select Pricing Name"
                      className="border-0"
                    >
                      <option value="">Select Price Name</option>
                      {pricingData?.map((name, index) => (
                        <option key={index} value={name?._id}>
                          {name?.name}
                        </option>
                      ))}
                    </Form.Select>
                  </div>
                </Col>
                <Col>
                  <div className="overflow-auto table-responsive">
                    <Table striped bordered hover className="mb-0 responsive">
                      <thead>
                        <tr>
                          <th className="border-start">Days</th>
                          <th className="border-start">Time Slots</th>
                          <th className="price-input border-start">Price</th>
                        </tr>
                      </thead>
                      <tbody>
                        {selectedPricing ? (
                          selectedPricing.weekday.map((day, i) => (
                            <tr key={i}>
                              <td>{day.day}</td>
                              <td>
                                <ul className="list-unstyled mb-0">
                                  {day.slots.map((slot, j) => (
                                    <li key={j}>
                                      <span>{slot.startTime} - {slot.endTime}</span>
                                    </li>
                                  ))}
                                </ul>
                              </td>
                              <td>
                                <ul className="list-unstyled mb-0">
                                  {day.slots.map((slot, j) => (
                                    <li key={j}>AED {slot.price}</li>
                                  ))}
                                </ul>
                              </td>
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td colSpan="3">Data not found</td>
                          </tr>
                        )}
                      </tbody>
                    </Table>
                  </div>
                </Col>
              </Row>

              <Row className="mt-3">
                <Form.Label style={{ fontWeight: "500" }}>Cancellation Policy</Form.Label>
                <Col xs={12} lg={12}>
                  <div className="form-control d-flex align-items-center px-0 mb-3">
                    <Form.Select
                      defaultValue={selectPolicy}
                      onChange={handleChange}
                      aria-label="Select Policy Name"
                      className="border-0"
                    >
                      <option value="">Select Policy Name</option>
                      {policyData?.map((name, index) => (
                        <option key={index} value={name?._id}>
                          {name?.name}
                        </option>
                      ))}
                    </Form.Select>
                  </div>
                </Col>
                <Col>
                  <div className="overflow-auto">
                    <Table striped bordered hover className="mb-0">
                      <thead>
                        <tr>
                          <th className="border-start" style={{ width: "50%", fontWeight: "500" }}>Lead Time</th>
                          <th className="border-start" style={{ fontWeight: "500" }}>Refund to Wallet</th>
                        </tr>
                      </thead>
                      <tbody>
                        {selectedPolicy ? (
                          selectedPolicy.slots?.sort((a, b) => b.amountPercentage - a.amountPercentage)?.map((time, i) => (
                            <tr key={i}>
                              <td>{time?.time} hours </td>
                              <td>{time?.amountPercentage}%</td>
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td colSpan="3">Data not found</td>
                          </tr>
                        )}
                      </tbody>
                    </Table>

                  </div>
                </Col>
              </Row>

              {venueType === "booth" &&
                <Col xs={12} lg={12}>
                  <div className="form-control d-flex align-items-center px-3">
                    <span>Booth Size:</span>
                    <div className="mx-3 gap-2 custom-checked d-flex">
                      <input style={{
                        height: '20px', width: '20px', marginTop: "3px", marginLeft: "0px",
                        marginRight: "0px"
                      }}
                        type="radio"
                        className="defaultCheckbox form-check-input"
                        name="booth_options"
                        value="S"
                        id="smallCheckbox"
                        onChange={handleCheckboxChange}
                        checked={newCheckboxValue === "S"}
                      />
                      <label htmlFor="smallCheckbox" className="checkbox-label fw-400">
                        Small
                      </label>
                    </div>

                    <div className="mx-3 gap-2 d-flex custom-checked">
                      <input style={{
                        height: '20px', width: '20px', marginTop: "3px", marginLeft: "0px",
                        marginRight: "0px"
                      }}
                        type="radio"
                        className="defaultCheckbox form-check-input"
                        name="booth_options"
                        value="M"
                        id="smallCheckbox1"
                        onChange={handleCheckboxChange}
                        checked={newCheckboxValue === "M"}
                      />
                      <label htmlFor="smallCheckbox1" className="checkbox-label fw-400">Medium</label>
                    </div>

                    <div className="mx-3 gap-2 d-flex custom-checked">
                      <input style={{
                        height: '20px', width: '20px', marginTop: "3px", marginLeft: "0px",
                        marginRight: "0px"
                      }}
                        type="radio"
                        className="defaultCheckbox form-check-input"
                        name="booth_options"
                        value="L"
                        id="smallCheckbox2"
                        onChange={handleCheckboxChange}
                        checked={newCheckboxValue === "L"}
                      />
                      <label htmlFor="smallCheckbox2" className="checkbox-label fw-400">Large</label>
                    </div>
                  </div>
                </Col>
              }

              <Col xs={12}>
                <Form.Label style={{ fontWeight: "500" }}>Minimum Booking Hours</Form.Label>
                <div className="form-control d-flex align-items-center px-1">
                  <input
                    className="form-control border-0 shadow-none px-0 px-3 text-black"
                    placeholder="Booking Slot hours"
                    type="number"
                    name="min_booking_time"
                    value={serviceData?.min_booking_time}
                    onKeyDown={(e) =>
                      ["e", "E", "+", "-", "."].includes(e.key) &&
                      e.preventDefault()
                    }
                    onChange={inputValChange}
                  />
                </div>
              </Col>

              <Col xs={12} className="d-flex">
                <p className="mb-0">Active :</p>
                <td>
                  <div className="form-switch userTable mx-3 gap-1 d-flex"
                    title={serviceData.isActive ? "Active" : "InActive"}
                  >
                    <input
                      className="form-check-input"
                      type="checkbox"
                      name="isActive"
                      checked={serviceData.isActive}
                      onChange={inputValChanges}
                    />
                  </div>
                </td>
              </Col>

              <Col xs={12} lg={12} xl={12}>
                <div class="upload__box">
                  <div class="upload__btn-box mb-0">
                    <label class="upload__btn d-flex align-items-center gap-1 px-2">
                      <span className="plush plush2">+</span>
                      <p className="mb-0 fs-6 placeholder-black">Upload single image</p>
                      <input
                        type="file"
                        name="main_image"
                        data-max_length="20"
                        class="upload__inputfile"
                        accept=".jpg, .jpeg, .png"
                        ref={fileInputRef}
                        onChange={changeImage}
                      />
                    </label>
                    {Boolean(selectedImage) && <div className="pt-2">
                      <img
                        src={selectedImage}
                        onClick={handleImageClick}
                        style={{ maxWidth: "100px", maxHeight: "100px" }}
                      />
                    </div>
                    }
                  </div>
                </div>
                <p className="placeholder-black mt-2">The image should have a 100:90 aspect ratio. For example, the dimensions could be 1000x900 pixels.</p> 
              </Col>

              <Col xs={12} lg={12} xl={12}>
                <div class="upload__btn-box mb-0">
                  <label class="upload__btn d-flex align-items-center gap-1 px-2">
                    <span className="plush plush2">+</span>
                    <p className="mb-0 fs-6 placeholder-black">Upload multiple images and videos</p>
                    <input
                      type="file"
                      data-max_length="20"
                      class="upload__inputfile"
                      accept=".jpg, .jpeg, .png, .mp4"
                      name="sub_images"
                      multiple
                      ref={fileInputRef}
                      onChange={handleFileChange}
                      value=""
                    />
                  </label>
                  <div className="d-flex flex-wrap justify-content-beetween pt-2 w-100">
                    {selectedImages?.map((file, index) => (
                      <p key={index}>
                        {file.type.startsWith('image/') ? (
                          <>
                            <img
                              className="px-1"
                              src={URL.createObjectURL(file)}
                              style={{ width: "100", height: "100px" }}
                            />
                            <img
                              src="../images/jpg/closee.jpg"
                              className="translate-middle cursor-pointer close-po3"
                              alt=""
                              name="deleteSubImage_id"
                              onClick={() => removeImage(index)}
                            />
                          </>
                        ) : (
                          <>
                            <video
                              style={{ width: "100px", height: "100px", objectFit: "cover" }}
                              controls
                            >
                              <source
                                src={file && URL.createObjectURL(file)}
                                type="video/mp4"
                              />
                              Your browser does not support the video tag.
                            </video>
                            <img
                              src="../images/jpg/closee.jpg"
                              className="translate-middle cursor-pointer close-po4"
                              alt=""
                              name="deleteSubImage_id"
                              onClick={() => removeImage(index)}
                            />
                          </>
                        )}
                      </p>
                    ))}
                  </div>
                </div>
              </Col>
            </Row>
            <br />


            {/* Lat/long */}
            <Row className="align-items-end ">
              <Col xs={12} lg={3} xl={4}>
                <Form.Label style={{ fontWeight: "500" }}>Latitude</Form.Label>
                <div className="form-control d-flex align-items-center px-3 mb-3">
                  <input
                    className="form-control border-0 shadow-none px-0 text-black"
                    placeholder="Enter latitude"
                    type="number"
                    name="Latitude"
                    value={coordinates.lat}
                    onChange={inputValChange}
                  />
                </div>
              </Col>

              <Col xs={12} lg={3} xl={4}>
                <Form.Label style={{ fontWeight: "500" }}>Longitude</Form.Label>
                <div className="form-control d-flex align-items-center px-3 mb-3">
                  <input
                    className="form-control border-0 shadow-none px-0 text-black"
                    placeholder="Enter longitude"
                    type="number"
                    name="Longitude"
                    value={coordinates.lng}
                    onChange={inputValChange}
                  />
                </div>
              </Col>

              <Col xs={12} lg={3} xl={4}>
                <Form.Label style={{ fontWeight: "500" }}>Search Location</Form.Label>
                <div className="form-control d-flex align-items-center px-3 mb-3 select-location-main">
                  <input
                    className="form-control border-0 shadow-none px-0 text-black"
                    placeholder="Search Location"
                    onChange={changeInput}
                    onKeyUp={getAddress}
                  />
                </div>
              </Col>

              {/* <Col xs={12} lg={6} xl={4}>
              </Col> */}
            </Row>
            <Row>
              <Col md={12}>
                <div className="form-control d-flex align-items-center p-0 mb-3 overflow-hidden">
                  <div style={{ height: '300px', width: '100%' }}>
                    {isLoaded &&
                      <GoogleMap
                        mapContainerStyle={containerStyle}
                        center={center}
                        zoom={10}
                        // streetViewController={false}
                        options={mapOptions}
                        onClick={(e) => {
                          setCoordinates({
                            lat: e.latLng.lat(),
                            lng: e.latLng.lng(),
                          });
                        }
                        }
                      >
                        <MarkerF position={coordinates} />
                      </GoogleMap>
                    }
                  </div>
                </div>
              </Col>
            </Row>
            {/* <div className="d-flex flex-wrap align-items-center gap-3 studio-category">
              <Dropdown className="" style={{ width: "250px" }}>
                <Dropdown.Toggle
                  className="dropdown studio-add-dropdown"
                  variant="white"
                  id="dropdown-basic"
                >
                  {addCategories.name || "Choose categories"}

                </Dropdown.Toggle>
                <Dropdown.Menu style={{ padding: 0, width: "250px", border: "1px" }}>
                  {allCategories?.length > 0 ? (
                    allCategories?.map((val, index) => (
                      <Dropdown.Item
                        className="studio-add-dropdown-item"
                        onClick={() => setAddCategories(val)}
                      >
                        {val.name}
                      </Dropdown.Item>
                    ))
                  ) : (
                    <Dropdown.Item>{"No Data"}</Dropdown.Item>
                  )}
                </Dropdown.Menu>
              </Dropdown>
              <div className="edit-btn-main2">
                <Button className="me-2"
                  onClick={(e) => {
                    e.preventDefault();
                    if (addCategories?.name) {
                      setCategories([...categories, addCategories]);
                      setAddCategories({});
                    }
                  }}>
                  Add categories</Button>
              </div>
            </div>
            <div className="category">
              <div className="category-btn mt-3">
                {
                  categories?.length > 0 &&
                  categories?.map((v, i) => {
                    return (
                      <div class="closeSpan" key={v._id}>
                        <span className="">{v.name}</span>
                        <button className={"closeBtn"} onClick={() => changeCategory(v)} >
                          <img src="/images/svg/delete.svg" />
                        </button>
                      </div>
                    );
                  })
                }
              </div>
            </div> */}
          </Form>
        </Modal.Body>
        <Modal.Footer className="mx-md-4 border-0 bg-transparent pt-0">

          <button className='secondary-button text-decoration-none' onClick={addStudioRental}  >
            Add
          </button>

        </Modal.Footer>
      </Modal >

      <Modal
        size="md"
        centered
        show={editDataModel}
        onHide={() => setEditDataModel(false)}
        className="modal-main delete-modal"
      >
        <Modal.Header
          className="d-block position-relative border-0"
        >
          <Modal.Title className="text-center">Discard</Modal.Title>
        </Modal.Header>
        <Modal.Body className="mx-4 border-0">
          <p className="text-center">Are you sure you want to discard changes?</p>
        </Modal.Body>
        <Modal.Footer className="border-0">
          <div className="d-flex m-auto gap-2 pb-3">
            <button
              className="secondary-button text-decoration-none"
              onClick={() => {
                setLgShow(false);
                setEditDataModel(false);
                setServiceData({
                  studio_name: "",
                  address: "",
                  title: "",
                  desc: "",
                  start_time: "",
                  end_time: "",
                  Latitude: "",
                  Longitude: "",
                  sub_images: [],
                  min_booking_time: 1,
                  isActive: true
                });
              }}
            >
              Discard
            </button>
            <Button
              className="primary-button"
              onClick={() => {
                // setLgShow(false);
                setEditDataModel(false)
              }}
            >
              Cancel
            </Button>
          </div>
        </Modal.Footer>
      </Modal >
    </>
  );
}

export default StudioRental;
