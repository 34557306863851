import React, { useContext, useEffect, useState } from 'react';
import { getPaymentDetails } from "../controller/Api";
import { Modal } from 'react-bootstrap';
import moment from "moment";
import { Container, Row, Col, Table, Form, Button } from "react-bootstrap";
import TablePagination from '../components/TablePagination';
import Loader from "../components/Loader";

const PaymentModel = ({ setPaymentModel, paymentModel, userId }) => {
    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(10);
    const [payment, setPayment] = useState();
    const [loading, setLoading] = useState(false);

    const GetPayment = async () => {
        try {
            setLoading(true);
            const result = await getPaymentDetails(userId, page);
            const { data, message, status } = result;
            if (status === 1) {
                setPayment(result);
            }
        } catch (error) {
            console.log(error);
        }
        setLoading(false);
    }

    useEffect(() => {
        GetPayment();
    }, [page, userId])

    return (
        <div>
            {loading && <Loader />}
            <Modal
                size="xl"
                show={paymentModel}
                onHide={() => setPaymentModel(false)}
                centered
                dialogClassName="modal-90w"
                className="moadl-main delete-modal"
            >
                <Modal.Header
                    className="d-block position-relative border-0"
                    closeButton
                >
                    <Modal.Title className="text-center">Payment</Modal.Title>
                </Modal.Header>
                <Modal.Body className="mx-4 border-0">
                    <Row>
                        <Col>
                            <Table bordered responsive="xl">
                                <thead>
                                    <tr>
                                        <th className='number-width border'>No</th>
                                        <th>Payment ID</th>
                                        <th>Paid Type</th>
                                        <th>Name</th>
                                        <th>Paid By </th>
                                        <th>Payment Amount</th>
                                        <th>Payment Date </th>
                                    </tr>
                                </thead>
                                {Boolean(payment?.data?.length) ?
                                    <tbody>
                                        {
                                            payment?.data?.map((element, index) => {
                                                const { _id, booking_detail, created_at, customer_details, email, paid_by, payment_amount,
                                                    payment_date, payment_info, payment_method, payment_result, payment_status, transaction_id, updated_at } = element;

                                                const utcFormattedDateTime = payment_date ? moment(payment_date).utc().format("YYYY-MM-DD, HH:mm:ss") : "-";
                                                const currentNumber = (page - 1) * perPage + index + 1;
                                                return (
                                                    <tr>
                                                        <td>{currentNumber}</td>
                                                        <td>{transaction_id || "-"}</td>
                                                        <td>{payment_info?.payment_method || "-"}</td>
                                                        <td>{customer_details?.name || "-"}</td>
                                                        <td>{customer_details?.email || "-"}</td>
                                                        <td>AED {payment_amount || "-"}</td>
                                                        <td>{utcFormattedDateTime}</td>
                                                    </tr>
                                                );
                                            })}
                                    </tbody>
                                    :
                                    <tbody>
                                        <tr>
                                            <td colSpan={10}>
                                                <div className='nodata-table'>
                                                    <h3 className="text-center p-5">Data Not Found</h3>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                }

                            </Table>
                            <div>
                                {
                                    (
                                        payment && payment.data?.length >= 1 && (
                                            <div className='d-md-flex justify-content-end align-items-center '>
                                                <TablePagination
                                                    currentPage={payment?.page}
                                                    totalPages={payment?.pages}
                                                    onPageChange={(newPage) => {
                                                        setPage(newPage);
                                                    }}
                                                />
                                            </div>
                                        )
                                    )
                                }
                            </div>
                        </Col>
                    </Row>
                </Modal.Body>
            </Modal>
        </div>
    )
}

export default PaymentModel