import React, { useState } from 'react'
import { Col, Form, Modal, Row, Table } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import TablePagination from "../components/TablePagination";
import { addOnsDelete, addOnsEdit } from '../controller/Api';
import { toast } from 'react-toastify';

const AddOnsTable = ({ perPage, page, getBoothList, setPage, addOnsGetApi, addOnsList, setAddOnsList, admin }) => {
    const [show, setShow] = useState(false);
    const handleShow = () => setShow(true);
    const handleClose = () => setShow(false);
    const navigate = useNavigate();
    const [modelOpen, setModelOpen] = useState();
    const [id, setId] = useState();
    const [editShowData, setEditShowData] = useState({
        title: "",
        price: "",
        description: ""
    });

    //Edit 
    const addOnsEditApi = async () => {
        if (!editShowData.title) {
            toast.error("Add-ons is required");
            return;
        }
        if (!editShowData.price) {
            toast.error("Price is required");
            return;
        }
        // if (!editShowData.price) {
        //     toast.error("Price is required");
        //     return;
        // }
        try {
            const result = await addOnsEdit(id, editShowData);
            const { status, message } = result;
            if (status === 1) {
                toast.success(message);
                addOnsGetApi();
                handleClose(true);
            } else {
                toast.error(message);
            }
        } catch (error) {
            console.error(error);
        }
    };

    // Delete Equipments
    const handleDelete = async () => {
        try {
            const result = await addOnsDelete(id);
            const { message, status } = result;
            if (status === 1) {
                toast.success(message);
                setModelOpen(false);
                handleClose();
                addOnsGetApi();
                if (addOnsList?.data?.length === 1) {
                    setPage(1);
                } else {
                    addOnsGetApi();
                }
            } else {
                toast.error(message);
            }
        } catch (error) {
            console.log(error);
        }
    }

    const toggleButton = async (_id, currentStatus) => {
        try {
            const updatedStatus = !currentStatus;
            const obj = {
                isActive: updatedStatus,
            };
            const res = await addOnsEdit(_id, obj);
            const updatedBoothList = addOnsList.data.map((booth) =>
                booth._id === _id ? { ...booth, isActive: updatedStatus } : booth
            );
            setAddOnsList({ ...getBoothList, data: updatedBoothList });
        } catch (error) {
            toast.error('Error updating booth status.');
        }
    };

    const handleEdit = (element) => {
        setEditShowData(element);
    }

    const inputValChange = (e) => {
        const { name, value } = e.target;
        setEditShowData((oldVal) => {
            return {
                ...oldVal,
                [name]: value
            };
        });
    };

    return (
        <>
            {/* {loading && <Loader />} */}
            <Row>
                <Col>
                    <Table bordered responsive="xl">
                        <thead>
                            <tr>
                                <th className='number-width border'>No</th>
                                <th style={{ width: "250px", wordBreak: "break-all", whiteSpace: "break-spaces" }}> Title</th>
                                <th style={{ width: "700px", wordBreak: "break-all", whiteSpace: "break-spaces" }}> Description</th>
                                <th style={{ width: "200px", wordBreak: "break-all", whiteSpace: "break-spaces" }}> Price</th>
                                <th style={{ width: "200px", wordBreak: "break-all", whiteSpace: "break-spaces" }}> Sub Admin</th>
                                <th style={{ width: "150px", wordBreak: "break-all", whiteSpace: "break-spaces" }}> Enabled</th>
                                <th style={{ width: "100px", wordBreak: "break-all", whiteSpace: "break-spaces" }}>Action</th>
                            </tr>
                        </thead>
                        {Boolean(addOnsList?.data?.length) ?
                            <tbody>
                                {
                                    addOnsList?.data?.map((element, index) => {
                                        const { _id, title, description, price, isActive, userId } = element;
                                        const currentNumber = (page - 1) * perPage + index + 1;
                                        return (
                                            <tr>
                                                <td>{currentNumber}</td>
                                                <td>{title || "-"}</td>
                                                <td>{description || "-"}</td>
                                                <td style={{ width: "200px", wordBreak: "break-all", whiteSpace: "break-spaces" }}>{price || "-"}</td>
                                                {admin && <td style={{ width: "200px", wordBreak: "break-all", whiteSpace: "break-spaces" }}>{userId?.fullName || "-"}</td>}
                                                <td>
                                                    <div className="form-switch userTable" title={isActive ? "Enabled" : "Disabled"}>
                                                        <input
                                                            className="form-check-input"
                                                            type="checkbox"
                                                            name="isActive"
                                                            checked={isActive}
                                                            onChange={(e) => toggleButton(_id, isActive)}
                                                        />
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="d-flex justify-content-center align-items-center gap-3" title='Edit'>
                                                        <button
                                                            className="border-0 bg-transparent"
                                                            onClick={() => {
                                                                handleShow();
                                                                setId(_id);
                                                                handleEdit(element);
                                                            }}
                                                        >
                                                            <img src="../images/png/edit.png" style={{ height: "18px" }} alt="" />
                                                        </button>
                                                    </div>
                                                </td>
                                            </tr>
                                        );
                                    })}
                            </tbody>
                            :
                            <tbody>
                                <tr>
                                    <td colSpan={10}>
                                        <div className='nodata-table'>
                                            <h3 className="text-center p-5">Data Not Found</h3>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        }
                    </Table>
                    <div className='d-md-flex justify-content-between align-items-center pb-3'>
                        <button className='secondary-button text-decoration-none' onClick={() => navigate("/dashboard")} >
                            <svg
                                stroke="currentColor"
                                fill="currentColor"
                                strokeWidth={0}
                                viewBox="0 0 24 24"
                                height="2em"
                                width="2em"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path d="M12.707 17.293 8.414 13H18v-2H8.414l4.293-4.293-1.414-1.414L4.586 12l6.707 6.707z" />
                            </svg>
                            Back
                        </button>
                       
                        {
                            addOnsList?.data && addOnsList?.data?.length >= 1 &&
                            <TablePagination
                                currentPage={addOnsList?.page}
                                totalPages={addOnsList?.pages}
                                onPageChange={(newPage) => {
                                    setPage(newPage);
                                }}
                            />
                        }
                    </div>
                </Col>
            </Row>

            {/* Edit */}
            <Modal
                show={show}
                size="xl"
                onHide={handleClose}
                centered
                className='moadl-main info-modal'
            >
                <Modal.Header className='d-block position-relative border-0' closeButton>
                    <Modal.Title className='text-center'>Edit Add-Ons</Modal.Title>
                </Modal.Header>
                <Modal.Body className='mx-md-4 border-0 bg-transparent pb-0'>
                    <div className="d-flex justify-content-end ">
                        <button
                            className="primary-button" onClick={() => {
                                setModelOpen(true)
                                handleClose();
                            }}>
                            Delete
                        </button>
                    </div>
                    <Form className='mt-4'>
                        <Row>
                            <Col md={12}>
                                <div className='form-control d-flex align-items-center px-1 mb-3'>
                                    <input className='form-control border-0 shadow-none px-0 px-3 text-black w-100'
                                        placeholder='Enter Title'
                                        type="text"
                                        name='title'
                                        value={editShowData?.title}
                                        onChange={inputValChange}
                                    />
                                </div>
                            </Col>
                            <Col md={12}>
                                <div className='form-control d-flex align-items-center px-1 mb-3'>
                                    <input className='form-control border-0 shadow-none px-0 px-3 text-black w-100'
                                        placeholder='Enter price'
                                        type="text"
                                        name='price'
                                        value={editShowData?.price}
                                        onChange={inputValChange}
                                    />
                                </div>
                            </Col>
                            <Col md={12}>
                                <div className='form-control d-flex align-items-center px-1 mb-3'>
                                    <input className='form-control border-0 shadow-none px-0 px-3 text-black w-100'
                                        placeholder='Enter description'
                                        type="text"
                                        name='description'
                                        value={editShowData?.description}
                                        onChange={inputValChange}
                                    />
                                </div>
                            </Col>

                        </Row>
                    </Form>
                </Modal.Body>
                <Modal.Footer className='mx-md-4 border-0 bg-transparent pt-0'>
                    <button
                        onClick={addOnsEditApi}
                        className='secondary-button text-decoration-none' >
                        Save
                    </button>
                </Modal.Footer>
            </Modal>

            <Modal
                size="md"
                show={modelOpen}
                onHide={() => setModelOpen(false)}
                centered className='moadl-main delete-modal'
            >
                <Modal.Header className='d-block position-relative border-0' closeButton>
                    <Modal.Title className='text-center'>Delete Add-Ons</Modal.Title>
                </Modal.Header>
                <Modal.Body className='mx-4 border-0'>
                    <p>Are you sure you want to delete?</p>
                </Modal.Body>
                <Modal.Footer className='border-0'>
                    <div className='d-flex m-auto gap-2 pb-3'>
                        <button className="primary-button" onClick={() => {
                            handleDelete();
                        }}>Delete</button>
                        <button className="primary-button" onClick={() => setModelOpen(false)}>Cancel</button>
                    </div>
                </Modal.Footer>
            </Modal >
        </>
    )
}

export default AddOnsTable