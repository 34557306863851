import React, { useState, useEffect, useRef } from 'react';
import { Card, Container } from 'react-bootstrap';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import { submitLogin } from "../controller/Api";
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { validate } from './Validation';
import { useContextData } from '../context/CartContext';

function Login() {
    const { setToken, setUser, setAdmin } = useContextData();
    const [showPassword, setShowPassword] = useState(false);
    const [errors, setErrors] = useState({});
    const [submitCount, setSubmitCount] = useState(0);
    // const navigate = useNavigate();
    const ref = useRef(null);


    const togglePasswordVisibility = () => {
        setShowPassword((prevShowPassword) => !prevShowPassword);
    };

    const [login, setLogin] = useState({
        email: "",
        password: ""
    });

    const inputValchange = (e) => {
        const { name, value } = e.target;
        if (submitCount > 0) {
            const validationErrors = validate({ ...login, [name]: value });
            setErrors(validationErrors);
        }
        setLogin((oldVal) => {
            return {
                ...oldVal,
                [name]: value
            }
        });
    }

    const loginData = async (e) => {
        e.preventDefault();
        if (login.email.trim() === "") {
            toast.error("Email is required");
            return;
        }
        const email = login.email.replace(/\s+/g, '');
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailRegex.test(email)) {
            toast.error("Invalid email format");
            return;
        }
        if (login.password.trim() === "") {
            toast.error("Password is required");
            return;
        }
        try {
            const loginResponse = await submitLogin({ ...login, email: login.email.toLowerCase() });
            const { status, data, message } = loginResponse;
            if (status === 1) {
                setUser(data);
                localStorage.setItem("token", data.token);
                setToken(data.token)
                setAdmin(data.user_role === "admin")
                delete data.token
                const userDataToStore = {
                    email: data.email,
                    fullName: data.fullName,
                    user_role: data.user_role,
                    _id: data._id,
                    teacher_verified: data.teacher_verified,
                    profile_image: data.profile_image,
                    company_name:data.company_name,
                    company_website:data.company_website,
                };
                localStorage.setItem("user", JSON.stringify(userDataToStore));
                toast.success("logged in successfully!");
            } else {
                toast.error(message);
            }
        }
        catch (error) {
            console.log(error);
        }
    }

    useEffect(() => {
        const callback = (event) => {
            if (event.key === 'Enter') {
                event.preventDefault();
                ref.current.click();
            }
        };
        document.addEventListener('keydown', callback);
        return () => {
            document.removeEventListener('keydown', callback);
        };
    }, []);

    return (
        <>
            <section className='d-flex justify-content-center align-items-center vh-100'>
                <Container>
                    <Row className='justify-content-center align-items-center'>
                        <Col className='col-12 col-md-7 col-lg-6 col-xl-5'>
                            <Card className='border-0'>
                                <Card.Img variant="top" className='login-logo m-auto' src="../images/logo/logo.png" />
                                <h2 className='form-title'>Sign in</h2>
                                <p className='form-subtitle'>Please fill your information below</p>
                                <Card.Body className='px-0 px-md-3'>
                                    <Form>
                                        <div className='form-control d-flex align-items-center px-3 mb-3' style={{ borderColor: `${login?.email?.length ? "#464C44" : "#E8E8E8"}` }}>
                                            <img src="./images/svg/shape.svg" className='imput-img' alt="shape.svg" />
                                            <input className='form-control border-0 shadow-none pl-black text-black' placeholder='E-mail'
                                                type="text" name="email" value={login.email} onChange={inputValchange} />
                                        </div>
                                        <div className='form-control form-item position-relative d-flex align-items-center px-3 mb-3' style={{ borderColor: `${login?.password?.length ? "#464C44" : "#E8E8E8"}` }}>
                                            <img src="./images/svg/password.svg" className='imput-password-img' alt="password.svg" />
                                            <input className='form-control border-0 shadow-none pl-black login-input text-black' placeholder='Password'
                                                type={showPassword ? 'text' : 'password'} name="password" value={login.password} onChange={inputValchange} />
                                            <img
                                                src={showPassword ? "./images/svg/eye-op.svg" : "./images/svg/eye-off.svg"}
                                                className='close-eye-img'
                                                alt={showPassword ? "eye-op.svg" : "eye-off.svg"}
                                                onClick={togglePasswordVisibility}
                                            />
                                            <label for="username">Password</label>
                                        </div>
                                        <button ref={ref} className='btn btn-secondary text-uppercase border-0 w-100 mt-3' onClick={(e) => { setSubmitCount(1); loginData(e); }}>Sign in</button>
                                    </Form>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </Container >
            </section>
        </>
    )
}

export default Login


