import { React, createContext, useContext, useState } from 'react';
export const CartContext = createContext();

export const CartProvider = ({ children }) => {
  const [userDataShow, setUserDataShow] = useState();
  const [studioData, setStudioData] = useState();
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [user, setUser] = useState(localStorage.getItem("user") ? JSON.parse(localStorage.getItem("user")) : {});
  const [token, setToken] = useState(localStorage.getItem("token"));
  const [getCategoryData, setgetCategoryData] = useState([]);
  const [admin, setAdmin] = useState(user.user_role == "admin");
  
  return (
    <CartContext.Provider value={{ token, setToken, getCategoryData, setgetCategoryData, userDataShow, setUserDataShow, studioData, setStudioData, page, setPage, perPage, setPerPage, user,setUser, admin,setAdmin }}>
      {children}
    </CartContext.Provider>
  )
}

export const useContextData = () => {
  return useContext(CartContext)
};