import React, { useContext, useEffect, useRef, useState } from 'react'
import { Col, Container, Form, Modal, Row } from 'react-bootstrap';
import Sidbar from '../layout/Sidbar';
import Header from '../layout/Header';
import { toast } from 'react-toastify';
import { CartContext } from '../context/CartContext';
import Loader from "../components/Loader";
import RecitalHallTable from '../components/RecitalHallTale';
import { getSubAdmin, recitalHallAdd, recitalHallGet } from '../controller/Api';

const RecitalHall = () => {
  const { perPage, admin } = useContext(CartContext);
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState();
  const ref = useRef(null);
  const [loading, setLoading] = useState(false);
  const [lgShow, setLgShow] = useState(false);
  const [findSubAdmin, setFindSubAdmin] = useState([]);
  const [key, setKey] = useState();
  const [getRecitalHall, setGetRecitalHall] = useState();

  const inputValChange = (value) => {
    setKey(value);
  };

  // const addData = () => {
  //   if (key?.trim() !== '' && !recitalHallList.some(item => item.key === key?.trim())) {
  //     const newEntry = {
  //       key: key?.trim(),
  //       value: '0',
  //     };
  //     setRecitalHallList([...recitalHallList, newEntry]);
  //     setKey('');
  //   }
  // };

  //post api
  const recitalHallApi = async () => {
    try {
      if (!key?.trim()){
        toast.error("Recital hall is required");
        return
      }
      const result = await recitalHallAdd(key)
      const { status, message } = result;
      if (status === 1) {
        toast.success(message);
        setKey("");
        recitalHallGetApi();
        setLgShow(false);
      } else {
        toast.error(message);
      }
    } catch (error) {
      console.log(error);
    }
  }

  // const getSubAdminBooth = async () => {
  //   try {
  //     const result = await getSubAdmin();
  //     const { data, status } = result;
  //     if (status === 1) {
  //       setFindSubAdmin(data)
  //     }
  //   } catch (error) {
  //     console.log(error);
  //   }
  // }

  const recitalHallGetApi = async (searchVal) => {
    const pageVal = searchVal ? 1 : page;
    try {
      setLoading(true);
      const result = await recitalHallGet(pageVal, perPage, search);
      const { message, status } = result;
      if (status === 1) {
        const { page } = result;
        setPage(page);
        setGetRecitalHall(result);
        // toast.success(message);
      } else {
        toast.error(message);
      }
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  }

  const inputHandle = (value) => {
    setSearch(value);
  }

  useEffect(() => {
    document.title = "Music Booth | Recital Hall"
    recitalHallGetApi();
  }, [page]);

  useEffect(() => {
    const callback = (event) => {
      if (event.key === 'Enter' && ref.current) {
        event.preventDefault();
        ref.current?.click();
      }
    };
    document.addEventListener('keydown', callback);
    return () => {
      document.removeEventListener('keydown', callback);
    };
  }, []);

  return (
    <>
      {loading && <Loader />}
      <section>
        <Container fluid>
          <Row className="vh-100">
            <Col
              xs={12}
              md={12}
              lg={3}
              xl={2}
              className="shadow-right d-none d-lg-block"
            >
              <Sidbar />
            </Col>
            <Col xs={12} lg={9} xl={10} className="position-relative px-md-5">
              <Header />
              <img src="../images/png/bg-imag.png" className="bg-imag" alt="" />
              <Row className="justify-content-between align-items-center pb-3">
                <Col md={6} lg={5} xl={7}>
                  <h1 className="title">Recital Hall</h1>
                </Col>
                <Col md={6} lg={7} xl={5} className={`${admin ? "mt-3 mt-md-0 d-flex gap-2 justify-content-end" : "mt-3 mt-md-0"}`}>
                  <Form>
                    <div className="d-flex justify-content-end gap-3">
                      <div className="form-control d-flex align-items-center px-3" style={{ width: "350px" }}>
                        <input
                          className="form-control border-0 shadow-none pl-black"
                          placeholder="Search"
                          type="text"
                          value={search}
                          onChange={(e) => { inputHandle(e.target.value) }}
                        />
                        <img className="cursor-pointer" ref={ref} src="../images/svg/search.svg"
                          onClick={() => recitalHallGetApi("search")}
                          alt="Search" />
                      </div>
                      <div
                        onClick={() => setLgShow(true)}
                        className="cursor-pointer"
                      >
                        <img
                          src="../images/svg/add.svg"
                          className="add-btn"
                          alt=""
                        />
                      </div>
                    </div>
                  </Form>
                </Col>
              </Row>
              <RecitalHallTable
                page={page}
                perPage={perPage}
                setPage={setPage}
                recitalHallGetApi={recitalHallGetApi}
                getRecitalHall={getRecitalHall}
                setGetRecitalHall={setGetRecitalHall}
              />
            </Col>
          </Row>
        </Container>
      </section>

      {/* Add */}
      <Modal
        size="xl"
        show={lgShow}
        onHide={() => {
          setLgShow(false);
          // setRecitalHallList([]);
          setKey("");
        }}
        centered
        className='moadl-main info-modal'
      >
        <Modal.Header className='d-block position-relative border-0' closeButton>
          <Modal.Title className='text-center'>Add Recital Hall</Modal.Title>
        </Modal.Header>
        <Modal.Body className='mx-md-4 border-0 bg-transparent pb-0'>
          <Form className='mt-4'>
            <Row>
              <Col md={12}>
                <div className='form-control d-flex align-items-center px-1 mb-3'>
                  <input className='form-control border-0 shadow-none px-0 px-2 text-black w-100'
                    placeholder='Enter Recital hall'
                    type="text"
                    name='key'
                    value={key}
                    onChange={(e) => { inputValChange(e.target.value) }}
                  />
                  {/* <div className="cursor-pointer" onClick={addData}>
                    <img
                      src="../images/svg/add.svg"
                      className="add-btn"
                      alt=""
                    />
                  </div> */}
                </div>
                {/* {recitalHallList.length ? <p className='fw-bold'>
                  Recital Hall List:
                </p> : ""}
                <ul>
                  {recitalHallList.map(item => <li>{item.key}</li>)}
                </ul> */}
              </Col>
            </Row>
          </Form>
        </Modal.Body>
        <Modal.Footer className='mx-md-4 border-0 bg-transparent pt-0'>
          <button
            className='secondary-button text-decoration-none'
            onClick={recitalHallApi}
          >
            Add
          </button>
        </Modal.Footer>
      </Modal>
    </>
  )
}

export default RecitalHall