import { useState, useEffect, useContext, useRef, useMemo } from "react";
import { Row, Col, Table, Form, Button, Dropdown } from "react-bootstrap";
import TablePagination from "../components/TablePagination";
import Modal from "react-bootstrap/Modal";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import moment from "moment";
import { deletestudirental, boothsActiveFlow, getEquipments, getPricing, getCancelPolicy, getSaltoUser, boothGet } from "../controller/Api";
import { pf } from "../helper/envConfig";
import { editStudio } from "../controller/Api";
import { SingleStudio } from "../controller/Api";
import { CartContext, useContextData } from "../context/CartContext";
import { subImageApi } from "../controller/Api";
import { editBoothFeatured } from "../controller/Api";
import Loader from "./Loader";
import React from "react";
import { BiSortAlt2 } from 'react-icons/bi';
import Select from 'react-select';
import {
  GoogleMap,
  MarkerF,
  useLoadScript,
} from "@react-google-maps/api";

const StudioRentalTable = ({ boothOrder, getStudioData, pricingData, policyData, timeSlot, setPricingData, setPolicyData, recitalHall, availableAddOns, availableBooths }) => {
  const { studioData, page, setPage, perPage } = useContext(CartContext);
  const { admin } = useContextData();
  const images = pf();
  const url = images;
  const [smShow, setSmShow] = useState(false);
  const [show, setShow] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [PricingId, setPricingId] = useState();
  const [selectedName, setSelectedName] = useState();
  const [singleStudio, setSingleStudio] = useState();
  const [categories, setCategories] = useState([]);
  const navigate = useNavigate();
  const [imageId, setImageId] = useState();
  const fileInputRef1 = useRef(null);
  const [addCategories, setAddCategories] = useState({});
  const [categoryData, setCategoryData] = useState([]);
  const [editMode, setEditMode] = useState(false);
  const [editData, setEditData] = useState();
  const [loading, setLoading] = useState(false);
  const [checkedOptions, setCheckedOptions] = useState([]);
  const [deleteSubImageArray, setDeleteSubImageArray] = useState([]);
  const [id, setId] = useState();
  const [status, setStatus] = useState(0);
  const [selectedPolicy, setSelectedPolicy] = useState();
  const [cancelPolicyId, setCancelPolicyId] = useState();
  const [searchLocation, setSearchLocation] = useState();
  const [userId, setUserId] = useState();
  const [studioEdit, setStudioEdit] = useState({});
  const [displayUser, setDisplayUser] = useState([]);
  const [saltoUser, setSaltoUser] = useState([]);
  const [selectSaltoUser, setSelectSaltoUser] = useState("");
  const [editDataModel, setEditDataModel] = useState(false);
  const [venueType, setVenueType] = useState();
  const [selectedBoothType, setSelectedBoothType] = useState({});
  const [recitalTypes, setRecitalTypes] = useState([]);
  const [selectedAddOn, setSelectedAddOn] = useState({});
  const [filteredAddOns, setFilteredAddOns] = useState([]);
  const [availableBoothTypes, setAvailableBoothTypes] = useState([]);
  const [availableRecitalTypes, setAvailableRecitalTypes] = useState([]);
  const handleShow = () => {
    setShow(true);
  }

  const handleClose = () => {
    setShow(false);
    setEditMode(false);
    setSelectedImage(null);
    setEditDataModel(false);
  };

  const handleCloseForPopup = () => {
    if (editMode) {
      setEditDataModel(true);
      return
    }
    setShow(false);
    setEditMode(false);
    setSelectedImage(null);
    setEditDataModel(false);
  };

  const handleChanges = () => {
    setDisplayUser(saltoUser[0]);
  };

  //Edit Api:-
  const EditStudio = async () => {
    setEditMode(true);
    handleShow();
    if (studioEdit?.studio_name === "") {
      toast.error("Studio name is required");
      return;
    }
    if (studioEdit?.address === "") {
      toast.error("Address is required");
      return;
    }
    if (venueType === "booth" && singleStudio?.boothTypes?.length === 0) {
      toast.error("Booth types is required.");
      return;
    }

    if (venueType === "recital" && !recitalTypes.every(v => v.value.trim())) {
      toast.error("Recital types is required.");
      return;
    }

    if (studioEdit?.start_time >= studioEdit?.end_time) {
      toast.error("Studio's end time must be after the start time");
      return;
    }
    // if (checkedOptions?.length === 0) {
    //   toast.error("Booth size field is required.");
    //   return;
    // }
    if (!studioEdit?.min_booking_time || studioEdit.min_booking_time < 1) {
      toast.error("Booking time is required and must be a minimum of 1 hour");
      return;
    }
    if (!PricingId) {
      toast.error("Pricing is required");
      return;
    }
    if (!cancelPolicyId) {
      toast.error("Cancellation Policy is required");
      return;
    }
    if (studioEdit.desc === "") {
      toast.error("Description is required");
      return;
    }
    if (studioEdit?.hourly_rate === "") {
      toast.error("Hours rate field is required");
      return;
    }
    if (studioEdit?.hourly_rate < 1) {
      toast.error("Hourly rate cannot be negative.");
      return;
    }

    try {
      // setLoading(true);
      const formData = new FormData();
      formData.append("studio_name", studioEdit.studio_name);
      formData.append("address", studioEdit.address);
      formData.append("desc", studioEdit.desc);
      formData.append("start_time", studioEdit.start_time);
      formData.append("end_time", studioEdit.end_time);
      formData.append("directions[lat]", coordinates?.lat || "");
      formData.append("directions[lon]", coordinates?.lng || "");
      formData.append("booth_options", studioEdit?.booth_options);
      formData.append("pricingId", PricingId);
      formData.append("main_image", studioEdit?.main_image);
      formData.append("isActive", true);
      formData.append("cancelPolicyId", cancelPolicyId);
      formData.append("min_booking_time", studioEdit?.min_booking_time);
      formData.append("userId", studioEdit.userId?._id);
      if (saltoUser.length > 0) {
        formData.append('lock', JSON.stringify(...saltoUser));
      } else {
        formData.delete("lock")
      }
      formData.append('venueType', venueType);
      if (venueType === 'booth' && singleStudio?.boothTypes?.length) {
        singleStudio?.boothTypes.forEach((booth, index) => {
          formData.append(`boothTypes[${index}]`, booth?._id);
        });
      } else {
        formData.delete("boothTypes");
      }
      if (venueType === 'recital' && recitalTypes?.length) {
        recitalTypes.forEach((recitalType, index) => {
          formData.append(`recitalTypes[${index}][key]`, recitalType.key || '');
          formData.append(`recitalTypes[${index}][value]`, recitalType.value || '');
        });
      } else {
        formData.delete("recitalTypes");
      }
      if (singleStudio?.addOns?.length > 0) {
        singleStudio.addOns.forEach((category, index) => {
          if (category?._id) {
            formData.append(`addOns[${index}]`, category._id);
          } else {
            formData.delete('addOns');
          }
        })
      }
      if (singleStudio?.categories?.length > 0) {
        singleStudio?.categories?.forEach((category, index) => {
          formData.append(`categories[${index}]`, category?._id);
        });
      } else {
        formData.append(`categories`, singleStudio?.categories);
      }

      const result = await editStudio(id, formData);
      const { status, message } = result;
      if (status === 1) {
        getStudioData();
        setEditMode(false);
        toast.success("Booth details updated successfully");
        setShow(false);
      } else {
        toast.error(message);
      }
    } catch (error) {
      toast.error(error);
    }
  };

  // single Booth get
  const getUser = async (_id) => {
    setSingleStudio()
    setStudioEdit()
    try {
      const res = await SingleStudio(_id);
      setSingleStudio(res.data);
      setStudioEdit(res.data);
      setCategories(res.data?.categories || []);
      setCheckedOptions(res?.data?.booth_options || []);
    } catch (error) {
      toast.error(error);
    }
  };

  //Multiple Image Api
  const subImageApiFunction = async () => {
    try {
      // setLoading(true);
      const formData = new FormData();
      if (deleteSubImageArray.length > 0) {
        deleteSubImageArray.forEach((v, i) => {
          formData.append(`deleteSubImage[${i}]`, v);
        });
      }
      studioEdit?.sub_images?.forEach((v) => {
        formData.append("sub_images", v);
      });
      const result = await subImageApi(id, formData);
      const { status } = result;
      if (status === 1) {
        setDeleteSubImageArray([]);
      }
    } catch (error) {
      toast.error(error);
    }
  };

  //studio delete
  const deleteApi = async (id) => {
    try {
      const deleteData = await deletestudirental(id);
      const { status } = deleteData;
      if (status === 1) {
        toast.success("Booth has been deleted successfully");
        setSmShow(false);
        if (studioData?.data?.length === 1) {
          setPage(1);
        } else {
          getStudioData();
        }
      }
    } catch (error) {
      toast.error(error);
    }
  };

  //get category
  const getCategoryData = async (userId) => {
    try {
      setUserId(userId);
      const res = await getEquipments(null, null, null, null);
      // const res = await getEquipments(null, null, null, userId);
      const { data } = res;
      setCategoryData(data);
    } catch (error) {
      console.log(error);
    }
  };

  const getPricingFlow = async (userId) => {
    try {
      setUserId(userId);
      const res = await getPricing(null, null, null, userId);
      const { data } = res;
      setPricingData(data);
    } catch (error) {
      console.log(error);
    }
  };

  const getCancellationPolicyFlow = async (userId) => {
    try {
      setUserId(userId);
      const res = await getCancelPolicy(null, null, null, userId);
      const { data } = res;
      setPolicyData(data);
    } catch (error) {
      console.log(error);
    }
  };

  //Input Change
  const inputChange = (e) => {
    const { name, value } = e.target;
    setStudioEdit((oldVal) => {
      const nestedProperties = name?.split(".");
      if (nestedProperties?.length === 2) {
        const [parent, child] = nestedProperties;
        return {
          ...oldVal,
          [parent]: {
            ...oldVal[parent],
            [child]: value,
          },
        };
      }
      return {
        ...oldVal,
        [name]: value,
      };
    });
  };

  const handleCheckboxChange = (e) => {
    const selectedValue = e.target.value;
    setStudioEdit(prevState => ({
      ...prevState,
      booth_options: selectedValue
    }));
  };

  const handleImageClick = () => {
    if (fileInputRef1.current) {
      fileInputRef1.current.click();
    }
  };

  const changeInput = (e) => {
    setSearchLocation(e.target.value);
  }

  //Pricing
  const selectedPricing = pricingData.find(item => item?.name === selectedName);


  const handleNameChange = (e) => {
    const getName = pricingData.find(item => item?._id === e.target.value);
    setSelectedName(getName?.name);
    setPricingId(e.target.value);
  };

  //Cancel Policy
  const selectedSetPolicy = policyData.find(item => item?.name === selectedPolicy);

  const selectChange = (e) => {
    const getName = policyData.find(item => item?._id === e.target.value);
    setSelectedPolicy(getName?.name);
    setCancelPolicyId(e.target.value);
  };

  //category
  const changeCategory = (value) => {
    setSingleStudio({ ...singleStudio, categories: singleStudio?.categories?.filter((v) => v?._id !== value._id) });
    setCategoryData([...categoryData, value]);
  };

  const addCategory = (value) => {
    setSingleStudio({ ...singleStudio, categories: [...singleStudio?.categories, value] });
    setCategoryData(categoryData.filter((v) => v?._id !== value._id));
  };

  //booth type
  const handleAddBoothType = () => {
    if (selectedBoothType?.name) {
      setSingleStudio(prevState => ({
        ...prevState,
        boothTypes: [...prevState?.boothTypes, selectedBoothType]
      }));
      setSelectedBoothType({});
    }
  };

  const filteredAvailableBooths = availableBooths?.filter(
    booth => !singleStudio?.boothTypes.some(existingBooth => existingBooth?._id === booth?._id)
  );

  const handleRemoveBoothType = (boothTypeToRemove) => {
    setSingleStudio(prevState => ({
      ...prevState,
      boothTypes: prevState?.boothTypes.filter(boothType => boothType?._id !== boothTypeToRemove?._id)
    }));
  };


  //single image
  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setSelectedImage(e.target.files[0]);
      setStudioEdit({
        ...studioEdit,
        [e.target.name]: e.target.files[0],
      });
    }
  };

  //multiple images Add
  const handleFileChanges = (event) => {
    const files = event.target.files;
    const maxFiles = event.target.getAttribute("data-max_length");
    if (maxFiles && files?.length > parseInt(maxFiles)) {
      alert(`Please select up to ${maxFiles} images.`);
      return;
    }
    const fileList = Array.from(files);
    const existingFiles = studioEdit[event.target.name] || [];
    const updatedFiles = existingFiles.concat(Array.from(files));
    setStudioEdit({
      ...studioEdit,
      sub_images: updatedFiles,
    });
  };

  const removeExistingSubImage = (id, index) => {
    setStudioEdit({
      ...studioEdit,
      sub_images: studioEdit?.sub_images?.filter((v, i) => i !== index)
    })
    !deleteSubImageArray?.includes(id) && setDeleteSubImageArray([...deleteSubImageArray, id]);
  }

  //Enter Key Move Next line
  const handleKeyDown = (event) => {
    if (event.keyCode === 13) {
      event.preventDefault();
      const textareaName = event.target?.name;
      const currentValue = studioEdit[textareaName];
      const cursorPosition = event.target.selectionStart;
      const newValue =
        currentValue?.substring(0, cursorPosition) +
        '\n' +
        currentValue?.substring(cursorPosition);
      setStudioEdit({ ...studioEdit, [textareaName]: newValue });
    }
  };

  const toggleButton = async (_id, currentStatus, isFeatured) => {
    try {
      if (isFeatured) {
        toast.info("Please update the featured status first.");
      }
      const newStatus = currentStatus ? 0 : 1;
      const editedUser = await boothsActiveFlow(_id, { isActive: newStatus });
      setStatus(editedUser);
      getStudioData();
    } catch (error) {
      toast.error(error);
    }
  }

  const featuredButton = async (_id, isFeatured, isActive) => {
    try {
      if (!isActive) {
        toast.info("Please activate the Booth first");
      }
      const newStatus = isFeatured ? 0 : 1;
      const editedUser = await editBoothFeatured(_id, { isFeatured: newStatus });
      setStatus(editedUser);
      getStudioData();
    } catch (error) {
      toast.error(error);
    }
  }

  const venueTypeChange = (e) => {
    const selectedValue = e.target.value;
    setVenueType(selectedValue);
    if (selectedValue === "booth") {
      setAvailableBoothTypes(singleStudio?.boothTypes || []);
      setAvailableRecitalTypes([]);
    } else if (selectedValue === "recital") {
      setAvailableBoothTypes([]);
      setAvailableRecitalTypes(singleStudio?.recitalTypes || []);
    };
  }

  const handleRecitalTypeChange = (e, index) => {
    const newRecitalTypes = [...recitalTypes];
    newRecitalTypes[index].value = e.target.value;
    setRecitalTypes(newRecitalTypes);
  };

  //add-ons
  const handleAddAddOn = () => {
    if (selectedAddOn?.title) {
      setSingleStudio(prevState => ({
        ...prevState,
        addOns: [...prevState?.addOns, selectedAddOn]
      }));

      setSelectedAddOn({});
    } else {
    }
  };

  // Handle removing an add-on
  const handleRemoveAddOn = (addOnToRemove) => {
    setSingleStudio(prevState => ({
      ...prevState,
      addOns: prevState?.addOns.filter(addOn => addOn?._id !== addOnToRemove?._id)
    }));
  };

  useEffect(() => {
    setAddCategories({});
  }, [show]);

  useEffect(() => {
    setCategoryData(categoryData?.filter((value2) => !categories?.some((value1) => value2?._id === value1._id)));
  }, [editMode]);

  useEffect(() => {
    getStudioData();
  }, [page]);

  useEffect(() => {
    if (userId) {
      getCategoryData(userId);
      getPricingFlow(userId);
      getCancellationPolicyFlow(userId);
    }
  }, [userId]);

  useEffect(() => {
    setCategoryData(
      categoryData?.filter(
        (value) => !singleStudio?.categories?.some((v) => v?._id == value?._id)
      )
    );
  }, [singleStudio]);

  //Booth Size Condition
  let defaultLabel = '';
  if (singleStudio?.booth_options === 'S') {
    defaultLabel = 'Small';
  } else if (singleStudio?.booth_options === 'M') {
    defaultLabel = 'Medium';
  } else if (singleStudio?.booth_options === 'L') {
    defaultLabel = 'Large';
  }

  const handleEdit = (element) => {
    setEditData(element);
    setStudioEdit(element);
    setSelectedName(element?.pricingId?.name)
    setSelectedPolicy(element?.cancelPolicyId?.name)
    setPricingId(element?.pricingId?._id);
    setCancelPolicyId(element?.cancelPolicyId?._id);
    setCoordinates({
      lat: element?.directions?.lat,
      lng: element?.directions?.lon
    })
  };

  const { isLoaded } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY
  });

  const [coordinates, setCoordinates] = useState({
    lat: studioEdit?.directions?.lat,
    lng: studioEdit?.directions?.lon
  });

  const [center, setCenter] = useState({
    lat: coordinates?.lat || 25.1406173,
    lng: coordinates?.lng || 55.22674240000001
  });

  useEffect(() => {
    setCenter({
      lat: coordinates?.lat || 25.1406173,
      lng: coordinates?.lng || 55.22674240000001
    });
  }, [coordinates]);

  const containerStyle = {
    width: "100%",
    height: "300px",
  };

  //Map 
  const getAddress = async () => {
    try {
      const apiKey = await process.env.REACT_APP_GOOGLE_MAPS_API_KEY;
      const response = await fetch(`https://maps.googleapis.com/maps/api/geocode/json?address=${searchLocation}&key=${apiKey}`);
      const data = await response.json();
      const { results, status } = data;
      if (status === "OK") {
        const findLocation = data.results[0]?.geometry.location;
        setCoordinates({
          lat: findLocation.lat,
          lng: findLocation.lng
        });
        setCenter({
          lat: findLocation.lat,
          lng: findLocation.lng
        });
      }
      else {
        console.error('No results found');
      }
    } catch (error) {
      console.error('Error fetching geocoder data:', error);
    }
  };

  //salto get api
  const getSaltoUserFunction = async () => {
    try {
      const result = await getSaltoUser(selectSaltoUser);
      const { data, status, message } = result;
      if (status === 1) {
        setSaltoUser(data);
      } else {
        toast.error(message)
      }
    } catch (error) {
      console.log(error);
    }
  }

  const handleKeyDowns = async (e) => {
    if (e.key === 'Enter') {
      await getSaltoUserFunction();
    }
  };

  const removeSaltoUser = () => {
    setSelectSaltoUser("");
    setDisplayUser("");
    setSaltoUser([])
  }

  useEffect(() => {
    setVenueType(singleStudio?.venueType || "booth");
  }, [singleStudio?.venueType])

  useEffect(() => {
    const updateFilteredAddOns = () => {
      const filtered = availableAddOns.filter(
        addOn => !singleStudio?.addOns?.some(
          existingAddOn => existingAddOn?._id === addOn?._id
        )
      );
      setFilteredAddOns(filtered);
    };

    updateFilteredAddOns();
  }, [availableAddOns, singleStudio?.addOns]);

  useEffect(() => {
    if (recitalHall) {
      const initialRecitalTypes = recitalHall.map(val => ({
        key: val.name.key,
        value: studioEdit?.recitalTypes?.find(rt => rt.key === val.name.key)?.value || ''
      }));
      setRecitalTypes(initialRecitalTypes);
    }
  }, [recitalHall, studioEdit, singleStudio]);

  return (
    <>
      {loading && <Loader />}
      <Row>
        <Col>
          <Table bordered responsive="xl" className="table-responsive">
            <thead>
              <tr>
                <th className="number-width border">No</th>
                <th style={{ width: "250px" }}>Booth Title</th>
                {/* <th style={{ width: "320px", wordBreak: "break-all", whiteSpace: "break-spaces" }}>Equipment</th> */}
                <th>Pricing Scheme</th>
                <th>Cancellation Policy</th>
                <th style={{ width: "100px" }}>Active</th>
                <th>Featured</th>
                {admin && <th>Created By</th>}
                <th>Created At <BiSortAlt2 className="fs-4 cursor-pointer" onClick={() => boothOrder("date")}></BiSortAlt2></th>
                <th>Actions</th>
              </tr>
            </thead>
            {Boolean(studioData?.data?.length) ?
              <tbody>
                {studioData?.data?.map((element, index) => {
                  const {
                    _id,
                    created_at,
                    studio_name,
                    pricingId,
                    cancelPolicyId,
                    isActive,
                    isFeatured,
                    userId
                  } = element;
                  const currentNumber = (page - 1) * perPage + index + 1;
                  return (
                    <>
                      <tr>
                        <td>{currentNumber || "-"}</td>
                        <td>{studio_name || "-"}</td>
                        {/* <td>{categories?.[0]?.name || "-"}</td> */}
                        <td>{pricingId?.name ? pricingId?.name : "-"}</td>
                        <td>{cancelPolicyId?.name ? cancelPolicyId?.name : "-"}</td>
                        <td>
                          <div className="form-switch userTable" title={isActive ? "Active" : "InActive"}>
                            <input
                              className="form-check-input"
                              type="checkbox"
                              name="isActive"
                              checked={isActive}
                              onChange={() => toggleButton(_id, isActive, isFeatured)}
                            />
                          </div>
                        </td>
                        <td>
                          <div className="form-switch userTable" title={isFeatured ? "Enabled" : "Disable"}>
                            <input
                              className="form-check-input"
                              type="checkbox"
                              name="isFeatured"
                              checked={isFeatured}
                              onChange={() => featuredButton(_id, isFeatured, isActive)}
                              disabled={!admin}
                            />
                          </div>
                        </td>
                        {admin && <td>{userId?.fullName || "-"}</td>}
                        <td>
                          {created_at
                            ? moment(created_at).format("YYYY-MM-DD, hh:mm:ss")
                            : "-"}
                        </td>
                        <td>
                          <div className="d-flex justify-content-center align-items-center gap-3">
                            <span
                              className="cursor-pointer"
                              onClick={() => {
                                handleShow();
                                setId(_id);
                                setImageId(_id);
                                handleEdit(element);
                                getUser(_id);
                                if (element.userId?._id) {
                                  getCategoryData(element.userId?._id);
                                } else {
                                  // toast.error("User ID is not available.");
                                }
                                getPricingFlow(element.userId?._id);
                                getCancellationPolicyFlow(element.userId?._id);
                              }}
                            >
                              <div title="Info">
                                <svg
                                  width="10"
                                  height="24"
                                  viewBox="0 0 10 24"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M4.64865 23.5203C4.28527 23.445 3.90859 23.4006 3.55407 23.281C2.08724 22.7891 1.3782 21.5837 1.52887 19.9928C1.64852 18.7431 2.04736 17.5643 2.36643 16.3634C2.65891 15.26 2.96468 14.1609 3.23943 13.0575C3.37238 12.5346 3.41669 11.9984 3.31477 11.4577C3.23057 11.0101 2.97797 10.7398 2.53039 10.6423C1.90112 10.5049 1.28957 10.5626 0.682452 10.7575C0.46974 10.824 0.252596 10.8949 0 10.9791C0.106356 10.5537 0.194986 10.1637 0.301343 9.77375C0.314637 9.72057 0.376678 9.66739 0.429856 9.64524C1.43581 9.2597 2.4329 8.82984 3.50532 8.63928C4.56002 8.45316 5.60143 8.47532 6.58965 8.95835C7.6222 9.46354 8.16727 10.5537 8.11852 11.7015C8.05648 13.2525 7.52027 14.6794 7.13916 16.1507C6.891 17.1212 6.62067 18.0828 6.39024 19.0578C6.27502 19.5364 6.21298 20.0283 6.31047 20.5246C6.42569 21.0963 6.69158 21.34 7.26324 21.4375C8.02103 21.5704 8.73894 21.4153 9.44798 21.1672C9.51445 21.145 9.58535 21.1184 9.68728 21.0785C9.58535 21.504 9.49229 21.9028 9.38594 22.2972C9.37264 22.3415 9.3106 22.3903 9.26185 22.408C8.46418 22.7049 7.67094 23.0195 6.86441 23.2854C6.49216 23.4095 6.08889 23.445 5.70335 23.5159C5.35326 23.5203 5.00317 23.5203 4.64865 23.5203Z"
                                    fill="#292D32"
                                  />
                                  <path
                                    d="M7.32516 6.16221C5.98241 6.1046 5.06509 5.59498 4.56432 4.50039C3.99266 3.25071 4.59091 1.79717 5.88935 1.16347C7.07256 0.582939 8.52166 0.875419 9.38581 1.87251C10.5646 3.23298 9.9752 5.33352 8.22475 5.95836C7.89239 6.08244 7.529 6.11789 7.32516 6.16221Z"
                                    fill="#292D32"
                                  />
                                </svg>
                              </div>
                            </span>
                            <button className="border-0 bg-transparent" title="Delete">
                              <svg
                                className="cursor-pointer"
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                                onClick={() => {
                                  setId(_id);
                                  setSmShow(true);
                                }}
                              >
                                <path
                                  d="M21.07 5.23C19.46 5.07 17.85 4.95 16.23 4.86V4.85L16.01 3.55C15.86 2.63 15.64 1.25 13.3 1.25H10.68C8.35004 1.25 8.13003 2.57 7.97004 3.54L7.76004 4.82C6.83004 4.88 5.90004 4.94 4.97004 5.03L2.93004 5.23C2.51004 5.27 2.21004 5.64 2.25004 6.05C2.29004 6.46 2.65004 6.76 3.07004 6.72L5.11004 6.52C10.35 6 15.63 6.2 20.93 6.73C20.96 6.73 20.98 6.73 21.01 6.73C21.39 6.73 21.72 6.44 21.76 6.05C21.79 5.64 21.49 5.27 21.07 5.23Z"
                                  fill="#292D32"
                                />
                                <path
                                  d="M19.23 8.14C18.99 7.89 18.66 7.75 18.32 7.75H5.67999C5.33999 7.75 4.99999 7.89 4.76999 8.14C4.53999 8.39 4.40999 8.73 4.42999 9.08L5.04999 19.34C5.15999 20.86 5.29999 22.76 8.78999 22.76H15.21C18.7 22.76 18.84 20.87 18.95 19.34L19.57 9.09C19.59 8.73 19.46 8.39 19.23 8.14ZM13.66 17.75H10.33C9.91999 17.75 9.57999 17.41 9.57999 17C9.57999 16.59 9.91999 16.25 10.33 16.25H13.66C14.07 16.25 14.41 16.59 14.41 17C14.41 17.41 14.07 17.75 13.66 17.75ZM14.5 13.75H9.49999C9.08999 13.75 8.74999 13.41 8.74999 13C8.74999 12.59 9.08999 12.25 9.49999 12.25H14.5C14.91 12.25 15.25 12.59 15.25 13C15.25 13.41 14.91 13.75 14.5 13.75Z"
                                  fill="#292D32"
                                />
                              </svg>
                            </button>
                          </div>
                        </td>
                      </tr>
                    </>
                  );
                })}
              </tbody>
              :
              <tbody>
                <tr>
                  <td className="bg-transparent" colSpan={10}>
                    <div className='nodata-table'>
                      <h3 className="text-center p-5">Data Not Found</h3>
                    </div>
                  </td>
                </tr>
              </tbody>
            }
          </Table>
          <div className="d-md-flex justify-content-between align-items-center">
            <button
              className="secondary-button text-decoration-none"
              onClick={() => navigate("/dashboard")} >
              <svg
                stroke="currentColor"
                fill="currentColor"
                strokeWidth={0}
                viewBox="0 0 24 24"
                height="2em"
                width="2em"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M12.707 17.293 8.414 13H18v-2H8.414l4.293-4.293-1.414-1.414L4.586 12l6.707 6.707z" />
              </svg>
              Back
            </button>

            {studioData?.data &&
              studioData?.data?.length >= 1 &&
              studioData.pagination !== 1 && (
                <TablePagination
                  currentPage={studioData?.page}
                  totalPages={studioData?.pages}
                  onPageChange={(newPage) => {
                    setPage(newPage);
                  }}
                  disablePrev={studioData.page === 1}
                  disableNext={studioData.page === studioData.pages}
                />
              )}
          </div>
        </Col>
      </Row>

      {/* Edit modal */}

      <Modal
        show={show}
        onHide={handleCloseForPopup}
        centered
        className="moadl-main info-modal"
        size="xl"
      >
        <Modal.Header
          className="d-block position-relative border-0"
          closeButton
        >
          <Modal.Title className="text-center">
            {!editMode ? "Info" : "Edit"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="mx-md-4 border-0 bg-transparent pb-0 service-page">

          <div className="d-flex align-items-center gap-4">
            {!editMode ? (
              <div className="w-100 ">
                <div className="row align-items-center mb-1">
                  <div className="col-3">
                    <label className="text-nowrap fw-600">Smart Lock UserName:</label>
                  </div>
                  <div className="col-9">
                    <input
                      className="mt-1 form-control border-0 fw-400 shadow-none pl-black px-0 label-pl bg-transparent gap-1"
                      type="text"
                      value={studioEdit?.lock?.name || "-"}
                      disabled
                    />
                  </div>
                </div>
                <div className="row align-items-center mb-1">
                  <div className="col-3">
                    <label className="text-nowrap fw-600">Smart Lock PhoneNo:</label>
                  </div>
                  <div className="col-9">
                    <input
                      className="form-control border-0 fw-400 shadow-none pl-black px-0 label-pl bg-transparent gap-1"
                      type="text"
                      value={studioEdit?.lock?.phoneNo || "-"}
                      disabled
                    />
                  </div>
                </div>
                <div className="row align-items-center mb-2">
                  <div className="col-3">
                    <label className="text-nowrap fw-600">Expire Date:</label>
                  </div>
                  <div className="col-9">
                    <input
                      className="form-control border-0 fw-400 shadow-none pl-black px-0 label-pl bg-transparent gap-1"
                      type="text"
                      value={studioEdit?.lock?.keyExpireDate || "-"}
                      disabled
                    />
                  </div>
                </div>
              </div>
            ) : (
              <Col xs={12}>
                <div className="row align-items-center mb-3 g-0">
                  <div className="col-12">
                    <label className="form-label text-nowrap fw-600 d-flex">Smart Lock User:</label>
                  </div>
                  <div className="col-12">
                    <div className="d-flex align-items-center gap-3">
                      <Select
                        placeholder="Search Smart Lock user associated with booth"
                        className="form-control fw-400 border-0 shadow-none pl-black px-0 label-pl bg-transparent"
                        onChange={handleChanges}
                        onKeyDown={(e) => handleKeyDowns(e)}
                        options={saltoUser?.map(user => ({
                          value: user.uid,
                          label: user.name
                        }))}
                        onInputChange={(e) => { setSelectSaltoUser(e) }}
                      />
                      <button className="border-0 bg-transparent" title="Delete">
                        <svg
                          className="cursor-pointer"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                          onClick={removeSaltoUser}
                        >
                          <path
                            d="M21.07 5.23C19.46 5.07 17.85 4.95 16.23 4.86V4.85L16.01 3.55C15.86 2.63 15.64 1.25 13.3 1.25H10.68C8.35004 1.25 8.13003 2.57 7.97004 3.54L7.76004 4.82C6.83004 4.88 5.90004 4.94 4.97004 5.03L2.93004 5.23C2.51004 5.27 2.21004 5.64 2.25004 6.05C2.29004 6.46 2.65004 6.76 3.07004 6.72L5.11004 6.52C10.35 6 15.63 6.2 20.93 6.73C20.96 6.73 20.98 6.73 21.01 6.73C21.39 6.73 21.72 6.44 21.76 6.05C21.79 5.64 21.49 5.27 21.07 5.23Z"
                            fill="#292D32"
                          />
                          <path
                            d="M19.23 8.14C18.99 7.89 18.66 7.75 18.32 7.75H5.67999C5.33999 7.75 4.99999 7.89 4.76999 8.14C4.53999 8.39 4.40999 8.73 4.42999 9.08L5.04999 19.34C5.15999 20.86 5.29999 22.76 8.78999 22.76H15.21C18.7 22.76 18.84 20.87 18.95 19.34L19.57 9.09C19.59 8.73 19.46 8.39 19.23 8.14ZM13.66 17.75H10.33C9.91999 17.75 9.57999 17.41 9.57999 17C9.57999 16.59 9.91999 16.25 10.33 16.25H13.66C14.07 16.25 14.41 16.59 14.41 17C14.41 17.41 14.07 17.75 13.66 17.75ZM14.5 13.75H9.49999C9.08999 13.75 8.74999 13.41 8.74999 13C8.74999 12.59 9.08999 12.25 9.49999 12.25H14.5C14.91 12.25 15.25 12.59 15.25 13C15.25 13.41 14.91 13.75 14.5 13.75Z"
                            fill="#292D32"
                          />
                        </svg>
                      </button>
                    </div>
                  </div>
                </div>

                {displayUser.length ? (
                  <div className="row align-items-center g-3">
                    <div className="col-12">
                      <div className="row g-3">
                        <div className="col-3">
                          <p className="text-nowrap fw-600 mt-0 mb-0">Smart Lock UserName: </p>
                        </div>
                        <div className="col-9">
                          <span className="">{studioEdit?.lock?.name || displayUser?.name}</span>
                        </div>
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="row g-3">
                        <div className="col-3">
                          <p className="text-nowrap fw-600 mb-0">
                            Smart Lock PhoneNo :
                          </p>
                        </div>
                        <div className="col-9">
                          <span> {studioEdit?.lock?.phoneNo || displayUser?.phoneNo}</span>
                        </div>
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="row g-3">
                        <div className="col-3">
                          <p className="text-nowrap fw-600 mb-3">
                            Expire Date :
                          </p>
                        </div>
                        <div className="col-9">
                          <span>
                            {studioEdit?.lock?.keyExpireDate || displayUser.keyExpireDate}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : ""}
              </Col>
            )
            }
          </div>

          {editMode && (
            <Col xs={12} lg={12} className="mb-3">
              <div className="form-control d-flex align-items-center px-3">
                <span>Venue Type:</span>
                <div className="mx-3 gap-2 custom-checked d-flex">
                  <input
                    style={{ height: '20px', width: '20px', marginTop: "3px" }}
                    type="radio"
                    className="defaultCheckbox form-check-input"
                    name="venueTypes"
                    value="booth"
                    id="smallCheckbox6"
                    onChange={venueTypeChange}
                    checked={venueType === "booth"}
                  />
                  <label htmlFor="smallCheckbox6" className="checkbox-label fw-400">
                    Booth
                  </label>
                </div>

                <div className="mx-3 gap-2 d-flex custom-checked">
                  <input
                    style={{ height: '20px', width: '20px', marginTop: "3px" }}
                    type="radio"
                    className="defaultCheckbox form-check-input"
                    name="venueTypes"
                    value="recital"
                    id="recitalCheckbox11"
                    onChange={venueTypeChange}
                    checked={venueType === "recital"}
                  />
                  <label htmlFor="recitalCheckbox11" className="checkbox-label fw-400">
                    Recital Hall
                  </label>
                </div>
              </div>
            </Col>
          )}

          {venueType === "booth" && (
            <div className={`${editMode ? "border p-3 mb-3" : "mb-3"}`}>
              <div className="row">
                <div className="col-12">
                  <label className="text-nowrap fw-600">Booth Type:</label>
                </div>
                <div className="col-12">
                  {editMode && (
                    <div className="mt-2 d-flex flex-wrap align-items-center gap-3 studio-category">
                      <Dropdown className="" style={{ width: "250px" }} drop="up">
                        <Dropdown.Toggle
                          className="dropdown studio-add-dropdown"
                          variant="white"
                          id="dropdown-basic"
                        >
                          {selectedBoothType.name || "Choose Booth Type"}
                        </Dropdown.Toggle>
                        <Dropdown.Menu style={{ padding: 0, width: "250px", border: "1px" }}>
                          {filteredAvailableBooths?.length > 0 ? (
                            filteredAvailableBooths.map((val, index) => (
                              <Dropdown.Item
                                key={index}
                                className="studio-add-dropdown-item"
                                onClick={() => setSelectedBoothType(val)}
                              >
                                {val.name}
                              </Dropdown.Item>
                            ))
                          ) : (
                            <Dropdown.Item>No Data</Dropdown.Item>
                          )}
                        </Dropdown.Menu>
                      </Dropdown>
                      <div className="edit-btn-main2">
                        <Button className="me-2" onClick={handleAddBoothType}>
                          Add
                        </Button>
                      </div>
                    </div>
                  )}
                </div>
              </div>

              <Row>
                <div className="category">
                  {singleStudio?.boothTypes?.length > 0 ? (
                    <div className="category-btn">
                      {singleStudio.boothTypes.map((boothType, index) => (
                        <div key={index} className="closeSpan mt-3">
                          <span>{boothType.name}</span>
                          &nbsp;
                          {editMode && (
                            <button
                              className="closeBtn"
                              onClick={() => handleRemoveBoothType(boothType)}
                            >
                              <img src="/images/svg/delete.svg" alt="delete" />
                            </button>
                          )}
                        </div>
                      ))}
                    </div>
                  ) : (
                    <p className={`${Boolean(editMode) && "charError"}`}>
                      No Booth Types Added
                    </p>
                  )}
                </div>
              </Row>
            </div>
          )}

          {venueType === "recital" && editMode ? (
            <div className="row mb-3 g-0 mt-1">
              <div className={`${editMode ? "col-12" : "col-3"}`}>
                <label className="form-label text-nowrap fw-600">Recital Type:</label>
              </div>
              <div className={`${editMode ? "row px-0" : "col-9"}`}>
                {recitalHall?.map((val, index) => (
                  <div key={index} xs={4} className={`${editMode ? "col-3" : "d-flex align-items-center"}`}>
                    <>
                      <p className={editMode ? "mt-3 mb-1" : "mt-0 mb-0 w-25"}>{val.name.key}</p>
                      <div className={`d-flex align-items-center px-1 ${editMode ? "form-control " : ""}`}>
                        <input
                          className="form-control border-0 shadow-none px-0 px-3 text-black"
                          placeholder={`Enter ${val.name.key} value`}
                          type="text"
                          value={recitalTypes[index]?.value || ''}
                          onChange={(e) => handleRecitalTypeChange(e, index)}
                          disabled={!editMode}
                        />
                      </div>
                    </>
                  </div>
                ))}
              </div>
            </div>
          ) :
            <div className="row mb-3 g-0 mt-1">
              <div className={`${editMode ? "col-12" : "col-3"}`}>
                <label className="form-label text-nowrap fw-600">Recital Type:</label>
              </div>
              <div className={`${editMode ? "row px-0" : "col-9"}`}>
                {recitalTypes?.filter((v) => v.value !== "").map((val, index) => (
                  // console.log('val: ', val),
                  <div key={index} className={`${editMode ? "col-3" : "d-flex align-items-center"}`}>
                    <>
                      <p className={editMode ? "mt-3 mb-1" : "mt-0 mb-0 w-25"}>{val.key}</p>
                      <div className={`d-flex align-items-center px-1 ${editMode ? "form-control " : ""}`}>
                        <input
                          className="form-control border-0 shadow-none px-0 px-3 text-black"
                          placeholder={`Enter ${val.key} value`}
                          type="text"
                          value={val.value || ''}
                          // onChange={(e) => handleRecitalTypeChange(e, index)}
                          disabled={!editMode}
                        />
                      </div>
                    </>
                  </div>
                ))}
              </div>
            </div>

          }

          <div className="row align-items-center mb-3 g-0">
            <div className={`${editMode ? "col-12" : "col-3"}`}>
              <label className="form-label text-nowrap fw-600">Booth Title: </label>
            </div>
            <div className={`${editMode ? "col-12" : "col-9"}`}>
              {editMode ?
                <div className="form-control d-flex align-items-center px-3">
                  <input
                    className="form-control fw-400 border-0 shadow-none pl-black px-0 label-pl bg-transparent"
                    type="text"
                    name="studio_name"
                    onChange={inputChange}
                    defaultValue={studioEdit?.studio_name}
                  />
                </div> :
                <div className="w-100">
                  <input
                    className="form-control border-0 fw-400 shadow-none pl-black px-0 label-pl bg-transparent gap-1"
                    type="text"
                    name="studio_name"
                    defaultValue={studioEdit?.studio_name}
                    disabled
                  />
                </div>
              }
            </div>
          </div>

          <div className="row align-items-center g-0 mb-3">
            <div className={`${editMode ? "col-12" : "col-3"}`}>
              <label className="form-label text-nowrap fw-600 ">Address :</label>
            </div>
            <div className={`${editMode ? "col-12" : "col-9"}`}>
              {editMode ?
                <>
                  <div className="form-control d-flex align-items-center px-3">
                    <input
                      className="form-control fw-400 border-0 shadow-none pl-black px-0 label-pl bg-transparent"
                      type="text"
                      name="address"
                      onChange={inputChange}
                      value={studioEdit?.address}
                    // disabled={!editMode}
                    />
                  </div>
                </>
                :
                <div className="w-100">
                  <input
                    className="form-control fw-400 border-0 shadow-none pl-black px-0 label-pl bg-transparent"
                    type="text"
                    name="address"
                    value={studioEdit?.address}
                    disabled={!editMode}
                  />
                </div>
              }
            </div>

          </div>
          {
            editMode ?
              <div className="mt-4 mt-lg-0 row mb-3">
                <div className="col-12">
                  <label className="form-label text-nowrap fw-600" >Description: </label>
                </div>
                <div className="col-12">
                  <div className="form-control d-flex align-items-center px-3">
                    <textarea
                      className="form-control fw-400 border-0 shadow-none pl-black px-0 label-pl bg-transparent"
                      rows={6}
                      name="desc"
                      onChange={inputChange}
                      onKeyDown={handleKeyDown}
                      value={studioEdit?.desc}
                      disabled={!editMode}
                    />
                  </div>
                </div>
              </div>
              :
              <div className="row align-items-center mb-3 g-0">
                <div className="col-3">
                  <label className="text-nowrap fw-600">Description:</label>
                </div>
                <div className="col-9">
                  <div className="fs-6 fw-400 w-100 mb-3 bg-transparent py-0" name="desc">
                    {studioEdit?.desc}
                  </div>
                </div>
              </div>
          }

          {/* -------------------------------------------- */}

          <div className={`${editMode ? "border p-3 mb-3" : "mb-3"}`}>
            <div className="row">
              <div className="col-12">
                <label className="text-nowrap fw-600">Add-Ons</label>
              </div>
              <div className="col-12">
                {editMode && (
                  <div className="mt-2 d-flex flex-wrap align-items-center gap-3 studio-category">
                    <Dropdown className="" style={{ width: "250px" }} drop="up">
                      <Dropdown.Toggle
                        className="dropdown studio-add-dropdown"
                        variant="white"
                        id="dropdown-basic"
                      >
                        {selectedAddOn.title || "Choose Add-ons"}
                      </Dropdown.Toggle>
                      <Dropdown.Menu style={{ padding: 0, width: "250px", border: "1px" }}>
                        {filteredAddOns?.length > 0 ? (
                          filteredAddOns.map((val, index) => (
                            <Dropdown.Item
                              key={index}
                              className="studio-add-dropdown-item"
                              onClick={() => setSelectedAddOn(val)}
                            >
                              {val.title}
                            </Dropdown.Item>
                          ))
                        ) : (
                          <Dropdown.Item>No Data</Dropdown.Item>
                        )}
                      </Dropdown.Menu>
                    </Dropdown>
                    <div className="edit-btn-main2">
                      <Button className="me-2" onClick={handleAddAddOn}>
                        Add
                      </Button>
                    </div>
                  </div>
                )}
              </div>
            </div>
            <Row>
              <div className="category">
                {singleStudio?.addOns?.length > 0 ? (
                  <div className="category-btn">
                    {singleStudio?.addOns?.map((addOn, index) => (
                      <div key={index} className="closeSpan mt-3">
                        <span>{addOn.title}</span>
                        {editMode && (
                          <button
                            className="closeBtn"
                            onClick={() => handleRemoveAddOn(addOn)}
                          >
                            <img src="/images/svg/delete.svg" alt="delete" />
                          </button>
                        )}
                      </div>
                    ))}
                  </div>
                ) : (
                  <p className={`${Boolean(editMode) && "charError"}`}>
                    No Add-ons Added
                  </p>
                )}
              </div>
            </Row>
          </div>


          <div className={`${editMode ? "border p-3 mb-3" : "mb-3"}`}>
            <div className="row">
              <div className="col-12">
                <label className="text-nowrap fw-600 ">Booth Equipments</label>
              </div>
              <div className="col-12">
                {(editMode && (
                  <div className="mt-2 d-flex flex-wrap align-items-center gap-3 studio-category ">
                    <Dropdown className="" style={{ width: "250px" }} drop="up">
                      <Dropdown.Toggle
                        className="dropdown studio-add-dropdown"
                        variant="white"
                        id="dropdown-basic"
                      >
                        {addCategories.name || "Choose Equipments"}
                      </Dropdown.Toggle>
                      <Dropdown.Menu style={{ padding: 0, width: "250px", border: "1px" }}>
                        {categoryData?.length > 0 ? (
                          categoryData?.filter(val => val.status === 1)?.map((val, index) => (
                            <Dropdown.Item
                              className="studio-add-dropdown-item"
                              onClick={() => setAddCategories(val)}
                            >
                              {val.name}
                            </Dropdown.Item>
                          ))
                        ) : (
                          <Dropdown.Item>{"No Data"}</Dropdown.Item>
                        )}
                      </Dropdown.Menu>
                    </Dropdown>
                    <div className="edit-btn-main2">
                      <Button
                        className="me-2"
                        onClick={(e) => {
                          e.preventDefault();
                          if (addCategories?.name) {
                            setCategories([...categories, addCategories]);
                            setAddCategories({});
                            addCategory(addCategories);
                          }
                        }}
                      >
                        Add
                      </Button>
                    </div>
                  </div>
                )) ||
                  ""}
              </div>
            </div>

            <Row>
              <div className="category ">
                {singleStudio?.categories?.length > 0 ? (
                  <div className="category-btn">
                    {singleStudio?.categories?.map((value, index) => (
                      <div key={index} className="closeSpan mt-3">
                        <span>{value.name}</span>
                        &nbsp;
                        {editMode && (
                          <button
                            className="closeBtn"
                            onClick={() => changeCategory(value)}
                          >
                            <img src="/images/svg/delete.svg" alt="delete" />
                          </button>
                        )}
                      </div>
                    ))}
                  </div>
                ) : (
                  <p className={`${Boolean(editMode) && "charError"}`}>
                    Any Equipments is not added
                  </p>
                )}
              </div>
            </Row>
          </div>

          <Row className="mb-3">
            {/* <p className="fw-600 mb-3">Location Timing </p> */}
            <Col xs={12} lg={6} className="mt-0">
              <div>
                <label className="text-nowrap fw-600 ">Start time:</label>
                <div className="form-control d-flex align-items-center">
                  <Form.Select
                    aria-label="Default select example"
                    className="select-bg form-control border-0 shadow-none pl-black pl-0 selectOption pr-0 location-ip bg-transparent"
                    name="start_time"
                    disabled={!editMode}
                    onChange={inputChange}
                    value={
                      studioEdit?.start_time
                    }
                  >
                    <option value={""}>Studio Start time</option>
                    <option value={"00:00"}>{"00:00"}</option>
                    {timeSlot.map((v, i) => {
                      return (
                        <option key={i} value={v}>
                          {v}
                        </option>
                      );
                    })}
                  </Form.Select>
                </div>
              </div>
            </Col>

            <Col xs={12} lg={6} className="mt-0">
              <div>
                <label htmlFor="" className="text-nowrap fw-600">
                  End time:
                </label>
                <div className="form-control d-flex align-items-center">
                  <Form.Select
                    aria-label="Default select example"
                    className="select-bg form-control border-0 shadow-none pl-black pl-0 selectOption pr-0 location-ip bg-transparent"
                    name="end_time"
                    onChange={inputChange}
                    disabled={!editMode}
                    value={studioEdit?.end_time}
                  >
                    {timeSlot.map((v, i) => {
                      return (
                        <option key={i} value={v}>
                          {v}
                        </option>
                      );
                    })}
                    <option value={"00:00"}>{"00:00"}</option>
                  </Form.Select>
                </div>
              </div>
            </Col>
          </Row>
          {!editMode ? (
            <Col className="mt-3">
              <span className="text-nowrap fs-6 fw-600">Pricing : </span>
              <div className="fw-400">{studioEdit?.pricingId?.name}</div>
              <div className="overflow-auto mt-2">
                <Table striped bordered hover>
                  <thead>
                    <tr>
                      <th className="border-start">Days</th>
                      <th className="border-start">Time Slots</th>
                      <th className="price-input border-start">Price</th>
                    </tr>
                  </thead>
                  <tbody>

                    {Boolean(studioEdit?.pricingId?.weekday?.length) ? (
                      studioEdit?.pricingId?.weekday?.map((day, i) => (
                        <tr key={i}>
                          <td>{day.day}</td>
                          <td>
                            <ul className="list-unstyled mb-0">
                              {day?.slots?.map((slot, j) => (
                                <li key={j}>
                                  <span>{slot.startTime} - {slot.endTime}</span>
                                </li>
                              ))}
                            </ul>
                          </td>
                          <td>
                            <ul className="list-unstyled mb-0">
                              {day.slots.map((slot, j) => (
                                <li key={j}>AED {slot.price}</li>
                              ))}
                            </ul>
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan={3} className="bg-trasf">
                          <h5 className="text-center mb-0">Data Not Found</h5>
                        </td>
                      </tr>
                    )}
                  </tbody>
                </Table>
              </div>
            </Col>
          ) : (
            <Row>
              <label className="text-nowrap fw-600 mb-2">Pricing:</label>
              <Col xs={12} lg={12}>
                <div className="form-control d-flex align-items-center px-0 mb-3">
                  <Form.Select
                    defaultValue={studioEdit?.pricingId?._id}
                    onChange={handleNameChange}
                    aria-label="Select Pricing Name"
                    className="border-0"
                  >
                    <option value="">{"Select Price Name"}</option>
                    {pricingData?.map((name, index) => (
                      <option key={index} value={name?._id}>
                        {name?.name}
                      </option>
                    ))}
                  </Form.Select>
                </div>
              </Col>
              <Col>
                <div className="overflow-auto mb-3">
                  <Table striped bordered hover className="mb-0">
                    <thead>
                      <tr>
                        <th className="border-start">Days</th>
                        <th className="border-start">Time Slots</th>
                        <th className="price-input border-start">Price</th>
                      </tr>
                    </thead>
                    <tbody>
                      {selectedPricing ? (
                        selectedPricing?.weekday?.map((day, i) => (
                          <tr key={i}>
                            <td>{day.day}</td>
                            <td>
                              <ul className="list-unstyled mb-0">
                                {day?.slots?.map((slot, j) => (
                                  <li key={j}>
                                    <span>{slot.startTime} - {slot.endTime}</span>
                                  </li>
                                ))}
                              </ul>
                            </td>
                            <td>
                              <ul className="list-unstyled mb-0">
                                {day.slots.map((slot, j) => (
                                  <li key={j}>AED {slot.price}</li>
                                ))}
                              </ul>
                            </td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td colSpan="3">Data not found</td>
                        </tr>
                      )}
                    </tbody>
                  </Table>
                </div>
              </Col>
            </Row>
          )}
          {!editMode ? (
            <Col className="mt-3">
              <span className="text-nowrap fs-6 fw-600">Cancellation Policy :</span>
              <div className="fw-400">{studioEdit?.cancelPolicyId?.name}</div>
              <div className="overflow-auto mt-2">
                <Table striped bordered hover>
                  <thead>
                    <tr>
                      <th className="border-start" style={{ width: "50%" }}>Lead Time</th>
                      <th className="border-start">Refund to Wallet</th>
                    </tr>
                  </thead>
                  <tbody>
                    {Boolean(studioEdit?.cancelPolicyId?.slots?.length) ? (
                      studioEdit?.cancelPolicyId?.slots?.sort((a, b) => b.amountPercentage - a.amountPercentage)?.map((time, i) => (
                        <tr key={i}>
                          <td>{time.time} hours</td>
                          <td>{time.amountPercentage}%</td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan={3} className="bg-trasf">
                          <h5 className="text-center mb-0">Data Not Found</h5>
                        </td>
                      </tr>
                    )}
                  </tbody>
                </Table>
              </div>
            </Col>
          ) : (
            <Row>
              <label className="text-nowrap fw-600 mb-2">Cancellation Policy :</label>
              <Col xs={12} lg={12}>
                <div className="form-control d-flex align-items-center px-0 mb-3">
                  <Form.Select
                    defaultValue={studioEdit?.cancelPolicyId?._id}
                    onChange={selectChange}
                    aria-label="Select Pricing Name"
                    className="border-0"
                  >
                    <option value="">{"Select Policy Name"}</option>
                    {policyData?.map((name, index) => (
                      <option key={index} value={name?._id}>
                        {name?.name}
                      </option>
                    ))}
                  </Form.Select>
                </div>
              </Col>
              <Col>
                <div className="overflow-auto mb-3">
                  <Table striped bordered hover className="mb-0">
                    <thead>
                      <tr>
                        <th className="border-start" style={{ width: "50%" }}>Lead Time</th>
                        <th className="border-start">Refund to Wallet</th>
                      </tr>
                    </thead>
                    <tbody>

                      {selectedSetPolicy ? (
                        selectedSetPolicy?.slots?.sort((a, b) => b.amountPercentage - a.amountPercentage)?.map((time, i) => (
                          <tr key={i}>
                            <td>{time?.time} hours</td>
                            <td>{time?.amountPercentage}%</td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td colSpan="3">Data not found</td>
                        </tr>
                      )}
                    </tbody>
                  </Table>
                </div>
              </Col>
            </Row>
          )}

          {singleStudio?.venueType === "booth" && (
            editMode ? (
              <Row>
                <Col xs={12} lg={12}>
                  <div className="d-flex align-items-center text-center text-nowrap gap-2 mb-3">
                    <span className="text-nowrap fs-6 fw-600">Booth Size:</span>
                    <div className="mx-3 gap-2 d-flex">
                      <input
                        style={{ height: "20px", width: "20px", marginTop: "3px" }}
                        type="radio"
                        className="defaultCheckbox form-check-input"
                        name="booth_options"
                        value="S"
                        id="smallCheckbox"
                        onChange={handleCheckboxChange}
                        checked={studioEdit?.booth_options === "S"}
                      />
                      <label htmlFor="smallCheckbox" className="checkbox-label fw-400">Small</label>
                    </div>
                    <div className="mx-3 gap-2 d-flex">
                      <input
                        style={{ height: '20px', width: '20px', marginTop: "3px" }}
                        type="radio"
                        className="defaultCheckbox form-check-input"
                        name="booth_options"
                        value="M"
                        id="mediumCheckbox"
                        onChange={handleCheckboxChange}
                        checked={studioEdit?.booth_options === "M"}
                      />
                      <label htmlFor="mediumCheckbox" className="checkbox-label fw-400">Medium</label>
                    </div>
                    <div className="mx-3 gap-2 d-flex">
                      <input
                        style={{ height: '20px', width: '20px', marginTop: "3px" }}
                        type="radio"
                        className="defaultCheckbox form-check-input"
                        name="booth_options"
                        value="L"
                        id="largeCheckbox"
                        onChange={handleCheckboxChange}
                        checked={studioEdit?.booth_options === "L"}
                      />
                      <label htmlFor="largeCheckbox" className="checkbox-label fw-400">Large</label>
                    </div>
                  </div>
                </Col>
              </Row>
            ) : (
              <Row>
                <div className="d-flex align-items-center text-center text-nowrap gap-2 mb-3">
                  <label className="text-nowrap fw-600">Booth Size:</label>
                  <input
                    className="fs-6 fw-400 text-black bg-transparent"
                    value={defaultLabel}
                    disabled={!editMode}
                  />
                </div>
              </Row>
            )
          )}

          <div className={`${editMode ? "mb-3" : "d-flex mb-3 gap-3 align-items-center"}`}>
            <p className="text-nowrap fs-6 fw-600 mb-0">Booking hours: </p>
            {editMode ?
              <div className="form-control d-flex align-items-center px-3">
                <input
                  className="form-control border-0 shadow-none pl-black px-0 label-pl bg-transparent"
                  type="number"
                  name="min_booking_time"
                  onChange={inputChange}
                  value={studioEdit?.min_booking_time}
                  onKeyDown={(e) =>
                    ["e", "E", "+", "-", "."].includes(e.key) &&
                    e.preventDefault()
                  }
                  disabled={!editMode}
                />
              </div> :
              <div className="">
                <input
                  className="form-control border-0 shadow-none pl-black px-0 label-pl bg-transparent"
                  type="text"
                  name="min_booking_time"
                  onChange={inputChange}
                  value={studioEdit?.min_booking_time}
                  disabled={!editMode}
                />
              </div>
            }
          </div>

          <div className="edit-booth">
            <label className="text-nowrap fw-600 mb-2">Booths Main Image: </label>
            <div className="d-lg-flex align-items-start gap-4 mb-3">
              {
                editMode ? (
                  <div className="position-relative edit-studio">
                    <div className="d-flex justify-content-center m-auto">
                      <img src={selectedImage ? URL.createObjectURL(selectedImage) : url + studioEdit.main_image || "../images/svg/camera.svg"}
                        className="kurzwll-studio"
                        onClick={handleImageClick}
                      />
                      <div className="kurzwll-studio bg-darklight position-absolute top-50 start-50 translate-middle">
                        <input
                          type="file"
                          accept=".jpg, .jpeg, .png"
                          ref={fileInputRef1}
                          name="main_image"
                          onChange={handleFileChange}
                          style={{ display: "none" }}
                        />
                      </div>
                    </div>
                    <img
                      src="../images/svg/camera.svg"
                      className="camera position-absolute top-50 start-50 translate-middle cursor-pointer"
                      onClick={handleImageClick}
                    />
                  </div>
                ) : (
                  <div className="position-relative edit-studio">
                    <img
                      src={
                        singleStudio?.main_image
                          ? url + studioEdit?.main_image
                          : "../images/svg/camera.svg"
                      }
                      className="kurzwll-studio"
                      alt=""
                    />
                  </div>
                )}
            </div>
          </div>
          {/* <p className="placeholder-black mt-2">The image ratio must be 100:90 for example, dimensions could be 1000x450 pixels.</p> */}
          <p className="placeholder-black mt-2">The image should have a 100:90 aspect ratio. For example, the dimensions could be 1000x900 pixels.</p> 

          <div className="edit-booth mt-3">
            {studioEdit?.sub_images?.length > 1 && <label className="text-nowrap fw-600 mb-2">Booths Sub Images: </label>}
            <Row className="gy-4 g-3">
              {editMode ?
                Array.from(studioEdit?.sub_images)?.map((subImage, index) => (
                  // index !== 0 && (
                  <Col key={index} xs="12" md="12" lg="4" xl="3">
                    <div className="position-relative">
                      <div className="d-flex justify-content-center m-auto">
                        {subImage?.filePath?.endsWith(".jpeg") ||
                          subImage?.filePath?.endsWith(".png") ||
                          subImage?.filePath?.endsWith(".jpg") ||
                          subImage?.type?.startsWith("image") ? (
                          <img
                            src={
                              subImage?.filePath
                                ? url + subImage?.filePath
                                : subImage?.type?.startsWith("image") ? URL.createObjectURL(subImage) : "../images/svg/camera.svg"
                            }
                            className="kurzwll-studio2"
                            alt=""
                          />
                        ) : (
                          <video controls className="kurzwll-studio2" style={{ objectFit: "cover" }} >
                            <source
                              src={subImage?.type?.startsWith("video") ? URL.createObjectURL(subImage) : url + subImage?.filePath}
                              type="video/mp4"
                            />
                            Your browser does not support the video tag.
                          </video>
                        )}
                      </div>
                      <img
                        src="../images/jpg/closee.jpg"
                        className="position-absolute translate-middle cursor-pointer close-po2"
                        alt=""
                        name="deleteSubImage_id"
                        onClick={() => {
                          removeExistingSubImage(subImage?._id, index);
                        }}
                      />
                    </div>
                  </Col>
                  // )
                ))
                : singleStudio?.sub_images.map((ele, index) => (
                  // index !== 0 && (
                  <Col xs="6" md="4" lg="3" xl="3" key={index}>
                    <div className="d-flex justify-content-center m-auto">
                      {ele?.filePath?.endsWith(".jpeg") ||
                        ele?.filePath?.endsWith(".png") ||
                        ele?.filePath?.endsWith(".jpg") ? (
                        <img
                          src={
                            ele?.filePath
                              ? url + ele?.filePath
                              : "../images/svg/camera.svg"
                          }
                          className="kurzwll-studio2"
                          alt=""
                        />
                      ) : (
                        <video controls className="kurzwll-studio2" style={{ objectFit: "cover" }}>
                          <source src={url + ele?.filePath} type="video/mp4" />
                          Your browser does not support the video tag.
                        </video>
                      )}
                    </div>
                  </Col>
                  // )
                ))}
            </Row>
          </div>
          

          {editMode && (
            <div className="d-flex justify-content-center mt-5">
              <div class="upload__box">
                <div class="upload__btn-box d-flex">
                  <label class="upload__btn d-flex align-items-center gap-3">
                    <span className="plush">+</span>
                    <p className="mb-0">Upload multiple image and video</p>
                    <input
                      type="file"
                      class="upload__inputfile"
                      accept=".jpg, .jpeg, .png, .mp4"
                      name="sub_images"
                      onChange={handleFileChanges}
                      multiple
                      value={""}
                    />
                  </label>
                </div>
              </div>
            </div>
          )}

          <Form className="mt-4">
            <Row className="align-items-end">
              <Col xs={12} lg="3" xl="4">
                <Form.Label className="fw-600">Latitude</Form.Label>
                <div className="form-control d-flex align-items-center px-3 mb-3">
                  <input
                    className="form-control border-0 shadow-none pl-black px-0 label-pl bg-transparent"
                    placeholder="Enter latitude"
                    type="text"
                    name="directions.lat"
                    value={coordinates?.lat}
                    onChange={inputChange}
                    disabled={!editMode}
                  />
                </div>
              </Col>

              <Col xs={12} lg="3" xl="4">
                <Form.Label className="fw-600">Longitude</Form.Label>
                <div className="form-control d-flex align-items-center px-3 mb-3">
                  <input
                    className="form-control border-0 shadow-none pl-black px-0 label-pl bg-transparent"
                    placeholder="Enter longitude"
                    type="text"
                    name="directions.lon"
                    value={coordinates?.lng}
                    // disabled={!editMode}
                    onChange={inputChange}
                  />
                </div>
              </Col>

              {editMode ?
                <Col xs={12} lg={3} xl={4}>
                  <Form.Label className="fw-600">Search Location</Form.Label>
                  <div className="form-control d-flex align-items-center px-3 mb-3 select-location-main">
                    <input
                      className="form-control border-0 shadow-none px-0 text-black"
                      placeholder="Search Location"
                      onChange={changeInput}
                      onKeyUp={getAddress}
                    />
                  </div>
                </Col> : ""}

              {editMode ? (
                <Col xs={12} lg="6" xl="4">
                  {/* <Button className="w-100 h-100 bg-transparent text-dark border-dark edit-directions">
                    Edit directions manually
                  </Button> */}
                </Col>
              ) : (
                ""
              )}
            </Row>

            <Row>
              {editMode ? (
                <Col xs={12}>
                  <div className="form-control d-flex align-items-center p-0 mb-3 overflow-hidden">
                    <div style={{ height: '300px', width: '100%' }}>
                      {isLoaded &&
                        <GoogleMap
                          mapContainerStyle={containerStyle}
                          center={center}
                          zoom={10}
                          onClick={(e) => {
                            setCoordinates({
                              lat: e.latLng.lat(),
                              lng: e.latLng.lng(),
                            },
                            );
                          }}
                        >
                          <MarkerF position={{
                            lat: coordinates?.lat,
                            lng: coordinates?.lng
                          }} />
                        </GoogleMap>
                      }
                    </div>
                  </div>
                </Col>
              ) : (
                ""
              )}
            </Row>
          </Form>
        </Modal.Body>

        <Modal.Footer className="mx-md-4 border-0 bg-transparent pt-0">
          <button
            className="secondary-button text-decoration-none"
            type="button"
            onClick={(e) => {
              e.preventDefault();
              setEditMode(!editMode);
              if (editMode) {
                EditStudio();
                subImageApiFunction();
              }
            }}
          >
            {editMode ? "SAVE" : "EDIT"}
          </button>
        </Modal.Footer>
      </Modal >

      {/* //delete */}
      <Modal Modal
        size="md"
        show={smShow}
        onHide={() => setSmShow(false)}
        centered
        className="moadl-main delete-modal"
      >
        <Modal.Header
          className="d-block position-relative border-0"
          closeButton
        >
          <Modal.Title className="text-center">Delete Booth</Modal.Title>
        </Modal.Header>
        <Modal.Body className="mx-4 border-0">
          <p>Are you sure you want to delete?</p>
        </Modal.Body>
        <Modal.Footer className="border-0">
          <div className="d-flex m-auto gap-2 pb-3">
            <button
              className="secondary-button text-decoration-none"
              onClick={() => {
                deleteApi(id);
              }}
            >
              Delete
            </button>
            <Button
              className="primary-button"
              onClick={() => {
                setSmShow(false);
              }}
            >
              Cancel
            </Button>
          </div>
        </Modal.Footer>
      </Modal >

      {/* //confirm model */}
      <Modal
        size="md"
        centered
        show={editDataModel}
        onHide={() => setEditDataModel(false)}
        className="modal-main delete-modal"
      >
        <Modal.Header
          className="d-block position-relative border-0"
        >
          <Modal.Title className="text-center">Discard</Modal.Title>
        </Modal.Header>
        <Modal.Body className="mx-4 border-0">
          <p className="text-center">Are you sure you want to discard changes?</p>
        </Modal.Body>
        <Modal.Footer className="border-0">
          <div className="d-flex m-auto gap-2 pb-3">
            <button
              className="secondary-button text-decoration-none"
              onClick={() => {
                handleClose()
              }}
            >
              Discard
            </button>
            <Button
              className="primary-button"
              onClick={() => setEditDataModel(false)}
            >
              Cancel
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default StudioRentalTable;
