import React, { useContext, useEffect, useRef, useState } from 'react'
import { Col, Container, Form, Modal, Row } from 'react-bootstrap';
import Sidbar from '../layout/Sidbar';
import Header from '../layout/Header';
import { CartContext } from '../context/CartContext';
import Loader from "../components/Loader";
import { toast } from 'react-toastify';
import PartnersTable from '../components/PartnersTable';
import { getPartner, postUser } from '../controller/Api';
import PhoneInput from 'react-phone-input-2';

const Partners = () => {
    const { perPage, admin } = useContext(CartContext);
    const [page, setPage] = useState(1);
    const [search, setSearch] = useState();
    const ref = useRef(null);
    const [loading, setLoading] = useState(false);
    const [getPartnerData, setGetPartnerData] = useState();
    const [lgShow, setLgShow] = useState(false);
    const [selectedRole, setSelectedRole] = useState("admin");
    const [showPassword, setShowPassword] = useState(false);
    const [showPasswordd, setShowPasswordd] = useState(false);
    const [subAdmin, setSubAdmin] = useState({
        fullName: "",
        email: "",
        password: "",
        user_role: "",
        is_block: "",
        phone_no: "",
        address: "",
        profile_image: ""
    });
    const fileInputRef = useRef(null);

    const inputChange = (e) => {
        const { name, value, files, type } = e?.target;
        if (type === "file") {
            setSubAdmin((oldVal) => ({
                ...oldVal,
                [name]: files[0]
            }));
        } else {
            setSubAdmin((oldVal) => ({
                ...oldVal,
                [name]: value
            }))
        }
    }

    const togglePasswordVisibility = () => {
        setShowPassword((prevShowPassword) => !prevShowPassword);
    };
    const togglePasswordVisibilityy = () => {
        setShowPasswordd((prevShowPassword) => !prevShowPassword);
    };

    const openFile = () => {
        if (fileInputRef.current) {
            fileInputRef.current.click();
        }
    };

    const addSubAdminApi = async () => {
        try {
            const formData = new FormData();
            formData.append('fullName', subAdmin.fullName);
            formData.append('email', subAdmin.email);
            formData.append('password', subAdmin.password);
            formData.append('phone_no', subAdmin.phone_no);
            formData.append('address', subAdmin.address);
            formData.append('company_website', subAdmin.company_website);
            formData.append('company_name', subAdmin.company_name);
            formData.append('user_role', selectedRole);
            const result = await postUser(formData);
            const { status, message } = result
            if (status === 1) {
                setSubAdmin({
                    fullName: "",
                    email: "",
                    password: "",
                    user_role: "",
                    is_block: false,
                    phone_no: "",
                    address: "",
                    profile_image: null,
                    company_website: "",
                    company_name: ""
                });
                toast.success(message);
            } else {
                toast.error(message);
            }
        } catch (error) {
            toast.error(error);
        }
    }

    const getPartnerApiGet = async (searchVal) => {
        const pageVal = searchVal ? 1 : page;
        try {
            setLoading(true);
            const result = await getPartner(pageVal, perPage, search);
            const { message, status } = result;
            if (status === 1) {
                setGetPartnerData(result);
            } else {
                toast.error(message);
            }
        } catch (error) {
            console.log(error);
        }
        setLoading(false);
    }

    const inputHandle = (value) => {
        setSearch(value);
    }

    useEffect(() => {
        getPartnerApiGet();
    }, []);

    useEffect(() => {
        const callback = (event) => {
            if (event.key === 'Enter' && ref.current) {
                event.preventDefault();
                ref.current?.click();
            }
        };
        document.addEventListener('keydown', callback);
        return () => {
            document.removeEventListener('keydown', callback);
        };
    }, []);

    return (
        <>
            {loading && <Loader />}
            <section>
                <Container fluid>
                    <Row className="vh-100">
                        <Col
                            xs={12}
                            md={12}
                            lg={3}
                            xl={2}
                            className="shadow-right d-none d-lg-block"
                        >
                            <Sidbar />
                        </Col>
                        <Col xs={12} lg={9} xl={10} className="position-relative px-md-5">
                            <Header />
                            <img src="../images/png/bg-imag.png" className="bg-imag" alt="" />
                            <Row className="justify-content-between align-items-center pb-3">
                                <Col md={6} lg={5} xl={7}>
                                    <h1 className="title">Partners</h1>
                                </Col>
                                <Col md={6} lg={7} xl={5} className={`${admin ? "mt-3 mt-md-0 d-flex gap-2 justify-content-end" : "mt-3 mt-md-0"}`}>
                                    {/* {admin && (
                                        <Form.Select
                                            style={{ width: "200px", height: "50px" }}
                                            className="select-bg cursor-pointer"
                                            aria-label="Default select example"
                                            value={selectedSubAdminId}
                                            onChange={(e) => {
                                                setSelectedSubAdminId(e.target.value);
                                                setPage(1)
                                            }}
                                        >
                                            <option value={""}>Select Company</option>
                                            {findSubAdmin?.map((item, index) => (
                                                <option key={index} value={item._id}>
                                                    {item.fullName}
                                                </option>
                                            ))}
                                        </Form.Select>
                                    )} */}
                                    <Form>
                                        <div className="d-flex justify-content-end gap-3">
                                            <div className="form-control d-flex align-items-center px-3" style={{ width: "350px" }}>
                                                <input
                                                    className="form-control border-0 shadow-none pl-black"
                                                    placeholder="Search"
                                                    type="text"
                                                    value={search}
                                                    onChange={(e) => { inputHandle(e.target.value) }}
                                                />
                                                <img
                                                    className='cursor-pointer'
                                                    ref={ref}
                                                    src="../images/svg/search.svg"
                                                    alt="Search"
                                                    onClick={() => { getPartnerApiGet("search") }}
                                                />
                                            </div>

                                            {/* <div>
                                                <div
                                                    onClick={() => {
                                                        setLgShow(true)
                                                    }}
                                                    className="cursor-pointer"
                                                >
                                                    <img
                                                        src="../images/svg/add.svg"
                                                        className="add-btn"
                                                        alt=""
                                                    />
                                                </div>
                                            </div> */}
                                        </div>

                                    </Form>
                                </Col>
                            </Row>
                            <PartnersTable
                                page={page}
                                perPage={perPage}
                                setPage={setPage}
                                getPartnerData={getPartnerData}
                                getPartnerApiGet={getPartnerApiGet}
                            />
                        </Col>
                    </Row>
                </Container>
            </section>

            {/* Profile image */}
            <Modal
                size="lg"
                show={lgShow}
                onHide={() => {
                    setLgShow(false)
                    // setUserData({
                    //     fullName: "",
                    //     email: "",
                    //     password: "",
                    //     user_role: "",
                    //     is_block: "",
                    //     phone_no: "",
                    //     address: "",
                    //     profile_image: ""
                    // });
                    // setSelectedRole("");
                    // setSelectedImage({ profile_image: "" })
                }}
                centered
                className='moadl-main info-modal'
            >
                <Modal.Header className='d-block position-relative border-0' closeButton>
                    <Modal.Title className='text-center'>Create Partners</Modal.Title>
                </Modal.Header>
                <Modal.Body className='mx-md-4 border-0 bg-transparent pb-0'>
                    <div className='position-relative'>
                        <div className='d-flex justify-content-center m-auto edit-user'>
                            <img
                                alt=""
                                style={{ cursor: 'pointer' }}
                            />
                        </div>
                        <input
                            type="file"
                            accept=".jpg, .jpeg, .png"
                            style={{ display: 'none' }}
                            name='profile_image'
                            ref={fileInputRef}
                            onChange={inputChange}
                        />
                        <img
                            src="../images/svg/camera.svg"
                            className='camer position-absolute top-50 start-50 translate-middle'
                            alt=""
                            style={{ cursor: 'pointer' }}
                            onClick={openFile}
                        />
                    </div>

                    <Form className='mt-4'>
                        <Row>
                            <Col md={6}>
                                <div className='form-control d-flex align-items-center px-3 mb-3'>
                                    <svg
                                        width={13}
                                        height={17}
                                        viewBox="0 0 13 17"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            fillRule="evenodd"
                                            clipRule="evenodd"
                                            d="M10.7689 4.3877C10.7689 6.81102 8.8586 8.77552 6.50215 8.77552C4.14571 8.77552 2.23543 6.81102 2.23543 4.3877C2.23543 1.96437 4.14571 -0.00012207 6.50215 -0.00012207C8.8586 -0.00012207 10.7689 1.96437 10.7689 4.3877ZM10.1643 11.2483C11.5209 11.5229 12.4066 11.9711 12.7862 12.694C13.0713 13.2582 13.0713 13.9298 12.7862 14.4939C12.4066 15.2168 11.5561 15.6939 10.1502 15.9397C9.5238 16.066 8.8897 16.1481 8.25236 16.1855C7.66191 16.2505 7.07146 16.2505 6.47398 16.2505H5.39851C5.17357 16.2216 4.95567 16.2071 4.74479 16.2071C4.10744 16.1744 3.47319 16.0946 2.84691 15.9686C1.49028 15.7084 0.6046 15.2457 0.225024 14.5229C0.0784937 14.2428 0.00124302 13.93 8.97829e-05 13.612C-0.00296445 13.2921 0.07195 12.9765 0.217995 12.694C0.590542 11.9711 1.47622 11.5013 2.84691 11.2483C3.47595 11.1248 4.11236 11.0451 4.75182 11.0097C5.92156 10.9154 7.09668 10.9154 8.26642 11.0097C8.90341 11.0468 9.53737 11.1265 10.1643 11.2483Z"
                                            fill="black"
                                        />
                                    </svg>

                                    <input
                                        className='form-control border-0 shadow-none pl-black'
                                        placeholder='Enter partners name'
                                        type="text"
                                        name='fullName'
                                        value={subAdmin.fullName}
                                        onChange={inputChange}
                                    />
                                </div>
                            </Col>
                            <Col md={6}>
                                <div className='form-control d-flex align-items-center px-3 mb-3'>
                                    <svg
                                        width={18}
                                        height={15}
                                        viewBox="0 0 18 15"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            fillRule="evenodd"
                                            clipRule="evenodd"
                                            d="M2.45455 0C1.09894 0 0 1.11929 0 2.5V12.5C0 13.8807 1.09894 15 2.45455 15H15.5455C16.9011 15 18 13.8807 18 12.5V2.5C18 1.11929 16.9011 0 15.5455 0H2.45455ZM4.61472 3.5265C4.26758 3.23187 3.75166 3.27964 3.46238 3.6332C3.17311 3.98676 3.22001 4.51224 3.56714 4.80687L7.42866 8.08439C8.33892 8.85699 9.66112 8.85699 10.5714 8.08439L14.4329 4.80687C14.78 4.51224 14.8269 3.98676 14.5377 3.6332C14.2484 3.27964 13.7325 3.23187 13.3853 3.5265L9.52381 6.80402C9.22039 7.06156 8.77965 7.06156 8.47623 6.80403L4.61472 3.5265Z"
                                            fill="black"
                                        />
                                    </svg>
                                    <input
                                        className='form-control border-0 shadow-none pl-black'
                                        placeholder='Enter email'
                                        type="text"
                                        name='email'
                                        value={subAdmin.email}
                                        onChange={inputChange}
                                    />
                                </div>
                            </Col>

                            <Col md={6}>
                                <div className='form-control form-item position-relative d-flex align-items-center px-3 mb-3'>
                                    <img src="./images/svg/password.svg" className='input-password-img' alt="password.svg" />
                                    <input className='form-control border-0 shadow-none pl-black'
                                        placeholder='Enter password'
                                        type={showPassword ? 'text' : 'password'}
                                        name='password'
                                        value={subAdmin.password}
                                        onChange={inputChange}
                                    />
                                    <img
                                        src={showPassword ? "./images/svg/eye-op.svg" : "./images/svg/eye-off.svg"}
                                        className='close-eye-img'
                                        alt={showPassword ? "open-eye.svg" : "close-eye.svg"}
                                        onClick={togglePasswordVisibility}
                                    />
                                </div>
                            </Col>

                            <Col md={6}>
                                <div className='form-control form-item position-relative d-flex align-items-center px-3 mb-3'>
                                    <img src="./images/svg/password.svg" className='input-password-img' alt="password.svg" />
                                    <input className='form-control border-0 shadow-none pl-black'
                                        placeholder='Enter confirm password'
                                        type={showPasswordd ? 'text' : 'password'}
                                        name='confirm_password'
                                        value={subAdmin.confirm_password}
                                        onChange={inputChange}
                                    />
                                    <img
                                        src={showPasswordd ? "./images/svg/eye-op.svg" : "./images/svg/eye-off.svg"}
                                        className='close-eye-img'
                                        alt={showPasswordd ? "open-eye.svg" : "close-eye.svg"}
                                        onClick={togglePasswordVisibilityy}
                                    />
                                </div>
                            </Col>
                            {/* <Col md={6}>
                                <div className="form-control d-flex justify-content-between align-items-center px-3 mb-3" style={{ padding: "12px 0" }}>
                                    <div className="d-flex gap-2">
                                        Status
                                        <div class="form-switch userTable d-flex justify-content-between">
                                            <input
                                                class="form-check-input"
                                                type="checkbox"
                                                name="is_block"
                                                placeholder='Status'
                                                role="switch"
                                                id="flexSwitchCheckChecked"
                                                checked={isActive}
                                                value={userData.is_block}
                                                onChange={() => toggleButton()}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </Col> */}
                            {/* <Col md={6}>
                                <div className='form-control d-flex align-items-center px-3 mb-3'>
                                    <input className='form-control border-0 shadow-none pl-black px-0' placeholder='Status: Confirmed' 
                                    type="text"
                                        name='status' value={userData.status} onChange={inputValChange}
                                    />
                                </div>
                            </Col> */}
                            <Col md={6}>
                                <div className='form-control d-flex align-items-center px-3 mb-3 number'>
                                    <PhoneInput
                                        inputClass="form-control border-0 shadow-none pl-black"
                                        country="ae"
                                        name="phone_no"
                                        value={subAdmin.phone_no}
                                        onChange={(e) => setSubAdmin((oldVal) => ({
                                            ...oldVal,
                                            phone_no: e
                                        }))}
                                        enableSearch={true}
                                        disableSearchIcon={false}
                                    />
                                </div>
                            </Col>
                            <Col md={6}>
                                <div className='form-control d-flex align-items-center px-3 mb-3'>
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width={14}
                                        height={18}
                                        viewBox="0 0 14 18"
                                        fill="none"
                                    >
                                        <path
                                            d="M6.98431 18C4.13135 15.5371 1.52101 12.9406 0.358069 9.27541C-0.913634 5.25125 1.31185 1.22708 5.29428 0.216864C10.2221 -1.03547 14.8404 3.331 13.8699 8.29859C13.6273 9.55092 13.1504 10.7031 12.5062 11.8051C11.0922 14.2096 9.16796 16.1799 6.98431 18ZM7.00941 3.66496C5.16879 3.65661 3.66282 5.15106 3.66282 6.98781C3.66282 8.80787 5.14369 10.3107 6.95921 10.319C8.82493 10.3357 10.3309 8.83292 10.3309 6.97947C10.3225 5.16776 8.82493 3.6733 7.00941 3.66496Z"
                                            fill="black"
                                        />
                                    </svg>
                                    <input className='form-control border-0 shadow-none pl-black address-ip'
                                        placeholder='Enter address'
                                        type="text"
                                        name='address'
                                        value={subAdmin.address}
                                        onChange={inputChange}
                                    />
                                </div>
                            </Col>
                            <Col md={6}>
                                <div className='form-control d-flex align-items-center px-3 mb-3'>
                                    <input className='form-control border-0 shadow-none pl-black address-ip'
                                        placeholder='Enter Company Website'
                                        type="url"
                                        name='company_website'
                                        value={subAdmin.company_website}
                                        onChange={inputChange}
                                    />
                                </div>
                            </Col>
                            <Col md={6}>
                                <div className='form-control d-flex align-items-center px-3 mb-3'>
                                    <input className='form-control border-0 shadow-none pl-black address-ip'
                                        placeholder='Enter Company Name'
                                        type="text"
                                        name='company_name'
                                        value={subAdmin.company_name}
                                        onChange={inputChange}
                                    />
                                </div>
                            </Col>
                            <Col md={6}>
                                <Form.Select
                                    style={{ width: "347px", height: "50px" }}
                                    className="select-bg cursor-pointer"
                                    aria-label="Default select example"
                                    value={selectedRole}
                                    onChange={(e) => setSelectedRole(e.target.value)}
                                >
                                    <option value={""}>Select Role</option>
                                    <option value={"subAdmin"}>Sub Admin</option>
                                </Form.Select>
                            </Col>
                        </Row>
                    </Form>
                </Modal.Body>
                <Modal.Footer className='mx-md-4 border-0 bg-transparent pt-0'>
                    <button ref={ref} className='secondary-button text-decoration-none'
                        onClick={addSubAdminApi}
                    >
                        Add
                    </button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default Partners