import { useState, useRef, useContext, useEffect } from 'react';
import { Container, Row, Col, Form } from 'react-bootstrap';
import Sidbar from '../layout/Sidbar';
import Header from './../layout/Header';
import Modal from 'react-bootstrap/Modal';
import UserTable from '../components/UserTable';
import { postUser } from "../controller/Api";
import { toast } from 'react-toastify';
import { CartContext } from '../context/CartContext';
import { getUser } from "../controller/Api";
import { editUser } from "../controller/Api";
import PhoneInput from 'react-phone-input-2';
import "react-phone-input-2/lib/style.css";
import Loader from "../components/Loader";
import * as XLSX from 'xlsx';
import moment from 'moment';

function UserDetails() {
    const ref = useRef(null);
    const { userDataShow, setUserDataShow } = useContext(CartContext);
    const fileInputRef = useRef(null);
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const ahandleClose = () => setShoww(false);
    const ahandleShow = () => setShoww(true);
    const [lgShow, setLgShow] = useState(false);
    const [selectedImage, setSelectedImage] = useState(null);
    const [showPassword, setShowPassword] = useState(false);
    const [showPasswordd, setShowPasswordd] = useState(false);
    const [loading, setLoading] = useState(false);
    const [showw, setShoww] = useState(false);
    const [sortType, setSortType] = useState("asc");
    const [selectedUserRole, setSelectedUserRole] = useState('');
    const [check, setCheck] = useState();
    const [userId, setUserId] = useState();
    const [search, setSearch] = useState("");
    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(10);
    const [sortByKey, setSortByKey] = useState("");
    const [selectedRole, setSelectedRole] = useState("admin");
    const [userData, setUserData] = useState({
        fullName: "",
        email: "",
        password: "",
        confirm_password: "",
        user_role: "subAdmin",
        is_block: false,
        phone_no: "",
        address: "",
        company_website: "",
        company_name: ""
    });
    
    const [editshowData, setEditShowData] = useState({
        fullName: "",
        email: "",
        user_role: "subAdmin",
        status: "",
        phone_no: "",
        address: "",
        company_website: "",
        company_name:""
    });

    const isValidURL = (url) => {
        const urlPattern = /^(ftp|http|https):\/\/[^ "]+$/;
        return urlPattern.test(url);
    };

    const userSubmit = async (e) => {
        e.preventDefault();
        if (userData.fullName.trim() === "") {
            toast.error("Name is required");
            return;
        }
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailRegex.test(userData.email.trim().toLowerCase())) {
            toast.error("Please enter a valid email address.");
            return;
        }
        if (userData.password.trim().length < 8) {
            toast.error("Password must be at least 8 characters long");
            return;
        }
        if (userData.password !== userData.confirm_password) {
            toast.error("Passwords do not match");
            return;
        }
        if (!isValidURL(userData.company_website)) {
            toast.error("Please enter a valid URL");
            return;
        }
        try {
            const formData = new FormData();
            formData.append('fullName', userData.fullName);
            formData.append('email', userData.email);
            formData.append('password', userData.password);
            formData.append('is_block', false);
            formData.append('phone_no', userData.phone_no);
            formData.append('address', userData.address);
            formData.append('profile_image', userData.profile_image);
            formData.append('company_website', userData.company_website);
            formData.append('company_name', userData.company_name);
            formData.append('user_role', selectedRole);
            const result = await postUser(formData);
            const { status, message } = result
            if (status === 1) {
                setUserData({
                    fullName: "",
                    email: "",
                    password: "",
                    user_role: "",
                    is_block: false,
                    phone_no: "",
                    address: "",
                    profile_image: null,
                    company_website: "",
                    company_name:""
                });
                setSelectedImage({ selectedImage: "" });
                toast.success(message);
                getDataUser();
                setLgShow(false);
            } else {
                toast.error(message);
            }
        } catch (error) {
            toast.error(error);
        }
        setLoading(false);
    }
    //Edit user
    const userEditSubmit = async (e) => {
        if (editshowData.fullName.trim() === "") {
            toast.error("Name is required");
            return;
        }
        if (!editshowData.email.trim().toLowerCase()) {
            toast.error("Please enter a valid mail address.");
            return;
        }
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailRegex.test(editshowData.email.trim().toLowerCase())) {
            toast.error("Please enter a valid email address.");
            return;
        }
        if (editshowData.password?.length < 8) {
            toast.error("Password must be at least 8 characters long");
            return;
        }
        e.preventDefault();
        try {
            // setLoading(true);
            const formData = new FormData();
            formData.append('fullName', editshowData.fullName);
            // formData.append('email', editshowData.email);
            // formData.append('phone_no', editshowData.phone_no);
            // formData.append('address', editshowData.address);
            formData.append('profile_image', editshowData.profile_image);
            formData.append('company_website', editshowData.company_website);
            formData.append('company_name', editshowData.company_name);
            // formData.append('user_role', selectedRole);
            // formData.append('teacher_verified', check);

            const result = await editUser(userId, formData);
            const { status, message } = result
            if (status === 1) {
                toast.success("User has been updated successfully");
                setShoww(false);
                getDataUser();
            } else {
                toast.error(message);
            }
        } catch (error) {
            toast.error(error);
        }
        // setLoading(false);
    }

    // User get api
    const getDataUser = async (searchVal, sortByKey) => {
        const pageVal = searchVal ? 1 : page;
        try {
            setLoading(true);
            const user = await getUser(perPage, pageVal, search, selectedUserRole, sortType, sortByKey);
            const { status, page, message } = user;
            if (status === 1) {
                setUserDataShow(user);
                setPage(page);
            } else {
                toast.error(message);
            }
        } catch (error) {
            toast.error(error);
        }
        setLoading(false);
    };

    //Asc & desc Order
    const userOrder = (key) => {
        const order = sortType === 'asc' ? 'desc' : 'asc';
        setSortType(order);
        setSortByKey(key);
    }

    const inputValChange = (e) => {
        const { name, value } = e.target;
        setUserData((oldVal) => {
            return {
                ...oldVal,
                [name]: value
            }
        });
    }

    const inputHandle = (e) => {
        setSearch(e.target.value);
    }

    //set image
    const handleImageClick = () => {
        fileInputRef.current.click();
    };

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setSelectedImage(reader.result);
            };
            reader.readAsDataURL(file);
            setUserData({
                ...userData,
                [e.target.name]: e.target.files[0],
            });
        }
    };

    //user table
    const inputValchange = (e) => {
        const { name, value } = e.target;
        setEditShowData((oldVal) => {
            return {
                ...oldVal,
                [name]: value
            }
        });
    }

    useEffect(() => {
        document.title = "Music Booth | User"
        getDataUser("", sortByKey);
    }, [setUserDataShow, page, sortByKey, sortType]);

    useEffect(() => {
        getDataUser("search");
    }, [selectedUserRole, perPage]);

    // useEffect(() => {
    //     getDataUser();
    // }, [perPage]);

    // useEffect(() => {
    //     document.title = "Music Booth | User"
    //     getDataUser("search", sortByKey);
    // }, [setUserDataShow, page, sortByKey, sortType, perPage, selectedUserRole]);

    useEffect(() => {
        const callback = (event) => {
            if (event.key === 'Enter') {
                event.preventDefault();
                ref.current?.click();
            }
        };
        document.addEventListener('keydown', callback);
        return () => {
            document.removeEventListener('keydown', callback);
        };
    }, []);

    const togglePasswordVisibility = () => {
        setShowPassword((prevShowPassword) => !prevShowPassword);
    };
    const togglePasswordVisibilityy = () => {
        setShowPasswordd((prevShowPassword) => !prevShowPassword);
    };

    //XLSX file
    const excelDataShow = (userDataShow) => {
        return userDataShow?.data?.map((element) => {
            const { _id, email, fullName, tagline, specialization, teacher_profile_visible, user_role, city, created_at, gender, gigs, instrument, language, phone_no, pricing, teacher_type } = element;
            const formattedCreatedAt = moment(created_at).format('YYYY-MM-DD');
            return {
                Id: _id || "",
                Create_At: formattedCreatedAt || "",
                FullName: fullName || "",
                Email: email || "",
                City: city || "",
                Gender: gender === 0 ? "Male" : gender === 1 ? "Female" : "",
                Gigs: gigs === true ? "Yes" : gigs === false ? "No" : "",
                Language: language?.[0] || "",
                Phone_no: phone_no || "",
                Pricing: pricing ? `${pricing} AED` : "0 AED",
                Specialization: specialization || "",
                Tagline: tagline || "",
                Teacher_Type: teacher_type || "",
                Teacher_Profile_Visible: teacher_profile_visible ? "Yes" : "No",
                Instrument: instrument[0] || "",
                Role: user_role || "",
            };
        }
        ) || [];
    };

    const convertToSheet = (userDataShow) => {
        const sheet = XLSX.utils.json_to_sheet(userDataShow);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, sheet, "Sheet1");
        return workbook;
    };

    const downloadExcel = (e, userDataShow) => {
        e.preventDefault();
        const result = excelDataShow(userDataShow);
        const workbook = convertToSheet(result);
        const today = new Date();
        const formattedDate = today.toISOString().split('T')[0];
        const filename = `Music Booth User Details - ${formattedDate}.xlsx`;
        XLSX.writeFile(workbook, filename);
    };

    return (
        <>
            {loading && <Loader />}
            <section>
                <Container fluid>
                    <Row className='vh-100'>
                        <Col xs={12} md={12} lg={3} xl={2} className='shadow-right d-none d-lg-block'>
                            <Sidbar />
                        </Col>
                        <Col xs={12} lg={9} xl={10} className='position-relative px-md-5'>
                            <Header />
                            <img src="../images/png/bg-imag.png" className='bg-imag' alt="" />
                            <Row className='justify-content-between align-items-center pb-3 '>
                                <Col md={4} lg={5} xl={6}>
                                    <h1 className='title'>User Overview</h1>
                                </Col>
                                <Col md={6} lg={7} xl={6} className='mt-3 mt-md-0'>
                                    <Form className=''>
                                        <div className='d-flex justify-content-end align-items-center gap-3'>
                                            <div className='form-control d-flex align-items-center px-3' style={{ height: "44px", width: "350px" }}>
                                                <input className='form-control border-0 shadow-none pl-black'
                                                    placeholder='Search'
                                                    type="text"
                                                    value={search}
                                                    onChange={inputHandle}
                                                />
                                                <img className='cursor-pointer' ref={ref} onClick={() => getDataUser("search", "date")} src="../images/svg/search.svg" alt="Search" />
                                            </div>
                                            <div
                                                onClick={() => setLgShow(true)}
                                                className="cursor-pointer d-flex align-items-center text-nowrap" >
                                                <div style={{ height: "44px", background: "#B99E6E", color: '#fff', fontWeight: 700, paddingLeft: "10px", borderRadius: "5px" }}>Add User
                                                    <img
                                                        style={{ height: "44px" }}
                                                        src="../images/svg/add.svg"
                                                        className="add-btn"
                                                        alt=""
                                                    />
                                                </div>
                                            </div>

                                            <div className='d-flex justify-content-end '>
                                                <button style={{ width: "200px", height: "44px" }}
                                                    onClick={(e) => downloadExcel(e, userDataShow)}
                                                    className='secondary-button text-decoration-none '>
                                                    Export to Excel
                                                </button>
                                            </div>
                                            <div className="">
                                                <Form.Select
                                                    style={{ width: "80px", height: "44px" }}
                                                    className="select-bg cursor-pointer"
                                                    aria-label="Default select example"
                                                    value={perPage}
                                                    onChange={(e) => setPerPage(e.target.value)}
                                                >
                                                    <option value={10}>10</option>
                                                    <option value={20}>20</option>
                                                    <option value={30}>30</option>
                                                    <option value={50}>50</option>
                                                    <option value={100}>100</option>
                                                </Form.Select>
                                            </div>


                                        </div>
                                    </Form>
                                </Col>
                            </Row>
                            <UserTable
                                page={page}
                                setPage={setPage}
                                search={search}
                                selectedUserRole={selectedUserRole}
                                setSelectedUserRole={setSelectedUserRole}
                                inputValchange={inputValchange}
                                getDataUser={getDataUser}
                                setUserId={setUserId}
                                userId={userId}
                                check={check}
                                setCheck={setCheck}
                                setEditShowData={setEditShowData}
                                editshowData={editshowData}
                                userEditSubmit={userEditSubmit}
                                perPage={perPage}
                                showw={showw}
                                setShoww={setShoww}
                                loading={loading}
                                setLoading={setLoading}
                                userOrder={userOrder}
                            />
                        </Col>
                    </Row>
                </Container>
            </section>
            {/* Add user modal */}
            <Modal
                size="lg"
                show={lgShow}
                onHide={() => {
                    setLgShow(false)
                    setUserData({
                        fullName: "",
                        email: "",
                        password: "",
                        user_role: "",
                        is_block: "",
                        phone_no: "",
                        address: "",
                        profile_image: ""
                    });
                    setSelectedRole("");
                    setSelectedImage({ profile_image: "" })
                }}
                centered
                className='moadl-main info-modal'
            >
                <Modal.Header className='d-block position-relative border-0' closeButton>
                    <Modal.Title className='text-center'>Create User</Modal.Title>
                </Modal.Header>
                <Modal.Body className='mx-md-4 border-0 bg-transparent pb-0'>
                    <div className='position-relative'>
                        <div className='d-flex justify-content-center m-auto edit-user'>
                            <img
                                src={selectedImage}
                                alt=""
                                onClick={handleImageClick}
                                style={{ cursor: 'pointer' }}
                            />
                        </div>
                        <input
                            type="file"
                            accept=".jpg, .jpeg, .png"
                            ref={fileInputRef}
                            style={{ display: 'none' }}
                            name='profile_image'
                            onChange={handleFileChange}
                        />
                        <img
                            src="../images/svg/camera.svg"
                            className='camer position-absolute top-50 start-50 translate-middle'
                            alt=""
                            onClick={handleImageClick}
                            style={{ cursor: 'pointer' }}
                        />
                    </div>

                    <Form className='mt-4'>
                        <Row>
                            <Col md={6}>
                                <div className='form-control d-flex align-items-center px-3 mb-3'>
                                    <svg
                                        width={13}
                                        height={17}
                                        viewBox="0 0 13 17"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            fillRule="evenodd"
                                            clipRule="evenodd"
                                            d="M10.7689 4.3877C10.7689 6.81102 8.8586 8.77552 6.50215 8.77552C4.14571 8.77552 2.23543 6.81102 2.23543 4.3877C2.23543 1.96437 4.14571 -0.00012207 6.50215 -0.00012207C8.8586 -0.00012207 10.7689 1.96437 10.7689 4.3877ZM10.1643 11.2483C11.5209 11.5229 12.4066 11.9711 12.7862 12.694C13.0713 13.2582 13.0713 13.9298 12.7862 14.4939C12.4066 15.2168 11.5561 15.6939 10.1502 15.9397C9.5238 16.066 8.8897 16.1481 8.25236 16.1855C7.66191 16.2505 7.07146 16.2505 6.47398 16.2505H5.39851C5.17357 16.2216 4.95567 16.2071 4.74479 16.2071C4.10744 16.1744 3.47319 16.0946 2.84691 15.9686C1.49028 15.7084 0.6046 15.2457 0.225024 14.5229C0.0784937 14.2428 0.00124302 13.93 8.97829e-05 13.612C-0.00296445 13.2921 0.07195 12.9765 0.217995 12.694C0.590542 11.9711 1.47622 11.5013 2.84691 11.2483C3.47595 11.1248 4.11236 11.0451 4.75182 11.0097C5.92156 10.9154 7.09668 10.9154 8.26642 11.0097C8.90341 11.0468 9.53737 11.1265 10.1643 11.2483Z"
                                            fill="black"
                                        />
                                    </svg>

                                    <input
                                        className='form-control border-0 shadow-none pl-black'
                                        placeholder='Enter User name'
                                        type="text"
                                        name='fullName'
                                        value={userData.fullName}
                                        onChange={inputValChange}
                                    />
                                </div>
                            </Col>
                            <Col md={6}>
                                <div className='form-control d-flex align-items-center px-3 mb-3'>
                                    <svg
                                        width={18}
                                        height={15}
                                        viewBox="0 0 18 15"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                    >
                                        <path
                                            fillRule="evenodd"
                                            clipRule="evenodd"
                                            d="M2.45455 0C1.09894 0 0 1.11929 0 2.5V12.5C0 13.8807 1.09894 15 2.45455 15H15.5455C16.9011 15 18 13.8807 18 12.5V2.5C18 1.11929 16.9011 0 15.5455 0H2.45455ZM4.61472 3.5265C4.26758 3.23187 3.75166 3.27964 3.46238 3.6332C3.17311 3.98676 3.22001 4.51224 3.56714 4.80687L7.42866 8.08439C8.33892 8.85699 9.66112 8.85699 10.5714 8.08439L14.4329 4.80687C14.78 4.51224 14.8269 3.98676 14.5377 3.6332C14.2484 3.27964 13.7325 3.23187 13.3853 3.5265L9.52381 6.80402C9.22039 7.06156 8.77965 7.06156 8.47623 6.80403L4.61472 3.5265Z"
                                            fill="black"
                                        />
                                    </svg>
                                    <input className='form-control border-0 shadow-none pl-black' placeholder='Enter email' type="text" name='email' value={userData.email} onChange={inputValChange} />
                                </div>
                            </Col>

                            <Col md={6}>
                                <div className='form-control form-item position-relative d-flex align-items-center px-3 mb-3'>
                                    <img src="./images/svg/password.svg" className='input-password-img' alt="password.svg" />
                                    <input className='form-control border-0 shadow-none pl-black'
                                        placeholder='Enter password'
                                        type={showPassword ? 'text' : 'password'}
                                        name='password'
                                        value={userData.password}
                                        onChange={inputValChange}
                                    />
                                    <img
                                        src={showPassword ? "./images/svg/eye-op.svg" : "./images/svg/eye-off.svg"}
                                        className='close-eye-img'
                                        alt={showPassword ? "open-eye.svg" : "close-eye.svg"}
                                        onClick={togglePasswordVisibility}
                                    />
                                </div>
                            </Col>

                            <Col md={6}>
                                <div className='form-control form-item position-relative d-flex align-items-center px-3 mb-3'>
                                    <img src="./images/svg/password.svg" className='input-password-img' alt="password.svg" />
                                    <input className='form-control border-0 shadow-none pl-black'
                                        placeholder='Enter confirm password'
                                        type={showPasswordd ? 'text' : 'password'}
                                        name='confirm_password'  // Use a different name for the confirm password field
                                        value={userData.confirm_password}
                                        onChange={inputValChange}  // Update userData.confirm_password
                                    />
                                    <img
                                        src={showPasswordd ? "./images/svg/eye-op.svg" : "./images/svg/eye-off.svg"}
                                        className='close-eye-img'
                                        alt={showPasswordd ? "open-eye.svg" : "close-eye.svg"}
                                        onClick={togglePasswordVisibilityy}
                                    />
                                </div>
                            </Col>
                            {/* <Col md={6}>
                                <div className='form-control d-flex align-items-center px-3 mb-3'>
                                    <select
                                        className='form-control border-0 shadow-none  pl-black px-10'
                                        name='user_role'
                                        value={userData.user_role}
                                        onChange={inputValChange}
                                    >
                                        <option value='' disabled>Select user role</option>
                                        {roles.map((role) => (
                                            <option key={role} value={role}>
                                                {role}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                            </Col> */}
                            {/* <Col md={6}>
                                <div className="form-control d-flex justify-content-between align-items-center px-3 mb-3" style={{ padding: "12px 0" }}>
                                    <div className="d-flex gap-2">
                                        Status
                                        <div class="form-switch userTable d-flex justify-content-between">
                                            <input
                                                class="form-check-input"
                                                type="checkbox"
                                                name="is_block"
                                                placeholder='Status'
                                                role="switch"
                                                id="flexSwitchCheckChecked"
                                                checked={isActive}
                                                value={userData.is_block}
                                                onChange={() => toggleButton()}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </Col> */}
                            {/* <Col md={6}>
                                <div className='form-control d-flex align-items-center px-3 mb-3'>
                                    <input className='form-control border-0 shadow-none pl-black px-0' placeholder='Status: Confirmed' 
                                    type="text"
                                        name='status' value={userData.status} onChange={inputValChange}
                                    />
                                </div>
                            </Col> */}
                            <Col md={6}>
                                <div className='form-control d-flex align-items-center px-3 mb-3 number'>
                                    
                                    <PhoneInput
                                        inputClass="form-control border-0 shadow-none pl-black"
                                        country="ae"
                                        value={userData.phone_no}
                                        onChange={phone => {
                                            inputValChange({ target: { name: 'phone_no', value: phone } });
                                        }}
                                        enableSearch={true}
                                        disableSearchIcon={false}
                                    />
                                </div>
                            </Col>
                            <Col md={6}>
                                <div className='form-control d-flex align-items-center px-3 mb-3'>
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width={14}
                                        height={18}
                                        viewBox="0 0 14 18"
                                        fill="none"
                                    >
                                        <path
                                            d="M6.98431 18C4.13135 15.5371 1.52101 12.9406 0.358069 9.27541C-0.913634 5.25125 1.31185 1.22708 5.29428 0.216864C10.2221 -1.03547 14.8404 3.331 13.8699 8.29859C13.6273 9.55092 13.1504 10.7031 12.5062 11.8051C11.0922 14.2096 9.16796 16.1799 6.98431 18ZM7.00941 3.66496C5.16879 3.65661 3.66282 5.15106 3.66282 6.98781C3.66282 8.80787 5.14369 10.3107 6.95921 10.319C8.82493 10.3357 10.3309 8.83292 10.3309 6.97947C10.3225 5.16776 8.82493 3.6733 7.00941 3.66496Z"
                                            fill="black"
                                        />
                                    </svg>
                                    <input className='form-control border-0 shadow-none pl-black address-ip'
                                        placeholder='Enter address'
                                        type="text"
                                        name='address'
                                        value={userData.address}
                                        onChange={inputValChange}
                                    />
                                </div>
                            </Col>
                            <Col md={6}>
                                <div className='form-control d-flex align-items-center px-3 mb-3'>
                                    <input className='form-control border-0 shadow-none pl-black address-ip'
                                        placeholder='Enter Company Website'
                                        type="url"
                                        name='company_website'
                                        value={userData.company_website}
                                        onChange={inputValChange}
                                    />
                                </div>
                            </Col>
                            <Col md={6}>
                                <div className='form-control d-flex align-items-center px-3 mb-3'>
                                    <input className='form-control border-0 shadow-none pl-black address-ip'
                                        placeholder='Enter Company Name'
                                        type="text"
                                        name='company_name'
                                        value={userData.company_name}
                                        onChange={inputValChange}
                                    />
                                </div>
                            </Col>
                            <Col md={6}>
                                <Form.Select
                                    style={{ width: "347px", height: "50px" }}
                                    className="select-bg cursor-pointer"
                                    aria-label="Default select example"
                                    value={selectedRole}
                                    onChange={(e) => setSelectedRole(e.target.value)}
                                >
                                    <option value={""}>Select Role</option>
                                    <option value={"admin"}>Admin</option>
                                    <option value={"subAdmin"}>Sub Admin</option>
                                </Form.Select>
                            </Col>
                        </Row>
                    </Form>
                </Modal.Body>
                <Modal.Footer className='mx-md-4 border-0 bg-transparent pt-0'>
                    <button ref={ref} className='secondary-button text-decoration-none' onClick={userSubmit} >
                        Add
                    </button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default UserDetails