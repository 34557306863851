import React, { useEffect, useRef } from "react";
import { Col, Container, Form, Modal, Row, Table } from "react-bootstrap";
import Header from "../layout/Header";
import Sidbar from "../layout/Sidbar";
import PricingTable from "../components/PricingTable";
import { useState } from "react";
import { addPricing, getPricing, getSubAdmin } from "../controller/Api";
import { toast } from "react-toastify";
import Loader from "../components/Loader";
import { useContextData } from "../context/CartContext";

const Pricing = () => {
    const { admin } = useContextData();
    const initialWeekday = [
        {
            day: "Monday",
            slots: [{ startTime: "00:00", endTime: "00:00", price: 0 }],
        },
        {
            day: "Tuesday",
            slots: [{ startTime: "00:00", endTime: "00:00", price: 0 }],
        },
        {
            day: "Wednesday",
            slots: [{ startTime: "00:00", endTime: "00:00", price: 0 }],
        },
        {
            day: "Thursday",
            slots: [{ startTime: "00:00", endTime: "00:00", price: 0 }],
        },
        {
            day: "Friday",
            slots: [{ startTime: "00:00", endTime: "00:00", price: 0 }],
        },
        {
            day: "Saturday",
            slots: [{ startTime: "00:00", endTime: "00:00", price: 0 }],
        },
        {
            day: "Sunday",
            slots: [{ startTime: "00:00", endTime: "00:00", price: 0 }],
        },
    ];
    const [pricingAdd, setPricingAdd] = useState();
    const [search, setSearch] = useState("");
    const [weekDays, setWeekDays] = useState(initialWeekday);
    const [dayIndex, setDayIndex] = useState(0);
    const [lgShow, setLgShow] = useState(false);
    const [weekDayTitle, setWeekDayTitle] = useState("Monday");
    const [name, setName] = useState("");
    const [desc, setDesc] = useState();
    const ref = useRef(null);
    const [timeRows, setTimeRows] = useState([]);
    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(10);
    const [loading, setLoading] = useState(false);
    const [findSubAdmin, setFindSubAdmin] = useState([]);
    const [selectedSubAdminId, setSelectedSubAdminId] = useState();
    const [selectedSubAdmin, setSelectedSubAdmin] = useState();

    // const [time, setTime] = useState({
    //     startTime: "",
    //     endTime: "",
    //     price: "",
    // });

    //Add api call
    const addPricingFlow = async (e) => {
        e.preventDefault();
        try {
            if (name.trim() === "") {
                toast.error("Name is required");
                return;
            }
            const result = await addPricing(name, desc, weekDays, selectedSubAdmin);
            const { status, message } = result;
            if (status === 1) {
                toast.success(message);
                setName("");
                setDesc("");
                setLgShow(false);
                getPricingFlow();
                setWeekDays(initialWeekday)
            } else {
                toast.error(message);
            }
        } catch (error) {
            console.log(error);
        }
    };

    //Get Api call
    const getPricingFlow = async () => {
        try {
            setLoading(true);
            const result = await getPricing(page, perPage, search, selectedSubAdminId);
            const { status, message } = result;
            if (status === 1) {
                setPricingAdd(result);
                setPage(page);
            } else {
                toast.error(message);
            }
        } catch (error) {
            console.log(error);
        }
        setLoading(false);
    };

    //get sub admin data
    const getSubAdminPricing = async () => {
        try {
            const result = await getSubAdmin();
            const { status, data } = result;
            if (status === 1) {
                setFindSubAdmin(data)
            }
        } catch (error) {
            console.log(error);
        }
    }


    const inputValChange = (e, key) => {
        const { name, value } = e.target;
        if (name === "price" && /^\d*\.?\d*$/.test(value)) {
            const updatedWeekdaySlots = [...weekDays];
            updatedWeekdaySlots[dayIndex].slots[key][name] = value;
            setWeekDays(updatedWeekdaySlots);
        } else if (name !== "price") {
            const updatedWeekdaySlots = [...weekDays];
            updatedWeekdaySlots[dayIndex].slots[key][name] = value;
            setWeekDays(updatedWeekdaySlots);
        }
    };

    const handleAddSlot = (e) => {
        const newSlot = { startTime: "00:00", endTime: "00:00", price: 0 };
        const updatedWeekdaySlots = [...weekDays];
        updatedWeekdaySlots[dayIndex]?.slots?.push(newSlot);
        setWeekDays(updatedWeekdaySlots);
    };

    const openTableDays = (e, day, index) => {
        e.preventDefault();
        setWeekDayTitle(day);
        setDayIndex(index);
    };

    const inputChange = (e) => {
        const { value } = e.target;
        setName(value);
    };

    const inputChanges = (e) => {
        const { value } = e.target;
        setDesc(value);
    };

    const inputHandle = (e) => {
        setSearch(e.target.value);
        setPage(1);
    }

    const deleteRow = (i) => {
        const updatedWeekdaySlots = [...weekDays];
        updatedWeekdaySlots[dayIndex].slots.splice(i, 1);
        setWeekDays(updatedWeekdaySlots)
    };

    useEffect(() => {
        const callback = (event) => {
            if (event.key === 'Enter') {
                event.preventDefault();
                ref.current?.click();
            }
        };
        document.addEventListener('keydown', callback);
        return () => {
            document.removeEventListener('keydown', callback);
        };
    }, []);

    useEffect(() => {
        getPricingFlow();
        document.title = "Music Booth | Pricing Schema"
        admin && getSubAdminPricing();
    }, [page, selectedSubAdminId]);

    const timeSlot = [
        "00:00",
        "01:00",
        "02:00",
        "03:00",
        "04:00",
        "05:00",
        "06:00",
        "07:00",
        "08:00",
        "09:00",
        "10:00",
        "11:00",
        "12:00",
        "13:00",
        "14:00",
        "15:00",
        "16:00",
        "17:00",
        "18:00",
        "19:00",
        "20:00",
        "21:00",
        "22:00",
        "23:00",
        "24:00"
    ];

    const addTimeRow = () => {
        const newRow = {
            startTime: "",
            endTime: "",
            price: "",
        };
        setTimeRows([...timeRows, newRow]);
    };

    return (
        <>
            {loading && <Loader />}
            <section>
                <Container fluid>
                    <Row className="vh-100">
                        <Col
                            xs={12}
                            md={12}
                            lg={3}
                            xl={2}
                            className="shadow-right d-none d-lg-block"
                        >
                            <Sidbar />
                        </Col>
                        <Col xs={12} lg={9} xl={10} className="position-relative px-md-5">
                            <Header />
                            <img src="../images/png/bg-imag.png" className="bg-imag" alt="" />
                            <Row className="justify-content-between align-items-center pb-3">
                                <Col md={6} lg={5} xl={7}>
                                    <h1 className="title">Pricing Schema</h1>
                                </Col>
                                <Col md={6} lg={7} xl={5} className={`${admin ? "mt-3 mt-md-0 d-flex gap-2 justify-content-end" : "mt-3 mt-md-0"}`}>
                                    {admin &&
                                        <Form.Select
                                            style={{ width: "200px", height: "50px" }}
                                            className="select-bg cursor-pointer"
                                            aria-label="Default select example"
                                            value={selectedSubAdminId}
                                            onChange={(e) => { setSelectedSubAdminId(e.target.value); setPage(1) }}
                                        >
                                            <option value={""}>Select Company</option>
                                            {findSubAdmin?.map((item, index) => (
                                                <option key={index} value={item._id}>
                                                    {item.fullName}
                                                </option>
                                            ))}
                                        </Form.Select>
                                    }
                                    <Form>
                                        <div className="d-flex justify-content-end gap-3">
                                            <div
                                                className="form-control d-flex align-items-center px-3"
                                                style={{ width: "350px" }}
                                            >
                                                <input
                                                    className="form-control border-0 shadow-none pl-black"
                                                    placeholder="Search"
                                                    type="text"
                                                    value={search}
                                                    onChange={inputHandle}
                                                />
                                                <img
                                                    className='cursor-pointer'
                                                    ref={ref}
                                                    src="../images/svg/search.svg"
                                                    alt="Search"
                                                    onClick={getPricingFlow}
                                                />
                                            </div>
                                            <div
                                                onClick={() => setLgShow(true)}
                                                className="cursor-pointer"
                                            >
                                                <img
                                                    src="../images/svg/add.svg"
                                                    className="add-btn"
                                                    alt=""
                                                />
                                            </div>
                                        </div>
                                    </Form>
                                </Col>
                            </Row>
                            <PricingTable
                                admin={admin}
                                findSubAdmin={findSubAdmin}
                                setPerPage={setPerPage}
                                perPage={perPage}
                                setPage={setPage}
                                page={page}
                                getPricingFlow={getPricingFlow}
                                pricingAdd={pricingAdd}
                                setPricingAdd={setPricingAdd}
                            />
                        </Col>
                    </Row>
                </Container>
            </section>
            <Modal
                size="xl"
                show={lgShow}
                onHide={() => {
                    setLgShow(false);
                    setName("");
                    setDesc("");
                    setWeekDays(initialWeekday)
                }}
                centered
                className="moadl-main info-modal"
            >
                <Modal.Header
                    className="d-block position-relative border-0"
                    closeButton
                >
                    <Modal.Title className="text-center">Add Pricing</Modal.Title>
                </Modal.Header>
                <Modal.Body className="mx-md-4 border-0 bg-transparent pb-0">
                    <Form className="mt-4">
                        <Row>
                            <Col xl={12} md={12}>
                                {admin &&
                                    <Form.Select
                                        style={{ width: "200px", height: "50px" }}
                                        className="select-bg cursor-pointer mb-3"
                                        aria-label="Default select example"
                                        value={selectedSubAdmin}
                                        onChange={(e) => setSelectedSubAdmin(e.target.value)}
                                    >
                                        <option value={""}>Select Company</option>
                                        {findSubAdmin?.map((item, index) => (
                                            <option key={index} value={item._id}>
                                                {item.fullName}
                                            </option>
                                        ))}
                                    </Form.Select>
                                }
                            </Col>

                            <Col md={12}>
                                <div className="form-control d-flex align-items-center px-1 mb-3">
                                    <input
                                        className="form-control border-0 shadow-none px-0 px-3 text-black w-100"
                                        placeholder="Enter name"
                                        type="text"
                                        name="name"
                                        value={name}
                                        onChange={inputChange}
                                    />
                                </div>
                            </Col>
                            <Col md={12}>
                                <div className="form-control d-flex align-items-center px-1 mb-3">
                                    <div className="d-flex w-100">
                                        <div className="mt-2"></div>
                                        <textarea
                                            className="form-control border-0 shadow-none px-0 px-3 text-black w-100"
                                            placeholder="Enter description"
                                            name="desc"
                                            rows={8}
                                            type="text"
                                            value={desc}
                                            onChange={inputChanges}
                                        />
                                    </div>
                                </div>
                            </Col>

                            <Col xs={12} lg={12}>
                                <div className="d-flex flex-wrap align-items-center text-center text-nowrap gap-2 mb-4 py-2">
                                    <span className="text-nowrap fs-6 fw-500">Weekday:</span>
                                    {weekDays && weekDays.length > 0 && weekDays?.map((days, index) => (
                                        <div key={index} className="custom-checked py-1">
                                            <button
                                                className={`days-button fw-500 ${days.day == weekDayTitle ? "text-black" : ""}`}
                                                onClick={(e) => openTableDays(e, days.day, index)}
                                            >
                                                {days.day}
                                            </button>
                                        </div>
                                    ))}
                                </div>
                            </Col>

                            <Col md={12} lg={10} xl={12}>
                                <div className="cursor-pointer d-flex justify-content-end mb-3">
                                    {weekDayTitle?.length > 1 ?
                                        <img
                                            src="../images/svg/add.svg"
                                            onClick={(e) => handleAddSlot(e)}
                                            className="add-btn"
                                            alt=""
                                        />
                                        : ""}
                                </div>
                                <div className="overflow-auto">
                                    <Table striped bordered hover>
                                        <thead>
                                            <tr>
                                                <th className="border-start">Index</th>
                                                <th className="border-start">Start Time</th>
                                                <th className="border-start">End Time</th>
                                                <th className="price-input border-start">Price</th>
                                                <th className="price-input border-start">Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {weekDays && weekDays.length > 0 && weekDays?.map((days, index) => {
                                                if (days.day === weekDayTitle) {
                                                    return days?.slots?.map((slot, i) => {
                                                        return (
                                                            <tr key={i}>
                                                                <td>{i + 1}</td>
                                                                <td>
                                                                    <Form.Select
                                                                        aria-label="Default select example"
                                                                        className="select-bg bg-transparent w-100"
                                                                        name="startTime"
                                                                        value={slot.startTime}
                                                                        onChange={(e) => {
                                                                            inputValChange(e, i);
                                                                        }}
                                                                    >
                                                                        <option value={""}>
                                                                            Studio Start time
                                                                        </option>
                                                                        {timeSlot.map((v, i) => (
                                                                            <option key={i} value={v}>
                                                                                {v}
                                                                            </option>
                                                                        ))}
                                                                    </Form.Select>
                                                                </td>
                                                                <td>
                                                                    <Form.Select
                                                                        aria-label="Default select example"
                                                                        className="select-bg bg-transparent w-100"
                                                                        name="endTime"
                                                                        value={slot.endTime}
                                                                        onChange={(e) => {
                                                                            inputValChange(e, i);
                                                                        }}
                                                                    >
                                                                        <option value={""}>Studio End time</option>
                                                                        {timeSlot.map((v, i) => (
                                                                            <option key={i} value={v}>
                                                                                {v}
                                                                            </option>
                                                                        ))}
                                                                    </Form.Select>
                                                                </td>
                                                                <td>
                                                                    <input
                                                                        type="text"
                                                                        className="form-control shadow-none border time-input"
                                                                        name="price"
                                                                        onChange={(e) => {
                                                                            inputValChange(e, i);
                                                                        }}
                                                                        value={slot.price}
                                                                    />
                                                                </td>
                                                                <td onClick={() => deleteRow(i)}>
                                                                    <img src="./images/svg/delete.svg" alt="" />
                                                                </td>
                                                            </tr>
                                                        );
                                                    });
                                                }
                                            })}
                                        </tbody>
                                    </Table>
                                </div>
                                <div className="d-flex justify-content-end">
                                    <button
                                        className="secondary-button text-decoration-none"
                                        onClick={addPricingFlow}
                                    >
                                        Save
                                    </button>
                                </div>
                            </Col>
                        </Row>
                    </Form>
                </Modal.Body>
                <Modal.Footer className="mx-md-4 border-0 bg-transparent pt-0">
                    {/* <button
                        // ref={ref}
                        className="secondary-button text-decoration-none"
                    >
                        Add
                    </button> */}
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default Pricing;
