import React, { useEffect, useRef } from "react";
import { Col, Container, Form, Modal, Row, Table } from "react-bootstrap";
import Header from "../layout/Header";
import Sidbar from "../layout/Sidbar";
import { useState } from "react";
import { toast } from "react-toastify";
import CancelationPolicyTable from "../components/CancelationPolicyTable";
import { addCancelPolicy, getCancelPolicy, getSubAdmin } from "../controller/Api";
import Loader from "../components/Loader";
import { useContextData } from "../context/CartContext";

const CancelationPolicy = () => {
  const { admin } = useContextData();
  const [lgShow, setLgShow] = useState(false);
  const ref = useRef(null);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState("");
  const [perPage, setPerPage] = useState(10);
  const [policyAdd, setPolicyAdd] = useState();
  const [findSubAdmin, setFindSubAdmin] = useState([]);
  const [selectedSubAdmin, setSelectedSubAdmin] = useState();
  const [selectedSubAdminId, setSelectedSubAdminId] = useState();
  const [policyData, setPolicyData] = useState({
    name: "",
    desc: "",
    slots: [{
      time: 1,
      amountPercentage: 1
    }]
  });

  const handleInputChange = (e, i) => {
    const { name, value } = e.target;
    if (name === "name" || name === "desc") {
      setPolicyData(prevState => ({
        ...prevState,
        [name]: value
      }));
    } else {
      if (/^\d*\.?\d*$/.test(value)) {
        let newSlots = [...policyData.slots];
        newSlots[i][name] = Number(value);
        setPolicyData({
          ...policyData,
          slots: newSlots
        });
      }
    }
  };

  const timeSlot = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24];

  const handleAddSlot = () => {
    const newSlot = { time: 1, amountPercentage: 1 }
    const updatedSlot = { ...policyData }
    updatedSlot.slots.push(newSlot)
    setPolicyData(updatedSlot);
  };

  const deleteRow = (index) => {
    const updatedSlots = [...policyData.slots];
    updatedSlots.splice(index, 1);
    setPolicyData(prevState => ({
      ...prevState,
      slots: updatedSlots
    }));
  };

  //get APi
  const CancelPolicy = async (searchVal) => {
    const pageVal = searchVal ? 1 : page;
    try {
      setLoading(true);
      const result = await getCancelPolicy(pageVal, perPage, search, selectedSubAdminId);
      const { status, message } = result;
      if (status === 1) {
        const { page } = result;
        setPage(page);
        setPolicyAdd(result);
      } else {
        toast.error(message);
      }
    } catch (error) {
      console.log(error);
    }
    finally {
      setLoading(false);
    }
  };

  //Post api
  const addCancellationPolicy = async (e) => {
    e.preventDefault();
    try {
      if (policyData?.name?.trim() === "") {
        toast.error("Policy name is required");
        return;
      }
      if (policyData.slots.some(slot => !slot.amountPercentage || isNaN(slot.amountPercentage) || slot.amountPercentage <= 0 || slot.amountPercentage > 100 || slot.time <= 0 || isNaN(slot.time))) {
        toast.error("Please enter a cancellation amount between 1 and 100, and a positive lead time for all slots.");
        return;
      }
      if (!policyData.slots.length > 0) {
        toast.error("Please enter at list one slots.");
        return;
      }
      const result = await addCancelPolicy({ ...policyData, userId: selectedSubAdmin });
      const { status, message } = result;
      if (status === 1) {
        toast.success(message);
        setLgShow(false);
        CancelPolicy();
        setPolicyData({
          name: "",
          desc: "",
          slots: [{
            time: 1,
            amountPercentage: 1
          }]
        });
      }else {
        toast.error(message);
      }
    } catch (error) {
      console.log(error);
    }
  }

  //sub admin role
  const getSubAdminCancelation = async () => {
    try {
      const result = await getSubAdmin();
      const { data, status } = result;
      if (status === 1) {
        setFindSubAdmin(data)
      }
    } catch (error) {
      console.log(error);
    }
  }

  const inputHandle = (e) => {
    setSearch(e.target.value);
  }

  useEffect(() => {
    admin && getSubAdminCancelation();
    CancelPolicy();
  }, [page, selectedSubAdminId])


  useEffect(() => {
    const callback = (event) => {
      if (event.key === 'Enter') {
        event.preventDefault();
        ref.current?.click();
      }
    };
    document.addEventListener('keydown', callback);
    return () => {
      document.removeEventListener('keydown', callback);
    };
  }, []);

  useEffect(() => {
    document.title = "Music Booth | Cancellation Policy"
  }, []);

  // useEffect(() => {
  //   setPolicyAdd();
  // }, [policyAdd])

  return (
    <>
      {loading && <Loader />}
      <section>
        <Container fluid>
          <Row className="vh-100">
            <Col
              xs={12}
              md={12}
              lg={3}
              xl={2}
              className="shadow-right d-none d-lg-block"
            >
              <Sidbar />
            </Col>
            <Col xs={12} lg={9} xl={10} className="position-relative px-md-5">
              <Header />
              <img src="../images/png/bg-imag.png" className="bg-imag" alt="" />
              <Row className="justify-content-between align-items-center pb-3">
                <Col md={6} lg={5} xl={7}>
                  <h1 className="title">Cancellation Policy</h1>
                </Col>
                <Col md={6} lg={7} xl={5} className="mt-3 mt-md-0 d-flex gap-2 justify-content-end">
                  {
                    admin &&
                    <Form.Select
                      style={{ width: "200px", height: "50px" }}
                      className="select-bg cursor-pointer"
                      aria-label="Default select example"
                      value={selectedSubAdminId}
                      onChange={(e) => {
                        setSelectedSubAdminId(e.target.value);
                        setPage(1)
                      }}
                    >
                      <option value={""}>Select Company</option>
                      {findSubAdmin?.map((item, index) => (
                        <option key={index} value={item._id} >
                          {item.fullName}
                        </option>
                      ))}
                    </Form.Select>
                  }
                  <Form>
                    <div className="d-flex justify-content-end text-end gap-3">
                      <div
                        className="form-control d-flex align-items-center px-3"
                        style={{ width: "350px" }}
                      >
                        <input
                          className="form-control border-0 shadow-none pl-black"
                          placeholder="Search"
                          type="text"
                          value={search}
                          onChange={inputHandle}
                        />
                        <img
                          ref={ref}
                          src="../images/svg/search.svg"
                          alt="Search"
                          className="cursor-pointer"
                          onClick={() => CancelPolicy("search")}
                        />
                      </div>
                      <div
                        onClick={() => setLgShow(true)}
                        className="cursor-pointer"
                      >
                        <img
                          src="../images/svg/add.svg"
                          className="add-btn cursor-pointer"
                          alt=""
                        />
                      </div>
                    </div>
                  </Form>
                </Col>
              </Row>
              <CancelationPolicyTable
                admin={admin}
                policyAdd={policyAdd}
                setPerPage={setPerPage}
                perPage={perPage}
                setPage={setPage}
                page={page}
                timeSlot={timeSlot}
                CancelPolicy={CancelPolicy}
              />
            </Col>
          </Row>
        </Container>
      </section>
      <Modal
        size="xl"
        show={lgShow}
        onHide={() => {
          setLgShow(false)
          setPolicyData({
            name: "", desc: "",
            slots: [{
              time: 1,
              amountPercentage: 1
            }]
          });
        }}
        centered
        className='moadl-main info-modal'
      >
        <Modal.Header className='d-block position-relative border-0' closeButton>
          <Modal.Title className='text-center'>Add Cancellation Policy</Modal.Title>
        </Modal.Header>
        <Modal.Body className='mx-md-4 border-0 bg-transparent pb-0'>
          <Form className='mt-4'>
            <Row>

              <Col>
                {admin &&
                  <Form.Select
                    style={{ width: "200px", height: "50px" }}
                    className="select-bg cursor-pointer mb-3"
                    aria-label="Default select example"
                    value={selectedSubAdmin}
                    onChange={(e) => setSelectedSubAdmin(e.target.value)}
                  >
                    <option value={""}>Select Company</option>
                    {findSubAdmin?.map((item, index) => (
                      <option key={index} value={item._id}>
                        {item.fullName}
                      </option>
                    ))}
                  </Form.Select>
                }
              </Col>

              <Col md={12}>
                <div className="form-control d-flex align-items-center px-1 mb-3">
                  <div className="d-flex w-100">
                    <input
                      className="form-control border-0 shadow-none px-0 px-3 text-black w-100"
                      placeholder="Enter Name"
                      name="name"
                      type="text"
                      value={policyData.name}
                      onChange={handleInputChange}
                    />
                  </div>
                </div>
              </Col>

              <Col md={12}>
                <div className="form-control d-flex align-items-center px-1 mb-3">
                  <div className="d-flex w-100">
                    <textarea
                      className="form-control border-0 shadow-none px-0 px-3 text-black w-100"
                      placeholder="Enter Description"
                      name="desc"
                      rows={3}
                      type="text"
                      value={policyData.desc}
                      onChange={handleInputChange}
                    />
                  </div>
                </div>
              </Col>

              <Col md={12} lg={10} xl={12}>
                <div className="cursor-pointer d-flex justify-content-end mb-3">
                  <img
                    src="../images/svg/add.svg"
                    onClick={(e) => handleAddSlot(e)}
                    className="add-btn"
                    alt=""
                  />
                </div>
                <div className="overflow-auto">
                  <Table striped bordered hover>
                    <thead>
                      <tr>
                        <th className="border-start">Lead Time(hours)</th>
                        <th className="border-start">Refund to Wallet</th>
                        <th className="border-start">Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {
                        policyData.slots?.map((e, i) => {
                          return (
                            <tr>
                              <td>

                                <div className="form-control d-flex align-items-center px-1 mb-3">
                                  <div className="d-flex w-100">
                                    <input
                                      className="form-control border-0 shadow-none px-0 px-3 text-black w-100 ms-2"
                                      placeholder="Enter Lead Time"
                                      name="time"
                                      type="text"
                                      value={e.time}
                                      onChange={(e) => {
                                        handleInputChange(e, i);
                                      }}
                                    />
                                    <span className="ms-2 fw-500 mt-1 m-lg-1" style={{ height: "12px" }}>hours</span>
                                  </div>
                                </div>
                              </td>
                              <td>
                                <div className="form-control d-flex align-items-center px-1 mb-3">
                                  <div className="d-flex w-100">
                                    <input
                                      className="form-control border-0 shadow-none px-0 px-3 text-black w-100 ms-2"
                                      placeholder="Enter Cancellation Amount"
                                      name="amountPercentage"
                                      type="text"
                                      value={e.amountPercentage}
                                      onChange={(e) => {
                                        handleInputChange(e, i);
                                      }}
                                    // onKeyDown={(e) =>
                                    //   ["e", "E", "+", "-", "."].includes(e.key) &&
                                    //   e.preventDefault()
                                    // }
                                    />
                                    <span className="ms-2 fw-600 mt-1 m-lg-1" style={{ height: "12px" }}>%</span>
                                  </div>
                                </div>
                              </td>
                              <td
                                onClick={() => deleteRow(i)}
                              >
                                <img src="./images/svg/delete.svg" alt="" />
                              </td>
                            </tr>
                          );
                        })
                      }
                    </tbody>
                  </Table>
                </div>
                <div className="d-flex justify-content-end mb-3">
                  <button
                    className="secondary-button text-decoration-none"
                    onClick={(e) => addCancellationPolicy(e)}
                  >
                    Save
                  </button>
                </div>
              </Col>

            </Row>
          </Form >
        </Modal.Body >
      </Modal >
    </>
  )
}

export default CancelationPolicy