import React, { useState, useEffect } from "react";
import { Container, Row, Col, Table, Form, Button } from "react-bootstrap";
import Sidbar from "../layout/Sidbar";
import Header from "../layout/Header";
import { useNavigate } from "react-router-dom";
import { getTerms } from "../controller/Api";
import { postTerms } from "../controller/Api";
import { EditAboutUs } from "../controller/Api";
import { toast } from "react-toastify";
import Loader from "../components/Loader";

const TermsAndConditions = () => {

  const intialValue = {
    title: "",
    desc: [""]
  }
  const [isValid, setIsValid] = useState(true);
  const [termsData, setTermsData] = useState([intialValue]);
  const [editMode, setEditMode] = useState(false);
  const [loading, setLoading] = useState(false);

  //Title
  const onchangeTitle = (e, index) => {
    let newTerms = [...termsData] //Old value
    newTerms[index].title = e.target.value; //All value title
    setTermsData(newTerms) //All title
  };

  //remove title
  const removeTitle = (index) => {
    let newTerms = [...termsData];
    newTerms.splice(index, 1);
    setTermsData(newTerms);
  };

  // sub Description
  const onchangeSubDesc = (e, index, innerIndex) => {
    let newTerms = [...termsData];
    if (!Array.isArray(newTerms[index].desc)) {
      newTerms[index].desc = [];
    }
    newTerms[index].desc[innerIndex] = e.target.value;
    setTermsData(newTerms);
  }

  const removeSubDesc = (index, innerIndex) => {
    let newTerms = [...termsData];
    if (newTerms[index] && Array.isArray(newTerms[index].desc)) {
      newTerms[index].desc = newTerms[index].desc.filter((desc, i) => i !== innerIndex);
      if (newTerms[index].desc.length === 0) {
        newTerms.splice(index, 1);
      }
      setTermsData(newTerms);
    }
  };

  const addNewTermsObj = () => {
    let newTerms = [...termsData]
    newTerms.push(intialValue)
    setTermsData(newTerms)
  }

  //sub desc
  const addSubDesc = (index) => {
    let newTerms = [...termsData];
    newTerms[index].desc.push("");
    setTermsData(newTerms);
  };

  const getAboutUsData = async () => {
    try {
      setLoading(true);
      const aboutUsResponse = await getTerms();
      const { status, message, data } = aboutUsResponse;
      if (status === 1) {
        setTermsData(data);
      } else {
        toast.error(message);
      }
    } catch (error) {
      console.log(error);
    }
    setLoading(false);
  };

  const editTermsData = async () => {
    try {
      let isValid = true;
      for (const term of termsData) {
        if (term.title.trim() === "") {
          isValid = false;
          break;
        }
      }
      for (const term of termsData) {
        if (term.desc.some(desc => desc.trim() === "")) {
          isValid = false;
          break;
        }
      }
      setIsValid(isValid);
      if (!isValid) {
        toast.error("Please fill in all fields.");
        return;
      }
      const result = await postTerms(termsData);
      const { status } = result;
      if (status === 1) {
        toast.success("Terms & Condition Update Successfully");
      } else {
        toast.error("error");
      }
    } catch (error) {
      toast.error(error);
    }
  }

  useEffect(() => {
    getAboutUsData();
  }, []);

  return (
    <>
      {loading && <Loader />}
      <section>
        <Container fluid>
          <Row className="vh-100">
            <Col
              xs={12}
              md={12}
              lg={3}
              xl={2}
              className="shadow-right d-none d-lg-block"
            >
              <Sidbar />
            </Col>
            <Col xs={12} lg={9} xl={10} className="position-relative px-md-5">
              <Header />
              <img src="../images/png/bg-imag.png" className="bg-imag" alt="" />
              <Row className="justify-content-between align-items-center pb-3">
                <div className="info-modal">
                  <div className="all-titles edit-btn-main mt-3 d-flex align-items-center justify-content-between">
                    <Col md={6} lg={5} xl={7}>
                      <h1 className="title">Terms & Conditions</h1>
                    </Col>
                    <button
                      className="mt-2 secondary-button contactEditLayout me-2"
                      onClick={(e) => {
                        e.preventDefault();
                        setEditMode(!editMode);
                        if (editMode) {
                          editTermsData();
                        }
                      }}
                    >
                      {editMode ? "SAVE" : "EDIT"}
                    </button>
                  </div>
                </div>
                <div>
                  <div className="d-flex align-items-center edit-btn-main gap-2 w-100 mt-3">
                    <h4 style={{ color: "#865826" }} className="mb-0">Title: </h4>
                    {editMode ? <img
                      style={{ height: "40px", cursor: editMode ? "pointer" : "" }}
                      src="../images/svg/add.svg"
                      onClick={editMode ? addNewTermsObj : null}
                      className="add-btn"
                      alt=""
                      disabled={!editMode}
                    /> : ""}
                    {/* <Button
                      className="add-btn"
                      onClick={addNewTermsObj}
                      style={{ width: "35px", height: "35px" }}
                      disabled={!editMode}
                    >
                    </Button> */}
                  </div>
                </div>
                {termsData?.length > 0 &&
                  termsData?.map((elem, index) => {
                    return (
                      <>
                        <div className="position-relative">
                          <Col xs={12}>
                            <div className="form-control d-flex align-items-center px-1 mt-4">
                              <input
                                className="form-control border-0 shadow-none px-0 px-3 text-black bg-transparent "
                                placeholder="Enter Title"
                                type="text"
                                name={"title"}
                                onChange={(e) => onchangeTitle(e, index)}
                                value={elem.title}
                                disabled={!editMode}
                              />
                            </div>
                          </Col>
                          {index != 0 && (
                            <div
                              className="position-absolute"
                              style={{ right: "20px", top: "32px", cursor: editMode ? "pointer" : "" }}
                            >
                              {editMode ?
                                <img src="./images/svg/del.svg"
                                  disabled={!editMode}
                                  onClick={() => editMode ? removeTitle(index) : null}
                                  alt="" />
                                : ""}
                            </div>
                          )}
                        </div>
                        <div className="mt-3">
                          <div className="d-flex align-items-center edit-btn-main gap-2 w-100 mt-1">
                            <h5 style={{ color: "#865826" }} className="mb-0">Description:</h5>
                            {editMode ?
                              <img
                                style={{ height: "40px", cursor: editMode ? "pointer" : "" }}
                                src="../images/svg/add.svg"
                                onClick={() => editMode ? addSubDesc(index) : null}
                                className="add-btn"
                                alt=""
                                disabled={!editMode}
                              />
                              : ""}
                          </div>
                          {elem?.desc?.map((value, i) => {
                            return (
                              <div className="position-relative">
                                <Col md={12}>
                                  <div className="form-control d-flex align-items-center px-1 mb-3 mt-3 w-100">
                                    <div className="d-flex w-100">
                                      <textarea
                                        className="form-control border-0 shadow-none px-0 px-3 text-black w-100 bg-transparent pe-5 scroll-bar-show"
                                        placeholder="Enter Description"
                                        name="desc"
                                        rows={8}
                                        type="text"
                                        onChange={(e) => onchangeSubDesc(e, index, i)}
                                        value={value}
                                        disabled={!editMode}
                                      />
                                    </div>
                                  </div>
                                </Col>
                                {i != 0 && (
                                  <div
                                    className="position-absolute"
                                    style={{ right: "6px", top: "8px", cursor: editMode ? "pointer" : "" }}
                                  >
                                    {editMode ?
                                      <img src="./images/svg/del.svg"
                                        disabled={!editMode}
                                        onClick={() => editMode ? removeSubDesc(index, i) : null}
                                        alt="" /> : ""}
                                  </div>
                                )}
                              </div>
                            );
                          })}
                        </div>
                      </>
                    );
                  })}
              </Row>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default TermsAndConditions;
