import React from 'react';
import "./Loaders.css";

const Loader = ({ loading }) =>
    loading ||
    (
        // <div className='loader-white'>
        //     <img src="../images/loader.gif" alt="loader" />
        // </div>
        <div className='loader-wrapper'>
            <div className='loader'>
                <div className="dot-loader"></div>
                <div className="dot-loader dot-loader--2"></div>
                <div className="dot-loader dot-loader--3"></div>
            </div>
        </div>
    )
export default Loader