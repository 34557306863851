import React, { useState } from 'react'
import { Col, Form, Modal, Row, Table } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import TablePagination from "../components/TablePagination";
import { recitalHallEdit, recitalHallDelete } from '../controller/Api';
import { toast } from 'react-toastify';
import { pf } from "../helper/envConfig";

const RecitalHallTable = ({ perPage, page, setPage, getRecitalHall, recitalHallGetApi, setGetRecitalHall }) => {
    const [show, setShow] = useState(false);
    const [editRecitalHall, setEditRecitalHall] = useState();
    const [modelOpen, setModelOpen] = useState();
    const [id, setId] = useState();
    const navigate = useNavigate();
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const handleEdit = (element) => {
        setEditRecitalHall(element.name?.key);
    }

    const inputValChange = (value) => {
        setEditRecitalHall(value);
    }

    const editRecitalHallApi = async () => {
        try {
            if (!editRecitalHall.trim()) {
                toast.error("Recital hall is required");
                return
            }
            const result = await recitalHallEdit(id, { key: editRecitalHall });
            const { status, message } = result;
            if (status === 1) {
                toast.success(message);
                recitalHallGetApi();
                handleClose(true);
            } else {
                toast.error(message);
            }
        } catch (error) {
            toast.error('recital hall already exist.');
        }
    };

    // const toggleButton = async (_id, currentStatus) => {
    //     try {
    //         const updatedStatus = !currentStatus;
    //         const data = { isActive: updatedStatus };
    //         const res = await recitalHallEdit(_id, data);
    //         const updatedBoothList = getRecitalHall.data.map((booth) =>
    //             booth._id === _id ? { ...booth, isActive: updatedStatus } : booth
    //         );
    //         setGetRecitalHall({ ...getRecitalHall, data: updatedBoothList });
    //     } catch (error) {
    //         toast.error('Error updating booth status.');
    //     }
    // };

    const handleDelete = async () => {
        try {
            const result = await recitalHallDelete(id);
            const { message, status } = result;
            if (status === 1) {
                toast.success(message);
                setModelOpen(false);
                handleClose();
                recitalHallGetApi();
                if (getRecitalHall?.data?.length === 1) {
                    setPage(1);
                } else {
                    recitalHallGetApi();
                }
            } else {
                toast.error(message);
            }
        } catch (error) {
            console.log(error);
        }
    }

    return (
        <>
            <Row>
                <Col>
                    <Table bordered responsive="xl">
                        <thead>
                            <tr>
                                <th className='number-width border'>No</th>
                                <th style={{ width: "500px", wordBreak: "break-all", whiteSpace: "break-spaces" }}> Recital Hall</th>
                                <th style={{ width: "80px", wordBreak: "break-all", whiteSpace: "break-spaces" }}>Action</th>
                            </tr>
                        </thead>
                        {Boolean(getRecitalHall?.data?.length) ?
                            <tbody>
                                {
                                    getRecitalHall?.data?.map((element, index) => {
                                        const { _id, name, status, isActive, userId } = element;
                                        const currentNumber = (page - 1) * perPage + index + 1;
                                        return (
                                            <tr>
                                                <td>{currentNumber}</td>
                                                <td>{name?.key || "-"}</td>
                                                {/* <td>{name.value || "0"}</td> */}

                                                {/* <td>
                                                    <div className="form-switch userTable" title={isActive ? "Enabled" : "Disabled"}>
                                                        <input
                                                            className="form-check-input"
                                                            type="checkbox"
                                                            name="isActive"
                                                            checked={isActive}
                                                            onChange={() => toggleButton(_id, isActive)}
                                                        />
                                                    </div>
                                                </td> */}
                                                <td>
                                                    <div className="d-flex justify-content-center align-items-center gap-3" title='Edit'>
                                                        <button
                                                            className="border-0 bg-transparent"
                                                            onClick={() => {
                                                                handleShow();
                                                                setId(_id);
                                                                handleEdit(element);
                                                            }}
                                                        >
                                                            <img src="../images/png/edit.png" style={{ height: "18px" }} alt="" />
                                                        </button>
                                                    </div>
                                                </td>
                                            </tr>
                                        );
                                    })}
                            </tbody>
                            :
                            <tbody>
                                <tr>
                                    <td colSpan={10}>
                                        <div className='nodata-table'>
                                            <h3 className="text-center p-5">Data Not Found</h3>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        }
                    </Table>
                    <div className='d-md-flex justify-content-between align-items-center pb-3'>
                        <button className='secondary-button text-decoration-none' onClick={() => navigate("/dashboard")} >
                            <svg
                                stroke="currentColor"
                                fill="currentColor"
                                strokeWidth={0}
                                viewBox="0 0 24 24"
                                height="2em"
                                width="2em"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path d="M12.707 17.293 8.414 13H18v-2H8.414l4.293-4.293-1.414-1.414L4.586 12l6.707 6.707z" />
                            </svg>
                            Back
                        </button>
                        {
                            getRecitalHall?.data && getRecitalHall?.data?.length >= 1 &&
                            <TablePagination
                                currentPage={getRecitalHall?.page}
                                totalPages={getRecitalHall?.pages}
                                onPageChange={(newPage) => {
                                    setPage(newPage);
                                }}
                            />
                        }
                    </div>
                </Col>
            </Row>

            {/* Edit Recital hall */}
            <Modal
                show={show}
                size="xl"
                onHide={handleClose}
                centered
                className='moadl-main info-modal'
            >
                <Modal.Header className='d-block position-relative border-0' closeButton>
                    <Modal.Title className='text-center'>Edit Recital Hall</Modal.Title>
                </Modal.Header>
                <Modal.Body className='mx-md-4 border-0 bg-transparent pb-0'>
                    <div className="d-flex justify-content-end ">
                        <button
                            className="primary-button" onClick={() => {
                                setModelOpen(true)
                                handleClose();
                            }}>
                            Delete
                        </button>
                    </div>
                    <Form className='mt-4'>
                        <Row>
                            <Col md={12}>
                                <div className='form-control d-flex align-items-center px-1 mb-3'>
                                    <input className='form-control border-0 shadow-none px-0 px-2 text-black w-100'
                                        placeholder='Enter No. of Seats'
                                        type="text"
                                        name='key'
                                        value={editRecitalHall}
                                        onChange={(e) => { inputValChange(e.target.value) }}
                                    />
                                    {/* <div className="cursor-pointer" >
                                        <img
                                            src="../images/svg/add.svg"
                                            className="add-btn"
                                            alt=""
                                        />
                                    </div> */}
                                </div>
                            </Col>
                        </Row>
                    </Form>
                </Modal.Body>
                <Modal.Footer className='mx-md-4 border-0 bg-transparent pt-0'>
                    <button
                        onClick={editRecitalHallApi}
                        className='secondary-button text-decoration-none' >
                        Save
                    </button>
                </Modal.Footer>
            </Modal>

            {/* Delete Recital hall  */}
            <Modal
                size="md"
                show={modelOpen}
                onHide={() => setModelOpen(false)}
                centered className='moadl-main delete-modal'
            >
                <Modal.Header className='d-block position-relative border-0' closeButton>
                    <Modal.Title className='text-center'>Delete Recital Hall</Modal.Title>
                </Modal.Header>
                <Modal.Body className='mx-4 border-0'>
                    <p>Are you sure you want to delete?</p>
                </Modal.Body>
                <Modal.Footer className='border-0'>
                    <div className='d-flex m-auto gap-2 pb-3'>
                        <button className="primary-button" onClick={() => {
                            handleDelete(id);
                        }}>Delete</button>
                        <button className="primary-button" onClick={() => setModelOpen(false)}>Cancel</button>
                    </div>
                </Modal.Footer>
            </Modal >
        </>
    )
}

export default RecitalHallTable