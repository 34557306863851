import React, { useState } from 'react'
import { Col, Form, Modal, Row, Table } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import TablePagination from "../components/TablePagination";
import { editEquipments, deleteEquipments } from '../controller/Api';
import { toast } from 'react-toastify';
import { pf } from "../helper/envConfig";

const EquipmentsTable = ({ showEquipments, perPage, page, listEquipments,setListEquipments, setPage, admin }) => {
    const [show, setShow] = useState(false);
    const handleShow = () => setShow(true);
    const handleClose = () => setShow(false);
    const navigate = useNavigate();
    const [modelOpen, setModelOpen] = useState();
    const [id, setId] = useState();
    const images = pf();
    const url = images;
    const [editShowData, setEditShowData] = useState({
        name: "",
        decs: "",
        file: null
    });

    const [status, setStatus] = useState(0);
    const [imagePreviewUrl, setImagePreviewUrl] = useState(editShowData.icon || "");
    //Edit Equipments
    const patchEquipments = async () => {
        if (editShowData.name.trim() === "") {
            toast.error("Equipments Name is required");
            return;
        }
        try {
            const formData = new FormData();
            formData.append('name', editShowData.name);
            formData.append('desc', editShowData.desc);
            if (editShowData.file) {
                formData.append('file', editShowData.file);
            } else {
                formData.append("icon", editShowData.icon)
            }
            const result = await editEquipments(id, formData);
            const { status, message } = result;
            if (status === 1) {
                toast.success(message);
                showEquipments();
                handleClose(true);
            } else {
                toast.error(message);
            }
        } catch (error) {
            console.error(error);
        }
    };

    //Delete Equipments
    const handleDelete = async (id) => {
        try {
            const result = await deleteEquipments(id);
            const { message, status } = result;
            if (status === 1) {
                setModelOpen(false);
                toast.success(message);
                handleClose();
                if (listEquipments?.data?.length === 1) {
                    setPage(1);
                } else {
                    showEquipments();
                }
            } else {
                toast.error(message);
            }
        } catch (error) {
            console.log(error);
        }
    }

    const toggleButton = async (_id, currentStatus, userId) => {
        try {
            const updatedStatus = currentStatus ? 0 : 1;
    
            // Prepare FormData
            const formData = new FormData();
            formData.append('status', updatedStatus);
            formData.append('userId', userId);
            const res = await editEquipments(_id, formData);
            if (res.error) {
                throw new Error(res.error);
            }
    
            // Update the local list of equipments
            const updatedBoothList = listEquipments?.data?.map((booth) =>
                booth._id === _id ? { ...booth, status: updatedStatus } : booth
            );
    
            // Update the state
            setListEquipments({ ...listEquipments, data: updatedBoothList });
    
        } catch (error) {
            // Handle errors, possibly show a toast notification
            // toast.error(`Error updating booth status: ${error.message || 'An unexpected error occurred.'}`);
        }
    };
    


    // // HOW TO MANAGE ONE API TO TWO FUNCTION
    // const toggleButton = async (_id, currentStatus, userId) => {
    //     console.log('userId: ', userId);
    //     try {
    //         // Find the equipment item from the list
    //         const e = listEquipments.data.find((e) => e._id === _id);
    
    //         // Prepare the object to send to the API
    //         const obj = {
    //             name: e.name,
    //             desc: e.desc,
    //             status: currentStatus ? 0 : 1,
    //             userId
    //         };
    
    //         // Send the data to the API
    //         const res = await editEquipments(_id, obj);
    
    //         setStatus(currentStatus ? 0 : 1);
    //         showEquipments();
    
    //     } catch (error) {
    //         // Show error toast
    //         toast.error(`Error: ${error.message}`);
    //     }
    // }
    

    const handleEdit = (element) => {
        setEditShowData(element);
    }

    const inputValChange = (e) => {
        const { name, value, files } = e.target;
        setEditShowData((oldVal) => {
            if (name === 'file') {
                const file = files[0];
                const previewUrl = file ? URL.createObjectURL(file) : "";
                setImagePreviewUrl(previewUrl);
                return {
                    ...oldVal,
                    [name]: file
                };
            } else {
                return {
                    ...oldVal,
                    [name]: value
                };
            }
        });
    };

    const handleInputChange = () => {
        setStatus((prevStatus) => !prevStatus);
    };

    const handleKeyDown = (event) => {
        if (event.keyCode === 13) {
            event.preventDefault();
            const textareaName = event.target?.name;
            const currentValue = editShowData[textareaName];
            const cursorPosition = event.target.selectionStart;
            const newValue =
                currentValue?.substring(0, cursorPosition) +
                '\n' +
                currentValue?.substring(cursorPosition);
            setEditShowData({ ...editShowData, [textareaName]: newValue });
        }
    };

    return (
        <>
            {/* {loading && <Loader />} */}
            <Row>
                <Col>
                    <Table bordered responsive="xl">
                        <thead>
                            <tr>
                                <th className='number-width border'>No</th>
                                <th style={{ width: "250px", wordBreak: "break-all", whiteSpace: "break-spaces" }}> Name</th>
                                <th style={{ width: "700px", wordBreak: "break-all", whiteSpace: "break-spaces" }}> Description</th>
                                {admin && <th style={{ width: "200px", wordBreak: "break-all", whiteSpace: "break-spaces" }}> Sub Admin</th>}
                                <th style={{ width: "150px", wordBreak: "break-all", whiteSpace: "break-spaces" }}> Enabled</th>
                                <th style={{ width: "100px", wordBreak: "break-all", whiteSpace: "break-spaces" }}>Action</th>
                            </tr>
                        </thead>
                        {Boolean(listEquipments?.data?.length) ?
                            <tbody>
                                {
                                    listEquipments?.data?.map((element, index) => {
                                        const { _id, name, status, desc, userId } = element;
                                        const currentNumber = (page - 1) * perPage + index + 1;
                                        return (
                                            <tr>
                                                <td>{currentNumber}</td>
                                                <td>{name || "-"}</td>
                                                <td style={{ width: "700px", wordBreak: "break-all", whiteSpace: "break-spaces" }}>{desc || "-"}</td>
                                                {admin && <td style={{ width: "200px", wordBreak: "break-all", whiteSpace: "break-spaces" }}>{userId?.fullName || "-"}</td>}
                                                <td>
                                                    <div className="form-switch userTable" title={status ? "Enabled" : "Disabled"}>
                                                        <input
                                                            className="form-check-input"
                                                            type="checkbox"
                                                            name="status"
                                                            checked={status}
                                                            onChange={() => toggleButton(_id, status, userId._id)}
                                                        />
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="d-flex justify-content-center align-items-center gap-3" title='Edit'>
                                                        <button
                                                            className="border-0 bg-transparent"
                                                            onClick={() => {
                                                                handleShow();
                                                                setId(_id);
                                                                handleEdit(element);
                                                            }}
                                                        >
                                                            <img src="../images/png/edit.png" style={{ height: "18px" }} alt="" />
                                                        </button>
                                                    </div>
                                                </td>
                                            </tr>
                                        );
                                    })}
                            </tbody>
                            :
                            <tbody>
                                <tr>
                                    <td colSpan={10}>
                                        <div className='nodata-table'>
                                            <h3 className="text-center p-5">Data Not Found</h3>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        }
                    </Table>
                    <div className='d-md-flex justify-content-between align-items-center pb-3'>
                        <button className='secondary-button text-decoration-none' onClick={() => navigate("/dashboard")} >
                            <svg
                                stroke="currentColor"
                                fill="currentColor"
                                strokeWidth={0}
                                viewBox="0 0 24 24"
                                height="2em"
                                width="2em"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path d="M12.707 17.293 8.414 13H18v-2H8.414l4.293-4.293-1.414-1.414L4.586 12l6.707 6.707z" />
                            </svg>
                            Back
                        </button>
                        {
                            listEquipments?.data && listEquipments?.data?.length >= 1 &&
                            <TablePagination
                                currentPage={listEquipments?.page}
                                totalPages={listEquipments?.pages}
                                onPageChange={(newPage) => {
                                    setPage(newPage);
                                }}
                            />
                        }
                    </div>
                </Col>
            </Row>

            <Modal
                show={show}
                size="xl"
                onHide={handleClose}
                centered
                className='moadl-main info-modal'
            >
                <Modal.Header className='d-block position-relative border-0' closeButton>
                    <Modal.Title className='text-center'>Edit Equipment</Modal.Title>
                </Modal.Header>
                <Modal.Body className='mx-md-4 border-0 bg-transparent pb-0'>
                    <div className="d-flex justify-content-end ">
                        <button
                            className="primary-button" onClick={() => {
                                setModelOpen(true)
                                handleClose();
                            }}>
                            Delete
                        </button>
                    </div>
                    <Form className='mt-4'>
                        <Row>
                            <Col md={12}>
                                <div className='form-control d-flex align-items-center px-1 mb-3'>
                                    <input className='form-control border-0 shadow-none px-0 px-3 text-black w-100' placeholder='Enter Equipment Name'
                                        type="text" name='name'
                                        value={editShowData?.name || "-"}
                                        onChange={inputValChange}
                                    />
                                </div>
                            </Col>
                            <Col md={12}>
                                <div className="form-control d-flex align-items-center px-1 mb-3">
                                    <div className="d-flex w-100">
                                        <textarea
                                            className="form-control border-0 shadow-none px-0 px-3 text-black w-100"
                                            placeholder="Enter Equipment description"
                                            name="desc"
                                            rows={10}
                                            type="text"
                                            value={editShowData?.desc || "-"}
                                            onChange={inputValChange}
                                            onKeyDown={handleKeyDown}
                                        />
                                    </div>
                                </div>
                            </Col>
                            <Col md={12}>
                                <div className="form-control d-flex align-items-center px-1 mb-3">
                                    <div className="d-flex w-100">
                                        <input
                                            type="file"
                                            name="file"
                                            onChange={inputValChange}
                                            accept=".png"
                                        />
                                    </div>
                                </div>
                            </Col>
                            <Col md={12}>
                                <div>
                                    <img
                                        src={imagePreviewUrl || (editShowData.icon ? `${url + editShowData.icon}` : '')}
                                        alt="Preview"
                                        style={{ width: '120px', height: '120px' }}
                                    />
                                </div>
                            </Col>
                        </Row>
                    </Form>
                </Modal.Body>
                <Modal.Footer className='mx-md-4 border-0 bg-transparent pt-0'>
                    <button onClick={patchEquipments}
                        className='secondary-button text-decoration-none' >
                        Save
                    </button>
                </Modal.Footer>
            </Modal>

            <Modal
                size="md"
                show={modelOpen}
                onHide={() => setModelOpen(false)}
                centered className='moadl-main delete-modal'
            >
                <Modal.Header className='d-block position-relative border-0' closeButton>
                    <Modal.Title className='text-center'>Delete Equipment</Modal.Title>
                </Modal.Header>
                <Modal.Body className='mx-4 border-0'>
                    <p>Are you sure you want to delete?</p>
                </Modal.Body>
                <Modal.Footer className='border-0'>
                    <div className='d-flex m-auto gap-2 pb-3'>
                        <button className="primary-button" onClick={() => {
                            handleDelete(id);
                        }}>Delete</button>
                        <button className="primary-button" onClick={() => setModelOpen(false)}>Cancel</button>
                    </div>
                </Modal.Footer>
            </Modal >
        </>
    )
}

export default EquipmentsTable