import React, { useEffect, useRef } from "react";
import { Col, Container, Form, Modal, Row, Table } from "react-bootstrap";
import Header from "../layout/Header";
import Sidbar from "../layout/Sidbar";
import FeedBackTable from "../components/FeedBackTable";
import GetInTouchTable from "../components/GetInTouchTable";
import PricingTable from "../components/PricingTable";
import { useState } from "react";
import { getFeedBack } from "../controller/Api";
import { toast } from "react-toastify";
import Loader from "../components/Loader";

const FeedBack = () => {
    const [page, setPage] = useState(1);
    const [perPage, setPerPage] = useState(10);
    const [search, setSearch] = useState("");
    const [feedBack, setFeedBack] = useState();
    const [loading, setLoading] = useState(false);
    const ref = useRef(null);

    const getFeedBackData = async (searchVal) => {
        const pageVal = searchVal ? 1 : page;
        try {
            setLoading(true);
            const result = await getFeedBack(pageVal, perPage, search);
            const { status, message } = result;
            if (status === 1) {
                setFeedBack(result);
                setPage(pageVal);
            } else {
                toast.error(message)
            }
        } catch (error) {
            console.log(error);
        }
        setLoading(false);
    }

    const inputHandle = (e) => {
        setSearch(e.target.value);
    }

    useEffect(() => {
        const callback = (event) => {
            if (event.key === 'Enter') {
                event.preventDefault();
                ref.current?.click();
            }
        };
        document.addEventListener('keydown', callback);
        return () => {
            document.removeEventListener('keydown', callback);
        };
    }, []);

    useEffect(() => {
        getFeedBackData();
    }, [page])

    return (
        <>
            {loading && <Loader />}
            <section>
                <Container fluid>
                    <Row className="vh-100">
                        <Col
                            xs={12}
                            md={12}
                            lg={3}
                            xl={2}
                            className="shadow-right d-none d-lg-block"
                        >
                            <Sidbar />
                        </Col>
                        <Col xs={12} lg={9} xl={10} className="position-relative px-md-5">
                            <Header />
                            <img src="../images/png/bg-imag.png" className="bg-imag" alt="" />
                            <Row className="justify-content-between align-items-center pb-3">
                                <Col md={6} lg={5} xl={7}>
                                    <h1 className="title">Feedback</h1>
                                </Col>
                                <Col md={6} lg={7} xl={5} className="mt-3 mt-md-0">
                                    <Form>
                                        <div className="d-flex justify-content-end gap-3">
                                            <div
                                                className="form-control d-flex align-items-center px-3"
                                                style={{ width: "350px" }}
                                            >
                                                <input
                                                    className="form-control border-0 shadow-none pl-black"
                                                    placeholder="Search"
                                                    type="text"
                                                    value={search}
                                                    onChange={inputHandle}
                                                />
                                                <img
                                                    className='cursor-pointer'
                                                    ref={ref}
                                                    src="../images/svg/search.svg"
                                                    alt="Search"
                                                    onClick={() => getFeedBackData("search")}
                                                />
                                            </div>
                                        </div>
                                    </Form>
                                </Col>
                            </Row>
                            <FeedBackTable
                                feedBack={feedBack}
                                page={page}
                                search={search}
                                setPage={setPage}
                                perPage={perPage}
                                setPerPage={setPerPage}
                                getFeedBackData={getFeedBackData}

                            />
                        </Col>
                    </Row>
                </Container>
            </section>

        </>
    );
}

export default FeedBack