import React, { useState } from 'react'
import { Col, Form, Modal, Row, Table } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import TablePagination from "../components/TablePagination";
import { boothDelete, boothEdit, boothGet } from '../controller/Api';
import { toast } from 'react-toastify';
import { pf } from "../helper/envConfig";
import { useEffect } from 'react';

const BoothTable = ({ perPage, page, getBoothList, setPage, getBoothApi, setGetBoothList }) => {
    const [editShowData, setEditShowData] = useState({
        name: "",
        image: null
    });
    const [show, setShow] = useState(false);
    const handleShow = () => setShow(true);
    const handleClose = () => {
        setShow(false);
        setEditShowData({
            name: "",
            image: null
        });
    };
    const navigate = useNavigate();
    const [modelOpen, setModelOpen] = useState();
    const [id, setId] = useState();
    const images = pf();
    const url = images;

    const [isActive, setIsActive] = useState(true);
    const [imagePreviewUrl, setImagePreviewUrl] = useState(editShowData.image || "");

    //Edit booth
    const boothEditApi = async () => {
        if (editShowData.name.trim() === "") {
            toast.error("Booth Name is required");
            return;
        }
        try {
            const formData = new FormData();
            formData.append('name', editShowData.name);
            if (editShowData.image) {
                formData.append('image', editShowData.image);
            } else {
                formData.append("image", editShowData.image)
            }
            const result = await boothEdit(id, formData);
            const { status, message } = result;
            if (status === 1) {
                toast.success(message);
                getBoothApi();
                handleClose(true);
            }
            else {
                toast.error(message);
            }
        } catch (error) {
            console.error(error);
        }
    };

    // Delete Equipments
    const handleDelete = async () => {
        try {
            const result = await boothDelete(id);
            const { message, status } = result;
            if (status === 1) {
                toast.success(message);
                setModelOpen(false);
                handleClose();
                getBoothApi();
                if (getBoothList?.data?.length === 1) {
                    setPage(1);
                } else {
                    getBoothApi();
                }
            } else {
                toast.error(message);
            }
        } catch (error) {
            console.log(error);
        }
    }

    const toggleButton = async (_id, currentStatus, name) => {
        try {
            if (name === "Recital Hall") {
                toast.error("Recital Hall booth type cannot be disabled.")
                return;
            }
            const updatedStatus = !currentStatus;
            const formData = new FormData();
            formData.append('isActive', updatedStatus);
            const res = await boothEdit(_id, formData);
            const updatedBoothList = getBoothList.data.map((booth) =>
                booth._id === _id ? { ...booth, isActive: updatedStatus } : booth
            );
            setGetBoothList({ ...getBoothList, data: updatedBoothList });
        } catch (error) {
            console.log('error: ', error);
        }
    };

    const handleEdit = (element) => {

        setEditShowData(element);
    }

    const inputValChange = (e) => {
        const { name, value, files } = e.target;
        setEditShowData((oldVal) => {
            if (name === 'image') {
                const file = files[0];
                const previewUrl = file ? URL.createObjectURL(file) : "";
                setImagePreviewUrl(previewUrl);
                return {
                    ...oldVal,
                    [name]: file
                };
            } else {
                return {
                    ...oldVal,
                    [name]: value
                };
            }
        });
    };

    return (
        <>
            {/* {loading && <Loader />} */}
            <Row>
                <Col>
                    <Table bordered responsive="xl">
                        <thead>
                            <tr>
                                <th className='number-width border'>No</th>
                                <th style={{ width: "250px", wordBreak: "break-all", whiteSpace: "break-spaces" }}> Image</th>
                                <th style={{ width: "700px", wordBreak: "break-all", whiteSpace: "break-spaces" }}> Booth Name</th>
                                <th style={{ width: "150px", wordBreak: "break-all", whiteSpace: "break-spaces" }}> Enabled</th>
                                <th style={{ width: "100px", wordBreak: "break-all", whiteSpace: "break-spaces" }}>Action</th>
                            </tr>
                        </thead>
                        {Boolean(getBoothList?.data?.length) ?
                            <tbody>
                                {
                                    getBoothList?.data?.map((element, index) => {
                                        const { _id, name, image, isActive } = element;
                                        const currentNumber = (page - 1) * perPage + index + 1;
                                        return (
                                            <tr>
                                                <td>{currentNumber}</td>
                                                <td>
                                                    <img height={80} src={`${url}${image}`} alt="" />
                                                </td>
                                                <td>{name || "-"}</td>
                                                <td>
                                                    <div className="form-switch userTable" title={isActive ? "Enabled" : "Disabled"}>
                                                        <input
                                                            className="form-check-input"
                                                            type="checkbox"
                                                            name="isActive"
                                                            checked={isActive}
                                                            onChange={(e) => toggleButton(_id, isActive, name)}
                                                        // disabled={name === "Recital Hall"}
                                                        />
                                                    </div>
                                                </td>
                                                <td>
                                                    <div className="d-flex justify-content-center align-items-center gap-3" title='Edit'>
                                                        <button
                                                            className="border-0 bg-transparent"
                                                            onClick={() => {
                                                                handleShow();
                                                                setId(_id);
                                                                handleEdit(element);
                                                            }} >
                                                            <img src="../images/png/edit.png" style={{ height: "18px" }} alt="" />
                                                        </button>
                                                        {/* <button
                                                            className="border-0 bg-transparent"
                                                            onClick={() => {
                                                                // handleShow();
                                                                // setId(_id);
                                                                // handleEdit(element);
                                                            }}
                                                        >
                                                            <button class="border-0 bg-transparent" title="Delete"><svg class="cursor-pointer" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M21.07 5.23C19.46 5.07 17.85 4.95 16.23 4.86V4.85L16.01 3.55C15.86 2.63 15.64 1.25 13.3 1.25H10.68C8.35004 1.25 8.13003 2.57 7.97004 3.54L7.76004 4.82C6.83004 4.88 5.90004 4.94 4.97004 5.03L2.93004 5.23C2.51004 5.27 2.21004 5.64 2.25004 6.05C2.29004 6.46 2.65004 6.76 3.07004 6.72L5.11004 6.52C10.35 6 15.63 6.2 20.93 6.73C20.96 6.73 20.98 6.73 21.01 6.73C21.39 6.73 21.72 6.44 21.76 6.05C21.79 5.64 21.49 5.27 21.07 5.23Z" fill="#292D32"></path><path d="M19.23 8.14C18.99 7.89 18.66 7.75 18.32 7.75H5.67999C5.33999 7.75 4.99999 7.89 4.76999 8.14C4.53999 8.39 4.40999 8.73 4.42999 9.08L5.04999 19.34C5.15999 20.86 5.29999 22.76 8.78999 22.76H15.21C18.7 22.76 18.84 20.87 18.95 19.34L19.57 9.09C19.59 8.73 19.46 8.39 19.23 8.14ZM13.66 17.75H10.33C9.91999 17.75 9.57999 17.41 9.57999 17C9.57999 16.59 9.91999 16.25 10.33 16.25H13.66C14.07 16.25 14.41 16.59 14.41 17C14.41 17.41 14.07 17.75 13.66 17.75ZM14.5 13.75H9.49999C9.08999 13.75 8.74999 13.41 8.74999 13C8.74999 12.59 9.08999 12.25 9.49999 12.25H14.5C14.91 12.25 15.25 12.59 15.25 13C15.25 13.41 14.91 13.75 14.5 13.75Z" fill="#292D32"></path></svg></button>
                                                        </button> */}
                                                    </div>
                                                </td>

                                            </tr>
                                        );
                                    })}
                            </tbody>
                            :
                            <tbody>
                                <tr>
                                    <td colSpan={10}>
                                        <div className='nodata-table'>
                                            <h3 className="text-center p-5">Data Not Found</h3>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        }
                    </Table>
                    <div className='d-md-flex justify-content-between align-items-center pb-3'>
                        <button className='secondary-button text-decoration-none' onClick={() => navigate("/dashboard")} >
                            <svg
                                stroke="currentColor"
                                fill="currentColor"
                                strokeWidth={0}
                                viewBox="0 0 24 24"
                                height="2em"
                                width="2em"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path d="M12.707 17.293 8.414 13H18v-2H8.414l4.293-4.293-1.414-1.414L4.586 12l6.707 6.707z" />
                            </svg>
                            Back
                        </button>
                        {

                            getBoothList?.data && getBoothList?.data?.length >= 1 &&
                            <TablePagination
                                currentPage={getBoothList?.page}
                                totalPages={getBoothList?.pages}
                                onPageChange={(newPage) => {
                                    setPage(newPage);
                                }}
                            />
                        }
                    </div>
                </Col>
            </Row>

            <Modal
                show={show}
                size="xl"
                onHide={handleClose}
                centered
                className='moadl-main info-modal'
            >
                <Modal.Header className='d-block position-relative border-0' closeButton>
                    <Modal.Title className='text-center'>Edit Booth</Modal.Title>
                </Modal.Header>
                <Modal.Body className='mx-md-4 border-0 bg-transparent pb-0'>
                    <div className="d-flex justify-content-end ">
                        <button
                            className="primary-button"
                            onClick={() => {
                                setModelOpen(true)
                                handleClose();
                            }}>
                            Delete
                        </button>
                    </div>
                    <Form className='mt-4'>
                        <Row>
                            <Col md={12}>
                                <div className='form-control d-flex align-items-center px-1 mb-3'>
                                    <input className='form-control border-0 shadow-none px-0 px-3 text-black w-100' placeholder='Enter Equipment Name'
                                        type="text" name='name'
                                        value={editShowData?.name}
                                        onChange={inputValChange}
                                        disabled={editShowData?.type === "recital"}
                                    />
                                </div>
                            </Col>

                            <Col md={12}>
                                <div className="form-control d-flex align-items-center px-1 mb-1">
                                    <div className="d-flex w-100">
                                        <input
                                            type="file"
                                            name="image"
                                            onChange={inputValChange}
                                            accept=".jpg, .jpeg, .png"
                                        />
                                    </div>
                                </div>
                                {/* <p className="placeholder-black">The image ratio must be 40:45 for example, dimensions could be 400x450 pixels.</p> */}
                                <p className="placeholder-black">The image should have a 40:45 aspect ratio. For example, the dimensions could be 400x450 pixels.</p> 
                            </Col>
                            <Col md={12}>
                                <div>
                                    <img
                                        src={imagePreviewUrl || (editShowData.image ? `${url + editShowData.image}` : '')}
                                        alt="Preview"
                                        style={{ width: '120px', height: '120px' }}
                                    />
                                </div>
                            </Col>
                        </Row>
                    </Form>
                </Modal.Body>
                <Modal.Footer className='mx-md-4 border-0 bg-transparent pt-0'>
                    <button
                        onClick={boothEditApi}
                        className='secondary-button text-decoration-none' >
                        Save
                    </button>
                </Modal.Footer>
            </Modal>

            <Modal
                size="md"
                show={modelOpen}
                onHide={() => setModelOpen(false)}
                centered className='moadl-main delete-modal'
            >
                <Modal.Header className='d-block position-relative border-0' closeButton>
                    <Modal.Title className='text-center'>Delete Booth</Modal.Title>
                </Modal.Header>
                <Modal.Body className='mx-4 border-0'>
                    <p>Are you sure you want to delete?</p>
                </Modal.Body>
                <Modal.Footer className='border-0'>
                    <div className='d-flex m-auto gap-2 pb-3'>
                        <button className="primary-button" onClick={() => {
                            handleDelete();
                        }}>Delete</button>
                        <button className="primary-button" onClick={() => setModelOpen(false)}>Cancel</button>
                    </div>
                </Modal.Footer>
            </Modal >
        </>
    )
}

export default BoothTable