
import React from 'react';
import { Pagination } from 'react-bootstrap';

const TablePagination = ({ currentPage, totalPages, onPageChange }) => {

  // Create an array of page numbers
  let pages = [];
  for (let i = 1; i <= totalPages; i++) {
    pages.push(i);
  }

  // Function to handle click on a page
  const handleClick = (page) => {
    if (onPageChange) onPageChange(page);
  };

  return (
    <>
      <div className='d-flex justify-content-end mt-3'>
        <Pagination className='pagination table-pagination'>
          {
            (totalPages > 0) &&
            <Pagination className="align-items-center">
              <Pagination.Prev onClick={() => handleClick(1)} disabled={currentPage === 1 ? true : false} style={{ opacity: currentPage === 1 ? 0.5 : 1 }}>

                <svg width="10" className="pagination_color" height="14" viewBox="0 0 10 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M7.50788 -6.11966e-07L0.507813 7L7.50788 14L9.49361 12.0141L4.47941 7L9.49361 1.98587L7.50788 -6.11966e-07Z" fill="#b99e6e" />
                </svg>
              </Pagination.Prev>
              {
                (currentPage > 2) && (totalPages !== 3) &&
                <Pagination.Ellipsis onClick={() => handleClick(currentPage - 2)} />
              }
              {
                (currentPage == totalPages) && (totalPages > 2) &&
                <Pagination.Item
                  active={currentPage === (currentPage - 2)}
                  onClick={() => handleClick(currentPage - 2)}
                >
                  {currentPage - 2}
                </Pagination.Item>
              }
              {
                (currentPage !== 1) &&
                <Pagination.Item
                  active={currentPage === (currentPage - 1)}
                  onClick={() => handleClick(currentPage - 1)}
                >
                  {currentPage - 1}
                </Pagination.Item>
              }
              <Pagination.Item
                active={currentPage === (currentPage)}
                onClick={() => handleClick(currentPage)}
              >
                {currentPage}
              </Pagination.Item>
              {
                (currentPage !== totalPages) &&
                <Pagination.Item
                  active={currentPage === (currentPage + 1)}
                  onClick={() => handleClick(currentPage + 1)}
                >
                  {currentPage + 1}
                </Pagination.Item>
              }
              {
                (currentPage == 1) && (totalPages > (currentPage + 1)) &&
                <Pagination.Item
                  active={currentPage === (currentPage + 2)}
                  onClick={() => handleClick(currentPage + 2)}
                >
                  {currentPage + 2}
                </Pagination.Item>
              }

              {
                (totalPages > (currentPage + 1)) && (totalPages !== 3) &&
                <Pagination.Ellipsis onClick={() => handleClick(currentPage + 2)} />
              }
              <Pagination.Next onClick={() => handleClick(totalPages)} disabled={currentPage === totalPages ? true : false} style={{ opacity: currentPage === totalPages ? 0.5 : 1 }}>

                <svg width="10" height="14" className="pagination_color" viewBox="0 0 10 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M2.49212 -6.11966e-07L9.49219 7L2.49212 14L0.506388 12.0141L5.52059 7L0.506388 1.98587L2.49212 -6.11966e-07Z" fill="#b99e6e" />
                </svg>

              </Pagination.Next>
            </Pagination>
          }
        </Pagination>
      </div>
    </>
  );
};

export default TablePagination;