import React, { useContext } from "react";
import { Navigate } from 'react-router-dom';
import PrivateRoute from './PrivateRoute';
// const Login = import("../auth/Login");
// const Booking = React.lazy(() => import("../pages/Booking"));
// const Dashboard = React.lazy(() => import('../pages/Dashboard'));
// const UserDetails = React.lazy(() => import('../pages/UserDetails'));
// const Services = React.lazy(() => import('../pages/Services'));
// const Payment = React.lazy(() => import('../pages/Payment'));
// const AboutUs = React.lazy(() => import('../pages/AboutUs'));
// const ContactUs = React.lazy(() => import('../pages/ContactUs'));
// const TermsCondition = React.lazy(() => import('../pages/TermsAndConditions'));
// const StudioRental = React.lazy(() => import('../pages/StudioRental'));
import Login from "../auth/Login";
import Booking from "../pages/Booking";
import Dashboard from "../pages/Dashboard";
import UserDetails from "../pages/UserDetails";
import Services from "../pages/Services";
import Payment from "../pages/Payment";
import AboutUs from "../pages/AboutUs";
import ContactUs from "../pages/ContactUs";
import TermsAndConditions from "../pages/TermsAndConditions";
import StudioRental from "../pages/StudioRental";
import GetInTouch from "../pages/GetInTouch";
import Pricing from "../pages/Pricing";
import Equipments from "../pages/Equipments";
import { useContextData } from "../context/CartContext";
import Teacher from "../pages/Teacher";
import CancelationPolicy from "../pages/CancelationPolicy";
import FeedBack from "../pages/FeedBack";
import Notification from "../pages/Notification";
import BroadCastNotification from "../pages/BroadCastNotification";
import HelpAndSupport from "../pages/HelpSupport";
import PartnerGuide from "../pages/PartnerGuide";
import DeleteAccountPage from "../pages/DeleteAccountPage";
import PartnerRequest from "../pages/PartnerRequest";
import Booth from "../pages/Booth";
import RecitalHall from "../pages/RecitalHall";
import AddOns from "../pages/AddOns";
import Partners from "../pages/Partners";

export const useRouter = () => {
    const { token, user, admin } = useContextData();
    return [
        {
            path: "/",
            element: (token && user._id) ? <Dashboard /> : <Login />,
        },
        {
            path: "/booking",
            element: <PrivateRoute><Booking /></PrivateRoute>,
        },
        {
            path: "/dashboard",
            element: <PrivateRoute><Dashboard /></PrivateRoute>,
        },
        {
            path: "/pricing",
            element: <PrivateRoute><Pricing /></PrivateRoute>,
        },
        {
            path: "/equipments",
            element: <PrivateRoute><Equipments /></PrivateRoute>,
        },
        {
            path: "/add-ons",
            element: <PrivateRoute><AddOns /></PrivateRoute>,
        },
        admin && {
            path: "/teacher",
            element: <PrivateRoute><Teacher /></PrivateRoute>,
        },
        admin && {
            path: "/contact-us",
            element: <PrivateRoute><ContactUs /></PrivateRoute>,
        },
        admin && {
            path: "/user-details",
            element: <PrivateRoute><UserDetails /></PrivateRoute>,
        },
        admin && {
            path: "/services",
            element: <PrivateRoute><Services /></PrivateRoute>,
        },
        {
            path: "/payment",
            element: <PrivateRoute><Payment /></PrivateRoute>,
        },
        {
            path: "/cancel-policy",
            element: <PrivateRoute><CancelationPolicy /></PrivateRoute>,
        },
        admin && ({
            path: "/about-us",
            element: <PrivateRoute><AboutUs /></PrivateRoute>,
        }),
        admin && ({
            path: "/terms-conditions",
            element: <PrivateRoute> <TermsAndConditions /></PrivateRoute>,
        }),
        {
            path: "/studio-rental",
            element: <PrivateRoute> <StudioRental /></PrivateRoute>,
        },
        admin && {
            path: "/get-in-touch",
            element: <PrivateRoute> <GetInTouch /></PrivateRoute>,
        },
        admin && {
            path: "/partner-request",
            element: <PrivateRoute><PartnerRequest /></PrivateRoute>,
        },
        admin && {
            path: "/feedback",
            element: <PrivateRoute> <FeedBack /></PrivateRoute>,
        },
        {
            path: "/notification",
            element: <PrivateRoute> <Notification /></PrivateRoute>,
        },
        admin && ({
            path: "/broadcast",
            element: <PrivateRoute> <BroadCastNotification /></PrivateRoute>,
        }),
        {
            path: "/helpandsupport",
            element: <PrivateRoute> <HelpAndSupport /></PrivateRoute>,
        },
        {
            path: "/guide",
            element: <PrivateRoute><PartnerGuide /></PrivateRoute>,
        },
        {
            path: "/delete-account",
            element: <DeleteAccountPage />,
        },
        admin && {
            path: "/booth",
            element: <Booth />
        },
        admin && {
            path: "/recital-hall",
            element: <RecitalHall />
        },
        admin && {
            path: "/partners",
            element: <Partners />
        },
        {
            path: "*",
            element: <Navigate to="/" />,
        },
    ];
} 