import moment from 'moment';
import React, { useState } from 'react'
import { Button, Col, Modal, Row, Table } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import TablePagination from "../components/TablePagination";
import { deleteFeedBack } from "../controller/Api";
import { toast } from 'react-toastify';
import { pf } from "../helper/envConfig";

const FeedBackTable = ({ feedBack, page, setPage, perPage, getFeedBackData }) => {
    const [show, setShow] = useState(false);
    const [smShow, setSmShow] = useState(false);
    const navigate = useNavigate();
    const handleShow = () => setShow(true);
    const handleShow1 = () => setShow(true);
    const handleClose = () => setShow(false);
    const [ModelData, setModelData] = useState();
    const [id, setId] = useState();
    const images = pf();
    const url = images;

    const handleEdit = (element) => {
        setModelData(element);
        setId(element._id)
    }

    const deleteComplaint = async (id) => {
        try {
            const deleteData = await deleteFeedBack(id);
            const { message, status } = deleteData;
            if (status === 1) {
                toast.success(message);
                setSmShow(false);
                if (feedBack?.data?.length === 1) {
                    setPage(1);
                } else {
                    getFeedBackData();
                }
            } else {
                toast.error(message);
            }
        } catch (error) {
            toast.error(error);
        }
    };

    return (
        <>
            <Row>
                <Col>
                    <Table bordered responsive="xl">
                        <thead>
                            <tr>
                                <th className='number-width border'>No</th>
                                <th style={{ width: "400px" }}> Name</th>
                                <th> Email</th>
                                <th> Subject</th>
                                <th> Description </th>
                                <th> Create At</th>
                                <th> Action</th>
                            </tr>
                        </thead>
                        {Boolean(feedBack?.data?.length) ?
                            <tbody>
                                {
                                    feedBack?.data?.map((element, index) => {
                                        const { email, desc, subject, userName, created_at } = element;
                                        // const newPage = search ? 1 : page
                                        // const currentNumber = (newPage - 1) * perPage + index + 1;
                                        const currentNumber = (page - 1) * perPage + index + 1;
                                        const utcFormattedDateTime = created_at ? moment(created_at).format("YYYY-MM-DD, HH:mm:ss") : "-";
                                        return (
                                            <tr>
                                                <td>{currentNumber}</td>
                                                <td>{userName || "-"}</td>
                                                <td style={{ width: "900px", wordBreak: "break-all", whiteSpace: "break-spaces" }}>{email || "-"}</td>
                                                <td style={{ width: "600px", wordBreak: "break-all", whiteSpace: "break-spaces" }}>{subject || "-"}</td>
                                                <td style={{ width: "1000px", wordBreak: "break-all", whiteSpace: "break-spaces" }}>{desc || "-"}</td>
                                                <td style={{ width: "700px", wordBreak: "break-all", whiteSpace: "break-spaces" }}>{utcFormattedDateTime || "-"}</td>
                                                <td>
                                                    <div className="d-flex justify-content-center align-items-center gap-3">
                                                        <div title='Info' className='cursor-pointer'>
                                                            <img src="./images/svg/info.svg" alt=""
                                                                onClick={() => {
                                                                    handleEdit(element);
                                                                    handleShow();
                                                                }} />
                                                        </div>
                                                        <div title='delete' className='cursor-pointer'>
                                                            <img src="./images/svg/delete.svg" alt=""
                                                                onClick={() => {
                                                                    setSmShow(true);
                                                                    handleEdit(element);
                                                                }} />
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                        );
                                    })
                                }
                            </tbody>
                            :
                            <tbody>
                                <tr>
                                    <td colSpan={10}>
                                    <div className='nodata-table'>
                                            <h3 className="text-center p-5">Data Not Found</h3>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        }
                    </Table>
                    <div className='d-md-flex justify-content-between align-items-center'>
                        <button className='secondary-button text-decoration-none' onClick={() => navigate("/dashboard")} >
                            <svg
                                stroke="currentColor"
                                fill="currentColor"
                                strokeWidth={0}
                                viewBox="0 0 24 24"
                                height="2em"
                                width="2em"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path d="M12.707 17.293 8.414 13H18v-2H8.414l4.293-4.293-1.414-1.414L4.586 12l6.707 6.707z" />
                            </svg>
                            Back
                        </button>

                        {
                            feedBack?.data && feedBack?.data?.length >= 1 &&
                            <TablePagination
                                currentPage={feedBack?.page}
                                totalPages={feedBack?.pages}
                                onPageChange={(newPage) => {
                                    setPage(newPage);
                                }}
                            />
                        }
                    </div>
                </Col >
            </Row>

            <Modal
                show={show}
                size="lg"
                onHide={handleClose}
                centered
                className='moadl-main info-modal'
            >
                <Modal.Header className='d-block position-relative border-0' closeButton>
                    <Modal.Title className='text-center'>Feedback</Modal.Title>
                </Modal.Header>
                <Modal.Body className="mx-md-4 border-0 bg-transparent pb-0 service-page">
                    <div className="d-flex align-items-center gap-2 mb-3">
                        <label className="text-nowrap fw-700 ">Name:</label>
                        <div className="w-100">
                            <input
                                className="form-control border-0 fw-500 shadow-none pl-black px-0 label-pl bg-transparent gap-1"
                                type="text"
                                name="studio_name"
                                value={ModelData?.userName || "-"}
                                disabled
                            />
                        </div>
                    </div>
                    <div className="d-flex align-items-center gap-2 mb-3">
                        <label className="text-nowrap fw-700 ">Email:</label>
                        <div className="w-100">
                            <input
                                className="form-control border-0 fw-500 shadow-none pl-black px-0 label-pl bg-transparent gap-1"
                                type="text"
                                // name="studio_name"
                                value={ModelData?.email || "-"}
                                disabled
                            />
                        </div>
                    </div> <div className="d-flex align-items-center gap-2 mb-3">
                        <label className="text-nowrap fw-700 ">Subject: </label>
                        <div className="w-100">
                            <input
                                className="form-control border-0 fw-500 shadow-none pl-black px-0 label-pl bg-transparent gap-1"
                                type="text"
                                // name="stusdio_name"
                                value={ModelData?.subject || "-"}
                                disabled
                            />
                        </div>
                    </div>

                    <div className="d-flex align-items-start gap-2 mb-3">
                        <label className="text-nowrap fw-700">Description:</label>
                        <div className="w-100">
                            <p className="form-control border-0 fw-500 shadow-none pl-black px-0 label-pl bg-transparent gap-1 pt-0">
                                {ModelData?.desc}
                            </p>
                        </div>
                    </div>

                    <div className="d-flex align-items-start gap-2 mb-3">
                        <label className="text-nowrap fw-700">Images:</label>
                        <div className="w-100 d-flex flex-column cursor-pointer">
                            {ModelData?.images.length ? (
                                <div className="row">
                                    {ModelData.images.map((e, i) => (
                                        <div key={i} className="col-md-4 mb-3">
                                            <a href={url + e} target="_blank" rel="noopener noreferrer">
                                                <img
                                                    src={url + e}
                                                    alt={`Image ${i}`}
                                                    style={{ maxWidth: "100%", height: "auto", cursor: "pointer" }}
                                                />
                                            </a>
                                        </div>
                                    ))}
                                </div>
                            ) : (
                                <span>-</span>
                            )}
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
            <Modal
                size="md"
                show={smShow}
                onHide={() => setSmShow(false)}
                centered
                className="moadl-main delete-modal"
            >
                <Modal.Header
                    className="d-block position-relative border-0"
                    closeButton
                >
                    <Modal.Title className="text-center">Delete Feedback</Modal.Title>
                </Modal.Header>
                <Modal.Body className="mx-4 border-0">
                    <p>Are you sure you want to delete?</p>
                </Modal.Body>
                <Modal.Footer className="border-0">
                    <div className="d-flex m-auto gap-2 pb-3">
                        <button className='secondary-button text-decoration-none'
                            onClick={() => {
                                deleteComplaint(id);
                            }}>
                            Delete
                        </button>
                        <Button
                            className="primary-button"
                            onClick={() => {
                                setSmShow(false);
                            }}
                        >
                            Cancel
                        </Button>
                    </div>
                </Modal.Footer>
            </Modal>

        </>
    )
}

export default FeedBackTable