import React, { useEffect, useState } from 'react'
import { Button, Col, Form, Modal, Row, Table } from 'react-bootstrap';
import TablePagination from '../components/TablePagination';
import { useNavigate } from 'react-router-dom';

import { toast } from 'react-toastify';
import moment from 'moment';

const BroadCastNotificationTable = ({ setLgShow, page, perPage, handleEdit, getBroadCastData, setPage }) => {
    const navigate = useNavigate();
    const [showInfo, setShowInfo] = useState(false);
    const [infoData, setInfoData] = useState();
    const [id, setId] = useState();
    const handleClose = () => setShowInfo(false);

    const handleInfo = (broadCast) => {
        setInfoData(broadCast);
        setId(broadCast?._id);
    }

    const methods = handleNotification(infoData);

    function handleNotification(infoData) {
        if (infoData?.method === "email") {
            return "Email Only";
        } else if (infoData?.method === "inApp") {
            return "In-App Notification";
        } else {
            return "Both";
        }
    }

    return (
        <>
            <Row>
                <Col>
                    <Table bordered responsive="xl">
                        <thead>
                            <tr>
                                <th className='number-width border'>No</th>
                                <th style={{ width: "150px" }}>Type</th>
                                <th style={{ width: "250px" }}>Booth name</th>
                                <th style={{ width: "200px" }}>Method</th>
                                <th style={{ width: "200px" }}>Title</th>
                                <th style={{ width: "500px" }}>Description</th>
                                <th style={{ width: "200px" }}>Schedule date</th>
                                <th>Action</th>
                            </tr>
                        </thead>
                        {Boolean(getBroadCastData?.data?.length) ?
                            <tbody>
                                {getBroadCastData?.data?.length && getBroadCastData?.data?.map((broadCast, index) => {
                                    const currentNumber = (page - 1) * perPage + index + 1;
                                    function handleNotificationMethod(broadCast) {
                                        if (broadCast.method === "email") {
                                            return "Email Only";
                                        } else if (broadCast.method === "inApp") {
                                            return "In-App Notification";
                                        } else {
                                            return "Both";
                                        }
                                    }
                                    const broadCastMethod = handleNotificationMethod(broadCast);
                                    const date = broadCast.scheduleTime ? moment.utc(broadCast?.scheduleTime).format("YYYY-MM-DD, HH:mm") : "-";

                                    return (
                                        <tr>
                                            <td>{currentNumber}</td>
                                            <td className='border-start'>
                                                {broadCast?.type ? broadCast.type.charAt(0).toUpperCase() + broadCast.type.slice(1) + `${broadCast?.type === "all" ? " User" : ""}` : "-"}
                                            </td>
                                            <td>{broadCast?.studioId?.studio_name || "-"}</td>
                                            <td>{broadCastMethod}</td>
                                            <td>{broadCast.title || "-"}</td>
                                            <td>{broadCast.desc || "-"}</td>
                                            <td className='notified'>{date || "-"}</td>
                                            <td>
                                                <div className="d-flex justify-content-center align-items-center gap-3">
                                                    <div title='Info' className='cursor-pointer'>
                                                        <img src="./images/svg/info.svg" alt=""
                                                            onClick={() => {
                                                                setShowInfo(true);
                                                                handleInfo(broadCast);
                                                            }}
                                                        />
                                                    </div>
                                                    <div
                                                        title='Edit'
                                                        className={`d-flex gap-3 cursor-pointer ${broadCast.type !== "booth" || (broadCast.type === "booth" && !broadCast.isSend) ? 'disabled' : ''}`}
                                                    >
                                                        <img
                                                            src="./images/svg/edit.svg"
                                                            alt=""
                                                            onClick={() => {
                                                                if (broadCast.type === "booth") {
                                                                    setLgShow(true);
                                                                    handleEdit(broadCast);
                                                                }
                                                            }}
                                                            style={{
                                                                pointerEvents: broadCast.type !== "booth" || (broadCast.type === "booth" && broadCast.isSend) ? 'none' : 'auto',
                                                                opacity: broadCast.type !== "booth" || (broadCast.type === "booth" && broadCast.isSend) ? 0.5 : 1,
                                                                filter: broadCast.type !== "booth" || (broadCast.type === "booth" && broadCast.isSend) ? 'grayscale(100%)' : 'none'
                                                            }}
                                                        />
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    );
                                })
                                }
                            </tbody>
                            :
                            <tbody>
                                <tr>
                                    <td className="bg-transparent" colSpan={10}>
                                        <div className='nodata-table'>
                                            <h3 className="text-center p-5">Data Not Found</h3>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        }
                    </Table>
                    <div className='d-md-flex justify-content-between align-items-center'>
                        <button className='secondary-button text-decoration-none' onClick={() => navigate("/dashboard")} >
                            <svg
                                stroke="currentColor"
                                fill="currentColor"
                                strokeWidth={0}
                                viewBox="0 0 24 24"
                                height="2em"
                                width="2em"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path d="M12.707 17.293 8.414 13H18v-2H8.414l4.293-4.293-1.414-1.414L4.586 12l6.707 6.707z" />
                            </svg>
                            Back
                        </button>
                        {
                            getBroadCastData?.data && getBroadCastData?.data?.length >= 1 &&
                            <TablePagination
                                currentPage={getBroadCastData?.page}
                                totalPages={getBroadCastData?.pages}
                                onPageChange={(newPage) => {
                                    setPage(newPage);
                                }}
                            />
                        }
                    </div>
                </Col>
            </Row>
            {/* //info */}
            <Modal
                show={showInfo}
                size="xl"
                onHide={handleClose}
                centered
                className='moadl-main info-modal'
            >
                <Modal.Header className='d-block position-relative border-0' closeButton>
                    <Modal.Title className='text-center'>Broadcast</Modal.Title>
                </Modal.Header>
                <Modal.Body className='mx-md-4 border-0 bg-transparent pb-0'>
                    <Form className='mt-4'>
                        <Row>
                            <Col md={12}>
                                <div className="overflow-auto">
                                    <Table striped bordered hover>
                                        <thead>
                                            <tr>
                                                <th className='border-start'>Type</th>
                                                <th>Booth Name</th>
                                                <th>Method</th>
                                                <th>Title</th>
                                                <th>Description</th>
                                                <th>Schedule Date</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td className='border-start'>{infoData?.type ? infoData.type.charAt(0).toUpperCase() + infoData.type.slice(1) : "-"}</td>
                                                <td>{infoData?.studioId?.studio_name || "-"}</td>
                                                <td>{methods || "-"}</td>
                                                <td>{infoData?.title || "-"}</td>
                                                <td>{infoData?.desc || "-"}</td>
                                                <td>
                                                    {infoData?.scheduleTime ? moment.utc(infoData?.scheduleTime).format("YYYY-MM-DD, HH:mm") : "-"}
                                                </td>
                                            </tr>
                                        </tbody>
                                    </Table>
                                </div>
                            </Col>
                        </Row>
                    </Form>
                </Modal.Body>
            </Modal >
        </>
    )
}

export default BroadCastNotificationTable