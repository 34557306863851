import moment from 'moment';
import React from 'react'
import { Col, Row, Table } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import TablePagination from '../components/TablePagination';
import { BiSortAlt2 } from 'react-icons/bi';

const GetInTouchTable = ({ getInTouch,  perPage, setPage, page, getInTouchOrder }) => {
    const navigate = useNavigate();
    return (
        <>
            <Row>
                <Col>
                    <Table bordered responsive="xl">
                        <thead>
                            <tr>
                                <th className='number-width border'>No</th>
                                <th>Name</th>
                                <th>Notification Email</th>
                                <th style={{ width: "800px" }}>Message</th>
                                <th>Create At<BiSortAlt2 className="fs-4 cursor-pointer" onClick={() => getInTouchOrder()}></BiSortAlt2></th>
                            </tr>
                        </thead>
                        {Boolean(getInTouch?.data?.length) ?
                            <tbody>
                                {
                                    getInTouch?.data?.map((element, index) => {
                                        const { name, email, message, created_at } = element;
                                        const currentNumber = (page - 1) * perPage + index + 1;
                                        const utcFormattedDateTime = created_at ? moment(created_at).format("YYYY-MM-DD, HH:mm:ss") : "-";
                                        return (
                                            <tr>
                                                <td>{currentNumber}</td>
                                                <td>{name || "-"}</td>
                                                <td>{email || "-"}</td>
                                                <td className='text-wrap'>{message || "-"}</td>
                                                <td>{utcFormattedDateTime}</td>
                                            </tr>
                                        );
                                    })}
                            </tbody>
                            :
                            <tbody>
                                <tr>
                                    <td colSpan={10}>
                                    <div className='nodata-table'>
                                            <h3 className="text-center p-5">Data Not Found</h3>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        }
                    </Table>
                    <div className='d-md-flex justify-content-between align-items-center'>
                        <button className='secondary-button text-decoration-none' onClick={() => navigate("/dashboard")} >
                            <svg
                                stroke="currentColor"
                                fill="currentColor"
                                strokeWidth={0}
                                viewBox="0 0 24 24"
                                height="2em"
                                width="2em"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path d="M12.707 17.293 8.414 13H18v-2H8.414l4.293-4.293-1.414-1.414L4.586 12l6.707 6.707z" />
                            </svg>
                            Back
                        </button>
                        {
                            getInTouch?.data && getInTouch?.data?.length >= 1 &&
                            <TablePagination
                                currentPage={getInTouch?.page}
                                totalPages={getInTouch?.pages}
                                onPageChange={(newPage) => {
                                    setPage(newPage);
                                }}
                            />
                        }
                    </div>
                </Col>
            </Row>
        </>
    )
}

export default GetInTouchTable
